import React, { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { getLogs } from '../../../../store/thunks/import';
import Loader from '../../../../ui/Loader';

import { Logs } from './Logs';

import styles from '../styles.module.css';

export const Journal = () => {
    const logs = useAppSelector(state => state.imports.logs);
    const logsLoader = useAppSelector(state => state.imports.logsLoader);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getLogs());
    }, []);

    return (
        <div className={styles.journalContainer}>
            <div style={{
                maxHeight: 680,
                overflowY: 'auto'
            }}>
                {!logsLoader ? (
                    <Logs logs={logs}/>
                ) : (
                    <Loader/>
                )}
            </div>
        </div>
    );
};
