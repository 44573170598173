import React, {
    Dispatch,
    FC,
    SetStateAction,
    useEffect,
    useState,
} from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ErrorMessage } from "@hookform/error-message";
import { Autocomplete, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import CatalogItemActionsComponent from "../../features/Catalogs/CatalogItemActionsComponent";
import ConfirmationModal from "../../features/ConfirmationModal";
import FormButtons from "../../features/FormButtons";
import { useAppDispatch, useAppSelector, useSetServerError } from "../../hooks";
import { countries } from "../../models/countries";
import { IFormInput, IUserPostArgs } from "../../models/user";
import { createUser, getUsers } from "../../store";
import { autocompleteStyles } from "../../styles/MUIStyles";
import { StyledSelect, StyledSelectError } from "../../ui/Select";
import { sendNotification } from "../../ui/Toast";

import styles from "../Catalogs/CatalogsLayout.module.scss";

const CreateUserForm: FC<{
    createOrUpdate: string | null;
    setIsDrawerOpen: Dispatch<SetStateAction<boolean>>;
    isDrawerOpen: boolean;
    setCreateOrUpdate: Dispatch<SetStateAction<string | null>>;
    debouncedSearchUserName: string;
    debouncedSearchUserEmail: string;
    pageNumber: number;
    pageSize: number;
    userStatus: string;
}> = ({
    createOrUpdate,
    setIsDrawerOpen,
    isDrawerOpen,
    setCreateOrUpdate,
    debouncedSearchUserName,
    debouncedSearchUserEmail,
    pageNumber,
    pageSize,
    userStatus,
}): JSX.Element => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();
    const currentLanguage = localStorage.getItem("i18nextLng");

    const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);

    const countries = useAppSelector((state) => state.catalogs.countries);
    const userRoles = useAppSelector((state) => state.users.roles);
    const userErrors = useAppSelector((state) => state.users.userErrors);

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        setError,
        formState: { errors },
        clearErrors,
        control,
        watch,
    } = useForm<IUserPostArgs>();

    const hasEmailError = errors?.email;
    const hasFirstNameError = !!errors?.firstName;
    const hasMiddleNameError = !!errors?.middleName;
    const hasLastNameError = !!errors?.lastName;
    const hasPasswordError = !!errors?.password;
    const hasConfirmPasswordError = !!errors?.confirmPassword;
    const hasPhoneNumberError = !!errors?.phoneNumber;

    const onSubmit: SubmitHandler<any> = async (formData: any) => {
        const response = await dispatch(
            createUser({
                email: formData.email,
                firstName: formData.firstName,
                middleName: formData.middleName,
                lastName: formData.lastName,
                password: formData.password,
                roleName: formData.roleName,
                phoneNumber: formData.phoneNumber,
                countryId: formData.countryId,
            }),
        );

        if (response.meta.requestStatus === "fulfilled") {
            sendNotification(t("notifications.successfullyCreated"), "success");
            await dispatch(
                getUsers({
                    userName: debouncedSearchUserName,
                    email: debouncedSearchUserEmail,
                    pageNumber,
                    userStatus,
                    pageSize,
                }),
            );
            setIsDrawerOpen(false);
        }
        return;
    };

    const setFormData = (
        fields: IFormInput | null,
        operation: string | null,
        state: string | null,
    ) => {
        // Object.entries(fields).forEach(([key, value]) => form.setValue(key, value)

        setValue("email", fields?.email || "");
        setValue("firstName", fields?.firstName || "");
        setValue("middleName", fields?.middleName || "");
        setValue("lastName", fields?.lastName || "");
        setValue("password", fields?.password || "");
        setValue("roleName", fields?.roleName || "");
        setValue("phoneNumber", fields?.phoneNumber || "");
        setValue("countryId", fields?.countryId || "");
        setValue("countryId", fields?.countryId || "");
        state && setIsDrawerOpen((prevState) => !prevState);
    };

    const formClose = () => {
        if (formChangesChecking()) setConfirmationModalOpen(true);
        else closeFormDrawer();
    };

    const closeFormDrawer = () => {
        setCreateOrUpdate(null);
        setIsDrawerOpen(false);
    };

    const closeModalConfirm = () => {
        setFormData(null, null, null);
        setIsDrawerOpen(false);
        setConfirmationModalOpen(false);
    };

    // returns true, if form was updated
    const formChangesChecking = () => {
        return Boolean(
            getValues("email") ||
                getValues("firstName") ||
                getValues("middleName") ||
                getValues("lastName") ||
                getValues("password") ||
                getValues("roleName") ||
                getValues("phoneNumber") ||
                getValues("countryId"),
        );
    };

    useEffect(() => {
        setFormData(null, null, null);
    }, [dispatch, t]);

    useSetServerError({
        serverError: userErrors,
        setError,
        clearErrors,
        errorNames: [
            "email",
            "firstName",
            "middleName",
            "lastName",
            "password",
            "roleName",
            "phoneNumber",
            "countryId",
        ],
    });

    return (
        <>
            <CatalogItemActionsComponent
                name="users"
                isDrawerOpen={isDrawerOpen}
                createOrUpdate={createOrUpdate}
                closeFormDrawer={formClose}
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={styles.formInputWrapper}>
                        <label
                            htmlFor="email"
                            className={styles.drawerTopSubTitle}
                        >
                            {t("data.email")}
                        </label>
                        <div>
                            <input
                                {...register("email", {
                                    required: t(
                                        "validation.requiredField",
                                    ) as string,
                                    maxLength: {
                                        value: 100,
                                        message: t("validation.maxLength100"),
                                    },
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i,
                                        message: t(
                                            "validation.invalidEmail",
                                        ) as string,
                                    },
                                })}
                                id="email"
                                type="text"
                                autoComplete="off"
                                placeholder={t("data.email") as string}
                                className={
                                    hasEmailError
                                        ? styles.catalogsDrawerInputError
                                        : styles.catalogsDrawerInput
                                }
                            />
                            <ErrorMessage
                                name="email"
                                errors={errors}
                                render={({ message }) => <span>{message}</span>}
                            />
                        </div>
                        <label
                            htmlFor="firstName"
                            className={styles.drawerTopSubTitle}
                        >
                            {t("data.firstName")}
                        </label>
                        <div>
                            <input
                                {...register("firstName", {
                                    required: t(
                                        "validation.requiredField",
                                    ) as string,
                                    maxLength: {
                                        value: 50,
                                        message: t("validation.maxLength50"),
                                    },
                                    pattern: {
                                        value: /^[А-ЯЁA-Z]+$/i,
                                        message: t(
                                            "validation.onlyLetters",
                                        ) as string,
                                    },
                                })}
                                id="firstName"
                                type="text"
                                autoComplete="off"
                                placeholder={t("data.firstName") as string}
                                className={
                                    hasFirstNameError
                                        ? styles.catalogsDrawerInputError
                                        : styles.catalogsDrawerInput
                                }
                            />
                            <ErrorMessage
                                name="firstName"
                                errors={errors}
                                render={({ message }) => <span>{message}</span>}
                            />
                        </div>
                        <label
                            htmlFor="middleName"
                            className={styles.drawerTopSubTitle}
                        >
                            {t("data.middleName")}
                        </label>
                        <div>
                            <input
                                {...register("middleName", {
                                    maxLength: {
                                        value: 50,
                                        message: t("validation.maxLength50"),
                                    },
                                    pattern: {
                                        value: /^[А-ЯЁA-Z]+$/i,
                                        message: t(
                                            "validation.onlyLetters",
                                        ) as string,
                                    },
                                })}
                                id="middleName"
                                type="text"
                                autoComplete="off"
                                placeholder={t("data.middleName") as string}
                                className={
                                    hasMiddleNameError
                                        ? styles.catalogsDrawerInputError
                                        : styles.catalogsDrawerInput
                                }
                            />
                            <ErrorMessage
                                name="middleName"
                                errors={errors}
                                render={({ message }) => <span>{message}</span>}
                            />
                        </div>
                        <label
                            htmlFor="lastName"
                            className={styles.drawerTopSubTitle}
                        >
                            {t("data.lastName")}
                        </label>
                        <div>
                            <input
                                {...register("lastName", {
                                    required: t(
                                        "validation.requiredField",
                                    ) as string,
                                    maxLength: {
                                        value: 50,
                                        message: t("validation.maxLength50"),
                                    },
                                    pattern: {
                                        value: /^[А-ЯЁA-Z]+$/i,
                                        message: t(
                                            "validation.onlyLetters",
                                        ) as string,
                                    },
                                })}
                                id="lastName"
                                type="text"
                                autoComplete="off"
                                placeholder={t("data.lastName") as string}
                                className={
                                    hasLastNameError
                                        ? styles.catalogsDrawerInputError
                                        : styles.catalogsDrawerInput
                                }
                            />
                            <ErrorMessage
                                name="lastName"
                                errors={errors}
                                render={({ message }) => <span>{message}</span>}
                            />
                        </div>
                        <label
                            htmlFor="password"
                            className={styles.drawerTopSubTitle}
                        >
                            {t("data.password")}
                        </label>
                        <div>
                            <input
                                {...register("password", {
                                    required: t(
                                        "validation.requiredField",
                                    ) as string,
                                    maxLength: {
                                        value: 50,
                                        message: t("validation.maxLength50"),
                                    },
                                    validate: (value) => {
                                        if (
                                            watch("confirmPassword") !== value
                                        ) {
                                            return t(
                                                "validation.passwordsDoNotMatch",
                                            ) as string;
                                        } else {
                                            clearErrors("confirmPassword");
                                        }
                                    },
                                })}
                                id="password"
                                type="password"
                                autoComplete="new-password"
                                placeholder={t("data.password") as string}
                                className={
                                    hasPasswordError
                                        ? styles.catalogsDrawerInputError
                                        : styles.catalogsDrawerInput
                                }
                            />
                            <ErrorMessage
                                name="password"
                                errors={errors}
                                render={({ message }) => <span>{message}</span>}
                            />
                        </div>
                        <label
                            htmlFor="confirmPassword"
                            className={styles.drawerTopSubTitle}
                        >
                            {t("data.confirmPassword")}
                        </label>
                        <div>
                            <input
                                {...register("confirmPassword", {
                                    required: t(
                                        "validation.requiredField",
                                    ) as string,
                                    maxLength: {
                                        value: 50,
                                        message: t("validation.maxLength50"),
                                    },
                                    validate: (value) => {
                                        if (watch("password") !== value) {
                                            return t(
                                                "validation.passwordsDoNotMatch",
                                            ) as string;
                                        } else {
                                            clearErrors("password");
                                        }
                                    },
                                })}
                                id="confirmPassword"
                                type="password"
                                autoComplete="new-password"
                                aria-autocomplete="none"
                                autoCorrect="none"
                                placeholder={
                                    t("data.confirmPassword") as string
                                }
                                className={
                                    hasConfirmPasswordError
                                        ? styles.catalogsDrawerInputError
                                        : styles.catalogsDrawerInput
                                }
                            />
                            <ErrorMessage
                                name="confirmPassword"
                                errors={errors}
                                render={({ message }) => <span>{message}</span>}
                            />
                        </div>
                        <label
                            htmlFor="phoneNumber"
                            className={styles.drawerTopSubTitle}
                        >
                            {t("data.phoneNumber")}
                        </label>
                        <div>
                            <input
                                {...register("phoneNumber", {
                                    required: t(
                                        "validation.requiredField",
                                    ) as string,
                                    maxLength: {
                                        value: 50,
                                        message: t("validation.maxLength50"),
                                    },
                                })}
                                id="phoneNumber"
                                type="text"
                                autoComplete="off"
                                placeholder={t("data.phoneNumber") as string}
                                className={
                                    hasPhoneNumberError
                                        ? styles.catalogsDrawerInputError
                                        : styles.catalogsDrawerInput
                                }
                            />
                            <ErrorMessage
                                name="phoneNumber"
                                errors={errors}
                                render={({ message }) => <span>{message}</span>}
                            />
                        </div>
                        <FormControl fullWidth sx={{ marginBottom: "1rem" }}>
                            <span className={styles.drawerTopSubTitle}>
                                {t("data.country")}
                            </span>
                            <Controller
                                name="countryId"
                                defaultValue=""
                                control={control}
                                rules={{
                                    required:
                                        createOrUpdate === "create"
                                            ? (t(
                                                  "validation.requiredField",
                                              ) as string)
                                            : false,
                                }}
                                render={({ field, fieldState }) => (
                                    <Autocomplete
                                        {...field}
                                        ListboxProps={{
                                            style: { maxHeight: "200px" },
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                size="small"
                                                sx={autocompleteStyles}
                                                placeholder={
                                                    t("data.country") || ""
                                                }
                                                error={!!fieldState.error}
                                            />
                                        )}
                                        options={[...countries].sort(
                                            (a: countries, b: countries) => {
                                                return a.name.localeCompare(
                                                    b.name,
                                                    currentLanguage === "en-US"
                                                        ? "en"
                                                        : "ru",
                                                );
                                            },
                                        )}
                                        getOptionLabel={(option) => option.name}
                                        onChange={(
                                            event,
                                            value: countries | null,
                                        ) => field.onChange(value?.id)}
                                        value={
                                            countries.filter(
                                                (el) =>
                                                    el?.id ===
                                                    getValues("countryId"),
                                            )[0] || null
                                        }
                                    />
                                )}
                            />
                            <ErrorMessage
                                name="countryId"
                                errors={errors}
                                render={({ message }) => (
                                    <span className={styles.selectErrorMsg}>
                                        {message}
                                    </span>
                                )}
                            />
                        </FormControl>
                        <FormControl fullWidth sx={{ marginBottom: "1rem" }}>
                            <span className={styles.drawerTopSubTitle}>
                                {t("data.roleName")}
                            </span>
                            <Controller
                                name="roleName"
                                defaultValue=""
                                control={control}
                                rules={{
                                    required:
                                        createOrUpdate === "create"
                                            ? (t(
                                                  "validation.requiredField",
                                              ) as string)
                                            : false,
                                }}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        input={
                                            errors?.roleName ? (
                                                <StyledSelectError />
                                            ) : (
                                                <StyledSelect />
                                            )
                                        }
                                    >
                                        {userRoles.map((item) => (
                                            <MenuItem
                                                key={item.roleName}
                                                value={item.roleName}
                                            >
                                                {t(`roles.${item.roleName}`)}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            />
                            <ErrorMessage
                                name="roleName"
                                errors={errors}
                                render={({ message }) => (
                                    <span className={styles.selectErrorMsg}>
                                        {message}
                                    </span>
                                )}
                            />
                        </FormControl>
                    </div>
                    <FormButtons
                        isLoading={false}
                        createOrUpdate={createOrUpdate}
                        cancelHandler={() => formClose()}
                    />
                </form>
            </CatalogItemActionsComponent>
            <ConfirmationModal
                isModalOpen={isConfirmationModalOpen}
                setIsModalOpen={setConfirmationModalOpen}
                actionName={"Close"}
                instanceName=""
                message={"closeFormWithoutSaving"}
                handleAction={() => closeModalConfirm()}
            />
        </>
    );
};

export default CreateUserForm;
