import React, { FC } from "react";
import viewIcon from "assets/icons/view.svg";
import userLogo from "assets/logos/user.png";
import dayjs from "dayjs";
import { useAppSelector } from "hooks";
import { ISortConfig } from "models/user";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import {
    materialReactTableCell,
    materialReactTableHeader,
} from "styles/MUIStyles";
import BootstrapTooltip from "ui/Tooltip";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Box } from "@mui/material";
import Avatar from "@mui/material/Avatar";

import { getFromLocalStorage } from "../../../core/utils";
import AgGrid from "../../../features/AgGrid/AgGrid";
import { setActiveSalary } from "../../../store";

interface IProps {
    sortConfig: ISortConfig;
    handleColumnClick: (column: string) => void;
    search: string;
    setSearch: (search: string) => void;
}

const SalariesTable: FC<IProps> = ({ sortConfig, handleColumnClick }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const salaries = useAppSelector((state) => state.salaries.salaries);
    const activeSalary = useAppSelector((state) => state.salaries.activeSalary);
    const allowedMenus = useAppSelector((state) => state.auth.allowedMenus);

    // const isGettingSalariesLoading = useAppSelector(
    //     (state) => state.salaries.isGettingSalariesLoading
    // );

    const getArrowForSortDirection = (column: string) => {
        if (sortConfig.column !== column) {
            return null;
        }

        return sortConfig.direction === "asc" ? (
            <ArrowUpwardIcon sx={{ color: "#605E5C" }} />
        ) : (
            <ArrowDownwardIcon sx={{ color: "#605E5C" }} />
        );
    };

    const HeadComponent = (p: any) => {
        switch (p.column.colId) {
            case "customActions":
                return (
                    <Box sx={materialReactTableHeader}>
                        <span>{t("data.actions")}</span>
                    </Box>
                );
            case "avatar":
                return (
                    <Box sx={materialReactTableHeader}>
                        <span>{t("data.photo")}</span>
                    </Box>
                );
            case "personnelNumber":
                return (
                    <Box
                        sx={materialReactTableHeader}
                        onClick={() => handleColumnClick("personnelNumber")}
                    >
                        <span>{t("data.tabNo")}</span>
                        {getArrowForSortDirection("personnelNumber")}
                    </Box>
                );
            case "fullName":
                return (
                    <Box
                        sx={materialReactTableHeader}
                        onClick={() => handleColumnClick("fullName")}
                    >
                        <span>
                            {t("salaries.Name") + " / " + t("salaries.Post")}
                        </span>
                        {getArrowForSortDirection("fullName")}
                    </Box>
                );
            case "skill":
                return (
                    <Box
                        sx={materialReactTableHeader}
                        onClick={() => handleColumnClick("skillLevel")}
                    >
                        <span>
                            {t("salaries.Skill") + " / " + t("salaries.Level")}
                        </span>
                        {getArrowForSortDirection("skillLevel")}
                    </Box>
                );
            case "organization":
                return (
                    <Box
                        sx={materialReactTableHeader}
                        onClick={() => handleColumnClick("organization")}
                    >
                        <span>
                            {t("salaries.Vendor") +
                                " / " +
                                t("salaries.Company")}
                        </span>
                        {getArrowForSortDirection("organization")}
                    </Box>
                );
            case "costCenter":
                return (
                    <Box
                        sx={materialReactTableHeader}
                        onClick={() => handleColumnClick("costCenter")}
                    >
                        <span>{t("salaries.CostCenter")}</span>
                        {getArrowForSortDirection("costCenter")}
                    </Box>
                );
            case "typeOfEmployment":
                return (
                    <Box
                        sx={materialReactTableHeader}
                        onClick={() => handleColumnClick("typeOfEmployment")}
                    >
                        <span>{t("salaries.Type")}</span>
                        {getArrowForSortDirection("typeOfEmployment")}
                    </Box>
                );
            case "fromDate":
                return (
                    <Box sx={materialReactTableHeader}>
                        <span>{t("salaries.FromDate")}</span>
                    </Box>
                );
            case "rateMonth":
                return (
                    <Box sx={materialReactTableHeader}>
                        <span>{t("salaries.RateMonth")}</span>
                    </Box>
                );
            case "rateWeek":
                return (
                    <Box sx={materialReactTableHeader}>
                        <span>{t("salaries.RateWeek")}</span>
                    </Box>
                );
            case "rateDay":
                return (
                    <Box sx={materialReactTableHeader}>
                        <span>{t("salaries.RateDay")}</span>
                    </Box>
                );
            case "rateHour":
                return (
                    <Box sx={materialReactTableHeader}>
                        <span>{t("salaries.RateHour")}</span>
                    </Box>
                );
            case "userStatus":
                return (
                    <Box sx={materialReactTableHeader}>
                        <span>{t("data.status")}</span>
                    </Box>
                );
        }
    };

    const RowComponent = (p: any) => {
        switch (p.colDef.field) {
            case "customActions":
                return (
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            height: "100%",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            "& img": {
                                cursor: "pointer",
                            },
                        }}
                    >
                        <BootstrapTooltip
                            sx={{ margin: "auto 0" }}
                            title={t("buttons.View")}
                            onClick={() => navigate(`${p.data?.employeeId}`)}
                            placement="top"
                        >
                            <img src={viewIcon} alt="view icon" />
                        </BootstrapTooltip>
                    </Box>
                );
            case "avatar":
                return (
                    <Box sx={materialReactTableCell}>
                        <Avatar
                            alt="Employee's photo"
                            src={
                                p.data?.avatar
                                    ? process.env.REACT_APP_STATIC_FILES_URL +
                                      p.data?.avatar
                                    : userLogo
                            }
                            sx={{ width: 35, height: 35 }}
                        />
                    </Box>
                );
            case "personnelNumber":
                return (
                    <Box sx={materialReactTableCell}>
                        {p.data?.personnelNumber}
                    </Box>
                );
            case "fullName":
                return (
                    <Box sx={materialReactTableCell}>
                        <span>{p.data?.fullName}</span>
                        <span>{p.data?.position}</span>
                    </Box>
                );
            case "skill":
                return (
                    <Box sx={materialReactTableCell}>
                        <span>{p.data?.skill}</span>
                        <span>{p.data?.skillLevel}</span>
                    </Box>
                );
            case "organization":
                return (
                    <Box
                        sx={materialReactTableCell}
                        onClick={() =>
                            localStorage.setItem(
                                "redirectOrganizationId",
                                p.data?.organizationId,
                            )
                        }
                    >
                        {allowedMenus.some(
                            (menu) => menu.name === "Organizations",
                        ) ? (
                            <Link to="/organizations">
                                {p.data?.organization}
                            </Link>
                        ) : (
                            p.data?.organization
                        )}
                    </Box>
                );
            case "costCenter":
                return (
                    <Box sx={materialReactTableCell}>{p.data?.costCenter}</Box>
                );
            case "typeOfEmployment":
                return (
                    <Box sx={materialReactTableCell}>
                        {p.data?.typeOfEmployment}
                    </Box>
                );
            case "fromDate":
                return (
                    <Box sx={materialReactTableCell}>
                        {p.data?.salaries.map((salary: any) => (
                            <span key={salary.id}>
                                {salary.fromDate
                                    ? dayjs(salary.fromDate).format(
                                          "DD.MM.YYYY",
                                      )
                                    : null}
                            </span>
                        ))}
                    </Box>
                );
            case "rateMonth":
                return (
                    <Box
                        sx={{
                            ...materialReactTableCell,
                            width: "100%",
                        }}
                    >
                        {p.data?.salaries.map((salary: any) => (
                            <Box
                                key={salary.id}
                                sx={{
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "1.5rem",
                                    "& span:last-of-type": {
                                        fontWeight: 600,
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                    },
                                }}
                            >
                                <span>
                                    {salary.rateMonth ? salary.currency : null}
                                </span>
                                <span>
                                    {salary.rateMonth ? salary.rateMonth : null}
                                </span>
                            </Box>
                        ))}
                    </Box>
                );
            case "rateWeek":
                return (
                    <Box
                        sx={{
                            ...materialReactTableCell,
                            width: "100%",
                        }}
                    >
                        {p.data?.salaries.map((salary: any) => (
                            <Box
                                key={salary.id}
                                sx={{
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "1.5rem",
                                    "& span:last-of-type": {
                                        fontWeight: 600,
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                    },
                                }}
                            >
                                <span>
                                    {salary.rateWeek ? salary.currency : null}
                                </span>
                                <span>
                                    {salary.rateWeek ? salary.rateWeek : null}
                                </span>
                            </Box>
                        ))}
                    </Box>
                );
            case "rateDay":
                return (
                    <Box
                        sx={{
                            ...materialReactTableCell,
                            width: "100%",
                        }}
                    >
                        {p.data?.salaries.map((salary: any) => (
                            <Box
                                key={salary.id}
                                sx={{
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "1.5rem",
                                    "& span:last-of-type": {
                                        fontWeight: 600,
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                    },
                                }}
                            >
                                <span>
                                    {salary.rateDay ? salary.currency : null}
                                </span>
                                <span>
                                    {salary.rateDay ? salary.rateDay : null}
                                </span>
                            </Box>
                        ))}
                    </Box>
                );
            case "rateHour":
                return (
                    <Box
                        sx={{
                            ...materialReactTableCell,
                            width: "100%",
                        }}
                    >
                        {p.data?.salaries.map((salary: any) => (
                            <Box
                                key={salary.id}
                                sx={{
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "1rem",
                                    "& span:last-of-type": {
                                        fontWeight: 600,
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                    },
                                }}
                            >
                                <span>
                                    {salary.rateHour ? salary.currency : null}
                                </span>
                                <span>
                                    {salary.rateHour ? salary.rateHour : null}
                                </span>
                            </Box>
                        ))}
                    </Box>
                );
            case "userStatus":
                return (
                    <Box
                        sx={{
                            ...materialReactTableCell,
                            fontWeight: "600",
                            color:
                                p.data?.userStatus === "Active"
                                    ? "#237657"
                                    : "#FA0E00",
                        }}
                    >
                        {p.data?.userStatus === "Active"
                            ? t("data.isActive")
                            : t("data.notActive")}
                    </Box>
                );
        }
    };

    const generateColumnDefs = () => {
        const savedColumnState = getFromLocalStorage("agGridSalariesTable");

        let updatedColumnDefs: any[] = [
            {
                field: "customActions",
                resizable: true,
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 110,
            },
            {
                field: "avatar",
                resizable: true,
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 80,
            },
            {
                field: "personnelNumber",
                resizable: true,
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 80,
            },
            {
                field: "fullName",
                resizable: true,
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 160,
            },
            {
                field: "skill",
                resizable: true,
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 220,
            },
            {
                field: "organization",
                resizable: true,
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 170,
            },
            {
                field: "costCenter",
                resizable: true,
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 150,
            },
            {
                field: "typeOfEmployment",
                resizable: true,
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 110,
            },
            {
                field: "fromDate",
                resizable: true,
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 100,
            },
            {
                field: "rateMonth",
                resizable: true,
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 140,
            },
            {
                field: "rateWeek",
                resizable: true,
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 150,
            },
            {
                field: "rateDay",
                resizable: true,
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 130,
            },
            {
                field: "rateHour",
                resizable: true,
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 130,
            },
            {
                field: "userStatus",
                resizable: true,
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 110,
            },
        ];

        if (Array.isArray(savedColumnState)) {
            updatedColumnDefs = savedColumnState.map((columnState: any) => {
                return {
                    field: columnState.colId,
                    width: columnState.width,
                    pinned: columnState.pinned,
                    headerComponent: HeadComponent,
                    cellRenderer: RowComponent,
                    resizable: true,
                };
            });
        }

        return updatedColumnDefs;
    };

    const saveColumnState = (columnState: any) => {
        localStorage.setItem(
            "agGridSalariesTable",
            JSON.stringify(columnState),
        );
    };

    const columnDefs: any[] = generateColumnDefs();

    return (
        <AgGrid
            rowData={salaries}
            columnDefs={columnDefs}
            saveColumnState={saveColumnState}
            activeRow={activeSalary}
            setActiveRow={setActiveSalary}
            height={"65vh"}
            page="salaries"
        />
    );
};

export default SalariesTable;
