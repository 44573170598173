import axios from "axios";

import { createAsyncThunk } from "@reduxjs/toolkit";

import axiosApi from "../../core/request";
import { ICountryArgs, ICountryErrResponse } from "../../models/countries";

export const getCountries = createAsyncThunk('catalogs/getCountries',
    async (countryEndpointPath: string, { rejectWithValue }) => {
        try {
            const response = await axiosApi.get(`/${countryEndpointPath}`);
            return response.data;
        } catch (error) {
            //TODO
            return rejectWithValue('Error that i need to handle');
        }
    }
);

export const createCountry = createAsyncThunk<any, ICountryArgs, {rejectValue: ICountryErrResponse | string}>('catalogs/createCountry',
    async (country, { rejectWithValue }) => {
        try {
            const formattedCountry = { ...country };
            delete formattedCountry.countryEndpointName;
            delete formattedCountry.id;
            const response = await axiosApi.post(`/${country.countryEndpointName}`, { ...formattedCountry });
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                if (error.response.status === 400) {
                    return rejectWithValue(error.response.data.errors);
                } else {
                    return rejectWithValue('Что-то пошло не так... Попробуйте обновить страницу');
                }
            }

            if (!navigator.onLine) {
                return rejectWithValue('Проверьте подключение к интернету');
            }

            return rejectWithValue('Что-то пошло не так... Попробуйте обновить страницу');
        }
    }
);

export const editCountry = createAsyncThunk<any, ICountryArgs, {rejectValue: ICountryErrResponse | string}>('catalogs/editCountry',
    async (country, { rejectWithValue }) => {
        try {
            const formattedCountry = { ...country };
            delete formattedCountry.countryEndpointName;
            const response = await axiosApi.put(`/${country.countryEndpointName}`, { ...formattedCountry });
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                if (error.response.status === 400) {
                    return rejectWithValue(error.response.data.errors);
                } else {
                    return rejectWithValue('Что-то пошло не так... Попробуйте обновить страницу');
                }
            }

            if (!navigator.onLine) {
                return rejectWithValue('Проверьте подключение к интернету');
            }

            return rejectWithValue('Что-то пошло не так... Попробуйте обновить страницу');
        }
    }
);
