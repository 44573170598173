import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ErrorMessage } from '@hookform/error-message';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import CatalogItemActionsComponent from '../../../features/Catalogs/CatalogItemActionsComponent';
import ConfirmationModal from '../../../features/ConfirmationModal';
import FormButtons from '../../../features/FormButtons';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import useSetServerError from '../../../hooks/useSetServerError';
import { IGrades } from '../../../models/grades';
import { clearCatalogTextFieldErrors, createGrade, editGrade } from '../../../store';
import { StyledSelect, StyledSelectError } from '../../../ui/Select';

import { IFormInput } from './Grades';

import styles from '../CatalogsLayout.module.scss';

export const GradeForm: FC<{
    name: string;
    activeCatalogItem: IGrades | null;
    setActiveCatalogItem: (catalogItem: IGrades | null) => void;
    createOrUpdate: string | null;
    isDrawerOpen: boolean;
    isGroupChecked: boolean;
    setCreateOrUpdate: Function;
    setIsDrawerOpen: Dispatch<SetStateAction<boolean>>;
    fulfilledStatusHandler: Function;
    setIsGroupChecked: Dispatch<SetStateAction<boolean>>;
}> = ({
          name,
          activeCatalogItem,
          setActiveCatalogItem,
          createOrUpdate,
          isDrawerOpen,
          isGroupChecked,
          setCreateOrUpdate,
          setIsDrawerOpen,
          fulfilledStatusHandler,
          setIsGroupChecked,
      }): JSX.Element => {
    const dispatch = useAppDispatch();
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors },
        setError,
        control,
        clearErrors,
    } = useForm<IFormInput>();
    const { t } = useTranslation();

    const grades = useAppSelector((state) => state.catalogs.grades);
    const gradeErrors = useAppSelector((state) => state.catalogs.gradeErrors);
    const isCreatingOrUpdatingLoading = useAppSelector(
        (state) => state.catalogs.isCreatingOrUpdatingLoading,
    );

    const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);

    const onSubmit: SubmitHandler<IFormInput> = async (
        formData: IFormInput,
    ) => {
        if (createOrUpdate === 'create') {
            const body = {
                name: formData.name,
                description: formData.description,
                gradeLevel: formData.gradeLevel as number,
                gradeValue: formData.gradeValue as number,
                gradesEndpointName: name,
                parentId: formData.parentId || null,
                isGroup: isGroupChecked,
            };

            const response = await dispatch(createGrade(body));

            if (response.meta.requestStatus === 'fulfilled') {
                fulfilledStatusHandler('notifications.successfullyCreated');
                setIsDrawerOpen(false);
            }

            return;
        }

        if (activeCatalogItem && createOrUpdate === 'update') {
            const body = {
                id: activeCatalogItem?.id,
                parentId: formData.parentId || null,
                name: formData.name,
                description: formData.description,
                gradeLevel: formData.gradeLevel as number,
                gradeValue: formData.gradeValue as number,
                isGroup: isGroupChecked,
                gradesEndpointName: name,
            };

            const response = await dispatch(editGrade(body));

            if (response.meta.requestStatus === 'fulfilled') {
                fulfilledStatusHandler('notifications.successfullySaved');
                setActiveCatalogItem(null);
                setIsDrawerOpen(false);
            }
        }
    };

    const setFormData = (fields: IFormInput | null) => {
        clearErrors();
        setValue('name', fields?.name || '');
        setValue('description', fields?.description || '');
        setValue('gradeValue', fields?.gradeValue || '');
        setValue('gradeLevel', fields?.gradeLevel || '');
        setValue('parentId', fields?.parentId || '');
        fields && setIsGroupChecked(fields.isGroup);
    };

    const handleIsGroupChecked = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setIsGroupChecked(event.target.checked);
        if (event.target.checked) {
            setValue('parentId', '');
            clearErrors('parentId');
        } else if (activeCatalogItem?.parentId) {
            setValue('parentId', activeCatalogItem.parentId);
        }
    };

    const closeFormDrawer = () => {
        setCreateOrUpdate(null);
        setIsDrawerOpen(false);
    };

    const formClose = () => {
        if (formChangesChecking()) setConfirmationModalOpen(true);
        else closeFormDrawer();
    };

    const closeModalConfirm = () => {
        setFormData(null);
        setIsDrawerOpen(false);
        setConfirmationModalOpen(false);
    };

    // returns true, if form was updated
    const formChangesChecking = () => {
        if (createOrUpdate === 'create') {
            return Boolean(
                getValues('name') ||
                getValues('description') ||
                getValues('gradeValue') ||
                getValues('gradeLevel') ||
                getValues('parentId'),
            );
        } else {
            return Boolean(
                getValues('name') !== activeCatalogItem?.name ||
                getValues('description') !==
                activeCatalogItem?.description ||
                getValues('gradeValue') !== activeCatalogItem?.gradeValue ||
                getValues('gradeLevel') !== activeCatalogItem?.gradeLevel ||
                getValues('parentId') !== activeCatalogItem?.parentId,
            );
        }
    };

    useSetServerError({
        serverError: gradeErrors,
        setError,
        clearErrors,
        errorNames: ['name', 'gradeLevel'],
    });

    useEffect(() => {
        if (isDrawerOpen) {
            setFormData(activeCatalogItem);
        } else {
            clearErrors();
            dispatch(clearCatalogTextFieldErrors());
        }
    }, [dispatch, isDrawerOpen]);

    return (
        <>
            <CatalogItemActionsComponent
                name={name}
                isDrawerOpen={isDrawerOpen}
                createOrUpdate={createOrUpdate}
                closeFormDrawer={formClose}
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={styles.formInputWrapper}>
                        <label
                            htmlFor="name"
                            className={styles.drawerTopSubTitle}
                        >
                            {t('data.name')}
                        </label>
                        <div>
                            <input
                                {...register('name', {
                                    required: t(
                                        'validation.requiredField',
                                    ) as string,
                                    maxLength: {
                                        value: 30,
                                        message: t('validation.maxLength30'),
                                    },
                                })}
                                id="name"
                                type="text"
                                autoComplete="off"
                                placeholder={t('data.name') as string}
                                className={
                                    errors?.name
                                        ? styles.catalogsDrawerInputError
                                        : styles.catalogsDrawerInput
                                }
                            />
                            <ErrorMessage
                                name="name"
                                errors={errors}
                                render={({ message }) => <span>{message}</span>}
                            />
                        </div>
                        <label
                            htmlFor="description"
                            className={styles.drawerTopSubTitle}
                        >
                            {t('data.description')}
                        </label>
                        <div>
                            <input
                                {...register('description', {
                                    maxLength: {
                                        value: 150,
                                        message: t('validation.maxLength150'),
                                    },
                                })}
                                id="description"
                                type="text"
                                autoComplete="off"
                                placeholder={t('data.description') as string}
                                className={
                                    errors?.description
                                        ? styles.catalogsDrawerInputError
                                        : styles.catalogsDrawerInput
                                }
                            />
                            <ErrorMessage
                                name="description"
                                errors={errors}
                                render={({ message }) => <span>{message}</span>}
                            />
                        </div>
                        <label
                            htmlFor="gradeLevel"
                            className={styles.drawerTopSubTitle}
                        >
                            {t('data.level')}
                        </label>
                        <div>
                            <input
                                {...register('gradeLevel', {
                                    required: t(
                                        'validation.requiredField',
                                    ) as string,
                                    pattern: {
                                        value: /^[0-9]+$/,
                                        message: t('validation.onlyDigits'),
                                    },
                                })}
                                id="gradeLevel"
                                type="number"
                                autoComplete="off"
                                placeholder={t('data.level') as string}
                                className={
                                    errors?.gradeLevel
                                        ? styles.catalogsDrawerInputError
                                        : styles.catalogsDrawerInput
                                }
                            />
                            <ErrorMessage
                                name="gradeLevel"
                                errors={errors}
                                render={({ message }) => <span>{message}</span>}
                            />
                        </div>
                        <label
                            htmlFor="gradeValue"
                            className={styles.drawerTopSubTitle}
                        >
                            {t('data.value')}
                        </label>
                        <div>
                            <input
                                {...register('gradeValue', {
                                    required: t(
                                        'validation.requiredField',
                                    ) as string,
                                    pattern: {
                                        value: /^[0-9]*\.?[0-9]*$/,
                                        message: t(
                                            'validation.bothTypesOfDigits',
                                        ),
                                    },
                                })}
                                id="gradeValue"
                                type="text"
                                autoComplete="off"
                                placeholder={t('data.value') as string}
                                className={
                                    errors?.gradeValue
                                        ? styles.catalogsDrawerInputError
                                        : styles.catalogsDrawerInput
                                }
                            />
                            <ErrorMessage
                                name="gradeValue"
                                errors={errors}
                                render={({ message }) => <span>{message}</span>}
                            />
                        </div>
                        <FormGroup sx={{ display: 'inline-block', mb: '5px' }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isGroupChecked}
                                        onChange={(event) =>
                                            handleIsGroupChecked(event)
                                        }
                                    />
                                }
                                label={
                                    <span className={styles.checkboxLabel}>
                                        {t('data.groupingElement')}
                                    </span>
                                }
                            />
                        </FormGroup>
                        {!isGroupChecked ? (
                            <FormControl
                                fullWidth
                                sx={{ margin: '.4rem 0 1.7rem 0' }}
                            >
                                <Controller
                                    name="parentId"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: t(
                                            'validation.requiredField',
                                        ) as string,
                                    }}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            input={
                                                errors?.parentId ? (
                                                    <StyledSelectError/>
                                                ) : (
                                                    <StyledSelect/>
                                                )
                                            }
                                        >
                                            {grades
                                                .filter(
                                                    (grade) =>
                                                        grade.isGroup &&
                                                        grade.isActive,
                                                )
                                                .map((grade) => (
                                                    <MenuItem
                                                        key={grade.id}
                                                        value={grade.id}
                                                    >
                                                        {grade.name}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    )}
                                />
                                <ErrorMessage
                                    name="parentId"
                                    errors={errors}
                                    render={({ message }) => (
                                        <span className={styles.selectErrorMsg}>
                                            {message}
                                        </span>
                                    )}
                                />
                            </FormControl>
                        ) : null}
                    </div>
                    <FormButtons
                        isLoading={isCreatingOrUpdatingLoading}
                        createOrUpdate={createOrUpdate}
                        cancelHandler={formClose}
                    />
                </form>
            </CatalogItemActionsComponent>

            <ConfirmationModal
                isModalOpen={isConfirmationModalOpen}
                setIsModalOpen={setConfirmationModalOpen}
                actionName={'Close'}
                instanceName=""
                message={'closeFormWithoutSaving'}
                handleAction={() => closeModalConfirm()}
            />
        </>
    );
};
