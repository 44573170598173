import { AxiosError } from "axios";
import axiosApi from "core/request";

import { createAsyncThunk } from "@reduxjs/toolkit";

interface ILoginForm {
    username: string;
    password: string;
}

export const login = createAsyncThunk(
    "auth/login",
    async ({ username, password }: ILoginForm, { rejectWithValue }) => {
        try {
            const response = await axiosApi.post("account/login", {
                username: username,
                password: password,
            });
            return response.data;
        } catch (err) {
            const error = err as any;
            const errorTitle = error?.response?.data;
            if (error.response?.status === 400 && error.response?.data) {
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue(errorTitle);
        }
    },
);

export const logout = createAsyncThunk("auth/logout", async () => {
    try {
        const response = await axiosApi.post("account/logoutall");
        return response.data;
    } catch (err) {
        console.log(err);
    }
});

export const getUserInfo = createAsyncThunk(
    "auth/userInfo",
    async (_, { rejectWithValue }) => {
        try {
            const response = await axiosApi.post("account/userinfo");
            return response.data;
        } catch (err) {
            //TODO
            return rejectWithValue("Error");
        }
    },
);

export const getAllowedMenus = createAsyncThunk(
    "auth/allowedMenus",
    async (_, { rejectWithValue }) => {
        try {
            const response = await axiosApi.get("account/allowedmenus");
            return response.data;
        } catch (err) {
            //TODO
            return rejectWithValue("Error");
        }
    },
);

export const getAllowedGroupsByMenuId = createAsyncThunk(
    "auth/allowedGroupsByMenuId",
    async (menuId: number, { rejectWithValue }) => {
        try {
            const response = await axiosApi.get(
                `account/allowedgroupsbymenuid?id=${menuId}`,
            );
            return response.data;
        } catch (err) {
            //TODO
            return rejectWithValue("Error");
        }
    },
);

export const getAllowedPermissionsByGroupId = createAsyncThunk(
    "auth/allowedPermissionsByGroupId",
    async (groupId: number, { rejectWithValue }) => {
        try {
            const response = await axiosApi.get(
                `account/allowedpermissionsbygroupid?permissionGroupId=${groupId}`,
            );
            return response.data;
        } catch (err) {
            //TODO
            return rejectWithValue("Error");
        }
    },
);
