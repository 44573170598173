import React, { FC } from 'react';
import Guard from 'features/Guard';
import { useDocumentDownloader } from 'hooks';
import { IDocument } from 'models/documents';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { cancelBtn, downloadDocumentButton, saveBtn } from 'styles/MUIStyles';

import Button from '@mui/material/Button';

import { ORGANIZATIONS_PATH } from '../../../../router/constants';
import ButtonWithProgress from '../../../../ui/ButtonWithProgress';

import styles from '../../Organizations.module.scss';


interface Props {
    id: number;
    fullName: string;

    organizationDocuments: IDocument[];
    allowedPermissions: any[];
}

const OrganizationDocuments: FC<Props> = ({
                                              id,
                                              fullName,

                                              organizationDocuments,
                                              allowedPermissions,
                                          }): JSX.Element => {
    const { t } = useTranslation();
    const { isLoading, downloadDocument } = useDocumentDownloader();
    const navigate = useNavigate();

    return (
        <div style={{ padding: '10px 0px' }}>
            <div className={styles.employeeFullNameWrapper}>
                <h2 className={styles.employeeFullName}>
                    {fullName}
                </h2>

                <div>
                    <Guard
                        allowedPermissions={allowedPermissions}
                        permissionName="Edit"
                    >
                        <ButtonWithProgress
                            loading={false}
                            disabled={false}
                            variant="contained"
                            sx={saveBtn}
                            onClick={() =>
                                navigate(`/${ORGANIZATIONS_PATH}/edit/${id}`)
                            }
                        >
                            {t('buttons.Edit')}
                        </ButtonWithProgress>
                    </Guard>

                    <Button
                        variant="text"
                        onClick={() => navigate(-1)}
                        sx={cancelBtn}
                    >
                        {t('buttons.Close')}
                    </Button>
                </div>
            </div>
            <div className={styles.employeeDocuments}>
                <ul className={styles.employeeDocumentsList}>
                    {organizationDocuments && organizationDocuments.map((organizationDocument) => (
                        <li
                            key={organizationDocument.id}
                            className={styles.documentItem}
                        >
                            <h4>{organizationDocument.name}</h4>
                            <span>{organizationDocument.description}</span>
                            <Guard
                                allowedPermissions={allowedPermissions}
                                permissionName="DownloadFile"
                            >
                                <Button
                                    disabled={isLoading.includes(organizationDocument.id!)}
                                    onClick={() =>
                                        downloadDocument(
                                            organizationDocument.id!,
                                            'organizations'
                                        )
                                    }
                                    sx={downloadDocumentButton}
                                    variant="text"
                                >
                                    {t('buttons.Open')}
                                </Button>
                            </Guard>
                        </li>
                    ))}

                </ul>
            </div>
        </div>
    );
};

export default OrganizationDocuments;
