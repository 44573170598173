import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { HubConnectionBuilder } from '@microsoft/signalr';
import { Button } from '@mui/material';

import { parseDate } from '../../../../helpers/parseDate';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { clearLogs, setCurrentLogs, setStartImport } from '../../../../store/slices/importSlice';
import { importGetLast, importIsStarted, importStart } from '../../../../store/thunks/import';
import { ETextVariant, Text } from '../../../../ui/Text/Text';

import { Logs } from './Logs';

import styles from '../styles.module.css';

interface LogElement {
    error: null | string;
    isCompleted: boolean;
    maxValue: number;
    progressValue: number;
    stageName: number;
    startTimeUtc: Date;
}

export const Manual = () => {
    const isStarted = useAppSelector(state => state.imports.isStarted);
    const lastUserImported = useAppSelector(state => state.imports.lastUserImported);
    const currentLogs = useAppSelector(state => state.imports.currentLogs);
    const [logs, setLogs] = useState<LogElement[]>([]);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const logsRef = useRef<LogElement[]>([]);

    const connectImport = async () => {
        const connection = new HubConnectionBuilder()
            .withUrl('https://impulse-dev.timelysoft.net/api/import/progress')
            .withAutomaticReconnect()
            .build();

        connection.on('ImportProgressInfo', (data: LogElement) => {
            logsRef.current = [...logsRef.current, data];
            // @ts-ignore
            dispatch(setCurrentLogs({ log: data }));
        });

        connection.onclose(() => console.log('connection has closed'));
        connection.on('error', x => console.log(`exception has thrown: ${x}`));

        try {
            await connection.start()
                .then(() => {
                    console.log('connection has opened');
                })
                .catch((e) => {
                    console.log(e);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (logsRef.current.length > 0) {
                setLogs(prevLogs => [...prevLogs, ...logsRef.current]);
                logsRef.current = [];
            }
        }, 100);

        return () => clearInterval(interval);
    }, []);

    const sync = () => {
        dispatch(clearLogs());
        dispatch(setStartImport({ isStarted: true }));
        dispatch(importStart())
            .then(() => {
                connectImport();
            })
            .catch((err) => {
                console.log('error', err);
            });
    };

    useEffect(() => {
        if (logs[logs.length - 1]?.isCompleted) {
            dispatch(importGetLast());
            dispatch(setStartImport({ isStarted: false }));
        }
    }, [logs]);

    useEffect(() => {
        dispatch(importIsStarted());
        dispatch(importGetLast());
    }, []);

    useEffect(() => {
        if (isStarted) {
            connectImport();
        }
    }, [isStarted]);

    return (
        <div className={styles.manualContainer}>
            <Text className={styles.manualTitle} variant={ETextVariant.BOLD} size={20}>gTop</Text>
            {lastUserImported && (
                <div>
                    <Text className={styles.lastImportTextContainer} variant={ETextVariant.BOLD}>
                        Последняя синхронизация:
                        {(typeof isStarted === 'boolean' ? isStarted : true) || logs.length !== 0 && !logs[logs.length - 1]?.isCompleted ? (
                            <Text size={18} variant={ETextVariant.REGULAR}>Выполняется...</Text>
                        ) : (
                            <>
                                {lastUserImported.startTimeUtc && (
                                    <Text size={18} variant={ETextVariant.REGULAR}>
                                        {parseDate(lastUserImported.startTimeUtc)}
                                    </Text>
                                )}
                                <Text size={18}
                                      className={lastUserImported.status === 'Error' ? styles.errorText : styles.successText}
                                      variant={ETextVariant.BOLD}>
                                    {t(`statuses.${lastUserImported.status}` || '')}
                                </Text>
                                <Text className={styles.userText} size={18} variant={ETextVariant.BOLD}>
                                    <Link to={`/employees/${lastUserImported.user.id}`}>
                                        <span style={{
                                            margin: '0 4px',
                                            color: '#0070C0'
                                        }}>{lastUserImported.user.firstName}</span>
                                        <span style={{
                                            margin: '0 4px',
                                            color: '#0070C0'
                                        }}>{lastUserImported.user.lastName}</span>
                                    </Link>
                                </Text>
                            </>
                        )}
                    </Text>
                </div>
            )}
            <Button
                disabled={(typeof isStarted === 'boolean' ? isStarted : true) || logs.length !== 0 && !logs[logs.length - 1]?.isCompleted}
                sx={{ margin: '10px 0' }}
                onClick={sync}
                variant="contained">
                Синхронизировать
            </Button>
            <div className={styles.manualLogs}>
                <Logs logs={currentLogs}/>
            </div>
        </div>
    );
};
