import axios from "axios";
import axiosApi from "core/request";

import { createAsyncThunk } from "@reduxjs/toolkit";

import { IFormInput } from "../../models/organizations";

export const getOrganizations = createAsyncThunk(
    "organizations/getOrganizations",
    async (
        searchQuery: {
            pageNumber?: number | null;
            pageSize?: number | null | string;
            types?: string[];
            code?: string;
            name?: string;
            managerName?: string;
            sortBy?: { column: string | null; direction: string | null };
        },
        { rejectWithValue },
    ) => {
        try {
            const params = new URLSearchParams();
            if (searchQuery) {
                const { pageNumber, pageSize, types, code, name, managerName } =
                    searchQuery;

                if (types && types.length > 0) {
                    types.forEach((type) => {
                        params.append("filter.types", type);
                    });
                }
                if (pageNumber)
                    params.append("pageNumber", pageNumber.toString());
                if (pageSize) params.append("pageSize", pageSize.toString());
                if (code) params.append("filter.code", code.toString());
                if (name) params.append("filter.name", name.toString());
                if (managerName)
                    params.append("filter.managerName", managerName.toString());
                if (
                    searchQuery.sortBy?.column &&
                    searchQuery.sortBy.direction
                ) {
                    const column = searchQuery.sortBy?.column;
                    const direction = searchQuery.sortBy.direction;
                    params.append("sortBy", `${column}.${direction}`);
                }
            }

            const response = await axiosApi.get("/organizations/all", {
                params,
            });
            return response.data;
        } catch (err) {
            //TODO
            return rejectWithValue("Error");
        }
    },
);
export const getOrganization = createAsyncThunk(
    "organizations/details",
    async (id: number, { rejectWithValue }) => {
        try {
            const response = await axiosApi.get(
                `/organizations/details?id=${id}`,
            );
       
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                if (error.response.status === 400) {
                    return rejectWithValue(error.response.data.errors);
                } else {
                    return rejectWithValue(
                        "Что-то пошло не так... Попробуйте обновить страницу",
                    );
                }
            }

            if (!navigator.onLine) {
                return rejectWithValue("Проверьте подключение к интернету");
            }

            return rejectWithValue(
                "Что-то пошло не так... Попробуйте обновить страницу",
            );
        }
    },
);


export const createOrganization = createAsyncThunk<
    any,
    IFormInput,
    {
        rejectValue: any | string;
    }
>(
    "organizations/createOrganization",
    async (organization: IFormInput, { rejectWithValue }) => {
        try {
            const response = await axiosApi.post("/organizations", {
                ...organization,
            });
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                if (error.response.status === 400) {
                    return rejectWithValue(error.response.data.errors);
                } else {
                    return rejectWithValue(
                        "Что-то пошло не так... Попробуйте обновить страницу",
                    );
                }
            }

            if (!navigator.onLine) {
                return rejectWithValue("Проверьте подключение к интернету");
            }

            return rejectWithValue(
                "Что-то пошло не так... Попробуйте обновить страницу",
            );
        }
    },
);

export const editOrganization = createAsyncThunk(
    "organizations/editOrganization",
    async (organization: IFormInput, { rejectWithValue }) => {
        try {
            //TODO TYPE THE RESPONSE FROM THE SERVER
            const response = await axiosApi.put("/organizations", {
                ...organization,
            });
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                if (error.response.status === 400) {
                    return rejectWithValue(error.response.data.errors);
                } else {
                    return rejectWithValue(
                        "Что-то пошло не так... Попробуйте обновить страницу",
                    );
                }
            }

            if (!navigator.onLine) {
                return rejectWithValue("Проверьте подключение к интернету");
            }

            return rejectWithValue(
                "Что-то пошло не так... Попробуйте обновить страницу",
            );
        }
    },
);

export const activateOrganization = createAsyncThunk('organizations/activateOrganization',
    async (id: number, { rejectWithValue }) => {
        try {
            //TODO TYPE THE RESPONSE FROM THE SERVER
            const response = await axiosApi.put(`/organizations/activate?id=${id}`);
            return response.data;
        } catch (error) {
            //TODO
            return rejectWithValue("Error that i need to handle");
        }
    }
);

export const deactivateOrganization = createAsyncThunk('organizations/deactivateOrganization',
    async (id: number, { rejectWithValue }) => {
        try {
            //TODO TYPE THE RESPONSE FROM THE SERVER
            const response = await axiosApi.put(`/organizations/deactivate?id=${id}`);
            return response.data;
        } catch (error) {
            //TODO
            return rejectWithValue("Error that i need to handle");
        }
    }
);

// export const deleteOrganization = createAsyncThunk('organizations/deleteOrganization',
//     async (id: string, {rejectWithValue}) => {
//         try {
//             //TODO TYPE THE RESPONSE FROM THE SERVER
//             const response = await axiosApi.delete('/deleteOrganization/${id}`);
//             return response.data;
//         } catch (error) {
//             //TODO
//             return rejectWithValue("Error that i need to handle");
//         }
//     }
// );

export const getOrganizationTypes = createAsyncThunk('organizations/getOrganizationTypes',
    async () => {
        try {
            const response = await axiosApi.get('/lookups/organizationtypes');
            return response.data;
        } catch (err) {
            console.log(err);
        }
    });

export const uploadLogo = createAsyncThunk<any, Record<string, any>, {
    rejectValue: any | string
}>("organizations/uploadLogo",
    async (data, { rejectWithValue }) => {
        try {
            const formData = new FormData();
            formData.append('organizationId', data.organizationId);
            formData.append('logo', data.logo);

            const response = await axiosApi.post('/organizations/attachmentlogo', formData);
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                if (error.response.status === 400) {
                    return rejectWithValue(error.response.data.errors);
                } else {
                    return rejectWithValue('Что-то пошло не так... Попробуйте обновить страницу');
                }
            }

            if (!navigator.onLine) {
                return rejectWithValue('Проверьте подключение к интернету');
            }

            return rejectWithValue('Что-то пошло не так... Попробуйте обновить страницу');
        }
    }
);


