import { createSlice } from "@reduxjs/toolkit";

import {
    IOrganization,
    IOrganizationErrResponse,
    IOrganizations,
} from "../../models/organizations";
import { revertAll } from "../store";
import {
    createOrganization,
    getOrganization,
    getOrganizations,
    getOrganizationTypes,
} from "../thunks";

interface IOrganizationSlice {
    isGettingOrganizationgLoading: boolean;
    organizations: IOrganizations[];
    organizationTypes: string[];
    organizationErrors: null | IOrganizationErrResponse | undefined | string;
    selectedOrganization: IOrganizations | null;
    organizationDetails: IOrganization | null;
    organizationsTotalPageCount: number | null;
    redirectOrganizationId: number | null;
}

const initialState: IOrganizationSlice = {
    isGettingOrganizationgLoading: false,
    organizations: [],
    organizationTypes: [],
    organizationErrors: null,
    selectedOrganization: null,
    organizationsTotalPageCount: null,
    redirectOrganizationId: null,
    organizationDetails: null,
};

const organizationSlice = createSlice({
    name: "organizations",
    initialState,
    reducers: {
        clearOrganizationTextFieldErrors: (state) => {
            state.organizationErrors = null;
        },
        setSelectedOrganization: (state, action) => {
            state.selectedOrganization = action.payload;
        },
        setRedirectOrganizationId: (state, action) => {
            state.redirectOrganizationId = action.payload;
        },
        setOrganizationDetails: (state, action) => {
            state.organizationDetails = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(revertAll, () => initialState)
            .addCase(getOrganizations.pending, (state) => {
                state.isGettingOrganizationgLoading = true;
            })
            .addCase(getOrganizations.fulfilled, (state, action) => {
                state.organizations = action.payload.data;
                state.organizationsTotalPageCount =
                    action.payload.metadata.totalItemCount;
                state.isGettingOrganizationgLoading = false;
            })
            .addCase(getOrganizations.rejected, (state) => {
                state.isGettingOrganizationgLoading = false;
            })
            .addCase(createOrganization.rejected, (state, action) => {
                if (action.payload) state.organizationErrors = action.payload;
            })
            .addCase(getOrganizationTypes.fulfilled, (state, action) => {
                state.organizationTypes = action.payload;
            })
            .addCase(getOrganization.fulfilled, (state, action) => {
                state.organizationDetails = action.payload;
            })
            .addCase(getOrganization.rejected, (state, action) => {
                //@ts-ignore
                if (action.payload) state.organizationErrors = action.payload;
            });
    },
});

export const {
    clearOrganizationTextFieldErrors,
    setSelectedOrganization,
    setRedirectOrganizationId,
    setOrganizationDetails,
} = organizationSlice.actions;

export default organizationSlice.reducer;
