import { FC, useEffect, useState } from "react";
import dayjs from "dayjs";
import { useAppDispatch, useAppSelector } from "hooks";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { DAYSOFF_PATH } from "router/constants";
import {
    deleteDaysOffByEmployee,
    getDaysOffByEmployee,
    postDaysOffByEmployee,
} from "store/thunks/vacationsSickDaysThunk";
import { cancelBtn, saveBtn } from "styles/MUIStyles";

import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import CloseIcon from "@mui/icons-material/Close";
import { Backdrop, Breadcrumbs, Button, CircularProgress } from "@mui/material";

import ConfirmationModal from "../../../features/ConfirmationModal/index";

import DaysOffEditEmployeeCard from "./DaysOffEditEmployeeCard/DaysOffEditEmployeeCard";

import styles from "./VacationsSickDaysEditEmployee.module.scss";

const VacationsSickDaysEditEmployee: FC = () => {
    const { t } = useTranslation();
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const currentDate = new Date();

    const {
        register,
        unregister,
        control,
        handleSubmit,
        reset,
        getValues,
        setValue,
        watch,
        clearErrors,
        formState: { errors, isSubmitting },
    } = useForm<any>({
        mode: "onChange",
        defaultValues: {
            daysOffDtoCreate: [
                {}, //what is the internal problem in react hook form
                {
                    id: null,
                    employeeId: params.employeeId,
                    startDate: null,
                    finishDate: null,
                    sign: 1,
                    remark: null,
                },
                {
                    id: null,
                    employeeId: params.employeeId,
                    startDate: null,
                    finishDate: null,
                    sign: 2,
                    remark: null,
                },
                {
                    id: null,
                    employeeId: params.employeeId,
                    startDate: null,
                    finishDate: null,
                    sign: 3,
                    remark: null,
                },
            ],
            daysOffDto: [], //created daysOff
        },
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: "daysOffDto",
    });

    const isGettingDaysOffByEmployeeLoding = useAppSelector(
        (state) => state.vacationsSickDays.isGettingDaysOffByEmployeeLoding,
    );
    const { fullName, personnelNumber, daysOff } = useAppSelector(
        (state) => state.vacationsSickDays.daysOffByEmployee,
    );
    const { role } = useAppSelector((state) => state.auth);
    const isDeletingDaysOffByEmployeeLoading = useAppSelector(
        (state) => state.vacationsSickDays.isDeletingDaysOffByEmployeeLoading,
    );

    const [openConfirmModal, setOpenConfirmModal] = useState<{
        isOpen: boolean;
        registerIndex: number | null;
        typeDaysOff: "vacation" | "sickLeave" | "daysOff" | null;
    }>({
        isOpen: false,
        registerIndex: null,
        typeDaysOff: null,
    });
    const [daysOffIds, setDaysOff] = useState<number[]>([]);

    useEffect(() => {
        dispatch(getDaysOffByEmployee({ ...params, role } as any)); //fixed any
    }, [dispatch, params]);

    useEffect(() => {
        reset({
            ...getValues(),
            daysOffDto: daysOff?.map((item: any) => ({
                ...item,
                employeeId: params.employeeId,
            })),
        });
    }, [daysOff, reset, getValues, params.employeeId]);

    register(`daysOffDtoCreate.0`); //what is the internal problem in react hook form
    useEffect(() => {
        if (isSubmitting) {
            unregister([
                // `daysOffDtoCreate.0.startDate`, //what is the internal problem in react hook form
                // `daysOffDtoCreate.0.finishDate`,
                `daysOffDtoCreate.1.startDate`,
                `daysOffDtoCreate.1.finishDate`,
                `daysOffDtoCreate.2.startDate`,
                `daysOffDtoCreate.2.finishDate`,
                `daysOffDtoCreate.3.startDate`,
                `daysOffDtoCreate.3.finishDate`,
            ]);
        }
    }, [isSubmitting, unregister]);

    const addEntry = (registerIndex: number): void => {
        const registerName = `daysOffDtoCreate.${registerIndex}`;
        append(getValues(registerName));
        setValue(registerName, {
            id: null,
            employeeId: params.employeeId,
            startDate: null,
            finishDate: null,
            sign: registerIndex,
            remark: null,
        });
    };

    const removeEntry = async () => {
        const id = getValues(`daysOffDto.${openConfirmModal.registerIndex}.id`);

        remove(openConfirmModal.registerIndex as number);

        if (id) {
            setDaysOff((prevState) => [...prevState, id]);
        }

        setOpenConfirmModal({
            registerIndex: null,
            isOpen: false,
            typeDaysOff: null,
        });
    };

    const onSubmitDaysOff = async (data: any) => {
        const daysOffDto = data.daysOffDto.map((item: any) => {
            return {
                ...item,
                startDate: dayjs(item.startDate).format("YYYY-MM-DDT00:00:00"),
                finishDate:
                    item.sign === 3
                        ? dayjs(item.startDate).format("YYYY-MM-DDT00:00:00")
                        : dayjs(item.finishDate).format("YYYY-MM-DDT00:00:00"),
            };
        });
        await dispatch(postDaysOffByEmployee(daysOffDto));
        if (daysOffIds.length) {
            await dispatch(deleteDaysOffByEmployee(daysOffIds));
        }
        dispatch(
            getDaysOffByEmployee({
                ...params,
                role,
            } as any),
        ); //fixed any
    };

    const handleChangeYear = (year: number): void => {
        if (year <= currentDate.getFullYear()) {
            navigate(`/daysoff/${year}/${params.employeeId}/edit`);
        }
    };

    const isCheckDaysOffDtoCreateErrors = (registerIndex: number): any => {
        const daysOffDtoCreate = watch(`daysOffDtoCreate.${registerIndex}`);

        if (
            daysOffDtoCreate?.startDate &&
            (daysOffDtoCreate?.finishDate || daysOffDtoCreate.sign === 3) //The days off do not have an end date ( 1 day )
        ) {
            if (Array.isArray(errors.daysOffDtoCreate)) {
                return errors.daysOffDtoCreate[registerIndex] ? true : false;
            }

            return false;
        }

        return true;
    };

    const handleIsCloseConfirmModal = (isClose: false) => {
        setOpenConfirmModal({ ...openConfirmModal, isOpen: isClose });
    };

    const countingDaysOff = (sign: number) => {
        const filtredDaysOff = getValues(`daysOffDto`)?.filter(
            (item: any) => item.sign === sign,
        );

        if (sign === 3) {
            return filtredDaysOff?.length;
        }

        return filtredDaysOff?.reduce((accumulator: number, item: any) => {
            return (accumulator +=
                item.numberOfDaysOff ??
                dayjs(item.finishDate).diff(item.startDate, "day") + 1);
        }, 0);
    };

    const daysOffDtoCreate = [
        {
            title: t("vacationsSickDays.Vacations"),
            numberOfDaysOff: countingDaysOff(1),
        },
        {
            title: t("vacationsSickDays.SickLeave"),
            numberOfDaysOff: countingDaysOff(2),
        },
        {
            title: t("vacationsSickDays.DaysOff"),
            numberOfDaysOff: countingDaysOff(3),
        },
    ];

    return (
        <div className={styles.container}>
            <Breadcrumbs>
                <Link to="/" className={styles.link_breadcrumbs}>
                    Impulse
                </Link>
                <Link
                    to={`/${DAYSOFF_PATH}`}
                    className={styles.link_breadcrumbs}
                >
                    {t("vacationsSickDays.VacationsAndSickDays")}
                </Link>
                <p>{t("data.Editing")}</p>
            </Breadcrumbs>
            <div className={styles.box_year}>
                <ArrowLeftIcon
                    htmlColor="gray"
                    sx={{ color: "green", fontSize: "40px" }}
                    onClick={() => handleChangeYear(Number(params.year) - 1)}
                />
                <p>{params.year}</p>
                <ArrowRightIcon
                    htmlColor="gray"
                    sx={{
                        color:
                            Number(params?.year) === currentDate.getFullYear()
                                ? "gray"
                                : "green",
                        fontSize: "40px",
                    }}
                    onClick={() => handleChangeYear(Number(params.year) + 1)}
                />
            </div>
            <form onSubmit={handleSubmit(onSubmitDaysOff)}>
                <div className={styles.boxTitle}>
                    <h2>{t("vacationsSickDays.VacationsAndSickDays")} </h2>
                    <div>
                        <Button type="submit" variant="contained" sx={saveBtn}>
                            {t("buttons.Save")}
                        </Button>
                        <Button
                            variant="text"
                            sx={cancelBtn}
                            onClick={() => navigate(`/${DAYSOFF_PATH}`)}
                        >
                            {t("buttons.Close")}
                        </Button>
                    </div>
                </div>
                <h3 className={styles.subtitle_name}>
                    {fullName} {t("data.tabNo")} {personnelNumber || "-"}
                </h3>
                <div className={styles.containerCards}>
                    {daysOffDtoCreate.map((item, index: number) => {
                        const checkIndex = index + 1; //to avoid registering the first register in react hook form
                        return (
                            <div key={index}>
                                <div className={styles.cardBox_title}>
                                    <h4>{item.title}</h4>
                                    <div>{item.numberOfDaysOff}</div>
                                </div>
                                <DaysOffEditEmployeeCard
                                    register={register}
                                    control={control}
                                    errors={errors}
                                    clearErrors={clearErrors}
                                    getValues={getValues}
                                    registerName={`daysOffDtoCreate.${checkIndex}`}
                                    isCheckDayOffThirdSign={
                                        index === 2 ? false : true
                                    }
                                />
                                <Button
                                    type="button"
                                    variant="contained"
                                    sx={{
                                        ...saveBtn,
                                        textTransform: "none",
                                        display: "block",
                                        margin: "10px 0 0 auto",
                                    }}
                                    onClick={() => addEntry(checkIndex)}
                                    disabled={isCheckDaysOffDtoCreateErrors(
                                        checkIndex,
                                    )}
                                    name={`daysOffDtoCreate.${checkIndex}.addBtn`}
                                >
                                    {t("vacationsSickDays.AddEntry")}
                                </Button>
                                <div className={styles.card_line}></div>
                            </div>
                        );
                    })}
                </div>
                <div className={styles.containerCards}>
                    <div>
                        {fields.map((field, index) => {
                            //@ts-ignore
                            if (field.sign === 1) {
                                return (
                                    <div
                                        className={styles.card_wrappEdit}
                                        key={field.id}
                                    >
                                        <DaysOffEditEmployeeCard
                                            register={register}
                                            control={control}
                                            errors={errors}
                                            clearErrors={clearErrors}
                                            getValues={getValues}
                                            registerName={`daysOffDto.${index}`}
                                            isCheckDayOffThirdSign={true}
                                        />
                                        <CloseIcon
                                            color="error"
                                            fontSize="large"
                                            onClick={() =>
                                                setOpenConfirmModal({
                                                    registerIndex: index,
                                                    isOpen: true,
                                                    typeDaysOff: "vacation",
                                                })
                                            }
                                        />
                                    </div>
                                );
                            }

                            return null;
                        })}
                    </div>
                    <div>
                        {fields.map((field, index) => {
                            //@ts-ignore
                            if (field.sign === 2) {
                                return (
                                    <div
                                        className={styles.card_wrappEdit}
                                        key={field.id}
                                    >
                                        <DaysOffEditEmployeeCard
                                            register={register}
                                            control={control}
                                            errors={errors}
                                            clearErrors={clearErrors}
                                            getValues={getValues}
                                            registerName={`daysOffDto.${index}`}
                                            isCheckDayOffThirdSign={true}
                                        />
                                        <CloseIcon
                                            color="error"
                                            fontSize="large"
                                            onClick={() =>
                                                setOpenConfirmModal({
                                                    registerIndex: index,
                                                    isOpen: true,
                                                    typeDaysOff: "sickLeave",
                                                })
                                            }
                                        />
                                    </div>
                                );
                            }

                            return null;
                        })}
                    </div>
                    <div>
                        {fields.map((field, index) => {
                            //@ts-ignore
                            if (field.sign === 3) {
                                return (
                                    <div
                                        className={styles.card_wrappEdit}
                                        key={field.id}
                                    >
                                        <DaysOffEditEmployeeCard
                                            register={register}
                                            control={control}
                                            errors={errors}
                                            clearErrors={clearErrors}
                                            getValues={getValues}
                                            registerName={`daysOffDto.${index}`}
                                            isCheckDayOffThirdSign={false}
                                        />
                                        <CloseIcon
                                            color="error"
                                            fontSize="large"
                                            onClick={() =>
                                                setOpenConfirmModal({
                                                    registerIndex: index,
                                                    isOpen: true,
                                                    typeDaysOff: "daysOff",
                                                })
                                            }
                                        />
                                    </div>
                                );
                            }

                            return null;
                        })}
                    </div>
                </div>
            </form>
            <Backdrop
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isGettingDaysOffByEmployeeLoding}
            >
                <CircularProgress sx={{ color: "#fff" }} />
            </Backdrop>
            <ConfirmationModal
                loading={isDeletingDaysOffByEmployeeLoading}
                isModalOpen={openConfirmModal.isOpen}
                setIsModalOpen={handleIsCloseConfirmModal}
                handleAction={removeEntry}
                actionName={"Delete"}
                instanceName={openConfirmModal.typeDaysOff as string}
            />
        </div>
    );
};

export default VacationsSickDaysEditEmployee;
