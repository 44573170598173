import React, { FC } from "react";
import { useDocumentDownloader } from "hooks";
import { IPersonalDocument, IPersonalDocuments } from "models/documents";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { cancelBtn, downloadDocumentButton } from "styles/MUIStyles";

import Button from "@mui/material/Button";

import styles from "../Employee/EmployeeView/Employees.module.scss";

interface Props {
    personalDocuments: IPersonalDocument[] | null;
    showCloseButton?: boolean;
    firstName?: string;
    lastName?: string;
}

const PersonalDocumentsTab: FC<Props> = ({
    firstName,
    lastName,
    showCloseButton,
    personalDocuments,
}): JSX.Element => {
    const { t } = useTranslation();
    const { isLoading, downloadDocument } = useDocumentDownloader();
    const navigate = useNavigate();

    return (
        <div style={{ padding: "10px 0" }}>
            <div className={styles.employeeFullNameWrapper}>
                <h2 className={styles.employeeFullName}>
                    {firstName} {lastName}
                </h2>
                <div>
                    {showCloseButton && (
                        <Button
                            variant="text"
                            onClick={() => navigate(-1)}
                            sx={cancelBtn}
                        >
                            {t("buttons.Close")}
                        </Button>
                    )}
                </div>
            </div>
            <div className={styles.employeeDocuments}>
                <ul className={styles.employeeDocumentsList}>
                    {personalDocuments?.map((document) => (
                        <li key={document.id}>
                            <h4>{document.originalFileName}</h4>
                            <h4>{document.link}</h4>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default PersonalDocumentsTab;
