import { FC } from "react";
import { useTranslation } from "react-i18next";

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";

import closeIcon from "../../assets/icons/closeIcon.svg";
import {
    activateOrDeactivateBtn,
    activateOrDeactivateModal,
    cancelBtn,
} from "../../styles/MUIStyles";
import ButtonWithProgress from "../../ui/ButtonWithProgress";

import styles from "../../views/Catalogs/CatalogsLayout.module.scss";

const Index: FC<{
    isModalOpen: boolean;
    setIsModalOpen: Function;
    actionName: string;
    instanceName: string;
    message?: string;
    loading?: boolean;
    handleAction: Function;
}> = ({
    isModalOpen,
    setIsModalOpen,
    actionName,
    instanceName,
    message,
    handleAction,
    loading,
}) => {
    const { t } = useTranslation();

    // const isActionResponseLoading = useAppSelector(state => state.catalogs.isDeactivationLoading);

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={isModalOpen || false}
                onClose={() => setIsModalOpen({ isOpen: false })}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={isModalOpen}>
                    <Box sx={activateOrDeactivateModal}>
                        {actionName && instanceName && (
                            <div className={styles.activateModalTopWrapper}>
                                <h3 className={styles.activateModalTitle}>
                                    {t(`buttons.${actionName}`)}{" "}
                                    {t(`catalogActions.${instanceName}`) || " "}
                                </h3>
                                <img
                                    src={closeIcon}
                                    alt=""
                                    onClick={() => setIsModalOpen(false)}
                                />
                            </div>
                        )}
                        <p className={styles.activateModalText}>
                            {t("notifications.areYouSure")}{" "}
                            {actionName &&
                                t(`buttons.${actionName}`).toLowerCase()}{" "}
                            {instanceName &&
                                t(`catalogActions.${instanceName}`) + "?"}
                            {message && t(`notifications.${message}`)}
                            {/*{isCatalogItemActive ? t('notifications.deactivateConfirmation') : t('notifications.activateConfirmation')} {activeCatalogItemName} ?*/}
                        </p>
                        <div className={styles.activateModalButtonWrapper}>
                            <Button
                                onClick={() => setIsModalOpen(false)}
                                variant="text"
                                sx={{ ...cancelBtn, margin: "5px" }}
                            >
                                {t("buttons.Cancel")}
                            </Button>
                            <ButtonWithProgress
                                loading={Boolean(loading)}
                                disabled={Boolean(loading)}
                                variant="contained"
                                onClick={() => handleAction()}
                                sx={activateOrDeactivateBtn}
                            >
                                {t(`buttons.${actionName}`)}
                            </ButtonWithProgress>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
};

export default Index;
