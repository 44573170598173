import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ErrorMessage } from '@hookform/error-message';

import CatalogItemActionsComponent from '../../../features/Catalogs/CatalogItemActionsComponent';
import ConfirmationModal from '../../../features/ConfirmationModal';
import FormButtons from '../../../features/FormButtons';
import { useAppDispatch, useAppSelector, useSetServerError } from '../../../hooks';
import { IFormInput } from '../../../models/timeZone';
import { clearCatalogTextFieldErrors, createTimeZone, editTimeZone } from '../../../store';

import { TimeZone } from './TimeZones';

import styles from '../CatalogsLayout.module.scss';

export const TimeZoneForm: FC<{
    name: string;
    activeTimeZone: TimeZone | null;
    setActiveTimeZone: (activeTimeZone: TimeZone | null) => void;
    createOrUpdate: string | null;
    isDrawerOpen: boolean;
    setCreateOrUpdate: Function;
    setIsDrawerOpen: Dispatch<SetStateAction<boolean>>;
    fulfilledStatusHandler: Function;
}> = ({
          name,
          activeTimeZone,
          setActiveTimeZone,
          createOrUpdate,
          isDrawerOpen,
          setCreateOrUpdate,
          setIsDrawerOpen,
          fulfilledStatusHandler,
      }): JSX.Element => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const timeZoneErrors = useAppSelector(
        (state) => state.catalogs.timeZoneErrors,
    );
    const isCreatingOrUpdatingLoading = useAppSelector(
        (state) => state.catalogs.isCreatingOrUpdatingLoading,
    );

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        setError,
        formState: { errors },
        clearErrors,
    } = useForm<IFormInput>();

    const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);

    const onSubmit: SubmitHandler<IFormInput> = async (
        formData: IFormInput,
    ) => {
        if (createOrUpdate === "create") {
            const response = await dispatch(
                createTimeZone({
                    nameZone: formData.nameZone,
                    offset: formData.offset,
                    timeZonesEndpointPath: name,
                }),
            );

            if (response.meta.requestStatus === "fulfilled") {
                fulfilledStatusHandler("notifications.successfullyCreated");
                setIsDrawerOpen(false);
            }
            return;
        }

        if (activeTimeZone && createOrUpdate === "update") {
            const response = await dispatch(
                editTimeZone({
                    id: activeTimeZone.id,
                    nameZone: formData.nameZone,
                    offset: formData.offset,
                    timeZonesEndpointPath: name,
                }),
            );

            if (response.meta.requestStatus === "fulfilled") {
                fulfilledStatusHandler("notifications.successfullySaved");
                setActiveTimeZone(null);
                setIsDrawerOpen(false);
            }
        }
    };

    const formClose = () => {
        if (formChangesChecking()) setConfirmationModalOpen(true);
        else closeFormDrawer();
    };

    const closeFormDrawer = () => {
        setCreateOrUpdate(null);
        setIsDrawerOpen(false);
    };

    const closeModalConfirm = () => {
        setFormData(null);
        setIsDrawerOpen(false);
        setConfirmationModalOpen(false);
    };

    // returns true, if form was updated
    const formChangesChecking = () => {
        if (createOrUpdate === "create") {
            return Boolean(getValues("nameZone") || getValues("offset"));
        } else {
            return Boolean(
                getValues("nameZone") !== activeTimeZone?.nameZone ||
                getValues("offset") !== activeTimeZone?.offset,
            );
        }
    };

    const setFormData = (fields: TimeZone | null) => {
        setValue("nameZone", fields?.nameZone || "");
        setValue("offset", fields?.offset || "");
        // operation && setCreateOrUpdate(operation);
        // setIsDrawerOpen( false);
    };

    useEffect(() => {
        if (isDrawerOpen) {
            setFormData(activeTimeZone);
        } else {
            clearErrors();
            dispatch(clearCatalogTextFieldErrors());
        }
    }, [dispatch, isDrawerOpen]);

    useSetServerError({
        serverError: timeZoneErrors,
        setError,
        clearErrors,
        errorNames: ["name", "offset"],
    });

    return (
        <>
            <CatalogItemActionsComponent
                name={name}
                isDrawerOpen={isDrawerOpen}
                createOrUpdate={createOrUpdate}
                closeFormDrawer={formClose}
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={styles.formInputWrapper}>
                        <label
                            htmlFor="nameZone"
                            className={styles.drawerTopSubTitle}
                        >
                            {t("data.name")}
                        </label>
                        <div>
                            <input
                                {...register("nameZone", {
                                    required: t(
                                        "timeZones.requiredField",
                                    ) as string,
                                    maxLength: {
                                        value: 10,
                                        message: t(
                                            "validation.timeZoneCodeField",
                                        ),
                                    },
                                })}
                                id="nameZone"
                                type="text"
                                autoComplete="off"
                                placeholder={t("data.name") as string}
                                className={
                                    errors?.nameZone
                                        ? styles.catalogsDrawerInputError
                                        : styles.catalogsDrawerInput
                                }
                            />
                            <ErrorMessage
                                name="nameZone"
                                errors={errors}
                                render={({ message }) => <span>{message}</span>}
                            />
                        </div>
                        <label
                            htmlFor="offset"
                            className={styles.drawerTopSubTitle}
                        >
                            {t("data.offset")}
                        </label>
                        <div>
                            <input
                                {...register("offset", {
                                    required: t(
                                        "timeZones.requiredField",
                                    ) as string,
                                    pattern: {
                                        value: /^[-]?[0-9]*\.?[0-9]*$/,
                                        message: t("validation.onlyDigits"),
                                    },
                                })}
                                id="offset"
                                type="text"
                                autoComplete="off"
                                placeholder={t("data.offset") as string}
                                className={
                                    errors?.offset
                                        ? styles.catalogsDrawerInputError
                                        : styles.catalogsDrawerInput
                                }
                            />
                            <ErrorMessage
                                name="offset"
                                errors={errors}
                                render={({ message }) => <span>{message}</span>}
                            />
                        </div>
                    </div>
                    <FormButtons
                        isLoading={isCreatingOrUpdatingLoading}
                        createOrUpdate={createOrUpdate}
                        cancelHandler={formClose}
                    />
                </form>
            </CatalogItemActionsComponent>

            <ConfirmationModal
                isModalOpen={isConfirmationModalOpen}
                setIsModalOpen={setConfirmationModalOpen}
                actionName={"Close"}
                instanceName=""
                message={"closeFormWithoutSaving"}
                handleAction={() => closeModalConfirm()}
            />
        </>
    );
};
