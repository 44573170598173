import React, { FC } from 'react';
import Guard from 'features/Guard';
import { useDocumentDownloader } from 'hooks';
import { IDocument } from 'models/documents';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { cancelBtn, downloadDocumentButton, saveBtn } from 'styles/MUIStyles';

import Button from '@mui/material/Button';

import { EMPLOYEES_PATH } from '../../../../router/constants';
import ButtonWithProgress from '../../../../ui/ButtonWithProgress';

import styles from '../Employees.module.scss';


interface Props {
    id: string;
    employeeFirstName: string;
    employeeLastName: string;
    employeeDocuments: IDocument[];
    allowedPermissions: any[];
    showCloseButton?: boolean;
    personalDocuments?: IDocument[];
}

const EmployeeDocuments: FC<Props> = ({
    id,
    employeeFirstName,
    employeeLastName,
    employeeDocuments,
    allowedPermissions,
    showCloseButton,
    personalDocuments,
}): JSX.Element => {
    const { t } = useTranslation();
    const { isLoading, downloadDocument } = useDocumentDownloader();
    const navigate = useNavigate();
    return (
        <div style={{ padding: "10px 0px" }}>
            <div className={styles.employeeFullNameWrapper}>
                <h2 className={styles.employeeFullName}>
                    {employeeFirstName} {employeeLastName}
                </h2>

                <div>
                    <Guard
                        allowedPermissions={allowedPermissions}
                        permissionName="Edit"
                    >
                        <ButtonWithProgress
                            loading={false}
                            disabled={false}
                            variant="contained"
                            sx={saveBtn}
                            onClick={() =>
                                navigate(`/${EMPLOYEES_PATH}/edit/${id}`)
                            }
                        >
                            {t("buttons.Edit")}
                        </ButtonWithProgress>
                    </Guard>

                    {showCloseButton && (
                        <Button
                            variant="text"
                            onClick={() => navigate(-1)}
                            sx={cancelBtn}
                        >
                            {t("buttons.Close")}
                        </Button>
                    )}
                </div>
            </div>
            <div className={styles.employeeDocuments}>
                <ul className={styles.employeeDocumentsList}>
                    {employeeDocuments.map((employeeDocument) => (
                        <li
                            key={employeeDocument.id}
                            className={styles.documentItem}
                        >
                            <h4>{employeeDocument.name}</h4>
                            <span>{employeeDocument.description}</span>
                            <span>{employeeDocument.originalFileName}</span>
                            <span>{employeeDocument.link}</span>
                            <span>{employeeDocument.createdTimeUtc}</span>
                            <Guard
                                allowedPermissions={allowedPermissions}
                                permissionName="DownloadFile"
                            >
                                <Button
                                    disabled={isLoading.includes(
                                        employeeDocument.id!,
                                    )}
                                    onClick={() =>
                                        downloadDocument(
                                            employeeDocument.id!,
                                            "employees",
                                        )
                                    }
                                    sx={downloadDocumentButton}
                                    variant="text"
                                >
                                    {t("buttons.Open")}
                                </Button>
                            </Guard>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default EmployeeDocuments;
