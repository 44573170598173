import axios from "axios";
import axiosApi from "core/request";

import { createAsyncThunk } from "@reduxjs/toolkit";

import {
    ICatalogsArgs,
    ICatalogsCurrencyArgs,
    ICatalogsCurrencyErrResponse,
    ICatalogsErrResponse,
} from "../../models/catalogs";

// Data By Catalog Name
export const getDataByCatalogName = createAsyncThunk(
    "catalogs/getDataByCatalogName",
    async (catalogName: string, { rejectWithValue }) => {
        try {
            //TODO TYPE THE RESPONSE FROM THE SERVER
            const response = await axiosApi.get(
                `/${catalogName.toLowerCase()}`,
            );
            return { catalogName, data: response.data };
        } catch (error) {
            //TODO
            return rejectWithValue("Error that i need to handle");
        }
    },
);

export const createDataByCatalogName = createAsyncThunk<
    any,
    ICatalogsArgs,
    { rejectValue: ICatalogsErrResponse | string }
>(
    "catalogs/createDataByCatalogName",
    async (catalogDataByName, { rejectWithValue }) => {
        try {
            //TODO TYPE THE RESPONSE FROM THE SERVER
            const response = await axiosApi.post(
                `/${catalogDataByName.catalogName.toLowerCase()}`,
                {
                    name: catalogDataByName.name,
                    description: catalogDataByName.description,
                },
            );
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                if (error.response.status === 400) {
                    return rejectWithValue(error.response.data.errors);
                } else {
                    return rejectWithValue(
                        "Что-то пошло не так... Попробуйте обновить страницу",
                    );
                }
            }

            if (!navigator.onLine) {
                return rejectWithValue("Проверьте подключение к интернету");
            }

            return rejectWithValue(
                "Что-то пошло не так... Попробуйте обновить страницу",
            );
        }
    },
);

export const editDataByCatalogName = createAsyncThunk(
    "catalogs/editDataByCatalogName",
    async (
        catalogDataByName: {
            catalogName: string;
            catalogNameId: number;
            name: string;
            description: string;
        },
        { rejectWithValue },
    ) => {
        try {
            //TODO TYPE THE RESPONSE FROM THE SERVER
            const response = await axiosApi.put(
                `/${catalogDataByName.catalogName.toLowerCase()}`,
                {
                    id: catalogDataByName.catalogNameId,
                    name: catalogDataByName.name,
                    description: catalogDataByName.description,
                },
            );
            return response.data;
        } catch (error) {
            //TODO
            return rejectWithValue("Error that i need to handle");
        }
    },
);

export const activateDataByCatalogName = createAsyncThunk(
    "catalogs/activateDataByCatalogName",
    async (
        catalogDataByName: { catalogName: string; catalogNameId: number },
        { rejectWithValue },
    ) => {
        try {
            //TODO TYPE THE RESPONSE FROM THE SERVER
            const response = await axiosApi.put(
                `/${catalogDataByName.catalogName.toLowerCase()}/activate?id=${catalogDataByName.catalogNameId}`,
            );
            return response.data;
        } catch (error) {
            //TODO
            return rejectWithValue("Error that i need to handle");
        }
    },
);

export const deactivateDataByCatalogName = createAsyncThunk(
    "catalogs/deactivateDataByCatalogName",
    async (
        catalogDataByName: { catalogName: string; catalogNameId: number },
        { rejectWithValue },
    ) => {
        try {
            //TODO TYPE THE RESPONSE FROM THE SERVER
            const response = await axiosApi.put(
                `/${catalogDataByName.catalogName.toLowerCase()}/deactivate?id=${catalogDataByName.catalogNameId}`,
            );
            return response.data;
        } catch (error) {
            //TODO
            return rejectWithValue("Error that i need to handle");
        }
    },
);

export const createDataWithParentByCatalogName = createAsyncThunk<
    any,
    ICatalogsArgs,
    { rejectValue: ICatalogsErrResponse | string }
>(
    "catalogs/createDataWithParentByCatalogName",
    async (catalogDataByName, { rejectWithValue }) => {
        try {
            //TODO TYPE THE RESPONSE FROM THE SERVER
            const response = await axiosApi.post(
                `/${catalogDataByName.catalogName.toLowerCase()}`,
                {
                    name: catalogDataByName.name,
                    description: catalogDataByName.description,
                    parentId: catalogDataByName.parentId,
                },
            );
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                if (error.response.status === 400) {
                    return rejectWithValue(error.response.data.errors);
                } else {
                    return rejectWithValue(
                        "Что-то пошло не так... Попробуйте обновить страницу",
                    );
                }
            }

            if (!navigator.onLine) {
                return rejectWithValue("Проверьте подключение к интернету");
            }

            return rejectWithValue(
                "Что-то пошло не так... Попробуйте обновить страницу",
            );
        }
    },
);

export const editDataWithParentByCatalogName = createAsyncThunk(
    "catalogs/editDataWithParentByCatalogName",
    async (
        catalogDataByName: {
            catalogName: string;
            catalogNameId: number;
            name: string;
            description: string;
            parentId: number | null | string | undefined;
        },
        { rejectWithValue },
    ) => {
        try {
            //TODO TYPE THE RESPONSE FROM THE SERVER
            const response = await axiosApi.put(
                `/${catalogDataByName.catalogName.toLowerCase()}`,
                {
                    id: catalogDataByName.catalogNameId,
                    name: catalogDataByName.name,
                    description: catalogDataByName.description,
                    parentId: catalogDataByName.parentId,
                },
            );
            return response.data;
        } catch (error) {
            //TODO
            return rejectWithValue("Error that i need to handle");
        }
    },
);

export const getCurrencyList = createAsyncThunk(
    "catalogs/getCurrencyList",
    async (gradesEndpointName: string, { rejectWithValue }) => {
        try {
            const response = await axiosApi.get(`/${gradesEndpointName}`);
            return response.data;
        } catch (error) {
            //TODO
            return rejectWithValue("Error that i need to handle");
        }
    },
);

export const createCurrency = createAsyncThunk<
    any,
    ICatalogsCurrencyArgs,
    { rejectValue: ICatalogsCurrencyErrResponse | string }
>("catalogs/createCurrency", async (currency, { rejectWithValue }) => {
    try {
        //TODO TYPE THE RESPONSE FROM THE SERVER
        const response = await axiosApi.post("/currencies", {
            name: currency.name,
            currencyCode: currency.currencyCode,
            isPrimary: currency.isPrimary,
        });
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            if (error.response.status === 400) {
                return rejectWithValue(error.response.data.errors);
            } else {
                return rejectWithValue(
                    "Что-то пошло не так... Попробуйте обновить страницу",
                );
            }
        }

        if (!navigator.onLine) {
            return rejectWithValue("Проверьте подключение к интернету");
        }

        return rejectWithValue(
            "Что-то пошло не так... Попробуйте обновить страницу",
        );
    }
});

export const editCurrency = createAsyncThunk<
    any,
    ICatalogsCurrencyArgs,
    { rejectValue: ICatalogsCurrencyErrResponse | string }
>("catalogs/editCurrency", async (currency, { rejectWithValue }) => {
    try {
        const response = await axiosApi.put("/currencies", { ...currency });
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            if (error.response.status === 400) {
                return rejectWithValue(error.response.data.errors);
            } else {
                return rejectWithValue(
                    "Что-то пошло не так... Попробуйте обновить страницу",
                );
            }
        }

        if (!navigator.onLine) {
            return rejectWithValue("Проверьте подключение к интернету");
        }

        return rejectWithValue(
            "Что-то пошло не так... Попробуйте обновить страницу",
        );
    }
});
