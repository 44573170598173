import React, { FC, memo, useEffect } from "react";
import userLogo from "assets/logos/user.png";
import { getFromLocalStorage } from "core/utils";
import ListItemActions from "features/ListItemActions";
import { useAppDispatch, useAppSelector } from "hooks";
import { IOrganizations } from "models/organizations";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { EMPLOYEES_PATH } from "router/constants";
import { getOrganizations, setSelectedOrganization } from "store";
import {
    materialReactTableCell,
    materialReactTableHeader,
} from "styles/MUIStyles";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";

import AgGrid from "../../features/AgGrid/AgGrid";

interface Props {
    sortConfig: {
        column: null | string;
        direction: null | string;
    };
    activeOrganizationId: IOrganizations | null;
    page: number;
    searchManager: string;
    isSearchingRef: { current: null | boolean };
    searchOrganizationByName: string;
    searchOrganizationByCode: string;
    setConfirmationModalOpen: (confirmationModal: {
        isOpen: boolean;
        actionName: string;
    }) => void;
    onEditOrganization: (id: number) => void;
    onViewOrganization: (id: number) => void;
    setPage: (page: number) => void;
    handleColumnClick: any;
}

const OrganizationsTable: FC<Props> = memo((props): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const {
        searchOrganizationByName,
        searchOrganizationByCode,
        activeOrganizationId,
        onEditOrganization,
        onViewOrganization,
        searchManager,
        isSearchingRef,
        setPage,
        page,
        handleColumnClick,
        sortConfig,
        setConfirmationModalOpen,
    } = props;

    const organizations = useAppSelector(
        (state) => state.organizations.organizations,
    );

    const getArrowForSortDirection = (column: string) => {
        if (sortConfig.column !== column) {
            return null;
        }

        return sortConfig.direction === "asc" ? (
            <ArrowUpwardIcon sx={{ color: "#605E5C" }} />
        ) : (
            <ArrowDownwardIcon sx={{ color: "#605E5C" }} />
        );
    };

    useEffect(() => {
        const organizationsFilters = getFromLocalStorage<{ types: string[] }>(
            "organizationsFilters",
        );
        const pageSize = getFromLocalStorage<number>("organizationsPageSize");
        if (isSearchingRef.current && page > 1) {
            setPage(1);
        } else {
            dispatch(
                getOrganizations({
                    pageNumber: page,
                    pageSize,
                    types: organizationsFilters?.types || [],
                    code: searchOrganizationByCode,
                    name: searchOrganizationByName,
                    managerName: searchManager,
                    sortBy: sortConfig,
                }),
            );
        }

        isSearchingRef.current = false;
    }, [
        dispatch,
        t,
        page,
        searchOrganizationByCode,
        searchOrganizationByName,
        searchManager,
        isSearchingRef,
        setPage,
        sortConfig,
    ]);

    const HeadComponent = (p: any) => {
        switch (p.column.colId) {
            case "customActions":
                return (
                    <Box sx={materialReactTableHeader}>
                        <span>{t("data.actions")}</span>
                    </Box>
                );
            case "logo":
                return (
                    <Box sx={materialReactTableHeader}>
                        <span>{t("data.photo")}</span>
                    </Box>
                );
            case "code":
                return (
                    <Box
                        sx={materialReactTableHeader}
                        onClick={() => handleColumnClick("code")}
                    >
                        <span>{t("data.code")}</span>
                        {getArrowForSortDirection("code")}
                    </Box>
                );
            case "name":
                return (
                    <Box
                        sx={materialReactTableHeader}
                        onClick={() => handleColumnClick("name")}
                    >
                        <span>{t("data.name")}</span>
                        {getArrowForSortDirection("name")}
                    </Box>
                );
            case "address":
                return (
                    <Box
                        sx={materialReactTableHeader}
                        onClick={() => handleColumnClick("address")}
                    >
                        <span>{t("data.address")}</span>
                        {getArrowForSortDirection("address")}
                    </Box>
                );
            case "contact":
                return (
                    <Box sx={materialReactTableHeader}>
                        <span>{t("data.contacts")}</span>
                    </Box>
                );
            case "managers":
                return (
                    <Box sx={materialReactTableHeader}>
                        <span>{t("data.manager")}</span>
                        {getArrowForSortDirection("managers")}
                    </Box>
                );
            case "type":
                return (
                    <Box sx={materialReactTableHeader}>
                        <span>{t("data.type")}</span>
                        {getArrowForSortDirection("type")}
                    </Box>
                );
            case "isActive":
                return (
                    <Box
                        sx={materialReactTableHeader}
                        onClick={() => handleColumnClick("isActive")}
                    >
                        <span>{t("data.status")}</span>
                        {getArrowForSortDirection("isActive")}
                    </Box>
                );
            default:
                return null;
        }
    };

    const RowComponent = (p: any) => {
        switch (p.colDef.field) {
            case "customActions":
                return (
                    <Box
                        sx={{
                            height: "100%",

                            "& span": {
                                display: "flex",
                                height: "100%",
                                justifyContent: "space-evenly",
                                alignItems: "center",
                                cursor: "pointer",
                            },
                        }}
                    >
                        <ListItemActions
                            itemId={p.data?.id}
                            isItemActive={p.data?.isActive}
                            activationAvailable={true}
                            setConfirmationModalOpen={setConfirmationModalOpen}
                            editItemClick={onEditOrganization}
                            viewItemClick={onViewOrganization}
                        />
                        {/* <Guard
                            allowedPermissions={allowedPermissions}
                            permissionName="View"
                        >
                            <BootstrapTooltip
                                title={t("buttons.View")}
                                onClick={() => navigate(`${p.data.id}`)}
                                placement="top"
                            >
                                <img src={viewIcon} alt="view icon" />
                            </BootstrapTooltip>
                        </Guard>
                        <Guard
                            allowedPermissions={allowedPermissions}
                            permissionName="Edit"
                        >
                            <BootstrapTooltip
                               onClick={() => navigate(`/organizations/edit/${p.data.id}`)}
                                title={t("buttons.Edit")}
                                placement="top"
                            >
                                <img src={editIcon} alt="edit icon" />
                            </BootstrapTooltip>
                        </Guard> */}
                    </Box>
                );
            case "logo":
                return (
                    <Box sx={materialReactTableCell}>
                        <Avatar
                            alt="Organization's logo"
                            src={
                                p.data?.logo
                                    ? process.env.REACT_APP_STATIC_FILES_URL +
                                      p.data?.logo
                                    : userLogo
                            }
                            sx={{ width: 35, height: 35 }}
                        />
                    </Box>
                );
            case "code":
                return <Box sx={materialReactTableCell}>{p.data?.code}</Box>;
            case "name":
                return <Box sx={materialReactTableCell}>{p.data?.name}</Box>;
            case "address":
                return <Box sx={materialReactTableCell}>{p.data?.address}</Box>;
            case "contact":
                return (
                    <Box sx={materialReactTableCell}>
                        <span>{p.data?.contact?.emailAddress}</span>
                        <span>{p.data?.contact?.phoneNumber}</span>
                    </Box>
                );
            case "managers":
                return (
                    <Box sx={materialReactTableCell}>
                        {p.data?.managers.map((manager: any) => (
                            <Link
                                to={`/${EMPLOYEES_PATH}/${manager.id}`}
                                key={manager.id}
                            >
                                {manager.fullName}
                            </Link>
                        ))}
                    </Box>
                );
            case "type":
                return (
                    <Box sx={materialReactTableCell}>
                        {t(`organizationTypes.${p.data?.type}`)}
                    </Box>
                );
            case "isActive":
                return (
                    <Box
                        sx={{
                            ...materialReactTableCell,
                            fontWeight: 600,
                            color: p.data?.isActive ? "#25c00e" : "#FA0E00",
                        }}
                    >
                        {p.data?.isActive
                            ? t("data.isActive")
                            : t("data.notActive")}
                    </Box>
                );
            default:
                return null;
        }
    };

    const generateColumnDefs = () => {
        const savedColumnState = getFromLocalStorage(
            "agGridOrganizationsTable",
        );

        let updatedColumnDefs: any[] = [
            {
                field: "customActions",
                resizable: true,
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 110,
            },
            {
                field: "logo",
                resizable: true,
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 100,
            },
            {
                field: "code",
                resizable: true,
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 100,
            },
            {
                field: "name",
                resizable: true,
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 140,
            },
            {
                field: "address",
                resizable: true,
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 120,
            },
            {
                field: "contact",
                resizable: true,
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 140,
                valueFormatter: () => "",
            },
            {
                field: "managers",
                resizable: true,
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 130,
                valueFormatter: () => "",
            },
            {
                field: "type",
                resizable: true,
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 110,
            },
            {
                field: "isActive",
                resizable: true,
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 110,
            },
        ];

        if (Array.isArray(savedColumnState)) {
            updatedColumnDefs = savedColumnState.map((columnState: any) => {
                return {
                    field: columnState.colId,
                    width: columnState.width,
                    pinned: columnState.pinned,
                    headerComponent: HeadComponent,
                    cellRenderer: RowComponent,
                    resizable: true,
                };
            });
        }

        return updatedColumnDefs;
    };

    const saveColumnState = (columnState: any) => {
        localStorage.setItem(
            "agGridOrganizationsTable",
            JSON.stringify(columnState),
        );
    };

    const columnDefs: any[] = generateColumnDefs();

    return (
        <AgGrid
            rowData={organizations}
            columnDefs={columnDefs}
            saveColumnState={saveColumnState}
            activeRow={activeOrganizationId}
            setActiveRow={setSelectedOrganization}
            height={"65vh"}
        />
    );
});

export default OrganizationsTable;
