import { FC, useEffect, useMemo, useState, } from 'react';
import { AscSortIcon, CreateIcon, DefaultSortIcon, DescSortIcon, EditIcon, } from 'assets/icons';
import Guard from 'features/Guard';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useTranslation } from 'react-i18next';
import { activateDataByCatalogName, deactivateDataByCatalogName, } from 'store';
import { createOrEditBtn } from 'styles/MUIStyles';

import Button from '@mui/material/Button';

import ConfirmationModal from '../../../features/ConfirmationModal';
import ListItemActions from '../../../features/ListItemActions';
import { baseCatalogItem } from '../../../models/catalogs';
import { ToastType } from '../../../ui/Toast';

import { BaseForm } from './BaseForm';

import styles from '../CatalogsLayout.module.scss';

const BaseCatalog: FC<{
    name: string;
    createOrUpdate: null | string;
    setCreateOrUpdate: Function;
    fulfilledStatusHandler: (message: string, type: ToastType) => void;
}> = ({
          name,
          createOrUpdate,
          setCreateOrUpdate,
          fulfilledStatusHandler,
      }): JSX.Element => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const currentLanguage = localStorage.getItem('i18nextLng');

    const dataByCatalogName = useAppSelector(
        (state) => state.catalogs.dataByCatalogName,
    );
    const allowedPermissions = useAppSelector(
        (state) => state.auth.allowedPermissions,
    );

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [activeCatalogItem, setActiveCatalogItem] =
        useState<null | baseCatalogItem>(null);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState<{
        isOpen: boolean;
        actionName: string;
    }>({ isOpen: false, actionName: '' });
    const [sortConfig, setSortConfig] = useState<{
        column: null | string;
        direction: null | string;
    }>({
        column: null,
        direction: null,
    });

    const activateItem = async () => {
        if (activeCatalogItem?.id || activeCatalogItem?.id === 0) {
            const response = await dispatch(
                activateDataByCatalogName({
                    catalogName: name,
                    catalogNameId: activeCatalogItem?.id,
                }),
            );
            if (response.meta.requestStatus === 'fulfilled') {
                fulfilledStatusHandler(
                    'notifications.successfullyActivated',
                    'success',
                );
                setConfirmationModalOpen({ isOpen: false, actionName: '' });
            }
            return;
        }
    };

    const deactivateItem = async () => {
        if (activeCatalogItem?.id || activeCatalogItem?.id === 0) {
            const response = await dispatch(
                deactivateDataByCatalogName({
                    catalogName: name,
                    catalogNameId: activeCatalogItem?.id,
                }),
            );

            if (
                response.meta.requestStatus === 'fulfilled' &&
                response.payload
            ) {
                fulfilledStatusHandler(
                    t('notifications.recordInUseNotice'),
                    'warn',
                );
                setConfirmationModalOpen({ isOpen: false, actionName: '' });
                return;
            }

            if (response.meta.requestStatus === 'fulfilled') {
                fulfilledStatusHandler(
                    'notifications.successfullyDeactivated',
                    'success',
                );
                setConfirmationModalOpen({ isOpen: false, actionName: '' });
            }
        }
    };

    const onCreateItem = () => {
        setIsDrawerOpen(true);
        setCreateOrUpdate('create');
        setActiveCatalogItem(null);
    };

    const onEditItem = () => {
        setIsDrawerOpen(true);
        setCreateOrUpdate('update');
    };

    const handleSort = (column: string) => {
        if (sortConfig.column === column) {
            setSortConfig({
                column,
                direction: sortConfig.direction === 'asc' ? 'desc' : 'asc',
            });
            return;
        }

        setSortConfig({ column, direction: 'asc' });
    };

    const confirmActionHandler = () => {
        switch (confirmationModalOpen.actionName) {
            case 'Activate':
                activateItem();
                break;
            case 'Deactivate':
                deactivateItem();
                break;
        }
    };

    const sortedGuilds = useMemo(() => {
        const guildsCopy = [...dataByCatalogName];

        if (sortConfig.column && name === 'guilds') {
            guildsCopy.sort((a: any, b: any) => {
                const isAscending = sortConfig.direction === 'asc';
                const compareResult =
                    sortConfig.column &&
                    a[sortConfig.column].localeCompare(
                        b[sortConfig.column],
                        currentLanguage === 'en-US' ? 'en' : 'ru',
                        {
                            sensitivity: 'base',
                        },
                    );

                return isAscending ? compareResult : -compareResult;
            });
        }

        return guildsCopy;
    }, [sortConfig, dataByCatalogName, currentLanguage, name]);

    const getArrowForSortDirection = (column: string) => {
        if (sortConfig.column !== column) {
            return <DefaultSortIcon/>;
        }

        return sortConfig.direction === 'asc' ? (
            <AscSortIcon/>
        ) : (
            <DescSortIcon/>
        );
    };

    useEffect(() => {
        setActiveCatalogItem(null);
    }, [dispatch, name]);

    return (
        <div>
            <div className={styles.crudButtonWrapper}>
                <Guard
                    allowedPermissions={allowedPermissions}
                    permissionName="Create"
                >
                    <Button
                        startIcon={<CreateIcon/>}
                        variant="text"
                        disabled={false}
                        onClick={() => onCreateItem()}
                        sx={createOrEditBtn}
                    >
                        {t('buttons.Create')}
                    </Button>
                </Guard>
                <Guard
                    allowedPermissions={allowedPermissions}
                    permissionName="Edit"
                >
                    <Button
                        startIcon={<EditIcon/>}
                        variant="text"
                        disabled={!activeCatalogItem?.isActive}
                        onClick={() => onEditItem()}
                        sx={createOrEditBtn}
                    >
                        {t('buttons.Edit')}
                    </Button>
                </Guard>
            </div>
            <div className={styles.tableHeader}>
                <span
                    className={styles.column}
                    onClick={() => handleSort('name')}
                >
                    {t('data.name')}{' '}
                    {name === 'guilds'
                        ? getArrowForSortDirection('name')
                        : null}
                </span>
                <span className={styles.column}>{t('data.description')}</span>
                <span className={styles.column}>{t('data.actions')}</span>
            </div>
            <ul className={styles.catalogNameDataList}>
                {name === 'guilds'
                    ? sortedGuilds.map((item) => (
                        <li
                            key={item.id}
                            className={
                                activeCatalogItem?.id === item.id
                                    ? `${styles.catalogNameDataListItem}
                                ${styles.catalogNameDataListItemActive}`
                                    : `${styles.catalogNameDataListItem}`
                            }
                            onClick={() =>
                                setActiveCatalogItem({
                                    id: item.id,
                                    name: item.name,
                                    description: item.description,
                                    isActive: item.isActive,
                                })
                            }
                        >
                              <span className={styles.guildsName}>
                                  {item.name}
                              </span>
                            <span>{item.description}</span>
                            <span>
                                  <ListItemActions
                                      itemId={item.id}
                                      isItemActive={item.isActive}
                                      activationAvailable={true}
                                      setConfirmationModalOpen={
                                          setConfirmationModalOpen
                                      }
                                      editItemClick={onEditItem}
                                  />
                              </span>
                        </li>
                    ))
                    : dataByCatalogName.map((item) => (
                        <li
                            key={item.id}
                            className={
                                activeCatalogItem?.id === item.id
                                    ? `${styles.catalogNameDataListItem}
                                ${styles.catalogNameDataListItemActive}`
                                    : `${styles.catalogNameDataListItem}`
                            }
                            onClick={() =>
                                setActiveCatalogItem({
                                    id: item.id,
                                    name: item.name,
                                    description: item.description,
                                    isActive: item.isActive,
                                })
                            }
                        >
                            <span>{item.name}</span>
                            <span>{item.description}</span>
                            <span>
                                  <ListItemActions
                                      itemId={item.id}
                                      isItemActive={item.isActive}
                                      activationAvailable={true}
                                      setConfirmationModalOpen={
                                          setConfirmationModalOpen
                                      }
                                      editItemClick={onEditItem}
                                  />
                              </span>
                        </li>
                    ))}
            </ul>
            <BaseForm
                name={name}
                activeCatalogItem={activeCatalogItem}
                setActiveCatalogItem={setActiveCatalogItem}
                createOrUpdate={createOrUpdate}
                setCreateOrUpdate={setCreateOrUpdate}
                isDrawerOpen={isDrawerOpen}
                setIsDrawerOpen={setIsDrawerOpen}
                fulfilledStatusHandler={fulfilledStatusHandler}
            />
            <ConfirmationModal
                isModalOpen={confirmationModalOpen.isOpen}
                setIsModalOpen={setConfirmationModalOpen}
                actionName={confirmationModalOpen.actionName}
                instanceName={name}
                handleAction={confirmActionHandler}
            />
        </div>
    );
};

export default BaseCatalog;
