import React, { useEffect } from "react";
import dayjs from "dayjs";
import Guard from "features/Guard";
import { useAppDispatch, useAppSelector } from "hooks";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CONTRACT_EMPLOYEES_PATH, CONTRACTS_PATH } from "router/constants";
import {
    getAllowedGroupsByMenuId,
    getAllowedPermissionsByGroupId,
} from "store";
import { setDefaultValuesForContractData } from "store/slices/contractsSlice";
import {
    getContractById,
    getMainContractById,
} from "store/thunks/contractsEmployees";
import Loader from "ui/Loader";

import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Box, Breadcrumbs, Typography } from "@mui/material";
import Button from "@mui/material/Button";

import { formatNumberWithSpaces } from "../../../../core/utils";

import styles from "./ContractView.module.scss";

const ContractView = () => {
    const params = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const editAndViewContractData = useAppSelector(
        (state) => state.contracts.editAndViewContractData,
    );
    const isGettingEditOrViewContractDataLoading = useAppSelector(
        (state) => state.contracts.isGettingEditOrViewContractDataLoading,
    );
    const mainContractData = useAppSelector(
        (state) => state.contracts.mainContractData,
    );
    const mainContractConclusionDate = localStorage.getItem(
        "mainContractConclusionDate",
    );

    useEffect(() => {
        if (params.contractId) {
            dispatch(getContractById(+params.contractId));
        }
    }, [params, t]);

    const allowedGroups = useAppSelector((state) => state.auth.allowedGroups);
    const allowedPermissions = useAppSelector(
        (state) => state.auth.allowedPermissions,
    );
    const allowedMenus = useAppSelector((state) => state.auth.allowedMenus);

    useEffect(() => {
        const menu = allowedMenus.find((menu) => menu.name === "Contract");
        typeof menu?.id === "number" &&
            dispatch(getAllowedGroupsByMenuId(menu.id));
    }, [allowedMenus, dispatch]);

    useEffect(() => {
        const group = allowedGroups.find(
            (menu) => menu.name === `api/contracts`,
        );
        group?.id && dispatch(getAllowedPermissionsByGroupId(group.id));
    }, [dispatch, allowedGroups]);
    //сумма месяц при редактировании нужно написать валюту

    // useEffect(() => {
    //     if (editAndViewContractData && editAndViewContractData.parentId) {
    //         dispatch(getMainContractById(editAndViewContractData.parentId));
    //     }
    // }, [editAndViewContractData]);

    useEffect(() => {
        return () => {
            dispatch(setDefaultValuesForContractData(null));
        };
    }, []);

    if (isGettingEditOrViewContractDataLoading) {
        return <Loader />;
    }

    return (
        <div className={styles.contractViewWrapper}>
            <Breadcrumbs sx={{ padding: "1rem 0 1rem 0" }}>
                <Typography
                    sx={{ color: "#201f1e", fontFamily: "Source Sans Pro" }}
                >
                    Impulse
                </Typography>
                <Link
                    to={`/${CONTRACTS_PATH}/${CONTRACT_EMPLOYEES_PATH}`}
                    className={styles.breadScrumbsLink}
                >
                    {t("contracts.contractsEmployees")}
                </Link>
            </Breadcrumbs>
            <div className={styles.contractViewContainer}>
                <div className={styles.contractViewTop}>
                    <div>
                        <span>
                            {editAndViewContractData?.number
                                ? t("contracts.contract") +
                                  " " +
                                  editAndViewContractData?.number
                                : null}{" "}
                            {editAndViewContractData?.conclusionDate ||
                            mainContractConclusionDate
                                ? t("contracts.from") +
                                  " " +
                                  dayjs(
                                      editAndViewContractData?.parentId
                                          ? mainContractData?.conclusionDate
                                          : editAndViewContractData?.conclusionDate ||
                                                mainContractConclusionDate,
                                  ).format("DD.MM.YYYY")
                                : null}
                        </span>
                    </div>
                    <Box sx={{ display: "flex", columnGap: "1rem" }}>
                        <Guard
                            allowedPermissions={allowedPermissions}
                            permissionName="Edit"
                        >
                            <Button
                                variant="contained"
                                type="submit"
                                onClick={() => {
                                    if (
                                        params?.contractId &&
                                        editAndViewContractData
                                    ) {
                                        if (!editAndViewContractData.parentId) {
                                            navigate(
                                                `/contracts/management/edit/${params.contractId}`,
                                                { state: params.contractId },
                                            );
                                        } else {
                                            navigate(
                                                `/contracts/details/management/edit/${params.contractId}`,
                                                { state: params.contractId },
                                            );
                                        }
                                    }
                                }}
                                sx={{
                                    fontFamily: "Source Sans Pro",
                                    backgroundColor: "#205493",
                                    textTransform: "capitalize",
                                    fontSize: "1rem",
                                    lineHeight: "1.333rem",
                                    color: "#fff",
                                    fontWeight: "400",
                                    padding: "0 0.7rem 0.1rem 0.7rem",
                                    height: "2.5rem",
                                    borderRadius: "5px",
                                }}
                            >
                                {t("buttons.Edit")}
                            </Button>
                        </Guard>
                        <Button
                            startIcon={<CloseOutlinedIcon />}
                            variant="contained"
                            onClick={() => navigate(-1)}
                            sx={{
                                fontFamily: "Source Sans Pro",
                                backgroundColor: "#205493",
                                textTransform: "capitalize",
                                fontSize: "1rem",
                                lineHeight: "1.333rem",
                                color: "#fff",
                                fontWeight: "400",
                                padding: "0 0.7rem 0.1rem 0.7rem",
                                height: "2.5rem",
                                borderRadius: "5px",
                            }}
                        >
                            {t("buttons.Close")}
                        </Button>
                    </Box>
                </div>
                <div className={styles.contractViewMainInfo}>
                    <h2>{editAndViewContractData?.employeeName || null}</h2>
                    <h3>{editAndViewContractData?.name || null}</h3>
                    <span className={styles.contractViewType}>
                        {editAndViewContractData?.type || null}
                    </span>
                    <span className={styles.contractViewDescription}>
                        {editAndViewContractData?.description || null}
                    </span>
                    <div className={styles.contractViewDataContainer}>
                        {editAndViewContractData?.conclusionDate ? (
                            <div>
                                <span
                                    className={
                                        styles.contractViewConclusionDateLabel
                                    }
                                >
                                    {t("contracts.conclusionDate")}
                                </span>
                                <span
                                    className={
                                        styles.contractViewConclusionDate
                                    }
                                >
                                    {dayjs(
                                        editAndViewContractData.conclusionDate,
                                    ).format("DD.MM.YYYY")}
                                </span>
                            </div>
                        ) : null}
                        {editAndViewContractData?.endDate ? (
                            <div>
                                <span
                                    className={styles.contractViewEndDateLabel}
                                >
                                    {t("contracts.endDate")}
                                </span>
                                <span className={styles.contractViewEndDate}>
                                    {dayjs(
                                        editAndViewContractData.endDate,
                                    ).format("DD.MM.YYYY")}
                                </span>
                            </div>
                        ) : null}
                        {editAndViewContractData?.contractAmount ||
                        editAndViewContractData?.contractAmount === 0 ? (
                            <div>
                                <span
                                    className={
                                        styles.contractViewAmountMonthLabel
                                    }
                                >
                                    {t("contracts.contractAmount")}
                                </span>
                                <div
                                    className={styles.contractViewContractInfo}
                                >
                                    <span
                                        className={
                                            styles.contractViewAmountMonth
                                        }
                                    >
                                        {editAndViewContractData.contractAmount ||
                                        editAndViewContractData.contractAmount ===
                                            0
                                            ? formatNumberWithSpaces(
                                                  editAndViewContractData.contractAmount,
                                              )
                                            : null}
                                    </span>
                                    <span
                                        className={styles.contractViewCurrency}
                                    >
                                        {editAndViewContractData.currency ||
                                            null}
                                    </span>
                                </div>
                            </div>
                        ) : null}
                        {editAndViewContractData?.amountMonth ||
                        editAndViewContractData?.amountMonth === 0 ? (
                            <div>
                                <span
                                    className={
                                        styles.contractViewAmountMonthLabel
                                    }
                                >
                                    {t("contracts.amountMonth")}
                                </span>
                                <div
                                    className={styles.contractViewContractInfo}
                                >
                                    <span
                                        className={
                                            styles.contractViewAmountMonth
                                        }
                                    >
                                        {editAndViewContractData.amountMonth ||
                                        editAndViewContractData.amountMonth ===
                                            0
                                            ? formatNumberWithSpaces(
                                                  editAndViewContractData.amountMonth,
                                              )
                                            : null}
                                    </span>
                                    <span
                                        className={styles.contractViewCurrency}
                                    >
                                        {editAndViewContractData.currency ||
                                            null}
                                    </span>
                                </div>
                            </div>
                        ) : null}
                        <span className={styles.contractViewNote}>
                            {editAndViewContractData?.note || null}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContractView;
