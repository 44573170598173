import React from "react";
import {
    CloseToastIcon,
    ErrorToastIcon,
    InfoToastIcon,
    SuccessToastIcon,
    WarningToastIcon,
} from "assets/icons";
import { toast } from "react-toastify";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import styles from "./Toast.module.scss";

export type ToastType = "info" | "success" | "error" | "warn";

type Props = {
    message: string;
    type: ToastType;
};

const Toast: React.FC<Props> = ({ message, type }): JSX.Element => {
    return (
        <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
            <div className={[styles.stick, styles[type]].join(" ")} />
            {type === "info" && <InfoToastIcon />}
            {type === "success" && <SuccessToastIcon />}
            {type === "error" && <ErrorToastIcon />}
            {type === "warn" && <WarningToastIcon />}
            <Typography ml={2}>{message}</Typography>
            <span className={styles.closeToastWrapper}>
                <CloseToastIcon />
            </span>
        </Box>
    );
};

export const sendNotification = (message: string, type: ToastType) => {
    toast(<Toast message={message} type={type} />, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        closeButton: false,
        bodyClassName: styles.body,
    });
};

export default Toast;
