import React, { FC } from "react";
import dayjs from "dayjs";
import { useAppDispatch, useAppSelector, useUpdateEffect } from "hooks";
import { useTranslation } from "react-i18next";

import { Autocomplete, Button, FormControl, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { getCalculatedFiltersCount } from "../../../../core/utils";
import { IProjectsFilters } from "../../../../models/projects";
import { IRate } from "../../../../models/rates";
import { getCurrencyRates } from "../../../../store/thunks/rateThunk";
import { cancelBtn as resetFiltersBtn } from "../../../../styles/MUIStyles";

type FilterPanelProps = {
    pageSize: number;
    pageNumber: number;
    setPageNumber: (page: number) => void;
    setFiltersCount: (filtersCount: number) => void;
    rateFilters: any;
    setRateFilters: any;
    setSelectedCurrencyName: (s: string | null) => void;
};

export const FilterPanel: FC<FilterPanelProps> = ({
    pageSize,
    pageNumber,
    setPageNumber,
    setFiltersCount,
    rateFilters,
    setRateFilters,
    setSelectedCurrencyName,
}) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const currencies: IRate[] = useAppSelector(
        (state) => state.lookups.currencies,
    );

    const resetFilters = () => {
        setRateFilters({
            ["filter.currencyId"]: null,
            ["filter.rateDate"]: null,
        });
    };

    useUpdateEffect(() => {
        setFiltersCount(getCalculatedFiltersCount(rateFilters));
        if (pageNumber > 1) {
            setPageNumber(1);
        } else {
            const date = new Date(rateFilters["filter.rateDate"])
                .toLocaleDateString()
                .replaceAll(".", "-");
            const formattedDate = dayjs(date, "DD.MM.YYYY").format(
                "YYYY.MM.DD",
            );
            const obj = rateFilters["filter.rateDate"]
                ? { ["filter.rateDate"]: formattedDate }
                : {};

            dispatch(
                getCurrencyRates({
                    ...rateFilters,
                    pageNumber: 1,
                    pageSize: pageSize,
                    ...obj,
                }),
            );
        }
    }, [rateFilters]);

    return (
        <div className="filter-panel">
            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    margin: "10px 0",
                }}
            >
                <Button
                    variant="text"
                    onClick={resetFilters}
                    sx={resetFiltersBtn}
                >
                    {t("buttons.Reset")}
                </Button>
            </div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <FormControl fullWidth>
                    <label
                        htmlFor="organizationId"
                        className="filterTopSubTitle"
                    >
                        {t("rates.name")}
                    </label>
                    <Autocomplete
                        id="organizationId"
                        autoHighlight
                        options={currencies.map((item) => ({
                            ...item,
                            label: item.currencyCode,
                        }))}
                        getOptionLabel={(option: IRate) => option.currencyCode}
                        onChange={(event, value: IRate | null) => {
                            setSelectedCurrencyName(
                                value?.currencyCode || null,
                            );
                            setRateFilters((prevState: any) => ({
                                ...prevState,
                                ["filter.currencyId"]: value?.id,
                            }));
                        }}
                        sx={{ width: 200 }}
                        value={
                            currencies.filter(
                                (el: IRate) =>
                                    el.id === rateFilters["filter.currencyId"],
                            )[0] || null
                        }
                        renderInput={(params) => (
                            <TextField {...params} size="small" />
                        )}
                    />
                </FormControl>
                <Box>
                    <h4 style={{ marginTop: 8 }}>{t("rates.rateDate")}</h4>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            "& > label": {
                                fontWeight: 600,
                            },
                        }}
                    >
                        <DesktopDatePicker
                            inputFormat="YYYY-MM-DD"
                            value={rateFilters["filter.rateDate"]}
                            onChange={(newValue) => {
                                setRateFilters(
                                    (prevState: IProjectsFilters) => ({
                                        ...prevState,
                                        ["filter.rateDate"]: newValue,
                                    }),
                                );
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    size="small"
                                    sx={{
                                        width: "100%",
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            borderRadius: "0",
                                            border: "1px solid #8A8886",
                                        },
                                        "& .MuiOutlinedInput-root": {
                                            "&:hover fieldset": {
                                                border: "1px solid #8A8886",
                                            },
                                            "&.Mui-focused fieldset": {
                                                borderColor: "#80bdff",
                                                boxShadow:
                                                    "0 0 0 0.2rem rgba(0, 123, 255, .25)",
                                            },
                                        },
                                        padding: "5px 0",
                                    }}
                                    inputProps={{
                                        ...params.inputProps,
                                        placeholder: t(
                                            "projects.dayMonthYear",
                                        ) as string,
                                        autoComplete: "off",
                                    }}
                                />
                            )}
                        />
                    </Box>
                </Box>
            </LocalizationProvider>
        </div>
    );
};
