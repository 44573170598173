import React, { FC, SyntheticEvent } from 'react';

import Tab from '@mui/material/Tab';
import TabsMui from '@mui/material/Tabs';

interface TabsProps {
    handleChangeTabs?: (event: SyntheticEvent, value: any) => void;
    // у mui any
    value: any;
    tabs: Tab[];
}

export interface Tab {
    label: string;
    value: any;
}

export const Tabs: FC<TabsProps> = ({ handleChangeTabs, value, tabs }) => {
    return (
        <TabsMui value={value} onChange={handleChangeTabs}>
            {tabs.map((tab) => (
                <Tab key={tab.label} {...tab} />
            ))}
        </TabsMui>
    );
};
