import axios from "axios";
import axiosApi from "core/request";
import { ITimeZone } from "models/timeZone";

import { createAsyncThunk } from "@reduxjs/toolkit";

export const getTimeZones = createAsyncThunk('catalogs/getTimeZones',
    async (timeZonesEndpointPath: string, { rejectWithValue }) => {
        try {
            const response = await axiosApi.get(`/${timeZonesEndpointPath}`);
            return response.data;
        } catch (error) {
            //TODO
            return rejectWithValue('Error that i need to handle');
        }
    }
);

export const createTimeZone = createAsyncThunk<any, ITimeZone, {rejectValue: {nameZone: string[], offset: string[]} | string}>('catalogs/createTimeZone',
    async (timeZoneData: ITimeZone, { rejectWithValue }) => {
        try {
            const response = await axiosApi.post(`/${timeZoneData.timeZonesEndpointPath}`, {
                nameZone: timeZoneData.nameZone,
                offset: timeZoneData.offset,
            });
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                if (error.response.status === 400 ) {
                    return rejectWithValue(error.response.data.errors);
                } else {
                    return rejectWithValue('Что-то пошло не так... Попробуйте обновить страницу');
                }
            }

            if (!navigator.onLine) {
                return rejectWithValue('Проверьте подключение к интернету');
            }

            return rejectWithValue('Что-то пошло не так... Попробуйте обновить страницу');
        }
    }
);

export const editTimeZone = createAsyncThunk<any, ITimeZone, {rejectValue: {nameZone: string[], offset: string[]} | string}>('catalogs/editTimeZone',
    async (timeZoneData, { rejectWithValue }) => {
        try {
            const response = await axiosApi.put(`/${timeZoneData.timeZonesEndpointPath}`, {
                id: timeZoneData.id,
                nameZone: timeZoneData.nameZone,
                offset: timeZoneData.offset
            });
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                if (error.response.status === 400 ) {
                    return rejectWithValue(error.response.data.errors);
                } else {
                    return rejectWithValue('Что-то пошло не так... Попробуйте обновить страницу');
                }
            }

            if (!navigator.onLine) {
                return rejectWithValue('Проверьте подключение к интернету');
            }

            return rejectWithValue('Что-то пошло не так... Попробуйте обновить страницу');
        }
    }
);
