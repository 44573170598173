import { FC } from "react";
import { useTranslation } from "react-i18next";

import Button from "@mui/material/Button";

import { cancelBtn, saveBtn } from "../../styles/MUIStyles";
import ButtonWithProgress from "../../ui/ButtonWithProgress";

import styles from "../../views/Catalogs/CatalogsLayout.module.scss";

const Index: FC<{
    isLoading: boolean,
    createOrUpdate: string | null,
    cancelHandler: () => void
}> = ({ isLoading, createOrUpdate, cancelHandler }) => {
    const { t } = useTranslation();

    return (
        <>
            <div className={styles.drawerButtonButtonsWrapper}>
                <ButtonWithProgress
                    loading={isLoading}
                    disabled={isLoading}
                    type="submit"
                    variant="contained"
                    sx={saveBtn}
                >
                    {createOrUpdate === 'create' ? t("buttons.Create") : t("buttons.Save")}
                </ButtonWithProgress>
                <Button
                    variant="text"
                    onClick={() => cancelHandler()}
                    sx={cancelBtn}
                >
                    {t("buttons.Cancel")}
                </Button>
            </div>
        </>
    );
};

export default Index;
