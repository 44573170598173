import { createAsyncThunk } from "@reduxjs/toolkit";

import axiosApi from "../../core/request";

export const getAllUsers = createAsyncThunk(
    "usersPermissions/getAllUsers",
    async (
        searchQuery: {
            sortBy?: { column: string | null; direction: string | null } | null;
            pageSize?: number | null;
            role?: string | null;
            country?: { id: number; name: string } | null;
            userName?: string | null;
            email?: string | null;
            pageNumber?: number | null;
        },
        { rejectWithValue },
    ) => {
        try {
            const params = new URLSearchParams();

            if (searchQuery.pageSize) {
                params.append("pageSize", String(searchQuery.pageSize));
            }

            if (searchQuery.pageNumber) {
                params.append("pageNumber", String(searchQuery.pageNumber));
            }

            if (searchQuery.role) {
                params.append("filter.roleName", String(searchQuery.role));
            }

            if (searchQuery.country) {
                params.append(
                    "filter.countryId",
                    String(searchQuery.country.id),
                );
            }

            if (searchQuery.userName) {
                params.append("filter.userName", String(searchQuery.userName));
            }

            if (searchQuery.email) {
                params.append("filter.email", String(searchQuery.email));
            }

            if (searchQuery.sortBy?.column && searchQuery.sortBy.direction) {
                const column = searchQuery.sortBy?.column;
                const direction = searchQuery.sortBy.direction;
                params.append("sortBy", `${column}.${direction}`);
            }

            const response = await axiosApi.get("/users/permissions/users", {
                params,
            });
            return response.data;
        } catch (error) {
            return rejectWithValue("Error");
        }
    },
);

export const getUsersAllPermissions = createAsyncThunk(
    "users/allPermissions",
    async (_, { rejectWithValue }) => {
        try {
            const response = await axiosApi.get("/users/permissions/all");
            return response.data;
        } catch (error) {
            return rejectWithValue("Error");
        }
    },
);

export const getListOfPermissionsByUserId = createAsyncThunk(
    "users/permissionsByUserId",
    async (userId: string, { rejectWithValue }) => {
        try {
            const response = await axiosApi.get(
                `/users/permissions?userId=${userId}`,
            );
            return response.data;
        } catch (error) {
            //TODO
            return rejectWithValue("Error");
        }
    },
);

export const updateUserPermissions = createAsyncThunk(
    "users/updateUserPermissions",
    async (
        permission: { userId: string; permissionIds: number[] },
        { rejectWithValue },
    ) => {
        try {
            const response = await axiosApi.post(
                "/users/permissions",
                permission,
            );
            return response.data;
        } catch (error) {
            //TODO
            return rejectWithValue("Error");
        }
    },
);
