import React, { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from '../../../hooks';
import { ICommonDocument } from '../../../models/commonDocument';
import { editPersonalDocument, getPersonalDocuments } from '../../../store';
import { editDocument, getDocuments } from '../../../store/thunks/commonDocumentsThunk';
import { SelectFile } from '../../../ui/SelectFile';
import { sendNotification } from '../../../ui/Toast';

interface EditFileProps {
    id: number;
    // setName?: string;
    name?: string;
    description?: string;
    isPersonal: boolean;
    setIsOpenEditFile: (isOpenEditFile: boolean) => void;
    currentDoc?: ICommonDocument | null;
}

export const EditFile: FC<EditFileProps> = ({ id, setIsOpenEditFile, isPersonal, currentDoc }) => {
    const [file, setFile] = useState<File | null>(null);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const fileInput = useRef(null);

    const [name, setName] = useState(currentDoc?.name|| '');
    const [description, setDescription] = useState(currentDoc?.description || '');
    const handleUpload = (event: any) => {
        setFile(event.target.files[0]);
    };

    const save = async () => {
        try {
            dispatch(isPersonal
                ? editPersonalDocument({ id, name, description, file })
                : editDocument({ id, name, description, file }))
                .then(() => {
                    setIsOpenEditFile(false);
                    dispatch(isPersonal ? getPersonalDocuments() : getDocuments());
                    sendNotification(t('statuses.Success'), 'success');
                });
        } catch (e) {
            console.log(e);
            sendNotification(t('statuses.Error'), 'error');
        }
    };

    return (
        <div>
        <SelectFile
            file={file}
            name={name}
            description={description}
            setName={setName}
            setDescription={setDescription}
            originalFileName={currentDoc?.originalFileName}
            fileInput={fileInput}
            handleCancelClick={() => setIsOpenEditFile(false)}
            handleUpload={handleUpload}
            save={save}
        />
    </div>
    );
};
