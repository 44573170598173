import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ILastUserImported, ILog } from "../../models/importLogs";
import {
    getLogs,
    importGetLast,
    importIsStarted,
    importStart,
} from "../thunks/import";

type ImportSlice = {
    isStarted: boolean;
    logs: ILog[];
    currentLogs: ILog[];
    lastUserImported: ILastUserImported | null;
    logsLoader: boolean;
};

const initialState: ImportSlice = {
    logs: [],
    currentLogs: [],
    isStarted: false,
    lastUserImported: null,
    logsLoader: false,
};

const importSlice = createSlice({
    name: "import",
    initialState,
    reducers: {
        setStartImport(
            state,
            action: PayloadAction<{
                isStarted: boolean;
            }>,
        ) {
            state.isStarted = action.payload.isStarted;
        },
        setCurrentLogs(state, action: PayloadAction<{ log: ILog }>) {
            state.currentLogs = [...state.currentLogs, action.payload.log];
        },
        clearLogs(state) {
            state.currentLogs = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(importStart.fulfilled, (state, action) => {
                state.isStarted = action.payload.data;
            })
            .addCase(getLogs.pending, (state) => {
                state.logsLoader = true;
            })
            .addCase(getLogs.fulfilled, (state, action) => {
                state.logsLoader = false;
                state.logs = action.payload.data;
            })
            .addCase(importIsStarted.fulfilled, (state, action) => {
                state.isStarted = action.payload.data.isStarted;
            })
            .addCase(importGetLast.fulfilled, (state, action) => {
                state.lastUserImported = action.payload.data;
            });
    },
});

export const { setStartImport, setCurrentLogs, clearLogs } =
    importSlice.actions;

export default importSlice.reducer;
