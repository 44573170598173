import React, { FC } from 'react';

import { Main } from './Main';

export const CurrencyRates: FC<{
    name: string;
    createOrUpdate: null | string;
    setCreateOrUpdate: Function;
}> = (props) => {
    return (
        <div>
            {/*<Header />*/}
            <Main {...props} />
        </div>
    );
};
