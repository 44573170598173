import React from 'react';

import { EditEmployee } from '../../features/EditEmployee/EditEmployee';
import { useAppSelector } from '../../hooks';

export const PersonalAccountEdit = () => {
    const id = useAppSelector((state) => state.auth.userId);

    return (
        <EditEmployee id={id!} isMyAccount/>
    );
};
