import React, { PropsWithChildren, useEffect, useState } from 'react';
import PageNotFound from "features/PageNotFound";
import { useAppSelector } from "hooks";
import { useLocation } from 'react-router-dom';
import Loader from "ui/Loader";

const HasPermission: React.FC<PropsWithChildren> = ({ children }) => {
    const location = useLocation();
    const allowedMenus = useAppSelector(state => state.auth.allowedMenus);
    const allowedGroups = useAppSelector(state => state.auth.allowedGroups);

    const [isInitialized, setIsInitialized] = useState(false);
    const [hasPermission, setHasPermission] = useState(false);

    //TODO: REMAKE HAS PERMISSION
    useEffect(() => {
        const path = location.pathname.split('/');
        if (allowedMenus.length > 0) {
            path[1] && allowedMenus.some(menu =>
                menu.name.toLowerCase() === path[1].toLowerCase()) && setHasPermission(true);
            if (hasPermission && allowedGroups.length > 0 ) {
                path[2] && setHasPermission(allowedGroups.some(
                    group =>
                        group.name.toLowerCase() === `api/${path[2].toLowerCase()}`
                        || group.name.toLowerCase() === `api/${path.slice(2).join("/").toLowerCase()}`
                        || group.name.toLowerCase() === `api/${path.slice(2, -1).join("/").toLowerCase()}`
                ));
            }
        }
        setIsInitialized(true);
    }, [allowedGroups, allowedMenus, location.pathname]);

    if (!isInitialized){
        return <Loader/>;
    }

    return hasPermission ? (
        <>{children}</>
    ) : (
        <PageNotFound/>
    );
};

export default HasPermission;
