import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ErrorMessage } from '@hookform/error-message';

import CatalogItemActionsComponent from '../../../features/Catalogs/CatalogItemActionsComponent';
import ConfirmationModal from '../../../features/ConfirmationModal';
import FormButtons from '../../../features/FormButtons';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import useSetServerError from '../../../hooks/useSetServerError';
import { baseCatalogItem } from '../../../models/catalogs';
import { clearCatalogTextFieldErrors, createDataByCatalogName, editDataByCatalogName } from '../../../store';

import styles from '../CatalogsLayout.module.scss';

export interface IFormInput {
    name: string;
    description: string;
}

export const BaseForm: FC<{
    name: string;
    activeCatalogItem: baseCatalogItem | null;
    setActiveCatalogItem: (catalogData: baseCatalogItem | null) => void;
    createOrUpdate: string | null;
    isDrawerOpen: boolean;
    setCreateOrUpdate: Function;
    setIsDrawerOpen: Dispatch<SetStateAction<boolean>>;
    fulfilledStatusHandler: Function;
}> = ({
          name,
          activeCatalogItem,
          setActiveCatalogItem,
          createOrUpdate,
          isDrawerOpen,
          setCreateOrUpdate,
          setIsDrawerOpen,
          fulfilledStatusHandler,
      }): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const isCreatingOrUpdatingLoading = useAppSelector(
        (state) => state.catalogs.isCreatingOrUpdatingLoading,
    );
    const catalogsDataByNameErrors = useAppSelector(
        (state) => state.catalogs.catalogsDataByNameErrors,
    );

    const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors },
        setError,
        clearErrors,
    } = useForm<IFormInput>();

    const onSubmit: SubmitHandler<IFormInput> = async (
        formData: IFormInput,
    ) => {
        const body = {
            catalogName: name,
            name: formData.name,
            description: formData.description,
        };

        if (createOrUpdate === 'create') {
            const response = await dispatch(
                createDataByCatalogName({ ...body }),
            );
            if (response.meta.requestStatus === 'fulfilled') {
                fulfilledStatusHandler('notifications.successfullyCreated');
                setIsDrawerOpen(false);
                // setActiveCatalogItem(null);
            }
            return;
        }

        if (activeCatalogItem && createOrUpdate === 'update') {
            const response = await dispatch(
                editDataByCatalogName({
                    ...body,
                    catalogNameId: activeCatalogItem.id,
                }),
            );
            if (response.meta.requestStatus === 'fulfilled') {
                fulfilledStatusHandler('notifications.successfullySaved');
                setIsDrawerOpen(false);
                setActiveCatalogItem(null);
            }
        }
    };

    const formClose = () => {
        if (formChangesChecking()) setConfirmationModalOpen(true);
        else closeFormDrawer();
    };

    const closeFormDrawer = () => {
        setCreateOrUpdate(null);
        setIsDrawerOpen(false);
    };

    const closeModalConfirm = () => {
        setFormData(null);
        setIsDrawerOpen(false);
        setConfirmationModalOpen(false);
    };

    // returns true, if form was updated
    const formChangesChecking = () => {
        if (createOrUpdate === 'create') {
            return Boolean(getValues('name') || getValues('description'));
        } else {
            return Boolean(
                getValues('name') !== activeCatalogItem?.name ||
                getValues('description') !== activeCatalogItem?.description,
            );
        }
    };

    const setFormData = (fields: IFormInput | null) => {
        setValue('name', fields?.name || '');
        setValue('description', fields?.description || '');
    };

    useSetServerError({
        serverError: catalogsDataByNameErrors,
        setError,
        clearErrors,
        errorNames: ['name', 'description'],
    });

    useEffect(() => {
        if (isDrawerOpen) {
            setFormData(activeCatalogItem);
        } else {
            clearErrors();
            dispatch(clearCatalogTextFieldErrors());
        }
    }, [dispatch, isDrawerOpen]);

    return (
        <>
            <CatalogItemActionsComponent
                name={name}
                isDrawerOpen={isDrawerOpen}
                createOrUpdate={createOrUpdate}
                closeFormDrawer={() => formClose()}
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={styles.formInputWrapper}>
                        <label
                            htmlFor="name"
                            className={styles.drawerTopSubTitle}
                        >
                            {t('data.name')}
                        </label>
                        <div>
                            <input
                                {...register('name', {
                                    required: t(
                                        'validation.requiredField',
                                    ) as string,
                                    maxLength: {
                                        value: 30,
                                        message: t('validation.maxLength30'),
                                    },
                                })}
                                id="name"
                                type="text"
                                autoComplete="off"
                                placeholder={t('data.name') as string}
                                className={
                                    errors?.name
                                        ? styles.catalogsDrawerInputError
                                        : styles.catalogsDrawerInput
                                }
                            />
                            <ErrorMessage
                                name="name"
                                errors={errors}
                                render={({ message }) => <span>{message}</span>}
                            />
                        </div>
                        <label
                            htmlFor="description"
                            className={styles.drawerTopSubTitle}
                        >
                            {t('data.description')}
                        </label>
                        <div>
                            <input
                                {...register('description', {
                                    maxLength: {
                                        value: 150,
                                        message: t('validation.maxLength150'),
                                    },
                                })}
                                id="description"
                                type="text"
                                autoComplete="off"
                                placeholder={t('data.description') as string}
                                className={
                                    errors?.description
                                        ? styles.catalogsDrawerInputError
                                        : styles.catalogsDrawerInput
                                }
                            />
                            <ErrorMessage
                                name="description"
                                errors={errors}
                                render={({ message }) => <span>{message}</span>}
                            />
                        </div>
                    </div>
                    <FormButtons
                        isLoading={isCreatingOrUpdatingLoading}
                        createOrUpdate={createOrUpdate}
                        cancelHandler={() => formClose()}
                    />
                </form>
            </CatalogItemActionsComponent>

            <ConfirmationModal
                isModalOpen={isConfirmationModalOpen}
                setIsModalOpen={setConfirmationModalOpen}
                actionName={'Close'}
                instanceName=""
                message={'closeFormWithoutSaving'}
                handleAction={() => closeModalConfirm()}
            />
        </>
    );
};
