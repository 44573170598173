import React from "react";
import { EditIcon, ViewIcon } from "assets/icons";
import dayjs from "dayjs";
import Guard from "features/Guard";
import { useAppSelector } from "hooks";
import { Link, useNavigate } from "react-router-dom";
import uuid from "react-uuid";
import { materialReactTableCell } from "styles/MUIStyles";

import { Box } from "@mui/material";

import { Employee, ProjectTracking } from "../types";

export const ProjectCellRenderer: React.FC<{ data: Employee }> = ({ data }) => {
    return (
        <Box sx={materialReactTableCell} style={{ textAlign: "center" }}>
            {data.partialTimeTrackingOfProjectDto?.map((tracking, index) => (
                <Box
                    key={uuid() + "Project"}
                    sx={{
                        ...materialReactTableCell,
                        display: "flex",
                        marginTop: index && "10px",
                        height: "20px",
                    }}
                >
                    <Link to={`/projects/view/${tracking.projectId}`}>
                        {tracking.projectCode}
                    </Link>
                    <Link to={`/projects/view/${tracking.projectId}`}>
                        {tracking.shortName}
                    </Link>
                </Box>
            ))}
        </Box>
    );
};
export const MainSkillCellRenderer: React.FC<{ data: Employee }> = ({
    data,
}) => {
    return (
        <Box sx={materialReactTableCell} style={{ textAlign: "center" }}>
            <p>{data.skill}</p>
            <p>{data.skillLevel}</p>
        </Box>
    );
};
export const FromDateCellRenderer: React.FC<{ data: Employee }> = ({
    data,
}) => {
    return (
        <Box sx={materialReactTableCell} key={uuid() + "TotalTime"}>
            {data?.partialTimeTrackingOfProjectDto?.map(
                (tracking: ProjectTracking, index: number) => (
                    <p
                        key={uuid() + "FromDate"}
                        style={{ marginTop: index && "10px", height: "20px" }}
                    >
                        {tracking.startDate
                            ? dayjs(tracking.startDate).format("DD.MM.YYYY")
                            : null}
                    </p>
                ),
            )}
        </Box>
    );
};
export const FteEmployeeCellRenderer: React.FC<{ data: any }> = ({ data }) => (
    <Box sx={materialReactTableCell} key={uuid() + "TotalTime"}>
        {data.partialTimeTrackingOfProjectDto?.map(
            (tracking: ProjectTracking, index: number) => (
                <p
                    key={uuid() + "TotalTime"}
                    style={{ marginTop: index && "10px", height: "20px" }}
                >
                    {tracking.fte}
                </p>
            ),
        )}
    </Box>
);
export const TotalTimeCellRenderer: React.FC<{ data: Employee }> = ({
    data,
}) => {
    return (
        <Box sx={materialReactTableCell}>
            {data.partialTimeTrackingOfProjectDto?.map((tracking, index) => (
                <p
                    key={uuid() + "TotalTime"}
                    style={{ marginTop: index && "10px", height: "20px" }}
                >
                    {tracking.startDate}
                </p>
            ))}
        </Box>
    );
};
export const TabNoCellRenderer: React.FC<{ data: Employee }> = ({ data }) => (
    <Box sx={materialReactTableCell} key={uuid() + "TotalTime"}>
        {data.personnelNumber ? data.personnelNumber : null}
    </Box>
);
export const EmployeeProjectCellRenderer: React.FC<{ data: any }> = ({
    data,
}) => {
    return <Box sx={materialReactTableCell}>{data.organization}</Box>;
};
export const NamePositionEmpoyeeCellRenderer: React.FC<{ data: any }> = ({
    data,
}) => {
    return (
        <Box sx={materialReactTableCell}>
            <Box key={uuid() + "Project"} style={{ textAlign: "center" }}>
                <Link to={`/employees/${data.employeeId}`}>
                    <p>{data.fullName}</p>
                </Link>
                <Link to={`/employees/${data.employeeId}`}>
                    <p>{data.position}</p>
                </Link>
            </Box>
        </Box>
    );
};
export const HoursEmpoyeeCellRenderer: React.FC<{ data: any }> = ({ data }) => {
    return (
        <Box sx={materialReactTableCell}>
            {data.partialTimeTrackingOfProjectDto?.map(
                (tracking: ProjectTracking, index: number) => (
                    <p
                        key={uuid() + "TotalTime"}
                        style={{ marginTop: index && "10px", height: "20px" }}
                    >
                        {tracking.totalTime}
                    </p>
                ),
            )}
        </Box>
    );
};

const ActionsCellRenderer = (props: any) => {
    const navigate = useNavigate();
    const allowedPermissions = props.allowedPermissions;
    return (
        <Box
            className={"actions_cell"}
            sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                width: "40%",
                cursor: "pointer",
            }}
        >
            <Guard
                allowedPermissions={allowedPermissions}
                permissionName="View"
            >
                <p
                    onClick={() =>
                        navigate(
                            `/tracking/employee/view/${props.data.employeeId}`,
                        )
                    }
                >
                    <ViewIcon />
                </p>
            </Guard>

            <Guard
                allowedPermissions={allowedPermissions}
                permissionName="Edit"
            >
                <p
                    onClick={() =>
                        navigate(`management/edit/${props.data.projectId}`)
                    }
                >
                    <EditIcon />
                </p>
            </Guard>
        </Box>
    );
};

export const EmployeeCellRenderer = (props: any) => {
    const { data, colDef } = props;
    const allowedPermissions = useAppSelector(
        (state) => state.auth.allowedPermissions,
    );

    switch (colDef.field) {
        case "project":
            return <ProjectCellRenderer data={data} />;
        case "nameAndPosition":
            return <NamePositionEmpoyeeCellRenderer data={data} />;
        case "skill":
            return <MainSkillCellRenderer data={data} />;
        case "fromDate":
            return <FromDateCellRenderer data={data} />;
        case "fte":
            return <FteEmployeeCellRenderer data={data} />;
        case "organization":
            return <EmployeeProjectCellRenderer data={data} />;
        case "partialTimeTrackingOfProjectDto":
            return <TotalTimeCellRenderer data={data} />;
        case "personnelNumber":
            return <TabNoCellRenderer data={data} />;
        case "hours":
            return <HoursEmpoyeeCellRenderer data={data} />;
        case "actions":
            return (
                <ActionsCellRenderer
                    data={data}
                    allowedPermissions={allowedPermissions}
                />
            );
        default:
            return null;
    }
};
