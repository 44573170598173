import { useParams } from "react-router-dom";

import { EditEmployee } from "../../features/EditEmployee/EditEmployee";

const EditEmployeeForm = () => {
    const { id } = useParams();

    return <EditEmployee id={id!} />;
};

export default EditEmployeeForm;
