import React, { FC, useEffect, useMemo, useState, } from 'react';
import { CreateIcon, EditIcon } from 'assets/icons';
import Guard from 'features/Guard';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useTranslation } from 'react-i18next';
import { activateDataByCatalogName, deactivateDataByCatalogName, } from 'store';
import { createOrEditBtn } from 'styles/MUIStyles';

import Button from '@mui/material/Button';

import ConfirmationModal from '../../../features/ConfirmationModal';
import ListItemActions from '../../../features/ListItemActions';
import { grades } from '../../../models/grades';

import { GradeForm } from './GradeForm';

import styles from '../CatalogsLayout.module.scss';

export interface IFormInput {
    name: string;
    description: string;
    gradeValue: number | string;
    gradeLevel: number | string;
    parentId: number | string | null;
    isGroup: boolean;
}

const Grades: FC<{
    name: string;
    createOrUpdate: null | string;
    setCreateOrUpdate: Function;
    fulfilledStatusHandler: Function;
}> = ({
          name,
          createOrUpdate,
          setCreateOrUpdate,
          fulfilledStatusHandler,
      }): JSX.Element => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const grades = useAppSelector((state) => state.catalogs.grades);
    const allowedPermissions = useAppSelector(
        (state) => state.auth.allowedPermissions,
    );

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [activeCatalogItem, setActiveCatalogItem] = useState<null | grades>(
        null,
    );
    const [isGroupChecked, setIsGroupChecked] = useState(false);

    const [confirmationModalOpen, setConfirmationModalOpen] = useState<{
        isOpen: boolean;
        actionName: string;
    }>({ isOpen: false, actionName: '' });

    const activateItem = async () => {
        if (activeCatalogItem?.id) {
            const response = await dispatch(
                activateDataByCatalogName({
                    catalogName: name,
                    catalogNameId: activeCatalogItem?.id,
                }),
            );
            if (response.meta.requestStatus === 'fulfilled') {
                fulfilledStatusHandler('notifications.successfullyActivated');
                setConfirmationModalOpen({ isOpen: false, actionName: '' });
            }
        }
    };

    const deactivateItem = async () => {
        if (activeCatalogItem?.id) {
            const response = await dispatch(
                deactivateDataByCatalogName({
                    catalogName: name,
                    catalogNameId: activeCatalogItem?.id,
                }),
            );

            if (
                response.meta.requestStatus === 'fulfilled' &&
                response.payload
            ) {
                fulfilledStatusHandler(
                    t('notifications.recordInUseNotice'),
                    'warn',
                );
                setConfirmationModalOpen({ isOpen: false, actionName: '' });
                return;
            }

            if (response.meta.requestStatus === 'fulfilled') {
                fulfilledStatusHandler('notifications.successfullyDeactivated');
                setConfirmationModalOpen({ isOpen: false, actionName: '' });
            }
        }
    };

    const onCreateItem = () => {
        setIsDrawerOpen(true);
        setCreateOrUpdate('create');
        setActiveCatalogItem(null);
    };

    const onEditItem = () => {
        setIsDrawerOpen(true);
        setCreateOrUpdate('update');
    };

    const confirmActionHandler = async () => {
        switch (confirmationModalOpen.actionName) {
            case 'Activate':
                await activateItem();
                break;
            case 'Deactivate':
                await deactivateItem();
                break;
        }
    };

    useEffect(() => {
        setActiveCatalogItem(null);
    }, [dispatch, name]);

    const sortedDataByGradeName = useMemo(() => {
        return [...grades].sort((a: grades, b: grades) => {
            if (
                a.name[0] === 'G' ||
                a.name[0] === 'P' ||
                a.name[0] === 'M' ||
                a.name[0] === 'T'
            ) {
                return a.name.localeCompare(b.name, undefined, {
                    numeric: true,
                });
            } else {
                return 1;
            }
        });
    }, [grades]);

    return (
        <div>
            <div className={styles.crudButtonWrapper}>
                <Guard
                    allowedPermissions={allowedPermissions}
                    permissionName="Create"
                >
                    <Button
                        startIcon={<CreateIcon/>}
                        variant="text"
                        disabled={false}
                        onClick={() => onCreateItem()}
                        sx={createOrEditBtn}
                    >
                        {t('buttons.Create')}
                    </Button>
                </Guard>
                <Guard
                    allowedPermissions={allowedPermissions}
                    permissionName="Edit"
                >
                    <Button
                        startIcon={<EditIcon/>}
                        variant="text"
                        disabled={!activeCatalogItem?.isActive}
                        onClick={() => onEditItem()}
                        sx={createOrEditBtn}
                    >
                        {t('buttons.Edit')}
                    </Button>
                </Guard>
            </div>
            <div className={styles.tableHeader}>
                <span className={styles.column}>{t('data.level')}</span>
                <span className={styles.column}>{t('data.name')}</span>
                <span className={styles.column}>{t('data.value')}</span>
                <span className={styles.column}>{t('data.description')}</span>
                <span className={styles.column}>{t('data.actions')}</span>
            </div>
            <ul className={styles.catalogNameDataList}>
                {sortedDataByGradeName.map((item) => (
                    <li
                        key={item.id}
                        className={
                            activeCatalogItem?.id === item.id
                                ? `${styles.catalogNameDataListItem}
                                ${styles.catalogNameDataListItemActive}`
                                : `${styles.catalogNameDataListItem}`
                        }
                        onClick={() => setActiveCatalogItem({ ...item })}
                    >
                        <span>{item.gradeLevel}</span>
                        <span>{item.name}</span>
                        <span>{item.gradeValue}</span>
                        <span>{item.description}</span>
                        <span>
                            <ListItemActions
                                itemId={item.id}
                                isItemActive={item.isActive}
                                activationAvailable={true}
                                setConfirmationModalOpen={
                                    setConfirmationModalOpen
                                }
                                editItemClick={onEditItem}
                            />
                        </span>
                    </li>
                ))}
            </ul>

            <GradeForm
                name={name}
                activeCatalogItem={activeCatalogItem}
                setActiveCatalogItem={setActiveCatalogItem}
                createOrUpdate={createOrUpdate}
                setCreateOrUpdate={setCreateOrUpdate}
                isDrawerOpen={isDrawerOpen}
                isGroupChecked={isGroupChecked}
                setIsDrawerOpen={setIsDrawerOpen}
                fulfilledStatusHandler={fulfilledStatusHandler}
                setIsGroupChecked={setIsGroupChecked}
            />
            <ConfirmationModal
                isModalOpen={confirmationModalOpen.isOpen}
                setIsModalOpen={setConfirmationModalOpen}
                actionName={confirmationModalOpen.actionName}
                instanceName={name}
                handleAction={confirmActionHandler}
            />
        </div>
    );
};

export default Grades;
