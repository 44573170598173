import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Header from "views/Layout/Header";
import SideBar from "views/Layout/SideBar";

import CustomNavigate from "../../core/utils/CustomNavigate/CustomNavigate";
import { getAllowedMenus } from "../../store";

import "./style.scss";

const Layout = () => {
    const location = useLocation();
    const dispatch = useAppDispatch();
    const allowedMenus = useAppSelector((state) => state.auth.allowedMenus);

    useEffect(() => {
        dispatch(getAllowedMenus());
    }, [dispatch]);

    if (location.pathname === "/" && allowedMenus.length) {
        return <Navigate to={allowedMenus[0].name.toLowerCase()} />;
    }

    return (
        <>
            <CustomNavigate />
            <div className={"app_layout"}>
                <Header className={"app_header"} />
                <SideBar className={"app_sidebar"} />
                <main className={"app_content"}>
                    <Outlet />
                </main>
            </div>
        </>
    );
};

export default Layout;
