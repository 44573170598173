import axiosApi from "core/request";

import { createAsyncThunk } from "@reduxjs/toolkit";

interface Params {
    pageSize?: number;
    pageNumber?: number;
}

export const getCurrencyRates = createAsyncThunk(
    "api/currencyrates",
    async (params: Params, { rejectWithValue }) => {
        try {
            const response = await axiosApi.get("/currencyRates", {
                params,
            });
            return response.data;
        } catch (error) {
            //TODO
            return rejectWithValue("Error that i need to handle");
        }
    },
);

export const getActiveRates = createAsyncThunk(
    "active-rates",
    async (params: Params, { rejectWithValue }) => {
        try {
            const response = await axiosApi.get("/currencyrates/active");
            return response.data;
        } catch (error) {
            //TODO
            return rejectWithValue("Error that i need to handle");
        }
    },
);

export const updateRate = createAsyncThunk(
    "edit-currency-rates",
    async (data: any, { rejectWithValue }) => {
        try {
            const response = await axiosApi.put("/currencyRates", {
                id: data.id,
                rate: data.rate,
                rateDate: data.rateDate,
            });

            return response.data;
        } catch (error) {
            //TODO
            return rejectWithValue("Error that i need to handle");
        }
    },
);

export const createRate = createAsyncThunk(
    "create-currency-rate",
    async (data: any, { rejectWithValue }) => {
        try {
            const response = await axiosApi.post("/currencyRates", data);

            return response.data;
        } catch (error) {
            //TODO
            return rejectWithValue("Error that i need to handle");
        }
    },
);
