export function mergeArrays(arr1: any[], arr2: any[]) {
    const merged: any = {};

    arr1.forEach(item => {
        merged[item.id] = Object.assign(merged[item.id] || {}, item);
    });

    arr2.forEach(item => {
        merged[item.id] = Object.assign(merged[item.id] || {}, item);
    });

    return Object.values(merged);
}
