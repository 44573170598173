import React, { FC, useCallback, useEffect } from "react";
import { ICellRendererParams, IHeaderParams } from "ag-grid-community";
import viewIcon from "assets/icons/view.svg";
import { formatNumberWithSpaces, getFromLocalStorage } from "core/utils";
import dayjs from "dayjs";
import AgGrid from "features/AgGrid/AgGrid";
import Guard from "features/Guard";
import { useAppDispatch, useAppSelector } from "hooks";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { handleNavBarExpanded, setPageWithMenuGroups } from "store";
import { setActiveContractEmployee } from "store/slices/contractsSlice";
import { getContractsEmployees } from "store/thunks/contractsEmployees";
import {
    materialReactTableCell,
    materialReactTableHeader,
} from "styles/MUIStyles";
import BootstrapTooltip from "ui/Tooltip";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import Box from "@mui/material/Box";

import {
    HeadComponents,
    useHeadComponents,
} from "../../../hooks/useHeadComponents";

interface Props {
    debouncedSearch: string;
    contractsEmployeesPageNumber: number;
    setContractsEmployeesPageNumber: (pageNumber: number) => void;
    sortConfig: {
        column: string | null;
        direction: string | null;
    };
    isContractsEmployeesSearchingRef: {
        current: boolean | null;
    };
    contractsEmployeesHandleColumnClick: any;
}

const ContractsEmployeesTable: FC<Props> = (props) => {
    const { t } = useTranslation();
    const headVariants = useHeadComponents();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const {
        sortConfig,
        debouncedSearch,
        contractsEmployeesPageNumber,
        setContractsEmployeesPageNumber,
        isContractsEmployeesSearchingRef,
        contractsEmployeesHandleColumnClick,
    } = props;

    const contractEmployees = useAppSelector(
        (state) => state.contracts.contractEmployees,
    );
    const activeContractEmployee = useAppSelector(
        (state) => state.contracts.activeContractEmployee,
    );
    const allowedPermissions = useAppSelector(
        (state) => state.auth.allowedPermissions,
    );
    const allowedMenus = useAppSelector((state) => state.auth.allowedMenus);

    const getArrowForSortDirection = (column: string) => {
        if (sortConfig.column !== column) {
            return null;
        }

        return sortConfig.direction === "asc" ? (
            <ArrowUpwardIcon sx={{ color: "#605E5C" }} />
        ) : (
            <ArrowDownwardIcon sx={{ color: "#605E5C" }} />
        );
    };

    const getStatusColor = useCallback(
        (status: "Active" | "Suspended" | "Completed") => {
            switch (status) {
                case "Active":
                    return "#237657";
                case "Suspended":
                    return "#FFBF00";
                case "Completed":
                    return "#BEBEBE";
                default:
                    return "black";
            }
        },
        [],
    );

    useEffect(() => {
        const contractsEmployeesPageSize = getFromLocalStorage<number>(
            "contractsEmployeesPageSize",
        );

        if (
            isContractsEmployeesSearchingRef.current &&
            contractsEmployeesPageNumber > 1
        ) {
            setContractsEmployeesPageNumber(1);
        } else {
            dispatch(
                getContractsEmployees({
                    search: debouncedSearch,
                    pageSize: contractsEmployeesPageSize || 0,
                    pageNumber: contractsEmployeesPageNumber,
                    sortBy: sortConfig,
                }),
            );
        }
    }, [
        dispatch,
        debouncedSearch,
        setContractsEmployeesPageNumber,
        isContractsEmployeesSearchingRef,
        contractsEmployeesPageNumber,
        sortConfig,
        t,
    ]);

    const HeadComponent = (p: IHeaderParams) => {
        const colId = p.column.getColId();

        switch (colId) {
            case "employeeNameAndPosition": {
                return (
                    <Box
                        sx={materialReactTableHeader}
                        onClick={() =>
                            contractsEmployeesHandleColumnClick("employeeName")
                        }
                    >
                        <span>{t("data.nameAndPosition")}</span>
                        {getArrowForSortDirection("employeeName")}
                    </Box>
                );
            }
            case "organization":
                return (
                    <Box
                        sx={materialReactTableHeader}
                        onClick={() =>
                            contractsEmployeesHandleColumnClick("organization")
                        }
                    >
                        <span>{t("data.vendor")}</span>
                        {getArrowForSortDirection("organization")}
                    </Box>
                );
            case "conclusionDate": {
                return (
                    <Box
                        sx={materialReactTableHeader}
                        onClick={() =>
                            contractsEmployeesHandleColumnClick(
                                "conclusionDate",
                            )
                        }
                    >
                        <span>{t("data.conclusionDate")}</span>
                        {getArrowForSortDirection("conclusionDate")}
                    </Box>
                );
            }
            case "endDate": {
                return (
                    <Box
                        sx={materialReactTableHeader}
                        onClick={() =>
                            contractsEmployeesHandleColumnClick("endDate")
                        }
                    >
                        <span>{t("data.endDate")}</span>
                        {getArrowForSortDirection("endDate")}
                    </Box>
                );
            }
            default:
                return headVariants[colId as keyof HeadComponents];
        }
    };

    const RowComponent = (p: ICellRendererParams) => {
        switch (p.colDef?.field) {
            case "customActions": {
                return (
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            "& img": {
                                cursor: "pointer",
                            },
                        }}
                    >
                        <Guard
                            allowedPermissions={allowedPermissions}
                            permissionName="View"
                        >
                            <BootstrapTooltip
                                title={t("buttons.View")}
                                onClick={() =>
                                    navigate(
                                        `/contracts/view/details/${p.data.id}`,
                                    )
                                }
                                placement="top"
                            >
                                <img src={viewIcon} alt="view icon" />
                            </BootstrapTooltip>
                        </Guard>
                    </Box>
                );
            }
            case "employeeNameAndPosition": {
                return (
                    <Box sx={materialReactTableCell}>
                        <p>{p.data.employeeName}</p>
                        <p>{p.data.position}</p>
                    </Box>
                );
            }
            case "organization": {
                return (
                    <Box
                        sx={materialReactTableCell}
                        onClick={() => {
                            localStorage.setItem(
                                "redirectOrganizationId",
                                p.data?.organizationId,
                            );
                            dispatch(handleNavBarExpanded(false));
                            dispatch(setPageWithMenuGroups(""));
                        }}
                    >
                        {allowedMenus.some(
                            (menu) => menu.name === "Organizations",
                        ) ? (
                            <Link to="/organizations">
                                {p.data?.organization}
                            </Link>
                        ) : (
                            p.data?.organization
                        )}
                    </Box>
                );
            }
            case "agreement": {
                return <Box sx={materialReactTableCell}>{p.data.name}</Box>;
            }
            case "conclusionDate": {
                return (
                    <Box sx={materialReactTableCell}>
                        {p.data.conclusionDate
                            ? dayjs(p.data.conclusionDate).format("DD.MM.YYYY")
                            : null}
                    </Box>
                );
            }
            case "endDate": {
                return (
                    <Box sx={materialReactTableCell}>
                        {p.data.endDate
                            ? dayjs(p.data.endDate).format("DD.MM.YYYY")
                            : null}
                    </Box>
                );
            }
            case "type": {
                return <Box sx={materialReactTableCell}>{p.data.type}</Box>;
            }
            case "amountMonth": {
                return (
                    <Box
                        sx={{
                            ...materialReactTableCell,
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            gap: "5%",
                            "& p:first-of-type": {
                                width: "30%",
                                fontWeight: 600,
                            },
                            "& p:last-of-type": {
                                width: "65%",
                                fontWeight: 600,
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                            },
                        }}
                    >
                        <p>{p.data.currency}</p>
                        <p>
                            {p.data.amountMonth || p.data.amountMonth === 0
                                ? formatNumberWithSpaces(p.data.amountMonth)
                                : null}
                        </p>
                    </Box>
                );
            }
            case "contractAmount": {
                return (
                    <Box
                        sx={{
                            ...materialReactTableCell,
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            gap: "5%",
                            "& p:first-of-type": {
                                width: "30%",
                                fontWeight: 600,
                            },
                            "& p:last-of-type": {
                                width: "65%",
                                fontWeight: 600,
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                            },
                        }}
                    >
                        <p>{p.data.currency}</p>
                        <p>
                            {p.data.contractAmount ||
                            p.data.contractAmount === 0
                                ? formatNumberWithSpaces(p.data.contractAmount)
                                : null}
                        </p>
                    </Box>
                );
            }
            case "status": {
                return (
                    <Box
                        sx={{
                            ...materialReactTableCell,
                            fontWeight: 600,
                            color: getStatusColor(p.data.status),
                        }}
                    >
                        <span>{t(`statuses.${p.data.status}`)}</span>
                    </Box>
                );
            }
        }
    };

    const generateColumnDefs = () => {
        const savedColumnState = getFromLocalStorage(
            "agGridContractsEmployeesTable",
        );

        let updatedColumnDefs: any[] = [
            {
                field: "customActions",
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 110,
            },
            {
                field: "employeeNameAndPosition",
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 150,
            },
            {
                field: "organization",
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 170,
            },
            {
                field: "agreement",
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 100,
            },
            {
                field: "conclusionDate",
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 160,
            },
            {
                field: "endDate",
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 150,
            },
            {
                field: "type",
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 80,
            },
            {
                field: "amountMonth",
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 150,
            },
            {
                field: "contractAmount",
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 90,
            },
            {
                field: "status",
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 100,
            },
        ];

        if (Array.isArray(savedColumnState)) {
            updatedColumnDefs = savedColumnState.map((columnState: any) => {
                return {
                    field: columnState.colId,
                    width: columnState.width,
                    pinned: columnState.pinned,
                    headerComponent: HeadComponent,
                    cellRenderer: RowComponent,
                    resizable: true,
                };
            });
        }

        return updatedColumnDefs;
    };

    const saveColumnState = (columnState: any) => {
        localStorage.setItem(
            "agGridContractsEmployeesTable",
            JSON.stringify(columnState),
        );
    };

    const columnDefs: any[] = generateColumnDefs();

    return (
        <AgGrid
            rowData={contractEmployees}
            columnDefs={columnDefs}
            saveColumnState={saveColumnState}
            activeRow={activeContractEmployee}
            setActiveRow={setActiveContractEmployee}
            height="65vh"
        />
    );
};

export default ContractsEmployeesTable;
