import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";

import { Breadcrumbs, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import PersonalDocumentsTab from "../../../features/PersonalDocumentsTab/PersonalDocumentsTab";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
    getPersonalDocuments,
    getPersonalInfoEmployeeById,
} from "../../../store";
import Loader from "../../../ui/Loader";
// import EmployeeDocuments from '../EmployeeDocuments/EmployeeDocuments';

export const PersonalNotifications = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const personalDocuments = useAppSelector(
        (state) => state.employees.personalDocuments,
    );
    const userId = useAppSelector((state) => state.auth.userId);
    const personalInfoEmployeeDetails = useAppSelector(
        (state) => state.employees.personalInfoEmployeeDetails,
    );
    const [employeesActiveTab, setEmployeesActiveTab] = useLocalStorage(
        "employeesActiveTab",
        0,
    );
    const [isLoading, setIsLoading] = useState(false);

    const handleActiveTab = (event: React.SyntheticEvent, newValue: number) => {
        setEmployeesActiveTab(newValue);
    };

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            await dispatch(getPersonalDocuments());
            if (userId !== null) {
                dispatch(getPersonalInfoEmployeeById(userId));
            }
            setIsLoading(false);
        };

        fetchData();
    }, [dispatch]);

    if (!personalDocuments || isLoading) {
        return <Loader />;
    }

    return (
        <Box sx={{ width: "100%", padding: "10px 0" }}>
            <Breadcrumbs sx={{ padding: "10px 10px" }}>
                <Link
                    to="/personalcabinet"
                    // className={styles.breadScrumbsLink}
                >
                    {t("menu.personalAccount")}
                </Link>
                <Typography
                    sx={{ color: "#201f1e", fontFamily: "Source Sans Pro" }}
                >
                    {`${personalInfoEmployeeDetails?.firstName} ${personalInfoEmployeeDetails?.lastName}`}
                </Typography>
            </Breadcrumbs>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={employeesActiveTab} onChange={handleActiveTab}>
                    <Tab label={t("tabs.newNotifications")} value={0} />
                    <Tab label={t("tabs.oldNotifications")} value={1} />
                </Tabs>
            </Box>

            {/* {employeesActiveTab === 0 && (
                <PersonalDocumentsTab
                    firstName={personalInfoEmployeeDetails?.firstName || ""}
                    lastName={personalInfoEmployeeDetails?.lastName || ""}
                    personalDocuments={personalDocuments?.commonDocuments ?? []}
                />
            )}
            {employeesActiveTab === 1 && (
                <PersonalDocumentsTab
                    firstName={personalInfoEmployeeDetails?.firstName || ""}
                    lastName={personalInfoEmployeeDetails?.lastName || ""}
                    personalDocuments={personalDocuments?.myDocuments ?? []}
                />
            )} */}
        </Box>
    );
};
