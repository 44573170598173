import { useCallback } from "react";

import useLocalStorage from "./useLocalStorage";

interface ISortConfig {
    column: null | string;
    direction: null | "asc" | "desc";
}

const useSortColumn = (tableSortKey: string) => {
    const [sortConfig, setSortConfig] = useLocalStorage<ISortConfig>(
        tableSortKey,
        {
            column: null,
            direction: null,
        },
    );

    const handleColumnClick = useCallback(
        (column: string) => {
            if (sortConfig.column === column) {
                if (sortConfig.direction === "asc") {
                    setSortConfig({ column, direction: "desc" });
                } else if (sortConfig.direction === "desc") {
                    setSortConfig({ column: "", direction: "asc" });
                }
            } else {
                setSortConfig({ column, direction: "asc" });
            }
        },
        [tableSortKey, sortConfig],
    );

    return { sortConfig, handleColumnClick };
};

export default useSortColumn;
