import React, { ChangeEvent, useState } from "react";
import { EditIcon } from "assets/icons";
import { useAppDispatch, useAppSelector, useLocalStorage } from "hooks";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getAllUsers } from "store/thunks/usersPermissionsThunk";
import { createOrEditBtn } from "styles/MUIStyles";

import { FormControl, Select } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Pagination from "@mui/material/Pagination";
import { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";

import { getFromLocalStorage } from "../../../core/utils";
import { IPermissionsUsers } from "../../../models/user";
import {
    ISortConfig,
    IUsersPermissionsFilters,
} from "../../../models/usersPermissions";

import UsersPermissionsFilters from "./UsersPermissionsFilters";
import UsersPermissionsTable from "./UsersPermissionsTable";

import styles from "./UsersPermissions.module.scss";

const UsersPermissions = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const usersPermissionsList = useAppSelector(
        (state) => state.users.usersPermissionsList,
    );

    const [usersPermissionsPageSize, setUsersPermissionsPageSize] =
        useLocalStorage("usersPermissionsPageSize", 10);
    const [usersPermissionsPageNumber, setUsersPermissionsPageNumber] =
        useLocalStorage("usersPermissionsPageNumber", 1);
    const [usersPermissionsActiveUser, setUsersPermissionsActiveUser] =
        useState<IPermissionsUsers | null>(null);

    const handlePageSizeChange = (event: SelectChangeEvent) => {
        setUsersPermissionsPageSize(+event.target.value);
        setUsersPermissionsPageNumber(1);
        const usersPermissionsFilters =
            getFromLocalStorage<IUsersPermissionsFilters>(
                "usersPermissionsFilters",
            );
        const pageNumber = getFromLocalStorage<number>(
            "usersPermissionsPageNumber",
        );
        const email = getFromLocalStorage<string>("usersPermissionsByEmail");
        const userName = getFromLocalStorage<string>(
            "usersPermissionsSearchByName",
        );
        const sortConfig = getFromLocalStorage<ISortConfig>(
            "usersPermissionsTableSortConfig",
        );
        dispatch(
            getAllUsers({
                sortBy: sortConfig,
                pageNumber,
                email,
                userName,
                pageSize: +event.target.value,
                ...usersPermissionsFilters,
            }),
        );
    };

    const handleOnUsersPermissionsPageChange = (
        event: ChangeEvent<unknown>,
        page: number,
    ) => {
        setUsersPermissionsPageNumber(page);
    };

    const setUserPermissionSettingsName = (user: {
        firstName: string;
        lastName: string;
    }) => {
        if (user.firstName && user.lastName) {
            localStorage.setItem(
                "userPermissionsSettingsUserName",
                user.firstName + " " + user.lastName,
            );
        }
    };

    return (
        <section className={styles.usersPermissionsWrapper}>
            <div className={styles.usersPermissionsContent}>
                <div className={styles.usersPermissionsHeader}>
                    <h2 className={styles.usersPermissionsTitle}>
                        {t("usersPermissions.users")}
                    </h2>
                    <p className={styles.usersPermissionsMessage}>
                        {t("usersPermissions.findUserMessage")}
                    </p>
                </div>
                <div className={styles.usersPermissionsToolbar}>
                    <Button
                        startIcon={<EditIcon />}
                        disabled={!usersPermissionsActiveUser}
                        onClick={() => {
                            if (usersPermissionsActiveUser) {
                                setUserPermissionSettingsName({
                                    firstName:
                                        usersPermissionsActiveUser.firstName,
                                    lastName:
                                        usersPermissionsActiveUser.lastName,
                                });
                                navigate(usersPermissionsActiveUser.id);
                            }
                        }}
                        variant="text"
                        sx={{
                            ...createOrEditBtn,
                            marginTop: "1rem",
                            "&:hover": {
                                background: "#f4f3f2",
                                borderRadius: "none",
                            },
                        }}
                    >
                        {t("usersPermissions.editPermissions")}
                    </Button>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <span className={styles.usersPermissionsElementsOnPage}>
                            {t("filters.elementsOnPage")}
                        </span>
                        <FormControl
                            variant="standard"
                            sx={{ margin: "4px 2rem 0 .8rem" }}
                        >
                            <Select
                                value={usersPermissionsPageSize}
                                onChange={handlePageSizeChange}
                            >
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={25}>25</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                                <MenuItem value={100}>100</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </div>
                <UsersPermissionsFilters
                    usersPermissionsPageNumber={usersPermissionsPageNumber}
                    setUsersPermissionsPageNumber={
                        setUsersPermissionsPageNumber
                    }
                />
                <UsersPermissionsTable
                    pageNumber={usersPermissionsPageNumber}
                    usersPermissionsActiveUser={usersPermissionsActiveUser}
                    setUsersPermissionsActiveUser={
                        setUsersPermissionsActiveUser
                    }
                />
                <Stack sx={{ width: "500px", margin: "auto" }}>
                    <Pagination
                        sx={{ margin: ".5rem auto" }}
                        count={usersPermissionsList?.metadata.pageCount}
                        page={usersPermissionsPageNumber}
                        size="medium"
                        onChange={handleOnUsersPermissionsPageChange}
                        shape="rounded"
                        color="primary"
                        variant="outlined"
                    />
                </Stack>
            </div>
        </section>
    );
};

export default UsersPermissions;
