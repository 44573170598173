import React, { FC, useEffect } from "react";
import { getFromLocalStorage } from "core/utils";
import dayjs from 'dayjs';
import AgGrid from "features/AgGrid/AgGrid";
import { useTranslation } from "react-i18next";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import SearchIcon from "@mui/icons-material/Search";
import { Box } from "@mui/material";

import { formatDate } from '../../helpers/formatDate';
import { useAppDispatch, useAppSelector } from "../../hooks";
import { ITrackingFilters } from "../../models/tracking";
import { setTrackingEmployeesSearch } from "../../store";
import { setTrackingEmployee } from "../../store";
import { getAllTimeKeepingOfEmployees } from "../../store/thunks/trackingThunk";
import {
    materialReactTableHeader,
} from "../../styles/MUIStyles";

import { EmployeeCellRenderer } from "./Rerenders/EmployeeCellRerenders";

import styles from "./Tracking.module.scss";

interface Props {
    setTrackingPageNumber: (pageNumber: number) => void;
    debouncedSearch: string;
    trackingPageNumber: number;
    isTrackingSearchingRef: {
        current: boolean | null;
    };
    trackingSortConfig: {
        column: string | null;
        direction: string | null;
    };
    trackingHandleColumnClick: any;
    year: number;
    month: number;
    trackingFilters: ITrackingFilters;
    setTrackingFilters: () => void;
    trackingPageSize: number;
}
const AccountantEmployeesTrackingTable: FC<Props> = (props) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    // const allowedPermissions = useAppSelector(
    //     (state) => state.auth.allowedPermissions
    // );

    const {
        debouncedSearch,
        setTrackingPageNumber,
        isTrackingSearchingRef,
        trackingSortConfig,
        trackingHandleColumnClick,
        trackingPageSize,
        trackingPageNumber,
        year,
        month,
        trackingFilters,
    } = props;


    // const trackings = useAppSelector(state => state.tracking.employeesTracking);
    const activeEmployeeTracking = useAppSelector(
        (state) => state.tracking.activeEmployeeTracking
    );
    // const allowedMenus = useAppSelector((state) => state.auth.allowedMenus);
    const search = useAppSelector(
        (state) => state.tracking.trackingEmployeesSearch
    );

    const getArrowForSortDirection = (column: string) => {
        if (trackingSortConfig.column !== column) {
            return null;
        }
        return trackingSortConfig.direction === "asc" ? (
            <ArrowUpwardIcon sx={{ color: "#605E5C" }} />
        ) : (
            <ArrowDownwardIcon sx={{ color: "#605E5C" }} />
        );
    };
    const employeesTracking = useAppSelector(
        (state) => state.tracking.employeesTracking
    );
    useEffect(() => {
        if (
            isTrackingSearchingRef.current &&
            trackingPageNumber > 1 &&
            search
        ) {
            setTrackingPageNumber(1);
        } else {
            trackingFilters.fromStartDate;
            const startDate = formatDate(trackingFilters, 'fromStartDate');
            const endDate = formatDate(trackingFilters, 'beforeStartDate');

            dispatch(
                getAllTimeKeepingOfEmployees(
                    // @ts-ignore
                    {
                        pageNumber: trackingPageNumber,
                        pageSize: trackingPageSize || 0,
                        year: year,
                        month: month,
                        fullName: debouncedSearch,
                        sortBy: trackingSortConfig,
                        ...trackingFilters,
                        fromStartDate: startDate.fromStartDate,
                        beforeStartDate: endDate.beforeStartDate
                    }
                )
            );
        }
    }, [
        dispatch,
        debouncedSearch,
        setTrackingPageNumber,
        isTrackingSearchingRef,
        trackingPageNumber,
        trackingSortConfig,
        year,
        month,
        trackingFilters,
        t,
        trackingPageSize,
        search
    ]);

    const EmployeeHeaderRenderer = (p: any) => {
        const { t } = useTranslation();
        let content;
        switch (p.column.colId) {
            case "actions":
                content = (
                    <Box sx={materialReactTableHeader}>{t("data.actions")}</Box>
                );
                break;
            case "personnelNumber":
                content = (
                    <Box
                        sx={materialReactTableHeader}
                        onClick={() => {
                            trackingHandleColumnClick(p.column.colId);
                        }}
                    >
                        {t("data.tabNo")}{" "}
                        {getArrowForSortDirection(p.column.colId)}
                    </Box>
                );
                break;
            case "nameAndPosition":
                content = (
                    <Box
                        sx={materialReactTableHeader}
                        onClick={() => {
                            trackingHandleColumnClick("fullName");
                        }}
                    >
                        {t("data.nameAndPosition")}{" "}
                        {getArrowForSortDirection("fullName")}
                    </Box>
                );
                break;
            case "skill":
                content = (
                    <Box sx={materialReactTableHeader}>
                        {t("data.mainSkill")}{" "}
                    </Box>
                );
                break;
            case "organization":
                content = (
                    <Box
                        sx={materialReactTableHeader}
                        onClick={() => {
                            trackingHandleColumnClick(p.column.colId);
                        }}
                    >
                        {t("data.vendor")}{" "}
                        {getArrowForSortDirection(p.column.colId)}
                    </Box>
                );
                break;
            case "project":
                content = (
                    <Box sx={materialReactTableHeader}>
                        {t("data.project")}{" "}
                    </Box>
                );
                break;
            case "fromDate":
                content = (
                    <Box sx={materialReactTableHeader}>
                        {t("data.fromDate")}{" "}
                    </Box>
                );
                break;
            case "fte":
                content = (
                    <Box sx={materialReactTableHeader}>{t("data.fte")} </Box>
                );
                break;
            case "hours":
                content = (
                    <Box sx={materialReactTableHeader}>{t("data.hours")} </Box>
                );
                break;
            default:
                content = null; // You can handle the default case based on your requirements
        }
        return content;
    };
    const saveColumnState = (columnState: any) => {
        localStorage.setItem(
            "agGridTrackingEmployeeTable",
            JSON.stringify(columnState)
        );
    };

    const generateColumnDefs = () => {
        const savedColumnState = getFromLocalStorage(
            "agGridTrackingEmployeeTable"
        );

        let columnDefs = [
            {
                headerName: t("data.actions"),
                field: "actions",
                cellRenderer: EmployeeCellRenderer,
                headerComponent: EmployeeHeaderRenderer,
                cellStyle: { textAlign: "center" },
                width: "100px",
            },
            {
                headerName: t("data.tabNo"),
                field: "personnelNumber",
                sortable: true,
                cellStyle: { textAlign: "center" },
                cellRenderer: EmployeeCellRenderer,
                width: "80px",
                headerComponent: EmployeeHeaderRenderer,
            },
            {
                headerName: t("data.nameAndPosition"),
                field: "nameAndPosition",
                sortable: true,
                cellRenderer: EmployeeCellRenderer,
                width: "250px",
                headerComponent: EmployeeHeaderRenderer,
            },
            {
                headerName: t("data.mainSkill"),
                field: "skill",
                sortable: true,
                cellStyle: { textAlign: "center" },
                cellRenderer: EmployeeCellRenderer,
                headerComponent: EmployeeHeaderRenderer,
            },
            {
                headerName: t("data.vendor"),
                field: "organization",
                sortable: true,
                cellRenderer: EmployeeCellRenderer,
                headerComponent: EmployeeHeaderRenderer,
            },
            {
                headerName: t("data.project"),
                field: "project",
                sortable: true,
                cellRenderer: EmployeeCellRenderer,
                width: "300px",
                headerComponent: EmployeeHeaderRenderer,
            },
            {
                headerName: t("data.fromDate"),
                field: "fromDate",
                sortable: true,
                cellRenderer: EmployeeCellRenderer,
                width: "140px",
                headerComponent: EmployeeHeaderRenderer,
            },
            {
                headerName: t("data.fte"),
                field: "fte",
                sortable: true,
                cellRenderer: EmployeeCellRenderer,
                cellStyle: { textAlign: "center" },
                headerComponent: EmployeeHeaderRenderer,
                width: "60px",
            },
            {
                headerName: t("data.hours"),
                field: "hours",
                sortable: true,
                cellRenderer: EmployeeCellRenderer,
                headerComponent: EmployeeHeaderRenderer,
                cellStyle: { textAlign: "center" },
                width: "70px",
            },
        ];
        if (Array.isArray(savedColumnState)) {
            columnDefs = savedColumnState.map((columnState: any) => {
                return {
                    // @ts-ignore
                    headerComponent: EmployeeHeaderRenderer,
                    // @ts-ignore
                    cellRenderer: EmployeeCellRenderer,
                    field: columnState.colId,
                    width: columnState.width,
                    pinned: columnState.pinned,
                    displayName: columnState.displayName,
                    resizable: true,
                    ...columnState,
                };
            });
        }

        return columnDefs;
    };
    const columns = generateColumnDefs();
    return (
        <div style={{ marginTop: "20px" }}>
            <div className={styles.searchWrapper}>
                <input
                    name="search"
                    type="text"
                    autoComplete="off"
                    value={search}
                    onChange={(e) => {
                        dispatch(setTrackingEmployeesSearch(e.target.value));
                        isTrackingSearchingRef.current = true;
                    }}
                    placeholder={t("filters.searchByNameAndSurname") as string}
                    className={styles.search}
                />
                <button type="button" className={styles.searchIconWrapper}>
                    <SearchIcon sx={{ color: "#fff" }} />
                </button>
            </div>

            <Box ml={1} mt={1}>
                <AgGrid
                    saveColumnState={saveColumnState}
                    rowData={employeesTracking}
                    gridOptions={{
                        defaultColDef: {
                            sortable: true,
                            cellRendererFramework: EmployeeCellRenderer,
                        },
                        rowClass: "rowClassTracking",
                    }}
                    height="60vh"
                    setActiveRow={setTrackingEmployee}
                    activeRow={activeEmployeeTracking}
                    columnDefs={columns}
                    page="employeeProject"
                />
            </Box>
        </div>
    );
};

export default AccountantEmployeesTrackingTable;
