import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { getAllowedGroupsByMenuId, getAllowedPermissionsByGroupId, } from 'store';
import { getAllSkillLevels, getAllSkills, getEmployeeById, } from 'store/thunks/employeesThunk';
import Loader from 'ui/Loader';
import { useLocalStorage } from 'usehooks-ts';

import { Breadcrumbs, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import { EMPLOYEES_PATH } from '../../../router/constants';
import { EmployeeGeneralInfo } from '../EmployeeGeneralInfo';
import EmployeeSkills from '../EmployeeSkills/EmployeeSkills';

import EmployeeDocuments from './EmployeeDocuments/EmployeeDocuments';

import styles from './Employees.module.scss';


const EmployeeView: FC = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const { employeeId } = useParams();
    const { t } = useTranslation();
    const employeeDetails = useAppSelector(
        (state) => state.employees.employeeDetails
    );

    const allSkills = useAppSelector((state) => state.employees.allSkills);
    const allSkillLevels = useAppSelector(
        (state) => state.employees.allSkillLevels
    );

    // const [activeTab, setActiveTab] = useState(0);
    const [employeesActiveTab, setEmployeesActiveTab] = useLocalStorage(
        'employeesActiveTab',
        0
    );

    // const activeTab = useReadLocalStorage("employeesActiveTab");
    const [isLoading, setIsLoading] = useState(false);

    const handleActiveTab = (event: React.SyntheticEvent, newValue: number) => {
        // localStorage.setItem("Employees_Tab_No", newValue.toString());
        setEmployeesActiveTab(newValue);
    };

    useEffect(() => {
        (async () => {
            if (employeeId) {
                setIsLoading(true);
                await Promise.all([
                    dispatch(getEmployeeById(employeeId)),
                    dispatch(getAllSkills()),
                    dispatch(getAllSkillLevels()),
                ]);
                setIsLoading(false);
            }
        })();

        return () => {
            setEmployeesActiveTab(0);
        };
    }, [dispatch, employeeId, t]);

    // useEffect(() => {
    //     localStorage.setItem("Employees_Tab_No", activeTab.toString());
    // }, [])

    const allowedGroups = useAppSelector((state) => state.auth.allowedGroups);
    const allowedPermissions = useAppSelector(
        (state) => state.auth.allowedPermissions
    );
    const allowedMenus = useAppSelector((state) => state.auth.allowedMenus);

    useEffect(() => {
        const menu = allowedMenus.find((menu) => menu.name === 'Employees');
        typeof menu?.id === 'number' &&
        dispatch(getAllowedGroupsByMenuId(menu.id));
    }, [allowedMenus, dispatch]);

    useEffect(() => {
        const group = allowedGroups.find(
            (menu) => menu.name === `api/employees`
        );
        group?.id && dispatch(getAllowedPermissionsByGroupId(group.id));
    }, [dispatch, allowedGroups]);

    if (!employeeDetails || isLoading) {
        return <Loader/>;
    }

    return (
        <Box sx={{ width: '100%', padding: '10px 0' }}>
            <Breadcrumbs sx={{ padding: '10px 10px' }}>
                <Link
                    to={'/' + EMPLOYEES_PATH}
                    className={styles.breadScrumbsLink}
                >
                    {t('menu.employees')}
                </Link>
                <Typography
                    sx={{ color: '#201f1e', fontFamily: 'Source Sans Pro' }}
                >
                    {employeeDetails.firstName} {employeeDetails.lastName}
                </Typography>
            </Breadcrumbs>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={employeesActiveTab} onChange={handleActiveTab}>
                    <Tab label={t('tabs.generalInfo')} value={0}/>
                    <Tab label={t('tabs.skills')} value={1}/>
                    <Tab label={t('tabs.docs')} value={2}/>
                    <Tab label={t('tabs.projects')} value={3}/>
                    <Tab label={t('tabs.history')} value={4}/>
                </Tabs>
            </Box>

            {employeesActiveTab === 0 && (
                <EmployeeGeneralInfo
                    editLink={`/${EMPLOYEES_PATH}/edit/${employeeDetails.id}`}
                    showCloseButton
                    allowedPermissions={allowedPermissions}
                    {...employeeDetails}
                />
            )}
            {employeesActiveTab === 1 && (
                <EmployeeSkills
                    showCloseButton
                    allowedPermissions={allowedPermissions}
                    id={employeeDetails?.id}
                    fte={employeeDetails?.fte}
                    employeeFirstName={employeeDetails.firstName}
                    employeeLastName={employeeDetails.lastName}
                    employeeSkills={employeeDetails.skills}
                    allSkills={allSkills}
                    allSkillLevels={allSkillLevels}
                />
            )}
            {employeesActiveTab === 2 && (
                <EmployeeDocuments
                    allowedPermissions={allowedPermissions}
                    id={employeeDetails?.id}
                    employeeFirstName={employeeDetails.firstName}
                    employeeLastName={employeeDetails.lastName}
                    employeeDocuments={employeeDetails.documents}
                />
            )}
        </Box>
    );
};

export default EmployeeView;
