// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ButtonWithProgress_wrapper__nhds8 {\n  position: relative;\n}\n\n.ButtonWithProgress_buttonProgress__lpPrE {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  margin-top: -12px;\n  margin-left: -12px;\n}", "",{"version":3,"sources":["webpack://./src/ui/ButtonWithProgress/ButtonWithProgress.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;;AAEA;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,iBAAA;EACA,kBAAA;AACF","sourcesContent":[".wrapper {\n  position: relative;\n}\n\n.buttonProgress {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  margin-top: -12px;\n  margin-left: -12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "ButtonWithProgress_wrapper__nhds8",
	"buttonProgress": "ButtonWithProgress_buttonProgress__lpPrE"
};
export default ___CSS_LOADER_EXPORT___;
