import { FC } from "react";
import closeIcon from "assets/icons/closeIcon.svg";
import { useAppSelector } from "hooks";
import { ILookupItem } from "models/lookups";
import { useTranslation } from "react-i18next";
import { cancelBtn } from "styles/MUIStyles";

import {
    Autocomplete,
    Box,
    Button,
    Drawer,
    FormControl,
    TextField,
} from "@mui/material";

interface IProps {
    isOpenFilterPanel: boolean;
    setIsOpenFilterPanel: (state: boolean) => void;
    filters: any;
    setFilters: (state: any) => void;
    resetFilters: () => void;
}

const VacationsSickFilterPanel: FC<IProps> = ({
    isOpenFilterPanel,
    setIsOpenFilterPanel,
    filters,
    setFilters,
    resetFilters,
}) => {
    const { t } = useTranslation();

    const organizations = useAppSelector(
        (state) => state.lookups.organizations,
    );
    const positions = useAppSelector((state) => state.lookups.positions);

    const closeFilterPanel = () => {
        setIsOpenFilterPanel(false);
    };

    return (
        <Drawer
            sx={{
                width: 300,
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                    width: 300,
                    top: "7.5vh",
                    padding: "1.2rem 0.7rem 1.2rem 1.2rem",
                    boxSizing: "border-box",
                },
            }}
            anchor="right"
            onClose={closeFilterPanel}
            open={isOpenFilterPanel}
        >
            <Box
                sx={{
                    display: "flex",
                    "& > img": { cursor: "pointer", marginLeft: "auto" },
                }}
            >
                <img
                    onClick={closeFilterPanel}
                    src={closeIcon}
                    alt="close filter"
                />
            </Box>
            <Box sx={{ display: "flex", marginBottom: "1.5rem" }}>
                <Button variant="text" onClick={resetFilters} sx={cancelBtn}>
                    {t("buttons.Reset")}
                </Button>
            </Box>
            <FormControl fullWidth>
                <label htmlFor="organizationId" className="filterTopSubTitle">
                    {t("data.vendor")}
                </label>
                <Autocomplete
                    id="organizationId"
                    autoHighlight
                    options={
                        organizations
                            .slice()
                            .sort((a: ILookupItem, b: ILookupItem) => {
                                const fa = a.name.toLowerCase(),
                                    fb = b.name.toLowerCase();
                                if (fa < fb) {
                                    return -1;
                                }
                                if (fa > fb) {
                                    return 1;
                                }
                                return 0;
                            }) || []
                    }
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value: ILookupItem | null) =>
                        setFilters((prevState: any) => ({
                            ...prevState,
                            organizationId: value?.id,
                        }))
                    }
                    sx={{ width: 200 }}
                    value={
                        organizations.filter(
                            (el) => el.id === filters.organizationId,
                        )[0] || null
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            placeholder={t("organizations.all") || ""}
                        />
                    )}
                />
            </FormControl>
            <FormControl fullWidth>
                <label htmlFor="positionId" className="filterTopSubTitle">
                    {t("data.position")}
                </label>
                <Autocomplete
                    id="positionId"
                    autoHighlight
                    ListboxProps={{ style: { maxHeight: "200px" } }}
                    options={
                        (positions &&
                            positions
                                .slice()
                                .sort((a: ILookupItem, b: ILookupItem) => {
                                    const fa = a.name.toLowerCase(),
                                        fb = b.name.toLowerCase();
                                    if (fa < fb) {
                                        return -1;
                                    }
                                    if (fa > fb) {
                                        return 1;
                                    }
                                    return 0;
                                })) ||
                        []
                    }
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value: ILookupItem | null) =>
                        setFilters((prevState: any) => ({
                            ...prevState,
                            positionId: value?.id,
                        }))
                    }
                    sx={{ width: 200 }}
                    value={
                        positions.filter(
                            (el) => el.id === filters.positionId,
                        )[0] || null
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            placeholder={t("organizations.all") || ""}
                        />
                    )}
                />
            </FormControl>
        </Drawer>
    );
};

export default VacationsSickFilterPanel;
