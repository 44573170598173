import React, { FC, useState } from 'react';
import dayjs from 'dayjs';
import { IDocument } from 'models/documents';

import { Modal } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';

import deleteIcon from '../../assets/icons/delete.svg';
import download from '../../assets/icons/download.svg';
import editIcon from '../../assets/icons/hoverEditIcon.svg';
import axiosApi from '../../core/request';
import { ICommonDocument } from '../../models/commonDocument';
import { materialReactTableCell } from '../../styles/MUIStyles';
import AgGrid from '../AgGrid/AgGrid';

import { DeleteFile } from './components/DeleteFile';
import { EditFile } from './components/EditFile';

import styles from './styles.module.css';

interface DocumentsListProps {
    documents: IDocument[];
    isPersonal?: boolean;

}

export const DocumentsList: FC<DocumentsListProps> = ({ documents, isPersonal = false, }) => {
    const [isOpenEditFile, setIsOpenEditFile] = useState(false);
    const [isOpenDeleteFile, setIsOpenDeleteFile] = useState(false);
    const [currentDoc, setCurrentDoc] = useState<null | ICommonDocument>(null);

    // const [downloadFileLoading, setDownloadFileLoading] = useState(false);
    const openEditFileModal = (docData: any) => {
        setCurrentDoc(docData);
        setIsOpenEditFile(true);
    };

    const openDeleteFileModal = (docData: any) => {
        setCurrentDoc(docData);
        setIsOpenDeleteFile(true);
    };

    const downloadDocument = async (docData: any, isPersonal: boolean) => {
        try {
            const currentPath = window.location.pathname;
            let apiUrl;

            if (currentPath.includes('common-info')) {
                apiUrl = isPersonal
                    ? `/personal-cabinet/documents/my-docs/${docData?.id}/download`
                    : `/personal-cabinet/documents/common-docs/${docData?.id}/download`;
            } else {
                apiUrl = `/common-documents/${docData?.id}/download`;
            }

            const response = await axiosApi.get(apiUrl, {
                responseType: 'blob',
            });

            const fileNameWithExtension = response.headers[
                'content-disposition'
                ]
                .split('filename=')[1]
                .split(';')[0]
                .replace(/"/g, '');
            const href = URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', decodeURI(fileNameWithExtension));
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.log(error);
        }
    };
    const currentPath = window.location.pathname;
    const viewDocument = async (docData: any, isPersonal: boolean) => {
        try {
            const currentPath = window.location.pathname;
            let apiUrl = `/common-documents/${docData?.id}/download`;

            if (currentPath.includes('common-info')) {
                apiUrl = !isPersonal
                    ? `/personal-cabinet/documents/my-docs/${docData?.id}/download`
                    : `personal-cabinet/documents/common-docs/${docData?.id}/download`;
            }

            const response = await axiosApi.get(apiUrl, {
                responseType: 'blob',
            });

            const file = new Blob([response.data], { type: response.headers['content-type'] });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL, '_blank');
        } catch (error) {
            console.log(error);
        }
    };

    const RowComponent = (p: any) => {

        switch (p.colDef.field) {
            case 'customActions':
                return (
                    <div>
                        {/* {downloadFileLoading ? (
                            <Box
                                sx={{
                                    width: "40%",
                                    padding: "10px",
                                    borderRadius: "8px",
                                    backgroundColor: "#fff",
                                    boxShadow: "0px -1px 0px 0px #E4E4EF inset",
                                }}
                            >
                                <p className={styles.fileIsDownloading}>
                                    File is downloading
                                </p>
                                <LinearProgress
                                    sx={{
                                        height: "6px",
                                        borderRadius: "5px",
                                        marginTop: "3px",
                                    }}
                                />
                            </Box>
                        ) : null} */}
                        <img
                            onClick={() => openEditFileModal(p.data)}
                            className={styles.icon}
                            src={editIcon}
                            alt="edit"
                        />
                        <img
                            onClick={() => downloadDocument(p.data, isPersonal)}
                            className={styles.icon}
                            src={download}
                            alt="download"
                        />
                        <img
                            onClick={() => openDeleteFileModal(p.data)}
                            className={styles.icon}
                            src={deleteIcon}
                            alt="delete"
                        />
                    </div>
                );
            case 'name':
                return (
                    <Box sx={materialReactTableCell}>
                        <a
                            href="#"
                            onClick={() => viewDocument(p.data, currentPath.includes('common-info'))}
                        >
                            {p.data.name}
                        </a>
                    </Box>
                );
            case 'description':
                return (
                    <Box sx={materialReactTableCell}>{p.data.description}</Box>
                );
            case 'originalFileName':
                return (
                    <Box sx={materialReactTableCell}>
                        {p.data.originalFileName}
                    </Box>
                );

            case 'createdTimeUtc':
                return (
                    <Box sx={materialReactTableCell}>
                        {dayjs(p.data.createdTimeUtc).format('DD.MM.YYYY')}
                    </Box>
                );
            default:
                return null;
        }
    };

    const generateColumnDefs = () => {
        const updatedColumnDefs: any[] = [
            {
                field: 'name',
                resizable: true,
                cellRenderer: RowComponent,
                flex: 3,
            },
            {
                field: 'description',
                resizable: true,
                cellRenderer: RowComponent,
                flex: 2,
            },
            {
                field: 'originalFileName',
                resizable: true,
                cellRenderer: RowComponent,
                flex: 2,
            },
            {
                field: 'createdTimeUtc',
                resizable: true,
                cellRenderer: RowComponent,
                flex: 1,
            },
            {
                field: 'customActions',
                resizable: true,
                cellRenderer: RowComponent,
                flex: 1,
            },
        ];

        return updatedColumnDefs;
    };

    const columnDefs: any[] = generateColumnDefs();

    return (
        <div>
            <AgGrid
                rowData={documents}
                columnDefs={columnDefs}
                activeRow={null}
                height={'35vh'}
                headerHeight={0}
            />

            <div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={isOpenEditFile}
                    onClose={() => setIsOpenEditFile(false)}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                        backdrop: {
                            timeout: 500,
                        },
                    }}
                >
                    <Box>
                        <EditFile
                            isPersonal={isPersonal}
                            setIsOpenEditFile={setIsOpenEditFile}
                  
                            id={currentDoc?.id || 0}
                            currentDoc={currentDoc} 
                        />
                    </Box>
                </Modal>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={isOpenDeleteFile}
                    onClose={() => setIsOpenDeleteFile(false)}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                        backdrop: {
                            timeout: 500,
                        },
                    }}
                >
                    <Box>
                        <DeleteFile
                            isPersonal={isPersonal}
                            setIsOpenDeleteFile={setIsOpenDeleteFile}
                            id={currentDoc?.id || 0}
                            originalFileName={currentDoc?.originalFileName || ''}
                        />
                    </Box>
                </Modal>
            </div>

        </div>
    );
};
