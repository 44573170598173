import React, { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { EditIcon, FilterIcon, ViewIcon } from "assets/icons";
import { getCalculatedFiltersCount } from "core/utils";
import FiltersComponent from "features/Catalogs/FiltersComponent";
import Guard from "features/Guard";
import {
    useAppDispatch,
    useAppSelector,
    useSortColumn,
    useUpdateEffect,
} from "hooks";
import useLocalStorage from "hooks/useLocalStorage";
// import TrackingFilterPanel from "./TrackingFilterPanel/TrackingFilterPanel";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
    clearTrackingEmployeesSearch,
    clearTrackingProjectsSearch,
    getAllowedGroupsByMenuId,
    getAllowedPermissionsByGroupId,
    getDataByCatalogName,
    getLookupByName,
    getSkillLevels,
    getSkills,
    getTypeofemployments,
    setTracking,
} from "store";
import { createOrEditBtn } from "styles/MUIStyles";
import { useDebounce, useEffectOnce } from "usehooks-ts";

import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import {
    Badge,
    Box,
    Button,
    FormControl,
    MenuItem,
    Select,
    SelectChangeEvent,
} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

import { ITrackingFilters } from "../../models/tracking";
import { getTrackingByEmployeeId } from "../../store/thunks/trackingThunk";

import { TrackingFilterPanel1 } from "./TrackingFilterPanel/TrakingFilterPanel";
import AccountantTrackingTable from "./AccountantTrackingTable";
import TrackingTable from "./TrackingTable";

import styles from "./Tracking.module.scss";

const Tracking: FC = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const currentDate = new Date();

    // const activeTracking = useAppSelector(
    //     (state) => state.tracking.activeTracking
    // );
    const totalTrackingCount = useAppSelector(
        (state) => state.tracking.totalTrackingCount,
    );
    const isTrackingLoading = useAppSelector(
        (state) => state.tracking.isTrackingLoading,
    );
    const trackingEmployeesSearch = useAppSelector(
        (state) => state.tracking.trackingEmployeesSearch,
    );
    const trackingProjectsSearch = useAppSelector(
        (state) => state.tracking.trackingProjectsSearch,
    );
    const allowedPermissions = useAppSelector(
        (state) => state.auth.allowedPermissions,
    );
    const allowedGroups = useAppSelector((state) => state.auth.allowedGroups);
    const allowedMenus = useAppSelector((state) => state.auth.allowedMenus);
    const { role } = useAppSelector((state) => state.auth);
    const activeTab = useAppSelector((state) => state.tracking.activeTab);
    const activeEmployeeTracking = useAppSelector(
        (state) => state.tracking.activeEmployeeTracking,
    );
    const activeProjectTracking = useAppSelector(
        (state) => state.tracking.activeProjectTracking,
    );

    const isTrackingEmployeesSearchingRef = useRef<boolean | null>(null);
    const isTrackingProjectsSearchingRef = useRef<boolean | null>(null);

    const debouncedEmployeesSearch = useDebounce(trackingEmployeesSearch, 1000);
    const debouncedProjectsSearch = useDebounce(trackingProjectsSearch, 1000);

    const [isTrackingFiltersOpen, setIsTrackingFiltersOpen] = useState(false);
    const [trackingEmployeesPageNumber, setTrackingEmployeesPageNumber] =
        useLocalStorage("trackingEmployeesPageNumber", 1);
    const [trackingEmployeesPageSize, setTrackingEmployeesPageSize] =
        useLocalStorage("trackingEmployeesPageSize", 10);
    const [trackingProjectsPageNumber, setTrackingProjectsPageNumber] =
        useLocalStorage("trackingProjectsPageNumber", 1);
    const [trackingProjectsPageSize, setTrackingProjectsPageSize] =
        useLocalStorage("trackingProjectsPageSize", 10);
    const {
        sortConfig: sortEmployeesConfig,
        handleColumnClick: handleEmployeesColumnClick,
    } = useSortColumn("trackingEmployeesTableSortConfig");
    const {
        sortConfig: sortProjectsConfig,
        handleColumnClick: handleProjectsColumnClick,
    } = useSortColumn("trackingProjectsTableSortConfig");
    const [filtersCount, setFiltersCount] = useLocalStorage(
        "trackingFilterBadge",
        0,
    );
    const activeTracking = useAppSelector(
        (state) => state.tracking.activeTracking,
    );

    const [year, setYear] = useLocalStorage<number>(
        "trackingFilterYear",
        currentDate.getFullYear(),
    );

    const [monthOfTracking, setMonthOfTracking] = useLocalStorage<number>(
        "trackingFilterMonth",
        currentDate.getMonth() + 1,
    );

    const initialStateFilters: ITrackingFilters = {
        organizationId: null,
        positionId: null,
        skillId: null,
        skillLevelId: null,
        costCenterId: null,
        status: null,
        typeOfEmploymentId: null,
        fromStartDate: null,
        beforeStartDate: null,
    };

    const [trackingFilters, setTrackingFilters] =
        useLocalStorage<ITrackingFilters>(
            "trackingFilters",
            initialStateFilters,
        );

    const handleEmployeesPageSizeChange = (event: SelectChangeEvent) => {
        setTrackingEmployeesPageSize(+event.target.value);
        setTrackingEmployeesPageNumber(1);
        dispatch(
            getTrackingByEmployeeId({
                // employeeId: '11771061-1b42-4c92-c884-08db4fad8094',
                year: year,
                month: monthOfTracking,
            }),
        );
    };

    const handleProjectsPageSizeChange = (event: SelectChangeEvent) => {
        setTrackingProjectsPageSize(+event.target.value);
        setTrackingProjectsPageNumber(1);
    };

    const handleOnTrackingEmployeesPageChange = (
        event: ChangeEvent<unknown>,
        page: number,
    ) => {
        dispatch(setTracking(null));
        setTrackingEmployeesPageNumber(page);
    };

    const handleOnTrackingProjectsPageChange = (
        event: ChangeEvent<unknown>,
        page: number,
    ) => {
        dispatch(setTracking(null));
        setTrackingProjectsPageNumber(page);
    };

    useEffect(() => {
        localStorage.removeItem("trackingFilters");
        dispatch(clearTrackingEmployeesSearch());
        dispatch(clearTrackingProjectsSearch());

        const menu = allowedMenus.find((menu) => menu.name === "Tracking");
        typeof menu?.id === "number" &&
            dispatch(getAllowedGroupsByMenuId(menu.id));
    }, [allowedMenus, dispatch]);

    useEffect(() => {
        const group = allowedGroups.find(
            (menu) => menu.name === `api/tracking`,
        );
        group?.id && dispatch(getAllowedPermissionsByGroupId(group.id));
    }, [dispatch, allowedGroups]);

    const handleChangeYear = (newYear: number): void => {
        const currentYear = currentDate.getFullYear();

        if (newYear <= currentYear) {
            setYear(newYear);
            if (
                newYear === currentYear &&
                monthOfTracking > currentDate.getMonth() + 1
            ) {
                // Если выбранный месяц превышает текущий месяц текущего года, установить текущий месяц
                setMonthOfTracking(currentDate.getMonth() + 1);
            }
        } else if (newYear > currentYear) {
            // Нужно увеличить месяц, если новый год больше текущего
            if (monthOfTracking === 12) {
                // Если текущий месяц - декабрь, установить январь следующего года
                setMonthOfTracking(1);
            }
        }
    };

    const handleChangeMonth = (newMonth: number): void => {
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1;
        if (newMonth === 13) setMonthOfTracking(1);

        if (newMonth < 1) {
            // Если новый месяц меньше января, перейти на декабрь предыдущего года
            setMonthOfTracking(12);
            setYear(year - 1);
        } else if (newMonth > 12) {
            // Если новый месяц больше декабря, перейти на январь следующего года
            setMonthOfTracking(1);
            setYear(year + 1);
        } else if (newMonth >= currentMonth && year === currentYear) {
            setMonthOfTracking(currentMonth);
        } else {
            setMonthOfTracking(newMonth);
        }
    };

    useUpdateEffect(() => {
        setFiltersCount(getCalculatedFiltersCount(trackingFilters));
    }, [trackingFilters]);

    useEffectOnce(() => {
        if (role !== "User") {
            dispatch(getLookupByName("organizations"));
            dispatch(getSkills("skills"));
            dispatch(getSkillLevels("skillLevels"));
            dispatch(getDataByCatalogName("positions"));
            dispatch(getDataByCatalogName("guilds"));
            dispatch(getDataByCatalogName("costCenters"));
            dispatch(getTypeofemployments());
        }
    });

    return (
        <div>
            <div className={styles.crudButtonWrapper}>
                <Box>
                    <Guard
                        allowedPermissions={allowedPermissions}
                        permissionName="Create"
                    >
                        <Button
                            startIcon={<EditIcon />}
                            variant="text"
                            sx={createOrEditBtn}
                            disabled={
                                !(
                                    activeEmployeeTracking ||
                                    activeTracking ||
                                    activeProjectTracking
                                )
                            }
                            onClick={() => {
                                ["accountant", "ceo"].includes(
                                    role?.toLowerCase()!,
                                )
                                    ? navigate(
                                          `/tracking/employee/view/${
                                              activeTab === 0
                                                  ? activeEmployeeTracking?.employeeId
                                                  : activeProjectTracking?.projectId
                                          }`,
                                      )
                                    : navigate(
                                          activeTracking?.timekeepingId
                                              ? `/tracking/management/edit/${activeTracking.timekeepingId}`
                                              : "/tracking/management/edit",
                                      );
                            }}
                        >
                            {t("buttons.Edit")}
                        </Button>
                    </Guard>
                    <Guard
                        allowedPermissions={allowedPermissions}
                        permissionName="View"
                    >
                        <Button
                            startIcon={<ViewIcon />}
                            variant="text"
                            sx={createOrEditBtn}
                            disabled={
                                !(
                                    activeEmployeeTracking ||
                                    activeTracking ||
                                    activeProjectTracking
                                )
                            }
                            onClick={() => {
                                ["accountant", "ceo"].includes(
                                    role?.toLowerCase()!,
                                )
                                    ? navigate(
                                          activeTab === 0
                                              ? `/tracking/employee/view/${activeEmployeeTracking?.employeeId}`
                                              : `/projects/view/${activeProjectTracking?.projectId}`,
                                      )
                                    : navigate(
                                          activeTracking?.timekeepingId
                                              ? `/tracking/management/view/${activeTracking.timekeepingId}`
                                              : "/tracking/management/view",
                                      );
                            }}
                        >
                            {t("buttons.View")}
                        </Button>
                    </Guard>
                </Box>
                {role?.toLowerCase() === "accountant" && (
                    <Stack direction="row" spacing={1} alignItems="center">
                        <p>{t("filters.elementsOnPage")}</p>
                        <FormControl variant="standard" sx={{ m: 1 }}>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={String(
                                    activeTab === 0
                                        ? trackingEmployeesPageSize
                                        : trackingProjectsPageSize,
                                )}
                                onChange={
                                    activeTab === 0
                                        ? handleEmployeesPageSizeChange
                                        : handleProjectsPageSizeChange
                                }
                            >
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={25}>25</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                                <MenuItem value={100}>100</MenuItem>
                            </Select>
                        </FormControl>
                        <Badge
                            color="primary"
                            badgeContent={filtersCount}
                            sx={{ "& span": { fontSize: "12px" } }}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                        >
                            <Button
                                startIcon={<FilterIcon />}
                                variant="text"
                                onClick={() => {
                                    setIsTrackingFiltersOpen(true);
                                }}
                                sx={createOrEditBtn}
                            >
                                {t("buttons.Filters")}
                            </Button>
                        </Badge>
                    </Stack>
                )}
            </div>
            <Box sx={{ display: "flex" }}>
                <div className={styles.box_year}>
                    <ArrowLeftIcon
                        htmlColor="gray"
                        sx={{ color: "green", fontSize: "40px" }}
                        onClick={() => handleChangeYear(year - 1)}
                    />
                    <p>{year}</p>
                    <ArrowRightIcon
                        htmlColor="gray"
                        sx={{
                            color:
                                year === currentDate.getFullYear()
                                    ? "gray"
                                    : "green",
                            fontSize: "40px",
                        }}
                        onClick={() => handleChangeYear(year + 1)}
                    />
                </div>
                <div className={styles.box_year}>
                    <ArrowLeftIcon
                        htmlColor="gray"
                        sx={{ color: "green", fontSize: "40px" }}
                        onClick={() => handleChangeMonth(monthOfTracking - 1)}
                    />
                    <p>{t(`months.${monthOfTracking}`)}</p>
                    <ArrowRightIcon
                        htmlColor="gray"
                        sx={{
                            color:
                                monthOfTracking ===
                                    currentDate.getMonth() + 1 &&
                                year === currentDate.getFullYear()
                                    ? "gray"
                                    : "green",
                            fontSize: "40px",
                        }}
                        onClick={() => handleChangeMonth(monthOfTracking + 1)}
                    />
                </div>
            </Box>
            {["accountant", "ceo"].includes(role?.toLowerCase()!) ? (
                <>
                    <AccountantTrackingTable
                        trackingPageSize={
                            activeTab === 0
                                ? trackingEmployeesPageSize
                                : trackingProjectsPageSize
                        }
                        setTrackingEmployeesPageNumber={
                            setTrackingEmployeesPageNumber
                        }
                        setTrackingProjectsPageNumber={
                            setTrackingProjectsPageNumber
                        }
                        debouncedEmployeesSearch={debouncedEmployeesSearch}
                        debouncedProjectsSearch={debouncedProjectsSearch}
                        trackingEmployeesPageNumber={
                            trackingEmployeesPageNumber
                        }
                        trackingProjectsPageNumber={trackingProjectsPageNumber}
                        isTrackingEmployeesSearchingRef={
                            isTrackingEmployeesSearchingRef
                        }
                        isTrackingProjectsSearchingRef={
                            isTrackingProjectsSearchingRef
                        }
                        trackingEmployeesSortConfig={sortEmployeesConfig}
                        trackingEmployeesHandleColumnClick={
                            handleEmployeesColumnClick
                        }
                        trackingProjectsSortConfig={sortProjectsConfig}
                        trackingProjectsHandleColumnClick={
                            handleProjectsColumnClick
                        }
                        handleColumnClick={sortProjectsConfig}
                        year={year}
                        month={monthOfTracking}
                        trackingFilters={trackingFilters}
                        setTrackingFilters={setTrackingFilters}
                    />
                    <Backdrop
                        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isTrackingLoading}
                    >
                        <CircularProgress sx={{ color: "#fff" }} />
                    </Backdrop>
                    <Stack sx={{ width: "500px", margin: "12px auto 0 auto" }}>
                        <Pagination
                            sx={{ margin: "auto" }}
                            count={
                                totalTrackingCount
                                    ? Math.ceil(
                                          totalTrackingCount /
                                              Number(
                                                  activeTab === 0
                                                      ? trackingEmployeesPageSize
                                                      : trackingProjectsPageSize,
                                              ),
                                      )
                                    : 1
                            }
                            page={
                                activeTab === 0
                                    ? trackingEmployeesPageNumber
                                    : trackingProjectsPageNumber
                            }
                            size="medium"
                            onChange={
                                activeTab === 0
                                    ? handleOnTrackingEmployeesPageChange
                                    : handleOnTrackingProjectsPageChange
                            }
                            shape="rounded"
                            color="primary"
                            variant="outlined"
                        />
                    </Stack>
                    <FiltersComponent
                        name={"filters"}
                        isDrawerOpen={isTrackingFiltersOpen}
                        createOrUpdate={null}
                        closeFormDrawer={() =>
                            setIsTrackingFiltersOpen(!isTrackingFiltersOpen)
                        }
                    >
                        <TrackingFilterPanel1
                            salariesFilter={trackingFilters}
                            setSalariesFilter={setTrackingFilters}
                            initialStateSalariesFilter={{
                                organizationId: 0,
                                positionId: 0,
                                skillId: 0,
                                skillLevelId: 0,
                                costCenterId: 2,
                                status: 2,
                                typeOfEmploymentId: 1,
                                fromStartDate: null,
                                beforeStartDate: null,
                            }}
                        />
                    </FiltersComponent>
                </>
            ) : (
                <>
                    <TrackingTable year={year} month={monthOfTracking} />
                    <Backdrop
                        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isTrackingLoading}
                    >
                        <CircularProgress sx={{ color: "#fff" }} />
                    </Backdrop>
                </>
            )}
        </div>
    );
};

export default Tracking;
