import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { CreateIcon, FilterIcon, ViewIcon } from "assets/icons";
import Guard from "features/Guard";
import {
    useAppDispatch,
    useAppSelector,
    useDebounce,
    useSortColumn,
} from "hooks";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getContractsEmployees } from "store/thunks/contractsEmployees";
import { createOrEditBtn } from "styles/MUIStyles";

import SearchIcon from "@mui/icons-material/Search";
import { Badge, FormControl, Select } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Pagination from "@mui/material/Pagination";
import { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";

import useLocalStorage from "../../../hooks/useLocalStorage";
import {
    getAllowedGroupsByMenuId,
    getAllowedPermissionsByGroupId,
    getLookupByName,
} from "../../../store";
import { setActiveContractEmployee } from "../../../store/slices/contractsSlice";

import ContractsEmployeesFilters from "./ContractsEmployeesFilters";
import ContractsEmployeesTable from "./ContractsEmployeesTable";

import styles from "./ContractEmployees.module.scss";

const ContractEmployees = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    // const contractEmployees = useAppSelector(
    //     (state) => state.contracts.contractEmployees
    // );
    const allowedGroups = useAppSelector((state) => state.auth.allowedGroups);
    const allowedMenus = useAppSelector((state) => state.auth.allowedMenus);
    const activeContractEmployee = useAppSelector(
        (state) => state.contracts.activeContractEmployee,
    );
    const pageCount = useAppSelector((state) => state.contracts.pageCount);
    const allowedPermissions = useAppSelector(
        (state) => state.auth.allowedPermissions,
    );

    const isContractsEmployeesSearchingRef = useRef<boolean | null>(null);
    const [
        isContractsEmployeesFiltersOpen,
        setIsContractsEmployeesFiltersOpen,
    ] = useState(false);
    const [contractsEmployeesPageNumber, setContractsEmployeesPageNumber] =
        useLocalStorage("contractsEmployeesPageNumber", 1);
    const [contractsEmployeesPageSize, setContractsEmployeesPageSize] =
        useLocalStorage("contractsEmployeesPageSize", 10);
    const { sortConfig, handleColumnClick } = useSortColumn(
        "contractsEmployeesTableSortConfig",
    );
    const [filtersCount, setFiltersCount] = useLocalStorage(
        "contractEmployeesFilterBadge",
        0,
    );
    const [search, setSearch] = useLocalStorage("contractsEmployeesSearch", "");
    const debouncedSearch = useDebounce(search, 1000);

    const handlePageSizeChange = (event: SelectChangeEvent) => {
        setContractsEmployeesPageSize(+event.target.value);
        setContractsEmployeesPageNumber(1);
        dispatch(
            getContractsEmployees({
                search: debouncedSearch,
                pageSize: +event.target.value,
                pageNumber: 1,
                sortBy: sortConfig,
            }),
        );
    };

    useEffect(() => {
        const menu = allowedMenus.find((menu) => menu.name === "Contract");
        typeof menu?.id === "number" &&
            dispatch(getAllowedGroupsByMenuId(menu.id));
    }, [dispatch]);

    useEffect(() => {
        const group = allowedGroups.find(
            (menu) => menu.name === `api/contracts`,
        );
        group?.id && dispatch(getAllowedPermissionsByGroupId(group.id));
    }, [dispatch, allowedGroups]);

    const handleOnPageChange = (event: ChangeEvent<unknown>, page: number) => {
        dispatch(setActiveContractEmployee(null));
        setContractsEmployeesPageNumber(page);
    };

    useEffect(() => {
        dispatch(getLookupByName("organizations"));
        dispatch(getLookupByName("contractstatuses"));
    }, [dispatch]);

    //badge

    return (
        <div>
            <div className={styles.crudButtonWrapper}>
                <Guard
                    allowedPermissions={allowedPermissions}
                    permissionName="Create"
                >
                    <Button
                        startIcon={<CreateIcon />}
                        variant="text"
                        sx={createOrEditBtn}
                        // disabled={!Boolean(activeContractEmployee)}
                        onClick={() => navigate("/contracts/management/create")}
                    >
                        {t("buttons.Create")}
                    </Button>
                </Guard>
                <Guard
                    allowedPermissions={allowedPermissions}
                    permissionName="View"
                >
                    <Button
                        startIcon={<ViewIcon />}
                        variant="text"
                        sx={createOrEditBtn}
                        disabled={!activeContractEmployee}
                        onClick={() => {
                            if (activeContractEmployee) {
                                navigate(
                                    `/contracts/view/details/${activeContractEmployee.id}`,
                                );
                            }
                        }}
                    >
                        {t("buttons.View")}
                    </Button>
                </Guard>
                <Box sx={{ marginLeft: "auto" }}>
                    <span>{t("filters.elementsOnPage")}</span>
                    <FormControl
                        variant="standard"
                        sx={{ margin: "4px 2rem 0 .8rem" }}
                    >
                        <Select
                            value={contractsEmployeesPageSize}
                            onChange={handlePageSizeChange}
                        >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={25}>25</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                            <MenuItem value={100}>100</MenuItem>
                        </Select>
                    </FormControl>
                    <Badge
                        color="primary"
                        badgeContent={filtersCount}
                        sx={{ "& span": { fontSize: "12px" } }}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                    >
                        <Button
                            startIcon={<FilterIcon />}
                            type="button"
                            variant="text"
                            sx={{ ...createOrEditBtn, marginLeft: "auto" }}
                            onClick={() =>
                                setIsContractsEmployeesFiltersOpen(true)
                            }
                        >
                            {t("buttons.Filters")}
                        </Button>
                    </Badge>
                </Box>
            </div>
            <div
                className={`${styles.scrollContainerHeight} + ${styles.scrollContainer}`}
            >
                <div className={styles.searchWrapper}>
                    <input
                        name="search"
                        type="text"
                        autoComplete="off"
                        value={search}
                        onChange={(e) => {
                            isContractsEmployeesSearchingRef.current = true;
                            setSearch(e.target.value);
                        }}
                        placeholder={t("filters.searchByName") as string}
                        className={styles.search}
                    />
                    <button type="button" className={styles.searchIconWrapper}>
                        <SearchIcon sx={{ color: "#fff" }} />
                    </button>
                </div>
                <ContractsEmployeesTable
                    debouncedSearch={debouncedSearch}
                    isContractsEmployeesSearchingRef={
                        isContractsEmployeesSearchingRef
                    }
                    contractsEmployeesPageNumber={contractsEmployeesPageNumber}
                    setContractsEmployeesPageNumber={
                        setContractsEmployeesPageNumber
                    }
                    sortConfig={sortConfig}
                    contractsEmployeesHandleColumnClick={handleColumnClick}
                />
            </div>
            <Stack sx={{ width: "500px", margin: "0 auto" }}>
                <Pagination
                    sx={{ margin: "auto" }}
                    count={pageCount || 1}
                    page={contractsEmployeesPageNumber}
                    size="medium"
                    onChange={handleOnPageChange}
                    shape="rounded"
                    color="primary"
                    variant="outlined"
                />
            </Stack>
            <ContractsEmployeesFilters
                debouncedSearch={search}
                isContractsEmployeesFiltersOpen={
                    isContractsEmployeesFiltersOpen
                }
                setIsContractsEmployeesFiltersOpen={
                    setIsContractsEmployeesFiltersOpen
                }
                contractsEmployeesPageNumber={contractsEmployeesPageNumber}
                setContractsEmployeesPageNumber={
                    setContractsEmployeesPageNumber
                }
                contractsEmployeesPageSize={contractsEmployeesPageSize}
                sortConfig={sortConfig}
                setFiltersCount={setFiltersCount}
            />
        </div>
    );
};

export default ContractEmployees;
