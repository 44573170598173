import React from "react";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

import "./Loader.scss";

const Loader = () => (
    //нужно переделать
    <Box
        style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100vh - 7.5vh)",
        }}
    >
        <CircularProgress size={40} sx={{ color: "#6C6463" }} />
    </Box>
);

export default Loader;
