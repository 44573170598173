import React, { useEffect, useState } from "react";
import { IHeaderParams } from "ag-grid-community";
import { EditIcon, ViewIcon } from "assets/icons";
import editIcon from "assets/icons/hoverEditIcon.svg";
import viewIcon from "assets/icons/view.svg";
import { formatNumberWithSpaces, getFromLocalStorage } from "core/utils";
import dayjs from "dayjs";
import AgGrid from "features/AgGrid/AgGrid";
import Guard from "features/Guard";
import { useAppDispatch, useAppSelector } from "hooks";
import { IContractDetailsWithAdditions } from "models/contracts";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CONTRACT_EMPLOYEES_PATH, CONTRACTS_PATH } from "router/constants";
import {
    getAllowedGroupsByMenuId,
    getAllowedPermissionsByGroupId,
} from "store";
import {
    setActiveContractsDetailsWithAdditions,
    setDefaultValuesForContractData,
} from "store/slices/contractsSlice";
import { getContractsDetailsToView } from "store/thunks/contractsEmployees";
import { createOrEditBtn, materialReactTableCell } from "styles/MUIStyles";
import Loader from "ui/Loader";
import BootstrapTooltip from "ui/Tooltip";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Breadcrumbs, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import {
    HeadComponents,
    useHeadComponents,
} from "../../../../hooks/useHeadComponents";

import styles from "./ContractDetails.module.scss";

const ContractsViewDetails = () => {
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const headVariants = useHeadComponents();

    const allowedGroups = useAppSelector((state) => state.auth.allowedGroups);
    const allowedPermissions = useAppSelector(
        (state) => state.auth.allowedPermissions,
    );
    const allowedMenus = useAppSelector((state) => state.auth.allowedMenus);

    useEffect(() => {
        const menu = allowedMenus.find((menu) => menu.name === "Contract");
        typeof menu?.id === "number" &&
            dispatch(getAllowedGroupsByMenuId(menu.id));
    }, [allowedMenus, dispatch]);

    useEffect(() => {
        const group = allowedGroups.find(
            (menu) => menu.name === `api/contracts`,
        );
        group?.id && dispatch(getAllowedPermissionsByGroupId(group.id));
    }, [dispatch, allowedGroups]);

    const isGettingContractsDetailsWithAdditionLoading = useAppSelector(
        (state) => state.contracts.isGettingContractsDetailsWithAdditionLoading,
    );
    const contractsDetailsWithAdditions = useAppSelector(
        (state) => state.contracts.contractsDetailsWithAdditions,
    );
    const activeContractsDetailsWithAdditions = useAppSelector(
        (state) => state.contracts.activeContractsDetailsWithAdditions,
    );

    const [mainContract, setMainContract] =
        useState<IContractDetailsWithAdditions | null>(null);

    useEffect(() => {
        if (params?.contractId) {
            dispatch(getContractsDetailsToView(+params.contractId));
        }
    }, [dispatch, params, t]);

    useEffect(() => {
        if (contractsDetailsWithAdditions.length) {
            const contractWithoutParentId =
                contractsDetailsWithAdditions.filter(
                    (contractWithAdditions) => !contractWithAdditions.parentId,
                );
            setMainContract(contractWithoutParentId[0]);
        }
    }, [contractsDetailsWithAdditions]);

    useEffect(() => {
        if (mainContract && mainContract.conclusionDate) {
            localStorage.setItem(
                "mainContractConclusionDate",
                mainContract.conclusionDate,
            );
        }
    }, [mainContract]);

    useEffect(() => {
        return () => {
            dispatch(setActiveContractsDetailsWithAdditions(null));
        };
    }, []);

    useEffect(() => {
        return () => {
            dispatch(setDefaultValuesForContractData(null));
        };
    }, []);

    useEffect(() => {
        const menu = allowedMenus.find((menu) => menu.name === "Contacts");
        typeof menu?.id === "number" &&
            dispatch(getAllowedGroupsByMenuId(menu.id));
    }, [allowedMenus, dispatch]);

    useEffect(() => {
        const group = allowedGroups.find(
            (menu) => menu.name === `api/contacts`,
        );
        group?.id && dispatch(getAllowedPermissionsByGroupId(group.id));
    }, [dispatch, allowedGroups]);

    const HeadComponent = (p: IHeaderParams) => {
        return headVariants[p.column.getColId() as keyof HeadComponents];
    };

    const RowComponent = (p: any) => {
        switch (p.colDef.field) {
            case "customActions": {
                return (
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            "& img": {
                                cursor: "pointer",
                            },
                        }}
                    >
                        <Guard
                            allowedPermissions={allowedPermissions}
                            permissionName="View"
                        >
                            {" "}
                            <BootstrapTooltip
                                title={t("buttons.View")}
                                onClick={() =>
                                    navigate(`/contracts/view/${p.data.id}`)
                                }
                                placement="top"
                            >
                                <img src={viewIcon} alt="view icon" />
                            </BootstrapTooltip>
                        </Guard>

                        <Guard
                            allowedPermissions={allowedPermissions}
                            permissionName="Edit"
                        >
                            <BootstrapTooltip
                                onClick={() => {
                                    if (!p.data.parentId) {
                                        navigate(
                                            `/contracts/management/edit/${p.data.id}`,
                                            {
                                                state: {
                                                    date: mainContract?.conclusionDate,
                                                },
                                            },
                                        );
                                    } else {
                                        navigate(
                                            `/contracts/details/management/edit/${p.data.id}`,
                                            {
                                                state: {
                                                    date: mainContract?.conclusionDate,
                                                },
                                            },
                                        );
                                    }
                                }}
                                title={t("buttons.Edit")}
                                placement="top"
                            >
                                <img src={editIcon} alt="edit icon" />
                            </BootstrapTooltip>
                        </Guard>
                    </Box>
                );
            }
            case "id": {
                return (
                    <Box sx={materialReactTableCell}>
                        <span>{p.data?.id}</span>
                    </Box>
                );
            }
            case "currency": {
                return (
                    <Box sx={materialReactTableCell}>
                        <span>{p.data?.currency}</span>
                    </Box>
                );
            }
            case "amountMonth": {
                return (
                    <Box
                        sx={{
                            ...materialReactTableCell,
                            "& > span": {
                                fontWeight: "600",
                            },
                        }}
                    >
                        <span>
                            {p.data.amountMonth || p.data.amountMonth === 0
                                ? formatNumberWithSpaces(p.data.amountMonth)
                                : null}
                        </span>
                    </Box>
                );
            }
            case "contractAmount": {
                return (
                    <Box
                        sx={{
                            ...materialReactTableCell,
                            "& > span": {
                                fontWeight: "600",
                            },
                        }}
                    >
                        <span>
                            {p.data.contractAmount ||
                            p.data.contractAmount === 0
                                ? formatNumberWithSpaces(p.data.contractAmount)
                                : null}
                        </span>
                    </Box>
                );
            }
            case "conclusionDate": {
                return (
                    <Box sx={materialReactTableCell}>
                        <span>
                            {p.data?.conclusionDate
                                ? dayjs(p.data.conclusionDate).format(
                                      "DD.MM.YYYY",
                                  )
                                : null}
                        </span>
                    </Box>
                );
            }
            case "endDate": {
                return (
                    <Box sx={materialReactTableCell}>
                        <span>
                            {p.data?.endDate
                                ? dayjs(p.data.endDate).format("DD.MM.YYYY")
                                : null}
                        </span>
                    </Box>
                );
            }
            case "note": {
                return (
                    <Box sx={materialReactTableCell}>
                        <span>{p.data?.note}</span>
                    </Box>
                );
            }
        }
    };

    const generateColumnDefs = () => {
        const savedColumnState = getFromLocalStorage(
            "agGridContractsViewDetailsTable",
        );

        let updatedColumnDefs: any[] = [
            {
                field: "customActions",
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 120,
            },
            {
                field: "id",
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 70,
            },
            {
                field: "currency",
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 120,
            },
            {
                field: "amountMonth",
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 140,
            },
            {
                field: "contractAmount",
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 120,
            },
            {
                field: "conclusionDate",
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 165,
            },
            {
                field: "endDate",
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 160,
            },
            {
                field: "note",
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 140,
            },
        ];

        if (Array.isArray(savedColumnState)) {
            updatedColumnDefs = savedColumnState.map((columnState: any) => {
                return {
                    field: columnState.colId,
                    width: columnState.width,
                    pinned: columnState.pinned,
                    headerComponent: HeadComponent,
                    cellRenderer: RowComponent,
                    resizable: true,
                };
            });
        }

        return updatedColumnDefs;
    };
    const saveColumnState = (columnState: any) => {
        localStorage.setItem(
            "agGridContractsViewDetailsTable",
            JSON.stringify(columnState),
        );
    };

    const columnDefs: any[] = generateColumnDefs();

    return (
        <div>
            {isGettingContractsDetailsWithAdditionLoading ? (
                <Loader />
            ) : (
                <div>
                    <Breadcrumbs sx={{ padding: "1rem 0 1rem 1rem" }}>
                        <Typography
                            sx={{
                                color: "#201f1e",
                                fontFamily: "Source Sans Pro",
                            }}
                        >
                            Impulse
                        </Typography>
                        <Link
                            to={`/${CONTRACTS_PATH}/${CONTRACT_EMPLOYEES_PATH}`}
                            className={styles.breadScrumbsLink}
                        >
                            {t("contracts.contractsEmployees")}
                        </Link>
                        {mainContract?.number ? (
                            <Typography
                                sx={{
                                    color: "#201f1e",
                                    fontFamily: "Source Sans Pro",
                                }}
                            >
                                {mainContract.number}
                            </Typography>
                        ) : null}
                    </Breadcrumbs>
                    <div className={styles.crudButtonWrapper}>
                        <Guard
                            allowedPermissions={allowedPermissions}
                            permissionName="View"
                        >
                            <Button
                                startIcon={<ViewIcon />}
                                variant="text"
                                sx={createOrEditBtn}
                                disabled={!activeContractsDetailsWithAdditions}
                                onClick={() => {
                                    if (activeContractsDetailsWithAdditions) {
                                        navigate(
                                            `/contracts/view/${activeContractsDetailsWithAdditions.id}`,
                                        );
                                    }
                                }}
                            >
                                {t("buttons.View")}
                            </Button>
                        </Guard>

                        <Guard
                            allowedPermissions={allowedPermissions}
                            permissionName="Edit"
                        >
                            <Button
                                startIcon={<EditIcon />}
                                variant="text"
                                disabled={!activeContractsDetailsWithAdditions}
                                sx={createOrEditBtn}
                                onClick={() => {
                                    if (activeContractsDetailsWithAdditions) {
                                        if (
                                            !activeContractsDetailsWithAdditions.parentId
                                        ) {
                                            navigate(
                                                `/contracts/management/edit/${activeContractsDetailsWithAdditions.id}`,
                                                {
                                                    state: {
                                                        date: mainContract?.conclusionDate,
                                                    },
                                                },
                                            );
                                        } else {
                                            navigate(
                                                `/contracts/details/management/edit/${activeContractsDetailsWithAdditions.id}`,
                                                {
                                                    state: {
                                                        date: mainContract?.conclusionDate,
                                                    },
                                                },
                                            );
                                        }
                                    }
                                }}
                            >
                                {t("buttons.Edit")}
                            </Button>
                        </Guard>
                    </div>
                    <div
                        className={`${styles.scrollContainerHeight} + ${styles.scrollContainer}`}
                    >
                        {mainContract ? (
                            <div className={styles.mainContractInfo}>
                                <div>
                                    <span>
                                        {mainContract?.number
                                            ? t("contracts.contract") +
                                              " " +
                                              mainContract?.number
                                            : null}{" "}
                                        {mainContract?.conclusionDate
                                            ? t("contracts.from") +
                                              " " +
                                              dayjs(
                                                  mainContract.conclusionDate,
                                              ).format("DD.MM.YYYY")
                                            : null}
                                    </span>
                                </div>
                                <span className={styles.employeeName}>
                                    {mainContract?.employeeName}
                                </span>
                                <span className={styles.contractName}>
                                    {mainContract?.name}
                                </span>
                                <span className={styles.contractType}>
                                    {mainContract?.type}
                                </span>
                            </div>
                        ) : null}
                        <div className={styles.buttonsContainer}>
                            <Guard
                                allowedPermissions={allowedPermissions}
                                permissionName="Create"
                            >
                                <Button
                                    startIcon={<AddOutlinedIcon />}
                                    variant="contained"
                                    onClick={() => {
                                        const contractDetailsWithParentId =
                                            contractsDetailsWithAdditions.filter(
                                                (contractDetailWithAddition) =>
                                                    !contractDetailWithAddition.parentId,
                                            );
                                        if (
                                            contractDetailsWithParentId.length
                                        ) {
                                            localStorage.setItem(
                                                "contractDetailsWithAdditionsParentId",
                                                String(
                                                    contractDetailsWithParentId[0]
                                                        .id,
                                                ),
                                            );
                                            navigate(
                                                `/contracts/details/management/create`,
                                                {
                                                    state: {
                                                        date: mainContract?.conclusionDate,
                                                    },
                                                },
                                            );
                                        }
                                    }}
                                    sx={{
                                        marginBottom: ".8rem",
                                        fontFamily: "Source Sans Pro",
                                        backgroundColor: "#205493",
                                        textTransform: "capitalize",
                                        fontSize: "1rem",
                                        lineHeight: "1.333rem",
                                        color: "#fff",
                                        fontWeight: "400",
                                        padding: "0 0.7rem 0.1rem 0.7rem",
                                        height: "2.5rem",
                                    }}
                                >
                                    {t("buttons.Add")}
                                </Button>
                            </Guard>

                            <Button
                                startIcon={<CloseOutlinedIcon />}
                                variant="contained"
                                onClick={() => navigate("/contracts/contracts")}
                                sx={{
                                    marginBottom: ".8rem",
                                    fontFamily: "Source Sans Pro",
                                    backgroundColor: "#205493",
                                    textTransform: "capitalize",
                                    fontSize: "1rem",
                                    lineHeight: "1.333rem",
                                    color: "#fff",
                                    fontWeight: "400",
                                    padding: "0 0.7rem 0.1rem 0.7rem",
                                    height: "2.5rem",
                                }}
                            >
                                {t("buttons.Close")}
                            </Button>
                        </div>
                        <div>
                            <AgGrid
                                rowData={contractsDetailsWithAdditions}
                                columnDefs={columnDefs}
                                saveColumnState={saveColumnState}
                                activeRow={activeContractsDetailsWithAdditions}
                                setActiveRow={
                                    setActiveContractsDetailsWithAdditions
                                }
                                height={"45vh"}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ContractsViewDetails;
