import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { CreateIcon, EditIcon, FilterIcon, TrackingIcon } from 'assets/icons';
import Guard from 'features/Guard';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useTranslation } from 'react-i18next';
import { createOrEditBtn } from 'styles/MUIStyles';

import { Badge, Box, FormControl, MenuItem, Select } from '@mui/material';
import Button from '@mui/material/Button';
import Pagination from '@mui/material/Pagination';
import { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';

import CatalogItemActionsComponent from '../../../features/Catalogs/CatalogItemActionsComponent';
import FilterComponent from '../../../features/Catalogs/FiltersComponent';
import useLocalStorage from '../../../hooks/useLocalStorage';
import { IRate, RateFilter } from '../../../models/rates';
import { getCurrencies } from '../../../store';
import { createRate, getActiveRates, getCurrencyRates, updateRate, } from '../../../store/thunks/rateThunk';

import { CreateNewRate } from './components/CreateNewRate';
import { EditRate } from './components/EditRate';
import { FilterPanel } from './components/Filter';
import { Table } from './components/Table';

import styles from '../CatalogsLayout.module.scss';

export const Main: FC<{
    name: string;
    createOrUpdate: null | string;
    setCreateOrUpdate: Function;
}> = ({ name, createOrUpdate, setCreateOrUpdate }): JSX.Element => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [rateValue, setRateValue] = useState('');
    const [newRateValue, setNewRateValue] = useState('');
    const [isRateValid, setIsRateValid] = useState(true);
    const [isNewRateValid, setNewIsRateValid] = useState(true);
    const [toggleStoryTable, setToggleStoryTable] = useState(false);
    const [currencyId, setCurrencyId] = useState<number | null>(null);
    const [selectedCurrencyName, setSelectedCurrencyName] = useState<string | null>(null);

    const handleChange = (event: SelectChangeEvent<typeof currencyId>) => {
        const {
            target: { value },
        } = event;
        setCurrencyId(Number(value));
    };
    const currencies: IRate[] = useAppSelector(
        (state) => state.lookups.currencies,
    );
    const rateIsLoading = useAppSelector((state) => state.rate.rateIsLoading);
    const activeCurrency = useAppSelector((state) => state.rate.activeCurrency);
    const activeRates = useAppSelector((state) => state.rate.activeRates);
    const editDisabled = activeCurrency?.isActive;
    const totalRateCount = useAppSelector((state) => state.rate.totalRateCount);

    const [rateFilters, setRateFilters] = useLocalStorage<RateFilter>(
        'rateFilters',
        {
            ['filter.currencyId']: null,
            ['filter.rateDate']: null,
        },
    );

    const [ratePageSize, setRatePageSize] = useLocalStorage('ratePageSize', 25);
    const [projectsPageNumber, setProjectsPageNumber] = useLocalStorage(
        'projectsPageNumber',
        1,
    );

    const handlePageSizeChange = (event: SelectChangeEvent) => {
        const value: number = Number(event.target.value);
        setRatePageSize(value);
        setProjectsPageNumber(1);

        dispatch(
            getCurrencyRates({
                pageNumber: projectsPageNumber,
                pageSize: value,
            }),
        );
    };

    const handleOnProjectsPageChange = (
        event: ChangeEvent<unknown>,
        page: number,
    ) => {
        setProjectsPageNumber(page);
    };

    const allowedPermissions = useAppSelector(
        (state) => state.auth.allowedPermissions,
    );
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isFilterOpen, setIsFilterOpen] = useState(false);

    const [filtersCount, setFiltersCount] = useLocalStorage(
        'rateFiltersBadge',
        0,
    );

    const onCreateItem = () => {
        setIsDrawerOpen(true);
        setCreateOrUpdate('create');
    };

    const closeDrawer = () => {
        setIsDrawerOpen(false);
    };

    const handleToggle = () => {
        setToggleStoryTable((prevState) => !prevState);
    };

    const handleSave = () => {
        const now = new Date().toISOString();

        const [integerPart, decimalPart] = rateValue.split('.');

        dispatch(
            updateRate({
                ...activeCurrency,
                rate: `${integerPart}.${(decimalPart || '').slice(0, 4)}`,
                rateDate: now,
            }),
        ).then(() => {
            setOpen(false);
            dispatch(getActiveRates({}));
        });
    };

    const createNewRate = () => {
        const now = new Date().toISOString();
        const [integerPart, decimalPart] = newRateValue.split('.');

        dispatch(
            createRate({
                currencyId,
                rate: `${integerPart}.${(decimalPart || '').slice(0, 4)}`,
                rateDate: now,
            }),
        ).then(() => {
            setIsDrawerOpen(false);
            dispatch(getActiveRates({})).then(() => {
                dispatch(
                    getCurrencyRates({
                        pageNumber: projectsPageNumber,
                        pageSize: ratePageSize,
                    }),
                );
            });
            setNewRateValue('');
            setCurrencyId(null);
        });
    };

    useEffect(() => {
        dispatch(
            getCurrencyRates({
                pageNumber: projectsPageNumber,
                pageSize: ratePageSize,
                ...rateFilters,
            }),
        );
    }, [dispatch, projectsPageNumber]);

    useEffect(() => {
        dispatch(getActiveRates({}));
        dispatch(getCurrencies());
    }, []);

    useEffect(() => {
        const activeR = currencies.find(
            (item) => item.id === rateFilters?.['filter.currencyId'],
        );
        // @ts-ignore
        setSelectedCurrencyName(activeR?.currencyCode);
    }, [rateFilters, currencies]);

    return (
        <div>
            <div
                className={styles.crudButtonWrapper}
                style={{ display: 'flex', alignItems: 'center' }}
            >
                <Guard
                    allowedPermissions={allowedPermissions}
                    permissionName="Create"
                >
                    <Button
                        startIcon={<CreateIcon/>}
                        variant="text"
                        // disabled={true}
                        onClick={() => onCreateItem()}
                        sx={createOrEditBtn}
                    >
                        {t('buttons.Create')}
                    </Button>
                </Guard>
                <Guard
                    allowedPermissions={allowedPermissions}
                    permissionName="Edit"
                >
                    <Button
                        startIcon={<EditIcon/>}
                        variant="text"
                        disabled={!editDisabled}
                        onClick={() => {
                            setRateValue(String(activeCurrency?.rate));
                            handleOpen();
                        }}
                        sx={createOrEditBtn}
                    >
                        {t('buttons.Edit')}
                    </Button>
                </Guard>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: 'auto',
                        marginLeft: 'auto',
                    }}
                >
                    <Box
                        sx={{
                            marginLeft: 'auto',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <span>{t('filters.elementsOnPage')}</span>
                        <FormControl
                            variant="standard"
                            sx={{ margin: '4px .8rem 0 .8rem' }}
                        >
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={ratePageSize}
                                onChange={handlePageSizeChange}
                            >
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={25}>25</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                                <MenuItem value={100}>100</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Badge
                        color="primary"
                        badgeContent={filtersCount}
                        sx={{ '& span': { fontSize: '12px' } }}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <Button
                            startIcon={<FilterIcon/>}
                            variant="text"
                            onClick={() => {
                                setIsFilterOpen(!isFilterOpen);
                            }}
                            sx={{
                                ...createOrEditBtn,
                            }}
                        >
                            {t('buttons.Filters')}
                        </Button>
                    </Badge>
                    <Button
                        style={{
                            fontSize: 14,
                        }}
                        startIcon={<TrackingIcon/>}
                        sx={{
                            ...createOrEditBtn,
                        }}
                        className="toggle-table-button"
                        onClick={handleToggle}
                        variant="text"
                    >
                        <div>
                            {toggleStoryTable
                                ? t('rates.closeHistory')
                                : t('rates.openHistory')}
                        </div>
                    </Button>
                </div>
            </div>
            <Table
                selectedCurrencyName={selectedCurrencyName}
                rateFilters={rateFilters}
                toggleStoryTable={toggleStoryTable}
            />
            <Stack sx={{ width: '500px', margin: '0 auto', marginTop: 3 }}>
                <Pagination
                    disabled={!toggleStoryTable}
                    sx={{ margin: 'auto' }}
                    count={
                        totalRateCount
                            ? Math.ceil(totalRateCount / Number(ratePageSize))
                            : 1
                    }
                    page={projectsPageNumber}
                    size="medium"
                    onChange={handleOnProjectsPageChange}
                    shape="rounded"
                    color="primary"
                    variant="outlined"
                />
            </Stack>
            <div>
                <CatalogItemActionsComponent
                    closeFormDrawer={handleClose}
                    name={name}
                    isDrawerOpen={open}
                    createOrUpdate="edit"
                >
                    <EditRate
                        rateIsLoading={rateIsLoading}
                        isRateValid={isRateValid}
                        rateValue={rateValue}
                        setIsRateValid={setIsRateValid}
                        setRateValue={setRateValue}
                        handleSave={handleSave}
                        closeDrawer={handleClose}
                    />
                </CatalogItemActionsComponent>
                <CatalogItemActionsComponent
                    name={name}
                    isDrawerOpen={isDrawerOpen}
                    createOrUpdate={createOrUpdate}
                    closeFormDrawer={closeDrawer}
                >
                    <CreateNewRate
                        newRateValue={newRateValue}
                        rateValue={newRateValue}
                        createNewRate={createNewRate}
                        setNewIsRateValid={setNewIsRateValid}
                        setNewRateValue={setNewRateValue}
                        handleChange={handleChange}
                        currencyId={currencyId}
                        isNewRateValid={isNewRateValid}
                        isRateValid={isRateValid}
                        rateIsLoading={rateIsLoading}
                        closeDrawer={closeDrawer}
                    />
                </CatalogItemActionsComponent>
            </div>
            <FilterComponent
                name=""
                createOrUpdate={createOrUpdate}
                isDrawerOpen={isFilterOpen}
                closeFormDrawer={() => setIsFilterOpen(false)}
            >
                <FilterPanel
                    setSelectedCurrencyName={setSelectedCurrencyName}
                    rateFilters={rateFilters}
                    setRateFilters={setRateFilters}
                    pageNumber={projectsPageNumber}
                    pageSize={ratePageSize}
                    setFiltersCount={setFiltersCount}
                    setPageNumber={setProjectsPageNumber}
                />
            </FilterComponent>
        </div>
    );
};
