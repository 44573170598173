import React, { useEffect, useRef, useState } from 'react';
import { IPersonalDocuments } from 'models/documents';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';

import { Box, Breadcrumbs, Button, Modal, Tab, Tabs, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';

import { DocumentsList } from '../../../features/DocumentsList/DocumentsList';
import { EmployeeGeneralInfo } from '../../../features/Employee/EmployeeGeneralInfo';
import EmployeeProjects from '../../../features/Employee/EmployeeProjects/EmployeeProjects';
import EmployeeSkills from '../../../features/Employee/EmployeeSkills/EmployeeSkills';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
    addPersonalDocument,
    getAllowedGroupsByMenuId,
    getAllowedPermissionsByGroupId,
    getAllSkillLevels,
    getAllSkills,
    getPersonalDocuments,
    getPersonalInfoEmployeeById,
} from '../../../store';
import Loader from '../../../ui/Loader';
import { SelectFile } from '../../../ui/SelectFile';
import { sendNotification } from '../../../ui/Toast';

import { AddDocument } from './AddDocument';

// import EmployeeDocuments from "../../../features/Employee/EmployeeView/EmployeeDocuments/EmployeeDocuments";

interface DocumentsListProps {
    documents: IPersonalDocuments[];
    downloadDocument: (docData: any, isPersonal: boolean) => Promise<void>;
}

export const PersonalInfo = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const fileInput = useRef(null);
    const [file, setFile] = useState<File | null>(null);
    const [newDocumentName, setNewDocumentName] = useState('');
    const [newDocumentDescription, setNewDocumentDescription] = useState('');
    const [isOpenAddFile, setIsOpenAddFile] = useState(false);
    const [isOpenConfigDocument, setIsOpenConfigDocument] = useState(false);

    const personalInfoEmployeeDetails = useAppSelector(
        (state) => state.employees.personalInfoEmployeeDetails,
    );
    const allSkills = useAppSelector((state) => state.employees.allSkills);
    const userId = useAppSelector((state) => state.auth.userId);
    const allSkillLevels = useAppSelector(
        (state) => state.employees.allSkillLevels,
    );
    const personalDocuments = useAppSelector(
        (state) => state.employees.personalDocuments,
    );
    const [employeesActiveTab, setEmployeesActiveTab] = useLocalStorage(
        'employeesActiveTab',
        0,
    );
    const [isLoading, setIsLoading] = useState(false);

    const handleActiveTab = (event: React.SyntheticEvent, newValue: number) => {
        setEmployeesActiveTab(newValue);
    };

    const handleUpload = (event: any) => {
        setFile(event.target.files[0]);
    };

    const addDocument = () => {
        setIsOpenConfigDocument(!isOpenConfigDocument);
        // setIsOpenAddFile(true);
    };

    const save = async () => {
        if (file) {
            try {
                dispatch(addPersonalDocument({
                    name: newDocumentName,
                    description: newDocumentDescription,
                    file
                }))
                    .then(() => {
                        setIsOpenAddFile(false);
                        dispatch(getPersonalDocuments());
                        sendNotification(t('statuses.Success'), 'success');
                    });
            } catch (e) {
                console.log(e);
                sendNotification(t('statuses.Error'), 'error');
            }
        }
    };

    useEffect(() => {
        (async () => {
            if (userId) {
                setIsLoading(true);
                await Promise.all([
                    dispatch(getPersonalDocuments()),
                    dispatch(getPersonalInfoEmployeeById(userId)),
                    dispatch(getAllSkills()),
                    dispatch(getAllSkillLevels()),
                ]);
                setIsLoading(false);
            }
        })();
    }, [dispatch, userId, t]);

    const allowedGroups = useAppSelector((state) => state.auth.allowedGroups);
    const allowedPermissions = useAppSelector(
        (state) => state.auth.allowedPermissions,
    );
    const allowedMenus = useAppSelector((state) => state.auth.allowedMenus);

    useEffect(() => {
        const menu = allowedMenus.find((menu) => menu.name === 'Employees');
        typeof menu?.id === 'number' &&
        dispatch(getAllowedGroupsByMenuId(menu.id));
    }, [allowedMenus, dispatch]);

    useEffect(() => {
        const group = allowedGroups.find(
            (menu) => menu.name === `api/employees`,
        );
        group?.id && dispatch(getAllowedPermissionsByGroupId(group.id));
    }, [dispatch, allowedGroups]);

    if (!personalInfoEmployeeDetails || isLoading) {
        return <Loader/>;
    }

    return (
        <Box sx={{ width: '100%', padding: '10px 0' }}>
            <Breadcrumbs sx={{ padding: '10px' }}>
                <Link
                    to="/personalcabinet"
                    // className={styles.breadScrumbsLink}
                >
                    {t('menu.personalAccount')}
                </Link>
                <Typography
                    sx={{ color: '#201f1e', fontFamily: 'Source Sans Pro' }}
                >
                    {`${personalInfoEmployeeDetails?.firstName} ${personalInfoEmployeeDetails?.lastName}`}
                </Typography>
            </Breadcrumbs>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={employeesActiveTab} onChange={handleActiveTab}>
                    <Tab label={t('tabs.generalInfo')} value={0}/>
                    <Tab label={t('tabs.skills')} value={1}/>
                    <Tab label={t('tabs.projects')} value={2}/>
                    <Tab label={t('tabs.documents')} value={3}/>
                    <Tab label={t('tabs.history')} value={4}/>
                    <Tab label={t('tabs.calendar')} value={5}/>
                    <Tab label={t('tabs.applications')} value={6}/>
                    <Tab label={t('tabs.notifications')} value={7}/>
                </Tabs>
            </Box>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={isOpenAddFile}
                onClose={() => setIsOpenAddFile(false)}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Box>
                    <SelectFile
                        name={newDocumentName}
                        description={newDocumentDescription}
                        setName={setNewDocumentName}
                        setDescription={setNewDocumentDescription}
                        fileInput={fileInput}
                        handleCancelClick={() => setIsOpenAddFile(false)}
                        handleUpload={handleUpload}
                        save={save}
                    />
                </Box>
            </Modal>
            {employeesActiveTab === 0 && (
                <EmployeeGeneralInfo
                    // editLink={`/${PERSONAL_ACCOUNT_EDIT}`}
                    allowedPermissions={allowedPermissions}
                    {...personalInfoEmployeeDetails}
                />
            )}

            {employeesActiveTab === 1 && (
                <EmployeeSkills
                    id={personalInfoEmployeeDetails?.id ?? ''}
                    allowedPermissions={allowedPermissions}
                    fte={personalInfoEmployeeDetails?.fte ?? 0}
                    employeeFirstName={
                        personalInfoEmployeeDetails?.firstName ?? ''
                    }
                    employeeLastName={
                        personalInfoEmployeeDetails?.lastName ?? ''
                    }
                    employeeSkills={personalInfoEmployeeDetails?.skills ?? []}
                    allSkills={allSkills}
                    allSkillLevels={allSkillLevels}
                />
            )}
            {employeesActiveTab === 2 && (
                <EmployeeProjects
                    id={personalInfoEmployeeDetails?.id ?? ''}
                    // @ts-ignore
                    projects={
                        personalInfoEmployeeDetails?.projectEmployees ?? []
                    }
                />
            )}
            {employeesActiveTab === 3 && (
                <div>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            margin: '15px',
                        }}
                    >
                        {isOpenConfigDocument ? (
                            <Button
                                variant="contained"
                                sx={{ margin: '0 10px' }}
                                onClick={() => addDocument()}
                            >
                                {t('buttons.Save')}
                            </Button>
                        ) : (
                            <Button
                                variant="contained"
                                sx={{ margin: '0 10px' }}
                                onClick={() => addDocument()}
                            >
                                {t('buttons.AddDocument')}
                            </Button>
                        )}
                            <Button
                                variant="contained"
                                onClick={() => (
                                    isOpenConfigDocument ? setIsOpenConfigDocument(!isOpenConfigDocument) : navigate(-1)
                                )}
                                sx={{ margin: '0 10px' }}
                            >
                                {t('buttons.Close')}
                            </Button>
                    </Box>
                    {isOpenConfigDocument ? (
                        <AddDocument />
                    ) : (
                        <Box>
                            <h4 style={{ margin: '15px' }}>  {t('menu.personal-documents')}</h4>
                            <DocumentsList
                                documents={personalDocuments?.myDocuments ?? []}
                                isPersonal={true}
                            />
                            <h4 style={{ margin: '15px' }}>  {t('menu.common-documents')}</h4>
                            <DocumentsList
                                documents={personalDocuments?.commonDocuments ?? []}
                                isPersonal={false}
                            />
                        </Box>
                    )}
                </div>
            )}
        </Box>
    );
};
