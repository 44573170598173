import { useEffect, useState } from "react";
import dayjs from "dayjs";
import ConfirmationModal from "features/ConfirmationModal";
import { useAppDispatch, useAppSelector } from "hooks";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link,useNavigate, useParams } from "react-router-dom";
import {
    getCurrencies,
    getEmployeeById,
    getEmployeesWithoutSalary,
} from "store";
import {
    createSalary,
    editSalary,
    getSalaries,
    getSalary,
} from "store/thunks/salariesThunk";
import { cancelBtn, saveBtn } from "styles/MUIStyles";

import { ErrorMessage } from "@hookform/error-message";
import {
    Autocomplete,
    Breadcrumbs,
    Button,
    TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { SALARIES_PATH } from "../../../router/constants";

import styles from "./SalariesActions.module.scss";

function SalariesActions() {
    const { t } = useTranslation();
    const params = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const {
        register,
        control,
        handleSubmit,
        reset,
        watch,
        setValue,
        getValues,
        clearErrors,
        formState: { errors, isDirty },
    } = useForm<any>({
        defaultValues: {
            currencyId: null,
            remark: params.id ? "" : null,
            fromDate: null,
        },
    });

    const employeesWithoutSalary = useAppSelector(
        (state) => state.lookups.employeesWithoutSalary
    );
    const currencies = useAppSelector((state) => state.lookups.currencies);
    const employeeInfo = useAppSelector(
        (state) => state.employees.employeeDetails
    );
    const salary = useAppSelector((state) => state.salaries.salary);

    const [isUnsavedChangesModalOpen, setIsUnsavedChangesModalOpen] =
        useState<boolean>(false);

    useEffect(() => {
        dispatch(getSalaries(null));
        dispatch(getEmployeesWithoutSalary());
        dispatch(getCurrencies());
        if (params.employeeId) {
            dispatch(getSalary(String(params.employeeId)));
            dispatch(getEmployeeById(String(params.employeeId)));
        }
    }, [dispatch, params.employeeId, t]);

    useEffect(() => {
        if (params.id) {
            const data = salary.find((item) => item.id === Number(params.id));
            reset({
                ...data,
            });
        }
    }, [params.id, salary, reset, setValue, getValues]);

    const watchedFields = watch([
        "rateMonth",
        "rateWeek",
        "rateDay",
        "rateHour",
    ]);

    const isAnyFieldFilled = !watchedFields.some((field) => field);

    useEffect(() => {
        if (!isAnyFieldFilled) {
            clearErrors("rateMonth");
            clearErrors("rateWeek");
            clearErrors("rateDay");
            clearErrors("rateHour");
        }
    }, [isAnyFieldFilled, clearErrors]);

    const onSubmit = async (data: any) => {
        const salaryData = {
            rateMonth: !data.rateMonth ? null : +data.rateMonth,
            rateWeek: !data.rateWeek ? null : +data.rateWeek,
            rateDay: !data.rateDay ? null : +data.rateDay,
            rateHour: !data.rateHour ? null : +data.rateHour,
            fromDate:
                dayjs(data.fromDate).format("YYYY-MM-DD").split("T")[0] +
                "T00:00:00.000Z",
            employeeId: params.employeeId ?? data.employeeId.id,
            currencyId: data.currencyId.id,
            remark: data.remark,
        };
        if (params.id) {
            await dispatch(
                editSalary({
                    id: params.id,
                    ...salaryData,
                })
            );
        } else {
            await dispatch(createSalary(salaryData));
        }

        navigate(-1);
    };

    return (
        <main className={styles.container}>
            <Box>
                <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: "1rem" }}>
                    <Link color="inherit" to="/">
                        Impulse
                    </Link>
                    <Link color="inherit" to={`/${SALARIES_PATH}`}>
                        {t("salaries.Title")}
                    </Link>
                    {Boolean(params.employeeId) && (
                        <Link
                            color="inherit"
                            to={`/${SALARIES_PATH}/${employeeInfo?.id}`}
                        >
                            {employeeInfo?.lastName} {employeeInfo?.firstName}
                        </Link>
                    )}
                    {params.employeeId && params.id ? (
                        <Link to="/" color="inherit">
                            {t("buttons.Edit")}
                        </Link>
                    ) : (
                        Boolean(params.employeeId || !params.id) && (
                            <Link to="/" color="inherit">
                                {t("buttons.Create")}
                            </Link>
                        )
                    )}
                </Breadcrumbs>
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.box_title}>
                    <div>
                        <h3>{t("salaries.Salary")}</h3>
                        {Boolean(params.employeeId) && (
                            <h2>
                                {employeeInfo?.lastName}{" "}
                                {employeeInfo?.firstName} {t("data.tabNo")}{" "}
                                {employeeInfo?.personnelNumber
                                    ? employeeInfo?.personnelNumber
                                    : "-"}
                            </h2>
                        )}
                    </div>
                    <div className={styles.box_btns}>
                        <Button type="submit" variant="contained" sx={saveBtn}>
                            {t("buttons.Save")}
                        </Button>
                        <Button
                            variant="text"
                            sx={cancelBtn}
                            onClick={() => {
                                if (isDirty) {
                                    setIsUnsavedChangesModalOpen(true);
                                } else {
                                    navigate(-1);
                                }
                            }}
                        >
                            {t("buttons.Close")}
                        </Button>
                    </div>
                </div>
                {Boolean(params.employeeId) || (
                    <Box sx={{ width: "20%" }}>
                        <p>{t("data.FIO")}</p>
                        <Controller
                            rules={{
                                required: {
                                    value: true,
                                    message: t("validation.requiredField"),
                                },
                            }}
                            render={({ field }) => (
                                <Autocomplete
                                    {...field}
                                    isOptionEqualToValue={(option, value) =>
                                        option.id === value.id
                                    }
                                    options={employeesWithoutSalary}
                                    getOptionLabel={(option) =>
                                        option.name ? option.name : ""
                                    }
                                    renderOption={(props: any, option) => {
                                        return (
                                            <p
                                                {...props}
                                                className={styles.dropDownItem}
                                            >
                                                {option.name}
                                            </p>
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            sx={{
                                                "& .MuiOutlinedInput-notchedOutline":
                                                    {
                                                        borderRadius: "0",
                                                        border: `1px solid ${
                                                            errors.employeeId
                                                                ? "#dc3545"
                                                                : "#8A8886"
                                                        }`,
                                                    },
                                                "& .MuiOutlinedInput-root": {
                                                    "&:hover fieldset": {
                                                        border: `1px solid ${
                                                            errors.employeeId
                                                                ? "#dc3545"
                                                                : "#8A8886"
                                                        }`,
                                                    },
                                                    "&.Mui-focused fieldset": {
                                                        borderColor: `${
                                                            errors.employeeId
                                                                ? "#dc3545"
                                                                : "#8A8886"
                                                        }`,
                                                        boxShadow: `${
                                                            errors.employeeId
                                                                ? "0 0 0 0.2rem rgba(220,53,69,.25)"
                                                                : "0 0 0 0.2rem rgba(0, 123, 255, .25)"
                                                        }`,
                                                    },
                                                },
                                                padding: "5px 0",
                                            }}
                                            size="small"
                                            variant="outlined"
                                            placeholder={`${t(
                                                "salaries.SelectEmployee"
                                            )}`}
                                        />
                                    )}
                                    onChange={(_, data) => field.onChange(data)}
                                    value={field.value}
                                />
                            )}
                            name={"employeeId"}
                            control={control}
                        />
                        <ErrorMessage
                            name="employeeId"
                            errors={errors}
                            render={({ message }) => (
                                <p
                                    style={{
                                        color: "#dc3545",
                                        fontSize: "0.9rem",
                                    }}
                                >
                                    {message}
                                </p>
                            )}
                        />
                    </Box>
                )}
                <Box sx={{ width: "20%" }}>
                    <p>{t("salaries.Currency")}</p>
                    <Controller
                        rules={{
                            required: {
                                value: true,
                                message: t("validation.requiredField"),
                            },
                        }}
                        render={({ field }) => (
                            <Autocomplete
                                disabled={Boolean(params.id)}
                                {...field}
                                isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                }
                                options={currencies}
                                getOptionLabel={(option) =>
                                    option.name ? option.name : ""
                                }
                                renderOption={(props: any, option) => {
                                    return (
                                        <p
                                            {...props}
                                            className={styles.dropDownItem}
                                        >
                                            {option.name}
                                        </p>
                                    );
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        sx={{
                                            "& .MuiOutlinedInput-notchedOutline":
                                                {
                                                    borderRadius: "0",
                                                    border: `1px solid ${
                                                        errors.currencyId
                                                            ? "#dc3545"
                                                            : "#8A8886"
                                                    }`,
                                                },
                                            "& .MuiOutlinedInput-root": {
                                                "&:hover fieldset": {
                                                    border: `1px solid ${
                                                        errors.currencyId
                                                            ? "#dc3545"
                                                            : "#8A8886"
                                                    }`,
                                                },
                                                "&.Mui-focused fieldset": {
                                                    borderColor: `${
                                                        errors.currencyId
                                                            ? "#dc3545"
                                                            : "#8A8886"
                                                    }`,
                                                    boxShadow: `${
                                                        errors.currencyId
                                                            ? "0 0 0 0.2rem rgba(220,53,69,.25)"
                                                            : "0 0 0 0.2rem rgba(0, 123, 255, .25)"
                                                    }`,
                                                },
                                            },
                                            padding: "5px 0",
                                        }}
                                        size="small"
                                        variant="outlined"
                                        placeholder={`${t(
                                            "salaries.SelectСurrency"
                                        )}`}
                                    />
                                )}
                                onChange={(_, data) => field.onChange(data)}
                                value={field.value}
                            />
                        )}
                        name={"currencyId"}
                        control={control}
                    />
                    <ErrorMessage
                        name="currencyId"
                        errors={errors}
                        render={({ message }) => (
                            <p style={{ color: "#dc3545", fontSize: "0.9rem" }}>
                                {message}
                            </p>
                        )}
                    />
                </Box>
                <Box sx={{ width: "20%" }}>
                    <p className={styles.title_input}>
                        {t("salaries.RateMonth")}
                    </p>
                    <input
                        {...register("rateMonth", {
                            pattern: {
                                value: /^\d{1,9}(\.\d{1,2})?$/,
                                message: t("validation.invalidNumber"),
                            },
                            required: {
                                value: isAnyFieldFilled,
                                message: t("validation.requiredField"),
                            },
                        })}
                        placeholder={`${t("salaries.EnterRateMonth")}`}
                        autoComplete="off"
                        type="text"
                        className={
                            errors.rateMonth ? styles.inputError : styles.input
                        }
                    />
                    <ErrorMessage
                        name="rateMonth"
                        errors={errors}
                        render={({ message }) => <p>{message}</p>}
                    />
                </Box>
                <Box sx={{ width: "20%" }}>
                    <p className={styles.title_input}>
                        {t("salaries.RateWeek")}
                    </p>
                    <input
                        {...register("rateWeek", {
                            pattern: {
                                value: /^\d{1,9}(\.\d{1,2})?$/,
                                message: t("validation.invalidNumber"),
                            },
                            required: {
                                value: isAnyFieldFilled,
                                message: t("validation.requiredField"),
                            },
                        })}
                        placeholder={`${t("salaries.EnterBidWeek")}`}
                        autoComplete="off"
                        type="text"
                        className={
                            errors.rateWeek ? styles.inputError : styles.input
                        }
                    />
                    <ErrorMessage
                        name="rateWeek"
                        errors={errors}
                        render={({ message }) => <p>{message}</p>}
                    />
                </Box>
                <Box sx={{ width: "20%" }}>
                    <p className={styles.title_input}>
                        {t("salaries.RateDay")}
                    </p>
                    <input
                        {...register("rateDay", {
                            pattern: {
                                value: /^\d{1,9}(\.\d{1,2})?$/,
                                message: t("validation.invalidNumber"),
                            },
                            required: {
                                value: isAnyFieldFilled,
                                message: t("validation.requiredField"),
                            },
                        })}
                        placeholder={`${t("salaries.EnterBidDay")}`}
                        autoComplete="off"
                        type="text"
                        className={
                            errors.rateDay ? styles.inputError : styles.input
                        }
                    />
                    <ErrorMessage
                        name="rateDay"
                        errors={errors}
                        render={({ message }) => <p>{message}</p>}
                    />
                </Box>
                <Box sx={{ width: "20%" }}>
                    <p className={styles.title_input}>
                        {t("salaries.RateHour")}
                    </p>
                    <input
                        {...register("rateHour", {
                            pattern: {
                                value: /^\d{1,9}(\.\d{1,2})?$/,
                                message: t("validation.invalidNumber"),
                            },
                            required: {
                                value: isAnyFieldFilled,
                                message: t("validation.requiredField"),
                            },
                        })}
                        placeholder={`${t("salaries.EnterBidHour")}`}
                        autoComplete="off"
                        type="text"
                        className={
                            errors.rateHour ? styles.inputError : styles.input
                        }
                    />
                    <ErrorMessage
                        name="rateHour"
                        errors={errors}
                        render={({ message }) => <p>{message}</p>}
                    />
                </Box>
                <Box sx={{ width: "20%" }}>
                    <p className={styles.title_input}>
                        {t("salaries.FromDate")}
                    </p>
                    <Controller
                        control={control}
                        name={"fromDate"}
                        rules={{
                            required: {
                                value: true,
                                message: t("validation.requiredField"),
                            },
                        }}
                        render={({ field }) => (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker
                                    disabled={Boolean(params.id)}
                                    {...field}
                                    inputFormat="DD.MM.YYYY"
                                    renderInput={(params) => (
                                        <TextField
                                            size="small"
                                            {...params}
                                            sx={{
                                                "& .MuiOutlinedInput-notchedOutline":
                                                    {
                                                        borderRadius: "0",
                                                        border: `1px solid ${
                                                            errors.fromDate
                                                                ? "#dc3545"
                                                                : "#8A8886"
                                                        }`,
                                                    },
                                                "& .MuiOutlinedInput-root": {
                                                    "&:hover fieldset": {
                                                        border: `1px solid ${
                                                            errors.fromDate
                                                                ? "#dc3545"
                                                                : "#8A8886"
                                                        }`,
                                                    },
                                                    "&.Mui-focused fieldset": {
                                                        borderColor: `${
                                                            errors.fromDate
                                                                ? "#dc3545"
                                                                : "#8A8886"
                                                        }`,
                                                        boxShadow: `${
                                                            errors.fromDate
                                                                ? "0 0 0 0.2rem rgba(220,53,69,.25)"
                                                                : "0 0 0 0.2rem rgba(0, 123, 255, .25)"
                                                        }`,
                                                    },
                                                },
                                                padding: "5px 0",
                                            }}
                                            inputProps={{
                                                ...params.inputProps,
                                                placeholder: `${t(
                                                    "data.dayMonthYear"
                                                )}`,
                                                autoComplete: "off",
                                            }}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        )}
                    />
                    <ErrorMessage
                        name="fromDate"
                        errors={errors}
                        render={({ message }) => (
                            <p style={{ color: "#dc3545", fontSize: "0.9rem" }}>
                                {message}
                            </p>
                        )}
                    />
                </Box>
                <Box sx={{ width: "20%" }}>
                    <p className={styles.title_input}>{t("salaries.Note")}</p>
                    <textarea
                        {...register("remark", {
                            maxLength: {
                                value: 2048,
                                message: t("validation.maxLength2048"),
                            },
                        })}
                        cols={5}
                        rows={5}
                        placeholder={`${t("salaries.EnterNote")}`}
                        className={
                            errors.remark
                                ? styles.textAreaError
                                : styles.textArea
                        }
                    />
                    <ErrorMessage
                        name="remark"
                        errors={errors}
                        render={({ message }) => <span>{message}</span>}
                    />
                </Box>
            </form>
            <ConfirmationModal
                isModalOpen={isUnsavedChangesModalOpen}
                setIsModalOpen={() => setIsUnsavedChangesModalOpen(false)}
                actionName={"Close"}
                instanceName={""}
                message={"closeFormWithoutSaving"}
                handleAction={() => {
                    if (isDirty) {
                        navigate(-1);
                    }
                }}
            />
        </main>
    );
}

export default SalariesActions;
