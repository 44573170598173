import React, { FC } from 'react';
import closeIcon from "assets/icons/closeIcon.svg";
import { useTranslation } from "react-i18next";
import { activateOrDeactivateBtn, activateOrDeactivateModal, cancelBtn } from "styles/MUIStyles";

import { Button, Modal } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";


interface Props {
    handleUpdatePermissions: () => void,
    handleReturnToInitialPermissions: () => void,
    isLeaveModalOpen: boolean,
    handleLeaveModalClose: () => void,
}

const LeaveModal: FC<Props> = (props): JSX.Element => {
    const {
        handleUpdatePermissions,
        handleReturnToInitialPermissions,
        isLeaveModalOpen,
        handleLeaveModalClose,
    } = props;

    const { t } = useTranslation();

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={isLeaveModalOpen}
                onClose={handleLeaveModalClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={isLeaveModalOpen}>
                    <Box sx={{ ...activateOrDeactivateModal, width: "650px" }}>
                        <Box sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginBottom: "1rem",
                            "& img": {
                                width: "25px",
                                height: "25px",
                                cursor: "pointer",
                            }
                        }}>
                            <Typography
                                id="transition-modal-title"
                                variant="h4"
                                component="h2"
                                sx={{
                                    fontFamily: "Source Sans Pro",
                                    lineHeight: 1.5,
                                    fontSize: "1.5rem",
                                    color: "#212529",
                                }}
                            >
                                {t('notifications.leavePageMessage')}
                            </Typography>
                            <img
                                src={closeIcon}
                                alt="close ion"
                                onClick={handleLeaveModalClose}
                            />
                        </Box>
                        <Typography
                            component="p"
                            sx={{
                                fontFamily: "Source Sans Pro",
                                color: "#212529",
                                marginBottom: "1rem",
                                fontSize: "1rem",
                            }}
                        >
                            {t('notifications.leavePageDescription')}
                        </Typography>
                        <Box sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            gap: "10px",
                        }}>
                            <Button
                                variant="text"
                                onClick={async () => {
                                    await handleUpdatePermissions();
                                    handleLeaveModalClose();
                                }}
                                sx={{ ...activateOrDeactivateBtn, color: "#fff" }}>
                                {t('buttons.Save')}
                            </Button>
                            <Button
                                variant="text"
                                onClick={() => {
                                    handleReturnToInitialPermissions();
                                    handleLeaveModalClose();
                                }}
                                sx={cancelBtn}>
                                {t('buttons.Reset')}
                            </Button>
                            <Button
                                variant="text"
                                sx={cancelBtn}
                                onClick={handleLeaveModalClose}>
                                {t('buttons.Cancel')}
                            </Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
};

export default LeaveModal;
