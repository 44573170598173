import dayjs from "dayjs";
import { IEditGeneralInfo, IFormData, IFormEmployee } from "models/projects";

export const handleEditEmployee = (data: IFormEmployee) => {
    const projectEmployee = {
        ...data,
        startDate: data.startDate
            ? dayjs(data.startDate).format("YYYY-MM-DDT00:00:00.000") + "Z"
            : null,
        finishDate: data.finishDate
            ? dayjs(data.finishDate).format("YYYY-MM-DDT00:00:00.000") + "Z"
            : null,
    };

    delete projectEmployee.employee;
    return projectEmployee;
};

export const handleAddEmployeeToProject = (
    data: IFormEmployee,
    projectId: number,
) => {
    const projectEmployee = {
        ...data,
        startDate: data.startDate
            ? dayjs(data.startDate).format("YYYY-MM-DDT00:00:00.000") + "Z"
            : null,
        finishDate: data.finishDate
            ? dayjs(data.finishDate).format("YYYY-MM-DDT00:00:00.000") + "Z"
            : null,
        employeeId: data?.employee?.id,
        fte: data.fte ? data.fte : 0,
        projectId,
    };

    delete projectEmployee.employee;

    return projectEmployee;
};

export const handleCreateGeneralInfo = (data: IFormData) => {
    const { ...rest } = data;
    const generalInfo = {
        ...rest,
        startDate: data.startDate
            ? dayjs(data.startDate).format("YYYY-MM-DDT00:00:00.000") + "Z"
            : null,
        finishDate: data.finishDate
            ? dayjs(data.finishDate).format("YYYY-MM-DDT00:00:00.000") + "Z"
            : null,
        clientId: data.client.id ? data.client.id : undefined,
    };

    if (!generalInfo.clientId) {
        delete generalInfo.clientId;
    }

    return generalInfo;
};

export const handleEditGeneralInfo = (data: IFormData) => {
    const { ...rest } = data;
    const generalInfo: IEditGeneralInfo = {
        ...rest,
        id: data.id ? +data.id : null,
        startDate: data.startDate
            ? dayjs(data.startDate).format("YYYY-MM-DDT00:00:00.000") + "Z"
            : null,
        finishDate: data.finishDate
            ? dayjs(data.finishDate).format("YYYY-MM-DDT00:00:00.000") + "Z"
            : null,
        clientId: data.client.id ? data.client.id : undefined,
    };

    return generalInfo;
};
