import { useAppSelector } from "hooks";
import { Navigate, useLocation } from "react-router-dom";
import { LoginForm } from "views/Login/LoginForm";

export function LoginPage() {
    const location = useLocation();
    const redirectPath = location.state?.path || "/";

    const isAuth = useAppSelector((state) => state.auth.isAuth);

    if (isAuth) {
        return <Navigate to={redirectPath} />;
    }

    return <LoginForm />;
}
