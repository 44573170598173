import React, { FC, memo, useEffect } from "react";
import { AscSortIcon, DefaultSortIcon, DescSortIcon } from "assets/icons";
import dayjs from "dayjs";
import ExtraUserActions from "features/ExtraUserActions";
import ListItemActions from "features/ListItemActions";
import { useAppDispatch, useAppSelector } from "hooks";
import { IUsersTable } from "models/user";
import { useTranslation } from "react-i18next";
import { projectsTableContainer, usersListTableCell } from "styles/MUIStyles";

import {
    styled,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
} from "@mui/material";

import { getUsers } from "../../store";

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        borderTop: "1px solid rgba(0, 0, 0, 0.1)",
        borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        color: "#3E444A",
        padding: "8px 8px 8px 6px",
        fontFamily: "Source Sans Pro",
        fontSize: "15px",
        lineHeight: "20px",
        fontWeight: "700 !important",
        "&:first-of-type": {
            padding: "8px 8px 8px 20px",
        },
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        padding: "8px",
        wordBreak: "break-word",
        borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        "&:first-of-type": {
            padding: "16px 16px 16px 20px",
        },
        "&:last-of-type": {
            padding: "16px",
        },
    },
}));

const StyledTableRow = styled(TableRow)(() => ({
    "&:last-child td, &:last-child th": {
        border: 0,
    },
    "& img": {
        visibility: "hidden",
    },
    "&": {
        cursor: "pointer",
    },
    "&:hover": {
        background: "#f4f3f2",
        "& img": {
            visibility: "visible",
            cursor: "pointer",
        },
    },
}));

const UsersTable: FC<IUsersTable> = memo((props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const {
        isUserSearchingRef,
        activeUser,
        setActiveUser,
        onEditUser,
        sortConfig,
        setConfirmationModalOpen,
        debouncedSearchUserName,
        debouncedSearchUserEmail,
        handleColumnClick,
        userStatus,
        setPageNumber,
        pageNumber,
        pageSize,
    } = props;

    const users = useAppSelector((state) => state.users.users);

    const getArrowForSortDirection = (column: string) => {
        if (sortConfig.column !== column) {
            return <DefaultSortIcon />;
        }

        return sortConfig.direction === "asc" ? (
            <AscSortIcon />
        ) : (
            <DescSortIcon />
        );
    };

    useEffect(() => {
        if (isUserSearchingRef.current && pageNumber > 1) {
            setPageNumber(1);
        } else {
            dispatch(
                getUsers({
                    userName: debouncedSearchUserName,
                    email: debouncedSearchUserEmail,
                    pageNumber,
                    userStatus,
                    pageSize,
                    sortBy: sortConfig,
                }),
            );
        }

        isUserSearchingRef.current = false;
    }, [
        dispatch,
        userStatus,
        pageNumber,
        sortConfig,
        debouncedSearchUserName,
        debouncedSearchUserEmail,
    ]);

    return (
        <TableContainer sx={projectsTableContainer}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <StyledTableCell sx={{ width: "18%" }} align="left">
                            <TableSortLabel
                                onClick={() => handleColumnClick("firstName")}
                            >
                                {t("data.firstName")}{" "}
                                {getArrowForSortDirection("firstName")}
                            </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell sx={{ width: "18%" }} align="left">
                            <TableSortLabel
                                onClick={() => handleColumnClick("lastName")}
                            >
                                {t("data.lastName")}{" "}
                                {getArrowForSortDirection("lastName")}
                            </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell sx={{ width: "20%" }} align="left">
                            <TableSortLabel
                                onClick={() => handleColumnClick("email")}
                            >
                                {t("data.email")}{" "}
                                {getArrowForSortDirection("email")}
                            </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell sx={{ width: "15%" }} align="left">
                            <TableSortLabel
                                onClick={() => handleColumnClick("roleName")}
                            >
                                {t("data.roleName")}{" "}
                                {getArrowForSortDirection("roleName")}
                            </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell
                            sx={{ width: "16.6666%" }}
                            align="left"
                        >
                            <TableSortLabel
                                onClick={() =>
                                    handleColumnClick("registrationDate")
                                }
                            >
                                {t("data.registrationDate")}{" "}
                                {getArrowForSortDirection("registrationDate")}
                            </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell sx={{ width: "13" }} align="left">
                            {t("data.actions")}
                        </StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users.map((item) => (
                        <StyledTableRow
                            key={item.id}
                            sx={{
                                background:
                                    item.id === activeUser?.id
                                        ? "#f4f3f2"
                                        : "transparent",
                                "& span img": {
                                    visibility:
                                        item.id === activeUser?.id
                                            ? "visible"
                                            : "hidden",
                                },
                            }}
                            onClick={() => setActiveUser({ ...item })}
                        >
                            <StyledTableCell sx={usersListTableCell}>
                                {item.firstName}
                            </StyledTableCell>
                            <StyledTableCell sx={usersListTableCell}>
                                {item.lastName}
                            </StyledTableCell>
                            <StyledTableCell
                                align="left"
                                sx={usersListTableCell}
                            >
                                {item.email}
                            </StyledTableCell>
                            <StyledTableCell
                                align="left"
                                sx={usersListTableCell}
                            >
                                {t(`roles.${item.roleName}`)}
                            </StyledTableCell>
                            <StyledTableCell
                                align="left"
                                sx={usersListTableCell}
                            >
                                {item.registrationDate
                                    ? dayjs(item.registrationDate).format(
                                          "DD.MM.YYYY",
                                      )
                                    : null}
                            </StyledTableCell>
                            <StyledTableCell
                                align="left"
                                sx={usersListTableCell}
                            >
                                <ListItemActions
                                    itemId={item.id}
                                    isItemActive={!item.blockUser}
                                    activationAvailable={false}
                                    setConfirmationModalOpen={
                                        setConfirmationModalOpen
                                    }
                                    editItemClick={onEditUser}
                                />
                                <ExtraUserActions
                                    userId={item.id}
                                    setConfirmationModalOpen={
                                        setConfirmationModalOpen
                                    }
                                    isUserActive={!item.blockUser}
                                />
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
});

export default UsersTable;
