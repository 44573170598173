export { ReactComponent as AscSortIcon } from "./ascSort.svg";
export { ReactComponent as BankCardIcon } from "./bankCardIcon.svg";
export { ReactComponent as CalendarIcon } from "./calendarVacationsSickDaysIcon.svg";
export { ReactComponent as CatalogsIcon } from './catalogs.svg';
export { ReactComponent as ChangePassword } from "./changePassword.svg";
export { ReactComponent as CheckedIcon } from "./checkedCheckbox.svg";
export { ReactComponent as CheckedDisabledIcon } from "./checkedDisabledIcon.svg";
export { ReactComponent as CloseToastIcon } from "./closeToast.svg";
export { ReactComponent as ContractsIcon } from './contracts.svg';
export { ReactComponent as CreateIcon } from "./create.svg";
export { ReactComponent as DefaultSortIcon } from "./defaultSort.svg";
export { ReactComponent as DeleteIcon } from "./delete.svg";
export { ReactComponent as DescSortIcon } from "./descSort.svg";
export { ReactComponent as DisturbIcon } from "./disturb.svg";
export { ReactComponent as DocumentIcon } from "./document.svg";
export { ReactComponent as DownloadIcon } from "./download.svg";
export { ReactComponent as EditIcon } from "./edit.svg";
export { ReactComponent as EmployeesIcon } from "./employees.svg";
export { ReactComponent as ExpandMoreIcon } from "./expandMore.svg";
export { ReactComponent as FilterIcon } from "./filter.svg";
export { ReactComponent as MenuIcon } from "./menu.svg";
export { ReactComponent as ProfileIcon } from './profile.svg';
export { ReactComponent as ProjectsIcon } from "./projects.svg";
export { ReactComponent as ResetIcon } from "./reset.svg";
export { ReactComponent as SaveAs } from "./saveAs.svg";
export { ReactComponent as ShowLessIcon } from './showLess.svg';
export { ReactComponent as ShowMoreIcon } from './showMore.svg';
export { ReactComponent as SkillIcon } from "./skill.svg";
export { ReactComponent as SystemSettingsIcon } from './systemSettings.svg';
export { ReactComponent as ErrorToastIcon } from "./toastError.svg";
export { ReactComponent as InfoToastIcon } from "./toastInfo.svg";
export { ReactComponent as SuccessToastIcon } from "./toastSuccess.svg";
export { ReactComponent as WarningToastIcon } from "./toastWarning.svg";
export { ReactComponent as TrackingIcon } from "./tracking.svg";
export { ReactComponent as UncheckedIon } from "./uncheckedCheckbox.svg";
export { ReactComponent as UsersIcon } from './users.svg';
export { ReactComponent as ViewIcon } from "./view.svg";
