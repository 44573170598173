import React, { FC } from "react";
import Guard from "features/Guard";
import { useTranslation } from "react-i18next";

import hoverEditIcon from "../../assets/icons/hoverEditIcon.svg";
import locked from "../../assets/icons/locked.svg";
import unlocked from "../../assets/icons/unlocked.svg";
import viewIcon from "../../assets/icons/view.svg";
import { useAppSelector } from "../../hooks";
import BootstrapTooltip from "../../ui/Tooltip";

const Index: FC<{
    itemId: number | string;
    isItemActive: boolean;
    activationAvailable: boolean;
    setConfirmationModalOpen: Function;
    editItemClick: Function;
    viewItemClick?: Function | any;
    isEmployee?: boolean;
}> = ({
    itemId,
    isItemActive,
    activationAvailable,
    setConfirmationModalOpen,
    editItemClick,
    viewItemClick,
    isEmployee,
}) => {
    const { t } = useTranslation();

    const allowedPermissions = useAppSelector(
        (state) => state.auth.allowedPermissions,
    );

    const handleClick = (actionName: string) => {
        setConfirmationModalOpen({ isOpen: true, actionName });
    };

    //Я СКОРО ПЕРЕДЕЛАЮ ЭТУ КОМПОНЕНТУ!1!!

    return (
        <>
            <span>
                {activationAvailable ? (
                    isItemActive ? (
                        <Guard
                            allowedPermissions={allowedPermissions}
                            permissionName="Deactivate"
                        >
                            <BootstrapTooltip
                                sx={{ position: "absolute" }}
                                title={t("buttons.Deactivate")}
                                placement="top"
                            >
                                <img
                                    onClick={() => {
                                        handleClick("Deactivate");
                                    }}
                                    src={locked}
                                    alt=""
                                />
                            </BootstrapTooltip>
                        </Guard>
                    ) : !isItemActive ? (
                        <Guard
                            allowedPermissions={allowedPermissions}
                            permissionName="Activate"
                        >
                            <BootstrapTooltip
                                title={t("buttons.Activate")}
                                placement="top"
                            >
                                <img
                                    onClick={() => {
                                        handleClick("Activate");
                                    }}
                                    src={unlocked}
                                    alt=""
                                />
                            </BootstrapTooltip>
                        </Guard>
                    ) : null
                ) : null}

                {isItemActive ? (
                    <Guard
                        allowedPermissions={allowedPermissions}
                        permissionName="Edit"
                    >
                        <BootstrapTooltip
                            title={t("buttons.Edit")}
                            placement="top"
                        >
                            <img
                                src={hoverEditIcon}
                                alt=""
                                onClick={() => editItemClick(itemId)}
                            />
                        </BootstrapTooltip>
                    </Guard>
                ) : null}
                {isItemActive && viewItemClick ? (
                    <Guard
                        allowedPermissions={allowedPermissions}
                        permissionName="View"
                    >
                        <BootstrapTooltip
                            title={t("buttons.View")}
                            placement="top"
                            style={{ marginLeft: isEmployee ? "10px" : "0" }}
                        >
                            <img
                                src={viewIcon}
                                alt=""
                                onClick={() => viewItemClick(itemId)}
                            />
                        </BootstrapTooltip>
                    </Guard>
                ) : null}
            </span>
        </>
    );
};

export default Index;
