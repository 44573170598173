import React from "react";
import { useAppDispatch } from "hooks";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { logout } from "store/thunks/authThunk";
import NavBar from "views/Layout/SideBar/NavBar";
import UserInfo from "views/Layout/UserInfo";

import Button from "@mui/material/Button";

import { LOGIN_PATH } from "../../../router/constants";
import { handleNavBarExpanded, revertAll } from "../../../store";

import "./styles.scss";

const SideBar: React.FC<{ className: string }> = ({ className }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleLogout = async () => {
        await dispatch(logout());
        dispatch(handleNavBarExpanded(false));
        const currentLanguage = localStorage.getItem("i18nextLng");
        localStorage.clear();
        currentLanguage && localStorage.setItem("i18nextLng", currentLanguage);
        dispatch(revertAll());
        navigate(LOGIN_PATH);
    };

    return (
        <div className={`${className} sidebar`}>
            <UserInfo/>
            <NavBar />
            <Button variant="outlined" className={'signOutBtn'} onClick={handleLogout}>{t('buttons.signOut')}</Button>
        </div>
    );
};

export default SideBar;
