import React, { FC } from 'react';
import { FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import styles from '../../CatalogsLayout.module.scss';

interface Props {
    rateValue: string;
    errors?:  FieldErrors<any>;
    setIsRateValid: (isRateValid: boolean) => void;
    setRateValue: (rateValue: string) => void;
}

export const TextFieldRate: FC<Props> = ({ rateValue, setRateValue, setIsRateValid, errors }) => {
    const { t } = useTranslation();

    return (
        <>
            <label htmlFor="countryName" className={styles.drawerTopSubTitle}>{t('rates.rate')}</label>
            <input
                style={{
                    border: errors?.rateValue ? '1px solid red' : '',
                    borderRadius: '4px'
                }}
                placeholder={t("rates.rate") || ''}
                className={
                    errors?.name ? styles.catalogsDrawerInputError : styles.catalogsDrawerInput
                }
                id="outlined-basic"
                value={rateValue}
                onChange={(e) => {
                    const value = e.target.value;

                    const onlyDigitsAndDot = /^[\d.]*$/.test(value);

                    const [integerPart, decimalPart] = value.split('.');

                    if (onlyDigitsAndDot) {
                        if ((integerPart?.length > 8 || decimalPart?.length > 4) || (integerPart?.length === 8 && e.target.selectionStart === 9)) {
                            setIsRateValid(false);
                            setRateValue(value);
                        } else {
                            setRateValue(value);
                            setIsRateValid(true);
                        }
                    }
                }}
            />
        </>
    );
};
