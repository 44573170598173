import { PropsWithChildren, ReactElement, ReactNode } from "react";

type GuardProps<T extends { name: string }> = {
    allowedPermissions: Array<T>;
    permissionName: "View" | "Create" | "DownloadFile" | "Edit" | "Delete" | 'Activate'| 'Deactivate';
    fallback?: ReactNode;
    extraCondition?: boolean;
};

const Guard = <T extends { name: string }>({
    allowedPermissions,
    permissionName,
    fallback = null,
    children,
}: PropsWithChildren<GuardProps<T>>): ReactElement | null => {

    return (
        <>
            {allowedPermissions.some(
                (permission) => permission.name === permissionName
            )
                ? children
                : fallback}
        </>
    );
};

export default Guard;
