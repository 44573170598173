import { FC } from "react";
import dayjs from "dayjs";
import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ErrorMessage } from "@hookform/error-message";
import { Box, TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import styles from "../VacationsSickDaysEditEmployee.module.scss";

interface IProps {
    register: Function;
    control: Control;
    errors: any;
    clearErrors: any;
    getValues: any;
    registerName: string;
    isCheckDayOffThirdSign: boolean;
}

const BoxDatePickerStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "10px",
};

const DaysOffEditEmployeeCard: FC<IProps> = ({
    register,
    control,
    errors,
    clearErrors,
    getValues,
    registerName,
    isCheckDayOffThirdSign,
}) => {
    const { t } = useTranslation();

    const validateDateRange = (dateType: string) => {
        const { startDate, finishDate } = getValues(registerName);

        const isValidStartDate = dayjs(startDate).isValid();
        const isValidFinishDate = dayjs(finishDate).isValid();

        if (!isValidStartDate && dateType === "startDate") {
            return t("validation.invalidDate");
        }
        if (!isValidFinishDate && dateType === "finishDate") {
            return t("validation.invalidDate");
        }

        //so that the fields become clean from validation immediately, and not when editing
        if (
            new Date(startDate) <= new Date(finishDate) &&
            isValidStartDate &&
            isValidFinishDate
        ) {
            clearErrors(`${registerName}.startDate`);
            clearErrors(`${registerName}.finishDate`);
            return true;
        }

        //only when the dates are validated check for the correct period and except for vacations
        if (isValidStartDate && isValidFinishDate && isCheckDayOffThirdSign) {
            return t("validation.startDateGreaterThanEndDateErrorMessage");
        }
    };

    return (
        <div className={styles.card}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box sx={BoxDatePickerStyle}>
                    <label className={styles.label}>
                        {t("data.startDate")}
                    </label>
                    <Controller
                        control={control}
                        name={`${registerName}.startDate`}
                        rules={{
                            required: {
                                value: true,
                                message: t("validation.requiredField"),
                            },
                            validate: () => validateDateRange("startDate"),
                        }}
                        render={({ field }) => (
                            <DesktopDatePicker
                                {...field}
                                inputFormat="DD.MM.YYYY"
                                renderInput={(params) => (
                                    <TextField
                                        name={`${registerName}.startDate`}
                                        size="small"
                                        {...params}
                                        sx={{
                                            width: "100%",
                                            "& .MuiOutlinedInput-notchedOutline":
                                                {
                                                    borderRadius: "0",
                                                    border: `1px solid ${
                                                        errors?.startDate
                                                            ? "#dc3545"
                                                            : "#8A8886"
                                                    }`,
                                                },
                                            "& .MuiOutlinedInput-root": {
                                                "&:hover fieldset": {
                                                    border: `1px solid ${
                                                        errors?.startDate
                                                            ? "#dc3545"
                                                            : "#8A8886"
                                                    }`,
                                                },
                                                "&.Mui-focused fieldset": {
                                                    borderColor: `${
                                                        errors?.startDate
                                                            ? "#dc3545"
                                                            : "#8A8886"
                                                    }`,
                                                    boxShadow: `${
                                                        errors?.startDate
                                                            ? "0 0 0 0.2rem rgba(220,53,69,.25)"
                                                            : "0 0 0 0.2rem rgba(0, 123, 255, .25)"
                                                    }`,
                                                },
                                            },
                                            padding: "5px 0",
                                        }}
                                        inputProps={{
                                            ...params.inputProps,
                                            placeholder: t(
                                                "projects.dayMonthYear",
                                            ) as string,
                                            autoComplete: "off",
                                        }}
                                    />
                                )}
                            />
                        )}
                    />
                </Box>
                <ErrorMessage
                    name={`${registerName}.startDate`}
                    errors={errors}
                    render={({ message }) => (
                        <p className={styles.errorsMessage}>{message}</p>
                    )}
                />
                {isCheckDayOffThirdSign && (
                    <>
                        <Box sx={{ ...BoxDatePickerStyle, marginTop: "10px" }}>
                            <label className={styles.label}>
                                {t("data.finishDate")}
                            </label>
                            <Controller
                                control={control}
                                name={`${registerName}.finishDate`}
                                rules={{
                                    required: {
                                        value: true,
                                        message: t("validation.requiredField"),
                                    },
                                    validate: () =>
                                        validateDateRange("finishDate"),
                                }}
                                render={({ field }) => (
                                    <DesktopDatePicker
                                        {...field}
                                        inputFormat="DD.MM.YYYY"
                                        renderInput={(params) => (
                                            <TextField
                                                name={`${registerName}.finishDate`}
                                                size="small"
                                                {...params}
                                                sx={{
                                                    width: "100%",
                                                    "& .MuiOutlinedInput-notchedOutline":
                                                        {
                                                            borderRadius: "0",
                                                            border: `1px solid ${
                                                                errors?.finishDate
                                                                    ? "#dc3545"
                                                                    : "#8A8886"
                                                            }`,
                                                        },
                                                    "& .MuiOutlinedInput-root":
                                                        {
                                                            "&:hover fieldset":
                                                                {
                                                                    border: `1px solid ${
                                                                        errors?.finishDate
                                                                            ? "#dc3545"
                                                                            : "#8A8886"
                                                                    }`,
                                                                },
                                                            "&.Mui-focused fieldset":
                                                                {
                                                                    borderColor: `${
                                                                        errors?.finishDate
                                                                            ? "#dc3545"
                                                                            : "#8A8886"
                                                                    }`,
                                                                    boxShadow: `${
                                                                        errors?.finishDate
                                                                            ? "0 0 0 0.2rem rgba(220,53,69,.25)"
                                                                            : "0 0 0 0.2rem rgba(0, 123, 255, .25)"
                                                                    }`,
                                                                },
                                                        },
                                                    padding: "5px 0",
                                                }}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    placeholder: t(
                                                        "projects.dayMonthYear",
                                                    ) as string,
                                                    autoComplete: "off",
                                                }}
                                            />
                                        )}
                                    />
                                )}
                            />
                        </Box>
                        <ErrorMessage
                            name={`${registerName}.finishDate`}
                            errors={errors}
                            render={({ message }) => (
                                <p className={styles.errorsMessage}>
                                    {message}
                                </p>
                            )}
                        />
                    </>
                )}
            </LocalizationProvider>
            <Box
                sx={{
                    marginTop: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "10px",
                }}
            >
                <label className={styles.label}>{t("salaries.Note")}</label>
                <textarea
                    cols={5}
                    rows={5}
                    placeholder={`${t("salaries.EnterNote")}`}
                    className={styles.textArea}
                    {...register(`${registerName}.remark`, {
                        maxLength: {
                            value: 200,
                            message: t("validation.maxLength200"),
                        },
                    })}
                />
            </Box>
            <ErrorMessage
                name={`${registerName}.remark`}
                errors={errors}
                render={({ message }) => (
                    <p className={styles.errorsMessage}>{message}</p>
                )}
            />
            {
                isCheckDayOffThirdSign || <Box sx={{ height: "58px" }} /> //the cards were the same size
            }
        </div>
    );
};

export default DaysOffEditEmployeeCard;
