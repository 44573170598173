import React, { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector, useSortColumn } from "hooks";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getAllUsers } from "store/thunks/usersPermissionsThunk";

import {
    styled,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import {
    AscSortIcon,
    DefaultSortIcon,
    DescSortIcon,
} from "../../../assets/icons";
import editIcon from "../../../assets/icons/hoverEditIcon.svg";
import { getFromLocalStorage } from "../../../core/utils";
import { IPermissionsUsers } from "../../../models/user";
import {
    ISortConfig,
    IUsersPermissionsFilters,
} from "../../../models/usersPermissions";
import BootstrapTooltip from "../../../ui/Tooltip";

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        borderTop: "1px solid rgba(0, 0, 0, 0.1)",
        borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        color: "#3E444A",
        padding: "8px 8px 8px 3rem",
        fontFamily: "Source Sans Pro",
        fontSize: "15px",
        lineHeight: "20px",
        fontWeight: "700 !important",
        "&:first-of-type": {
            padding: "8px 8px 8px 3rem",
        },
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        padding: "8px 8px 8px 3rem",
        wordBreak: "break-word",
        borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        "&:first-of-type": {
            padding: "16px 16px 16px 3rem",
        },
        "&:last-of-type": {
            padding: "16px",
        },
    },
}));

const StyledTableRow = styled(TableRow)(() => ({
    "&:last-child td, &:last-child th": {
        border: 0,
    },
    "& img": {
        visibility: "hidden",
    },
    "&": {
        cursor: "pointer",
    },
    "&:hover": {
        background: "#f4f3f2",
        "& img": {
            visibility: "visible",
            cursor: "pointer",
        },
    },
}));

interface Props {
    pageNumber: number;
    usersPermissionsActiveUser: IPermissionsUsers | null;
    setUsersPermissionsActiveUser: (activeUser: IPermissionsUsers) => void;
}

const UsersPermissionsTable: FC<Props> = (props): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { sortConfig, handleColumnClick } = useSortColumn(
        "usersPermissionsTableSortConfig",
    );

    const isGettingAllUsersLoading = useAppSelector(
        (state) => state.users.isGettingAllUsersLoading,
    );
    const usersPermissionsList = useAppSelector(
        (state) => state.users.usersPermissionsList,
    );

    const getArrowForSortDirection = (column: string) => {
        if (sortConfig.column !== column) {
            return <DefaultSortIcon />;
        }

        return sortConfig.direction === "asc" ? (
            <AscSortIcon />
        ) : (
            <DescSortIcon />
        );
    };

    const setUserPermissionSettingsName = (user: {
        firstName: string;
        lastName: string;
    }) => {
        if (user.firstName && user.lastName) {
            localStorage.setItem(
                "userPermissionsSettingsUserName",
                user.firstName + " " + user.lastName,
            );
        }
    };

    useEffect(() => {
        const usersPermissionsFilters =
            getFromLocalStorage<IUsersPermissionsFilters>(
                "usersPermissionsFilters",
            );
        const pageSize = getFromLocalStorage<number>(
            "usersPermissionsPageSize",
        );
        const userName = getFromLocalStorage<string>(
            "usersPermissionsSearchByName",
        );
        const userEmail = getFromLocalStorage<string>(
            "usersPermissionsByEmail",
        );
        const sortConfig = getFromLocalStorage<ISortConfig>(
            "usersPermissionsTableSortConfig",
        );
        dispatch(
            getAllUsers({
                sortBy: sortConfig,
                pageSize,
                userName,
                pageNumber: props.pageNumber,
                email: userEmail,
                ...usersPermissionsFilters,
            }),
        );
    }, [sortConfig, props.pageNumber, t, dispatch]);

    return (
        <div>
            <TableContainer
                sx={{
                    marginTop: "1rem",
                    maxHeight: "calc(100vh - 41vh)",
                    border: "none",
                    overflow: "auto",
                    "&::-webkit-scrollbar": {
                        width: "0.3333rem",
                        height: "0.3333rem",
                    },

                    "&::-webkit-scrollbar-thumb": {
                        borderRadius: "0.3333rem",
                        background: "#6d6d6d",
                    },

                    "&::-webkit-scrollbar-track": {
                        borderRadius: "0.3333rem",
                    },
                }}
            >
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell
                                onClick={() => handleColumnClick("email")}
                                sx={{ border: "none", width: "18%" }}
                            >
                                <TableSortLabel>
                                    {t("data.email")}
                                    {getArrowForSortDirection("email")}
                                </TableSortLabel>
                            </StyledTableCell>
                            <StyledTableCell
                                onClick={() => handleColumnClick("lastName")}
                                sx={{ border: "none", width: "18%" }}
                            >
                                <TableSortLabel>
                                    {t("data.lastName")}
                                    {getArrowForSortDirection("lastName")}
                                </TableSortLabel>
                            </StyledTableCell>
                            <StyledTableCell
                                onClick={() => handleColumnClick("firstName")}
                                sx={{ border: "none", width: "18%" }}
                            >
                                <TableSortLabel>
                                    {t("data.firstName")}
                                    {getArrowForSortDirection("firstName")}
                                </TableSortLabel>
                            </StyledTableCell>
                            <StyledTableCell
                                onClick={() => handleColumnClick("roleName")}
                                sx={{ border: "none", width: "18%" }}
                            >
                                <TableSortLabel>
                                    {t("data.roleName")}
                                    {getArrowForSortDirection("roleName")}
                                </TableSortLabel>
                            </StyledTableCell>
                            <StyledTableCell
                                onClick={() => handleColumnClick("country")}
                                sx={{ border: "none", width: "18%" }}
                            >
                                <TableSortLabel>
                                    {t("data.country")}
                                    {getArrowForSortDirection("country")}
                                </TableSortLabel>
                            </StyledTableCell>
                            <StyledTableCell
                                sx={{ border: "none", width: "10%" }}
                            />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {usersPermissionsList?.data.map((user) => (
                            <StyledTableRow
                                onClick={() =>
                                    props.setUsersPermissionsActiveUser(user)
                                }
                                key={user.id}
                                sx={{
                                    background:
                                        user.id ===
                                        props.usersPermissionsActiveUser?.id
                                            ? "#f4f3f2"
                                            : "transparent",
                                }}
                            >
                                <StyledTableCell
                                    sx={{
                                        border: "none",
                                        fontFamily: "Source Sans Pro",
                                        fontWeight: 500,
                                        fontSize: "16px",
                                        lineHeight: "20px",
                                        paddingLeft: "8px",
                                    }}
                                >
                                    {user.email}
                                </StyledTableCell>
                                <StyledTableCell
                                    sx={{
                                        border: "none",
                                        fontFamily: "Source Sans Pro",
                                        fontWeight: 500,
                                        fontSize: "16px",
                                        lineHeight: "20px",
                                        paddingLeft: "8px",
                                    }}
                                >
                                    {user.lastName}
                                </StyledTableCell>
                                <StyledTableCell
                                    sx={{
                                        border: "none",
                                        fontFamily: "Source Sans Pro",
                                        fontWeight: 500,
                                        fontSize: "16px",
                                        lineHeight: "20px",
                                        paddingLeft: "8px",
                                    }}
                                >
                                    {user.firstName}
                                </StyledTableCell>
                                <StyledTableCell
                                    sx={{
                                        border: "none",
                                        fontFamily: "Source Sans Pro",
                                        fontWeight: 500,
                                        fontSize: "16px",
                                        lineHeight: "20px",
                                        paddingLeft: "8px",
                                    }}
                                >
                                    {t(`roles.${user.roleName}`)}
                                </StyledTableCell>
                                <StyledTableCell
                                    sx={{
                                        border: "none",
                                        fontFamily: "Source Sans Pro",
                                        fontWeight: 500,
                                        fontSize: "16px",
                                        lineHeight: "20px",
                                        paddingLeft: "8px",
                                    }}
                                >
                                    {user.country}
                                </StyledTableCell>
                                <StyledTableCell
                                    sx={{
                                        border: "none",
                                        fontFamily: "Source Sans Pro",
                                        fontWeight: 500,
                                        fontSize: "16px",
                                        lineHeight: "20px",
                                        paddingLeft: "8px",
                                    }}
                                >
                                    <BootstrapTooltip
                                        onClick={() => {
                                            setUserPermissionSettingsName({
                                                firstName: user.firstName,
                                                lastName: user.lastName,
                                            });
                                            navigate(user.id);
                                        }}
                                        title={t("buttons.Edit")}
                                        placement="top"
                                    >
                                        <img src={editIcon} alt="edit icon" />
                                    </BootstrapTooltip>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Backdrop
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isGettingAllUsersLoading}
            >
                <CircularProgress sx={{ color: "#fff" }} />
            </Backdrop>
        </div>
    );
};

export default UsersPermissionsTable;
