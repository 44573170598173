import React, { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ErrorMessage } from "@hookform/error-message";

import FormButtons from "../../../../features/FormButtons";

import { TextFieldRate } from "./TextFieldRate";

import styles from "../../CatalogsLayout.module.scss";

interface Props {
    rateValue: string;
    isRateValid: boolean;
    rateIsLoading: boolean;
    handleSave: () => void;
    closeDrawer: () => void;
    setIsRateValid: (isRateValid: boolean) => void;
    setRateValue: (rateValue: string) => void;
}

export const EditRate: FC<Props> = ({
    rateValue,
    setRateValue,
    setIsRateValid,
    handleSave,
    rateIsLoading,
    closeDrawer,
    isRateValid,
}) => {
    const { t } = useTranslation();
    const {
        handleSubmit,
        formState: { errors },
        setError,
        clearErrors,
    } = useForm<any>();

    const onSubmit = async () => {
        const [integerPart, decimalPart] = rateValue.split(".");

        if (integerPart?.length > 8 || decimalPart?.length > 4) {
            setError("rateValue", {
                type: "required",
                message: t("rates.editValidate") || "Required",
            });
            return;
        }

        if (!rateValue) {
            setError("rateValue", {
                type: "required",
                message: t("validation.requiredField") || "Required",
            });
            return;
        }

        if (!rateIsLoading || !rateValue) {
            handleSave();
        }
    };

    useEffect(() => {
        const [integerPart, decimalPart] = rateValue.split(".");

        if (integerPart?.length < 8 || decimalPart?.length < 4) {
            clearErrors();
        }
    }, [rateValue]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div style={{ height: "calc(92.5vh - 8.8rem)" }}>
                <div>
                    <TextFieldRate
                        rateValue={rateValue}
                        setIsRateValid={setIsRateValid}
                        setRateValue={setRateValue}
                        errors={errors}
                    />
                    <ErrorMessage
                        name="rateValue"
                        errors={errors}
                        render={({ message }) => (
                            <span className={styles.selectErrorMsg}>
                                {message}
                            </span>
                        )}
                    />
                </div>
            </div>
            <FormButtons
                isLoading={rateIsLoading}
                createOrUpdate={"edit"}
                cancelHandler={closeDrawer}
            />
        </form>
    );
};
