import React, { FC, useEffect } from "react";
import {
    useAppDispatch,
    useAppSelector,
    useLocalStorage,
    useUpdateEffect,
} from "hooks";
import { useTranslation } from "react-i18next";
import {
    getDataByCatalogName,
    getEmployees,
    getOrganizations,
    getSkillLevels,
    getSkills,
} from "store";

import {
    Autocomplete,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    TextField,
} from "@mui/material";

import { getCalculatedFiltersCount } from "../../core/utils";
import { baseCatalogItem } from "../../models/catalogs";
import { IEmployeeSearchParams } from "../../models/employees";
import { ILookupItem } from "../../models/lookups";
import { IOrganizations } from "../../models/organizations";
import { cancelBtn as resetFiltersBtn } from "../../styles/MUIStyles";

import "./employees.css";

type FilterPanelProps = {
    fetchData?: (params: IEmployeeSearchParams) => Promise<void> | void;
    typeOfEmployments: ILookupItem[];
    pageSize: number;
    pageNumber: number;
    employeeFullName: string;
    setPageNumber: (page: number) => void;
    sortBy: { direction: null | string; column: null | string };
    setFiltersCount: (filtersCount: number) => void;
};

const FilterPanel: FC<FilterPanelProps> = (props) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const {
        sortBy,
        pageSize,
        pageNumber,
        setPageNumber,
        employeeFullName,
        typeOfEmployments,
        setFiltersCount,
    } = props;

    const [employeesFilters, setEmployeesFilters] =
        useLocalStorage<IEmployeeSearchParams>("employeesFilters", {
            organizationId: null,
            guildId: null,
            positionId: null,
            skillId: null,
            skillLevelId: null,
            costCenterId: null,
            status: null,
            typeOfEmployments: [],
        });

    const organizations = useAppSelector(
        (state) => state.organizations.organizations,
    );
    const skills = useAppSelector((state) => state.catalogs.skills);
    const skillLevels = useAppSelector((state) => state.catalogs.skillLevels);
    const positions = useAppSelector((state) => state.catalogs.positions);
    const guilds = useAppSelector((state) => state.catalogs.guilds);
    const costCenters = useAppSelector((state) => state.catalogs.costCenters);

    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value, checked } = event.target;

        setEmployeesFilters((prevState: IEmployeeSearchParams) => {
            if (checked) {
                if (!prevState.typeOfEmployments?.includes(value as string)) {
                    return {
                        ...prevState,
                        typeOfEmployments: [
                            ...(prevState?.typeOfEmployments || []),
                            value as string,
                        ],
                    };
                }
            } else {
                return {
                    ...prevState,
                    typeOfEmployments: prevState?.typeOfEmployments?.filter(
                        (type) => type !== value,
                    ),
                };
            }
            return prevState;
        });
    };

    const resetFilters = () => {
        setEmployeesFilters({
            organizationId: null,
            guildId: null,
            positionId: null,
            skillId: null,
            skillLevelId: null,
            costCenterId: null,
            status: null,
            typeOfEmployments: [],
        });
    };
    useEffect(() => {
        dispatch(getOrganizations({
            pageSize: 500
        }));
        dispatch(getSkills("skills"));
        dispatch(getSkillLevels("skillLevels"));
        dispatch(getDataByCatalogName("positions"));
        dispatch(getDataByCatalogName("guilds"));
        dispatch(getDataByCatalogName("costCenters"));
    }, [dispatch, t]);

    useUpdateEffect(() => {
        setFiltersCount(getCalculatedFiltersCount(employeesFilters));
        if (pageNumber > 1) {
            setPageNumber(1);
        } else {
            dispatch(
                getEmployees({
                    ...employeesFilters,
                    sortBy,
                    pageSize,
                    pageNumber: 1,
                    fullName: employeeFullName,
                }),
            );
        }
    }, [employeesFilters]);

    return (
        <div className="filter-panel">
            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    margin: "10px 0",
                }}
            >
                <Button
                    variant="text"
                    onClick={resetFilters}
                    sx={resetFiltersBtn}
                >
                    {t("buttons.Reset")}
                </Button>
            </div>
            <p>
                <b>{t("employees.show")}</b>
            </p>
            <FormGroup>
                {typeOfEmployments?.map((type) => (
                    <FormControlLabel
                        key={type.id}
                        control={
                            <Checkbox
                                style={{ height: "30px" }}
                                value={type.id.toString()}
                                checked={employeesFilters?.typeOfEmployments?.includes(
                                    type.id.toString(),
                                )}
                                onChange={handleFilterChange}
                            />
                        }
                        label={type.name}
                    />
                ))}
            </FormGroup>
            <hr />
            <FormGroup>
                <FormControlLabel
                    key={"active"}
                    control={
                        <Checkbox
                            style={{ height: "30px" }}
                            checked={
                                employeesFilters.status === 1 &&
                                employeesFilters.status !== null
                            }
                        />
                    }
                    label={t("employees.active")}
                    onChange={(event, checked) => {
                        if (checked) {
                            setEmployeesFilters(
                                (prevState: IEmployeeSearchParams) => ({
                                    ...prevState,
                                    status: 1,
                                }),
                            );
                        } else {
                            setEmployeesFilters(
                                (prevState: IEmployeeSearchParams) => ({
                                    ...prevState,
                                    status: null,
                                }),
                            );
                        }
                    }}
                />
                <FormControlLabel
                    key={"inactive"}
                    control={
                        <Checkbox
                            style={{ height: "30px" }}
                            checked={
                                employeesFilters.status === 2 &&
                                employeesFilters.status !== null
                            }
                        />
                    }
                    label={t("employees.inactive")}
                    onChange={(event, checked) => {
                        if (checked)
                            setEmployeesFilters(
                                (prevState: IEmployeeSearchParams) => ({
                                    ...prevState,
                                    status: 2,
                                }),
                            );
                        else
                            setEmployeesFilters(
                                (prevState: IEmployeeSearchParams) => ({
                                    ...prevState,
                                    status: null,
                                }),
                            );
                    }}
                />
            </FormGroup>
            <FormControl fullWidth>
                <label htmlFor="organizationId" className="filterTopSubTitle">
                    {t("data.vendor")}
                </label>
                <Autocomplete
                    id="organizationId"
                    autoHighlight
                    options={
                        organizations
                            .slice()
                            .sort((a: IOrganizations, b: IOrganizations) => {
                                const fa = a.name.toLowerCase(),
                                    fb = b.name.toLowerCase();
                                if (fa < fb) {
                                    return -1;
                                }
                                if (fa > fb) {
                                    return 1;
                                }
                                return 0;
                            }) || []
                    }
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value: IOrganizations | null) =>
                        setEmployeesFilters(
                            (prevState: IEmployeeSearchParams) => ({
                                ...prevState,
                                organizationId: value?.id,
                            }),
                        )
                    }
                    sx={{ width: 200 }}
                    value={
                        organizations.filter(
                            (el) => el.id === employeesFilters.organizationId,
                        )[0] || null
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            placeholder={t("organizations.all") || ""}
                        />
                    )}
                />
            </FormControl>
            <FormControl fullWidth>
                <label htmlFor="guildId" className="filterTopSubTitle">
                    {t("data.guild")}
                </label>
                <Autocomplete
                    id="guildId"
                    autoHighlight
                    ListboxProps={{ style: { maxHeight: "200px" } }}
                    options={
                        guilds
                            .slice()
                            .sort((a: baseCatalogItem, b: baseCatalogItem) => {
                                const fa = a.name.toLowerCase(),
                                    fb = b.name.toLowerCase();
                                if (fa < fb) {
                                    return -1;
                                }
                                if (fa > fb) {
                                    return 1;
                                }
                                return 0;
                            }) || []
                    }
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value: baseCatalogItem | null) =>
                        setEmployeesFilters(
                            (prevState: IEmployeeSearchParams) => ({
                                ...prevState,
                                guildId: value?.id,
                            }),
                        )
                    }
                    sx={{ width: 200 }}
                    value={
                        guilds.filter(
                            (el) => el.id === employeesFilters.guildId,
                        )[0] || null
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            placeholder={t("organizations.all") || ""}
                        />
                    )}
                />
            </FormControl>
            <FormControl fullWidth>
                <label htmlFor="positionId" className="filterTopSubTitle">
                    {t("data.position")}
                </label>
                <Autocomplete
                    id="positionId"
                    autoHighlight
                    ListboxProps={{ style: { maxHeight: "200px" } }}
                    options={
                        positions
                            .slice()
                            .sort((a: baseCatalogItem, b: baseCatalogItem) => {
                                const fa = a.name.toLowerCase(),
                                    fb = b.name.toLowerCase();
                                if (fa < fb) {
                                    return -1;
                                }
                                if (fa > fb) {
                                    return 1;
                                }
                                return 0;
                            }) || []
                    }
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value: baseCatalogItem | null) =>
                        setEmployeesFilters(
                            (prevState: IEmployeeSearchParams) => ({
                                ...prevState,
                                positionId: value?.id,
                            }),
                        )
                    }
                    sx={{ width: 200 }}
                    value={
                        positions.filter(
                            (el) => el.id === employeesFilters.positionId,
                        )[0] || null
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            placeholder={t("organizations.all") || ""}
                        />
                    )}
                />
            </FormControl>
            <FormControl fullWidth>
                <label htmlFor="skillId" className="filterTopSubTitle">
                    {t("data.skill")}
                </label>
                <Autocomplete
                    id="skillId"
                    autoHighlight
                    ListboxProps={{ style: { maxHeight: "200px" } }}
                    options={
                        (skills &&
                            skills
                                .slice()
                                .sort(
                                    (
                                        a: baseCatalogItem,
                                        b: baseCatalogItem,
                                    ) => {
                                        const fa = a.name.toLowerCase(),
                                            fb = b.name.toLowerCase();
                                        if (fa < fb) {
                                            return -1;
                                        }
                                        if (fa > fb) {
                                            return 1;
                                        }
                                        return 0;
                                    },
                                )) ||
                        []
                    }
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value: baseCatalogItem | null) =>
                        setEmployeesFilters(
                            (prevState: IEmployeeSearchParams) => ({
                                ...prevState,
                                skillId: value?.id,
                            }),
                        )
                    }
                    sx={{ width: 200 }}
                    value={
                        skills.filter(
                            (el) => el.id === employeesFilters.skillId,
                        )[0] || null
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            placeholder={t("organizations.all") || ""}
                        />
                    )}
                />
            </FormControl>
            <FormControl fullWidth>
                <label htmlFor="skillLevelId" className="filterTopSubTitle">
                    {t("data.level")}
                </label>
                <Autocomplete
                    id="skillLevelId"
                    autoHighlight
                    ListboxProps={{ style: { maxHeight: "200px" } }}
                    options={
                        (skillLevels &&
                            skillLevels
                                .slice()
                                .sort(
                                    (
                                        a: baseCatalogItem,
                                        b: baseCatalogItem,
                                    ) => {
                                        const fa = a.name.toLowerCase(),
                                            fb = b.name.toLowerCase();
                                        if (fa < fb) {
                                            return -1;
                                        }
                                        if (fa > fb) {
                                            return 1;
                                        }
                                        return 0;
                                    },
                                )) ||
                        []
                    }
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value: baseCatalogItem | null) =>
                        setEmployeesFilters(
                            (prevState: IEmployeeSearchParams) => ({
                                ...prevState,
                                skillLevelId: value?.id,
                            }),
                        )
                    }
                    sx={{ width: 200 }}
                    value={
                        skillLevels.filter(
                            (el) => el.id === employeesFilters.skillLevelId,
                        )[0] || null
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            placeholder={t("organizations.all") || ""}
                        />
                    )}
                />
            </FormControl>
            <FormControl fullWidth>
                <label htmlFor="costCenterId" className="filterTopSubTitle">
                    {t("data.costCenter")}
                </label>
                <Autocomplete
                    id="costCenterId"
                    autoHighlight
                    ListboxProps={{ style: { maxHeight: "200px" } }}
                    options={
                        (costCenters &&
                            costCenters
                                .slice()
                                .sort(
                                    (
                                        a: baseCatalogItem,
                                        b: baseCatalogItem,
                                    ) => {
                                        const fa = a.name.toLowerCase(),
                                            fb = b.name.toLowerCase();
                                        if (fa < fb) {
                                            return -1;
                                        }
                                        if (fa > fb) {
                                            return 1;
                                        }
                                        return 0;
                                    },
                                )) ||
                        []
                    }
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value: baseCatalogItem | null) =>
                        setEmployeesFilters(
                            (prevState: IEmployeeSearchParams) => ({
                                ...prevState,
                                costCenterId: value?.id,
                            }),
                        )
                    }
                    sx={{ width: 200 }}
                    value={
                        costCenters.filter(
                            (el) => el.id === employeesFilters.costCenterId,
                        )[0] || null
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            placeholder={t("organizations.all") || ""}
                        />
                    )}
                />
            </FormControl>
        </div>
    );
};

export default FilterPanel;
