import {
    IEmployeesTimeKeeping,
    IEmployeeTracking,
    IProjectsTimeKeeping,
} from "models/tracking";

import { createSlice } from "@reduxjs/toolkit";

import { revertAll } from "../store";
import {
    getAllTimeKeepingOfEmployees,
    getAllTimeKeepingOfProjects,
    getTrackingByEmployeeId,
    getTrackingById,
} from "../thunks/trackingThunk";

interface TrackingSlice {
    projectsTracking: IProjectsTimeKeeping[];
    employeesTracking: IEmployeesTimeKeeping[];
    employeeTracking: IEmployeeTracking[];
    editAndViewTrackingData: IEmployeeTracking | null;
    trackingId: number | null;
    activeTab: number;
    isTrackingLoading: boolean;
    activeTracking: IEmployeeTracking | null;
    trackingEmployeesSearch: string;
    trackingProjectsSearch: string;
    totalTrackingCount: number | null;
    activeEmployeeTracking: IEmployeesTimeKeeping | null;
    activeProjectTracking: IProjectsTimeKeeping | null;
}

const initialState: TrackingSlice = {
    projectsTracking: [],
    employeesTracking: [],
    employeeTracking: [],
    editAndViewTrackingData: null,
    trackingId: null,
    activeTab: 0,
    isTrackingLoading: false,
    activeTracking: null,
    trackingEmployeesSearch: "",
    trackingProjectsSearch: "",
    totalTrackingCount: null,
    activeEmployeeTracking: null,
    activeProjectTracking: null,
};

const trackingSlice = createSlice({
    name: "tracking",
    initialState,
    reducers: {
        clearTrackingData: (state) => {
            state.trackingId = null;
        },
        setTracking: (state, action) => {
            state.activeTracking = action.payload;
        },
        setTrackingEmployee: (state, action) => {
            state.activeEmployeeTracking = action.payload;
        },
        setTrackingProject: (state, action) => {
            state.activeProjectTracking = action.payload;
        },
        setTrackingEmployeesSearch: (state, action) => {
            state.trackingEmployeesSearch = action.payload;
        },
        clearTrackingEmployeesSearch: (state) => {
            state.trackingEmployeesSearch = "";
        },
        setTrackingProjectsSearch: (state, action) => {
            state.trackingProjectsSearch = action.payload;
        },
        clearTrackingProjectsSearch: (state) => {
            state.trackingProjectsSearch = "";
        },
        changeTrackingActiveTab: (state, action) => {
            state.activeTab = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(revertAll, () => initialState)
            .addCase(getAllTimeKeepingOfEmployees.pending, (state) => {
                state.isTrackingLoading = true;
            })
            .addCase(
                getAllTimeKeepingOfEmployees.fulfilled,
                (state, action) => {
                    state.employeesTracking = action.payload.data;
                    state.totalTrackingCount =
                        action.payload.metadata.totalItemCount;
                    state.isTrackingLoading = false;
                },
            )
            .addCase(getAllTimeKeepingOfEmployees.rejected, (state) => {
                state.isTrackingLoading = false;
            })
            .addCase(getAllTimeKeepingOfProjects.pending, (state) => {
                state.isTrackingLoading = true;
            })
            .addCase(getAllTimeKeepingOfProjects.fulfilled, (state, action) => {
                state.projectsTracking = action.payload.data;
                state.totalTrackingCount =
                    action.payload.metadata.totalItemCount;
                state.isTrackingLoading = false;
            })
            .addCase(getAllTimeKeepingOfProjects.rejected, (state) => {
                state.isTrackingLoading = false;
            })
            .addCase(getTrackingByEmployeeId.pending, (state) => {
                state.isTrackingLoading = true;
            })
            .addCase(getTrackingByEmployeeId.fulfilled, (state, action) => {
                state.employeeTracking = action.payload;
                // //TODO: remake
                //@ts-ignore
                state.editAndViewTrackingData =
                    action.payload.filter(
                        (el: IEmployeeTracking) =>
                            el.projectId === state?.activeTracking?.projectId,
                    )[0] || {};
                // state.totalTrackingCount = action.payload.metadata.totalItemCount;
                state.isTrackingLoading = false;
            })
            .addCase(getTrackingByEmployeeId.rejected, (state) => {
                state.isTrackingLoading = false;
            })
            .addCase(getTrackingById.pending, (state) => {
                state.isTrackingLoading = true;
            })
            .addCase(getTrackingById.fulfilled, (state, action) => {
                state.isTrackingLoading = false;
                state.editAndViewTrackingData = action.payload;
            })
            .addCase(getTrackingById.rejected, (state) => {
                state.isTrackingLoading = false;
            });
    },
});

export const {
    clearTrackingData,
    clearTrackingEmployeesSearch,
    clearTrackingProjectsSearch,
    setTracking,
    setTrackingEmployee,
    setTrackingProject,
    setTrackingEmployeesSearch,
    setTrackingProjectsSearch,
    changeTrackingActiveTab,
} = trackingSlice.actions;

export default trackingSlice.reducer;
