import { TypedUseSelectorHook,useDispatch, useSelector } from 'react-redux';
import type { AppDispatch,RootState } from "store/store";

//пока что пускай будут тут
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

//временно
export { default as useCopyToClipboard } from "./useCopyToClipboard";
export { default as useDebounce } from "./useDebounce";
export { default as useDocumentDownloader } from "./useDocumentDownloader";
export { default as useIsFirstRender } from "./useIsFirstRender";
export { default as useLocalStorage } from "./useLocalStorage";
export { default as useSetServerError } from "./useSetServerError";
export { default as useSortColumn } from "./useSortColumn";
export { default as useUpdateEffect } from "./useUpdateEffect";


