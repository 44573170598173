import React, { FC, useEffect, useState } from "react";
import dayjs from "dayjs";
import ConfirmationModal from "features/ConfirmationModal";
import { useAppDispatch, useAppSelector, useSetServerError } from "hooks";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { clearTextFieldErrors } from "store";
import { getClientsLookUp } from "store/thunks/projectsThunk";
import {
    activateOrDeactivateBtn as addDocumentBtn,
    cancelBtn,
} from "styles/MUIStyles";
import ButtonWithProgress from "ui/ButtonWithProgress";
import Loader from "ui/Loader";
import { StyledSelect, StyledSelectError } from "ui/Select";

import { ErrorMessage } from "@hookform/error-message";
import { Autocomplete } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { DesktopDatePicker } from "@mui/x-date-pickers";

import styles from "./GeneralInfo.module.scss";


//search how to type react hooks form's methods
interface Props {
    activeTab: number;
    register: any;
    control: any;
    errors: any;
    isDirty: any;
    watch: any;
    setError: any;
    clearErrors: any;
}

const GeneralInfo: FC<Props> = ({
    activeTab,
    control,
    clearErrors,
    setError,
    watch,
    register,
    errors,
    isDirty,
}): JSX.Element => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();

    const hasProjectCodeError = errors?.projectCode;
    const hasProjectShortNameError = errors?.shortName;
    const hasProjectTypeError = errors?.projectType;
    const hasProjectDescriptionError = errors?.description;
    const hasProjectFullNameError = errors?.fullName;
    const hasProjectStatusError = errors?.projectStatus;

    const clients = useAppSelector((state) => state.projects.clients);
    const generalInfoError = useAppSelector(
        (state) => state.projects.generalInfoError
    );
    const isProjectEditLoading = useAppSelector(
        (state) => state.projects.isProjectEditLoading
    );
    const projectDataFromServer = useAppSelector(
        (state) => state.projects.projectDataFromServer
    );
    const isProjectEmployeeCreatingOrEditing = useAppSelector(
        (state) => state.projects.isProjectEmployeeCreatingOrEditing
    );

    const [isUnsavedChangesModalOpen, setIsUnsavedChangesModalOpen] =
        useState(false);

    useEffect(() => {
        if (activeTab === 0 && !clients.length) {
            dispatch(getClientsLookUp());
        }

        return () => {
            dispatch(clearTextFieldErrors());
        };
    }, [dispatch, clients, activeTab]);

    useSetServerError({
        serverError: generalInfoError,
        setError,
        clearErrors,
        errorNames: ["projectCode"],
    });

    return (
        <div>
            <div className={styles.formWrapper}>
                {isProjectEditLoading ? (
                    <Box sx={{ flexGrow: 1 }}>
                        <Loader />
                    </Box>
                ) : (
                    <React.Fragment>
                        <div className={styles.projectNameWrapper}>
                            <h3 className={styles.projectName}>
                                {params?.operation === "create" && ""}
                                {params?.operation === "edit" &&
                                    (projectDataFromServer?.fullName ||
                                        projectDataFromServer?.shortName)}
                            </h3>
                            <div className={styles.generalInfoTopBtns}>
                                <ButtonWithProgress
                                    variant="contained"
                                    type="submit"
                                    loading={isProjectEmployeeCreatingOrEditing}
                                    disabled={
                                        isProjectEmployeeCreatingOrEditing ||
                                        !!errors?.startDate ||
                                        !!errors?.finishDate
                                    }
                                    sx={{ ...addDocumentBtn }}
                                >
                                    {t("buttons.Save")}
                                </ButtonWithProgress>
                                <Button
                                    variant="text"
                                    sx={cancelBtn}
                                    onClick={() => {
                                        if (isDirty) {
                                            setIsUnsavedChangesModalOpen(true);
                                        } else {
                                            navigate(-1);
                                        }
                                    }}
                                >
                                    {t("buttons.Close")}
                                </Button>
                            </div>
                        </div>
                        <div className={styles.formInnerWrapper}>
                            <Box>
                                <Controller
                                    name="isActive"
                                    control={control}
                                    render={({ field }) => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    onChange={(e) =>
                                                        field.onChange(
                                                            e.target.checked
                                                        )
                                                    }
                                                    checked={field.value}
                                                />
                                            }
                                            label={t("projects.active")}
                                        />
                                    )}
                                />
                            </Box>
                            <div className={styles.projectCodeWrapper}>
                                <label htmlFor="code">
                                    {t("projects.code")}
                                </label>
                                <input
                                    {...register("projectCode", {
                                        required: t("validation.requiredField"),
                                        pattern: {
                                            value: /^[\w\s-]+$/,
                                            message: t(
                                                "validation.alphanumericErrorMessage"
                                            ),
                                        },
                                        maxLength: {
                                            value: 6,
                                            message: t(
                                                "validation.mustLength6"
                                            ),
                                        },
                                        minLength: {
                                            value: 6,
                                            message: t(
                                                "validation.mustLength6"
                                            ),
                                        },
                                    })}
                                    type="text"
                                    autoComplete="off"
                                    placeholder={t("projects.projectCode")}
                                    id="code"
                                    className={
                                        hasProjectCodeError
                                            ? styles.projectCodeInputErrorError
                                            : styles.projectCodeInput
                                    }
                                />
                                <ErrorMessage
                                    name={"projectCode"}
                                    errors={errors}
                                    render={({ message }) => (
                                        <span>{message}</span>
                                    )}
                                />
                            </div>
                            <div className={styles.projectDescriptionWrapper}>
                                <label htmlFor="description">
                                    {t("projects.description")}
                                </label>
                                <textarea
                                    {...register("description", {
                                        maxLength: {
                                            value: 2048,
                                            message: t(
                                                "validation.maxLength2048"
                                            ),
                                        },
                                    })}
                                    id="description"
                                    cols={5}
                                    rows={5}
                                    placeholder={t(
                                        "projects.projectDescription"
                                    )}
                                    className={
                                        hasProjectDescriptionError
                                            ? styles.projectDescriptionTextAreaError
                                            : styles.projectDescriptionTextArea
                                    }
                                />
                                <ErrorMessage
                                    name="description"
                                    errors={errors}
                                    render={({ message }) => (
                                        <span>{message}</span>
                                    )}
                                />
                            </div>
                            <div className={styles.projectShortNameWrapper}>
                                <label htmlFor="shortName">
                                    {t("projects.shortName")}
                                </label>
                                <input
                                    {...register("shortName", {
                                        required: t("validation.requiredField"),
                                        maxLength: {
                                            value: 30,
                                            message: t(
                                                "validation.maxLength30"
                                            ),
                                        },
                                    })}
                                    type="text"
                                    autoComplete="off"
                                    placeholder={t("projects.projectShortName")}
                                    id="shortName"
                                    className={
                                        hasProjectShortNameError
                                            ? styles.projectShortNameInputError
                                            : styles.projectShortNameInput
                                    }
                                />
                                <ErrorMessage
                                    name="shortName"
                                    errors={errors}
                                    render={({ message }) => (
                                        <span>{message}</span>
                                    )}
                                />
                            </div>
                            <div className={styles.projectFullNameWrapper}>
                                <label htmlFor="fullName">
                                    {t("projects.fullName")}
                                </label>
                                <input
                                    {...register("fullName", {
                                        maxLength: {
                                            value: 100,
                                            message: t(
                                                "validation.maxLength100"
                                            ),
                                        },
                                    })}
                                    type="text"
                                    autoComplete="off"
                                    placeholder={t("projects.projectFullName")}
                                    id="fullName"
                                    className={
                                        hasProjectFullNameError
                                            ? styles.projectFullNameInputError
                                            : styles.projectFullNameInput
                                    }
                                />
                                <ErrorMessage
                                    name="fullName"
                                    errors={errors}
                                    render={({ message }) => (
                                        <span>{message}</span>
                                    )}
                                />
                            </div>
                            <div className={styles.projectTypeWrapper}>
                                <label>{t("projects.projectType")}</label>
                                <FormControl fullWidth>
                                    <Controller
                                        rules={{
                                            required: t(
                                                "validation.requiredField"
                                            ) as string,
                                        }}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                input={
                                                    hasProjectTypeError ? (
                                                        <StyledSelectError />
                                                    ) : (
                                                        <StyledSelect />
                                                    )
                                                }
                                                sx={{
                                                    "& .MuiInputBase-input": {
                                                        padding:
                                                            "0.575rem 0.75rem",
                                                        borderRadius: 0,
                                                        marginTop: "0.3rem",
                                                    },
                                                }}
                                            >
                                                <MenuItem value={1}>
                                                    {t(
                                                        "projects.projectTypeInternal"
                                                    )}
                                                </MenuItem>
                                                <MenuItem value={2}>
                                                    {t(
                                                        "projects.projectTypeExternal"
                                                    )}
                                                </MenuItem>
                                            </Select>
                                        )}
                                        name="projectType"
                                        control={control}
                                    />
                                    <ErrorMessage
                                        name={"projectType"}
                                        errors={errors}
                                        render={({ message }) => (
                                            <span
                                                className={
                                                    styles.projectTypeInputErrorMessage
                                                }
                                            >
                                                {message}
                                            </span>
                                        )}
                                    />
                                </FormControl>
                            </div>
                            <div className={styles.projectIsBillableWrapper}>
                                <Controller
                                    name="isBillable"
                                    control={control}
                                    render={({ field }) => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    onChange={(e) =>
                                                        field.onChange(
                                                            e.target.checked
                                                        )
                                                    }
                                                    checked={field.value}
                                                />
                                            }
                                            label={t("projects.paid")}
                                        />
                                    )}
                                />
                            </div>
                            <div className={styles.projectClientWrapper}>
                                <span>{t("projects.client")}</span>
                                <Controller
                                    render={({ field }) => (
                                        <Autocomplete
                                            {...field}
                                            ListboxProps={{
                                                style: {
                                                    maxHeight: "200px",
                                                },
                                            }}
                                            sx={{ width: "100%" }}
                                            isOptionEqualToValue={() => true}
                                            options={clients}
                                            getOptionLabel={(option) =>
                                                option.name ? option.name : ""
                                            }
                                            renderOption={(
                                                props: any,
                                                option
                                            ) => {
                                                return (
                                                    <span
                                                        {...props}
                                                        className={
                                                            styles.dropDownItem
                                                        }
                                                    >
                                                        {option.name}
                                                    </span>
                                                );
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    sx={{
                                                        "& .MuiOutlinedInput-notchedOutline":
                                                            {
                                                                borderRadius:
                                                                    "0",
                                                                border: "1px solid #8A8886",
                                                            },
                                                        "& .MuiOutlinedInput-root":
                                                            {
                                                                "&:hover fieldset":
                                                                    {
                                                                        border: "1px solid #8A8886",
                                                                    },
                                                                "&.Mui-focused fieldset":
                                                                    {
                                                                        borderColor:
                                                                            "#80bdff",
                                                                        boxShadow:
                                                                            "0 0 0 0.2rem rgba(0, 123, 255, .25)",
                                                                    },
                                                            },
                                                        padding: "5px 0",
                                                    }}
                                                    size="small"
                                                    variant="outlined"
                                                />
                                            )}
                                            onChange={(_, data) =>
                                                field.onChange(data)
                                            }
                                            value={field.value}
                                        />
                                    )}
                                    name={"client"}
                                    control={control}
                                />
                            </div>
                            <div className={styles.projectStartDateWrapper}>
                                <span>{t("projects.start")}</span>
                                <Controller
                                    control={control}
                                    name="startDate"
                                    render={({ field }) => (
                                        <DesktopDatePicker
                                            {...field}
                                            maxDate={watch("finishDate")}
                                            onError={(reason, inputValue) => {
                                                if (reason === "minDate") {
                                                    const currentEnteredDate =
                                                        new Date(
                                                            dayjs(
                                                                inputValue
                                                            ).format(
                                                                "DD.MM.YYYY"
                                                            )
                                                        ).getTime();
                                                    const minMUIDate = new Date(
                                                        dayjs(
                                                            "01/01/1900"
                                                        ).format("DD.MM.YYYY")
                                                    ).getTime();

                                                    if (
                                                        currentEnteredDate <
                                                        minMUIDate
                                                    ) {
                                                        setError("startDate", {
                                                            type: "custom",
                                                            message: t(
                                                                "validation.minDateErrorMessage"
                                                            ),
                                                        });
                                                    }
                                                }

                                                if (reason === "maxDate") {
                                                    setError("startDate", {
                                                        type: "custom",
                                                        message: t(
                                                            "validation.projectStartDateGreaterThanEndDateErrorMessage"
                                                        ),
                                                    });
                                                }

                                                if (reason === "invalidDate") {
                                                    setError("startDate", {
                                                        type: "custom",
                                                        message: t(
                                                            "validation.invalidDate"
                                                        ),
                                                    });
                                                }

                                                if (reason === null) {
                                                    clearErrors("startDate");
                                                }
                                            }}
                                            inputFormat="DD/MM/YYYY"
                                            renderInput={(params) => (
                                                <TextField
                                                    size="small"
                                                    {...params}
                                                    sx={{
                                                        "& .MuiOutlinedInput-notchedOutline":
                                                            {
                                                                borderRadius:
                                                                    "0",
                                                                border: "1px solid #8A8886",
                                                            },
                                                        "& .MuiOutlinedInput-root":
                                                            {
                                                                "&:hover fieldset":
                                                                    {
                                                                        border: errors?.startDate
                                                                            ? "1px solid rgb(255, 0, 0)"
                                                                            : "1px solid #8A8886",
                                                                    },
                                                                "&.Mui-focused fieldset":
                                                                    {
                                                                        borderColor:
                                                                            errors?.startDate
                                                                                ? "rgb(255, 0, 0)"
                                                                                : "#80bdff",
                                                                        boxShadow:
                                                                            errors?.startDate
                                                                                ? "0 0 0 0.2rem rgba(0, 255, 0, 0)"
                                                                                : "0 0 0 0.2rem rgba(0, 123, 255, .25)",
                                                                        borderWidth:
                                                                            "1px",
                                                                    },
                                                            },
                                                        padding: "5px 0",
                                                    }}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        placeholder: t(
                                                            "projects.dayMonthYear"
                                                        ) as string,
                                                        autoComplete: "off",
                                                    }}
                                                />
                                            )}
                                        />
                                    )}
                                />
                                <ErrorMessage
                                    name={"startDate"}
                                    errors={errors}
                                    render={({ message }) => (
                                        <span
                                            className={
                                                styles.projectTypeInputErrorMessage
                                            }
                                        >
                                            {message}
                                        </span>
                                    )}
                                />
                            </div>
                            <div className={styles.projectFinishDateWrapper}>
                                <span>{t("projects.end")}</span>
                                <Controller
                                    control={control}
                                    name="finishDate"
                                    render={({ field }) => (
                                        <DesktopDatePicker
                                            {...field}
                                            inputFormat="DD/MM/YYYY"
                                            minDate={watch("startDate")}
                                            onError={(reason, inputValue) => {
                                                if (reason === "minDate") {
                                                    const currentEnteredDate =
                                                        new Date(
                                                            dayjs(
                                                                inputValue
                                                            ).format(
                                                                "DD.MM.YYYY"
                                                            )
                                                        ).getTime();
                                                    const minMUIDate = new Date(
                                                        dayjs(
                                                            "01/01/1900"
                                                        ).format("DD.MM.YYYY")
                                                    ).getTime();

                                                    if (
                                                        currentEnteredDate <
                                                        minMUIDate
                                                    ) {
                                                        setError("finishDate", {
                                                            type: "custom",
                                                            message: t(
                                                                "validation.minDateErrorMessage"
                                                            ),
                                                        });
                                                    }
                                                }

                                                if (reason === "invalidDate") {
                                                    setError("finishDate", {
                                                        type: "custom",
                                                        message: t(
                                                            "validation.engProjectDate"
                                                        ),
                                                    });
                                                }

                                                if (reason === null) {
                                                    clearErrors("finishDate");
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    size="small"
                                                    {...params}
                                                    sx={{
                                                        "& .MuiOutlinedInput-notchedOutline":
                                                            {
                                                                borderRadius:
                                                                    "0",
                                                                border: "1px solid #8A8886",
                                                            },
                                                        "& .MuiOutlinedInput-root":
                                                            {
                                                                "&:hover fieldset":
                                                                    {
                                                                        border: errors?.finishDate
                                                                            ? "1px solid rgb(255, 0, 0)"
                                                                            : "1px solid #8A8886",
                                                                    },
                                                                "&.Mui-focused fieldset":
                                                                    {
                                                                        borderColor:
                                                                            errors?.finishDate
                                                                                ? "rgb(255, 0, 0)"
                                                                                : "#80bdff",
                                                                        boxShadow:
                                                                            errors?.finishDate
                                                                                ? "0 0 0 0.2rem rgba(0, 255, 0, 0)"
                                                                                : "0 0 0 0.2rem rgba(0, 123, 255, .25)",
                                                                        borderWidth:
                                                                            "1px",
                                                                    },
                                                            },
                                                        padding: "5px 0",
                                                    }}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        placeholder: t(
                                                            "projects.dayMonthYear"
                                                        ) as string,
                                                        autoComplete: "off",
                                                    }}
                                                />
                                            )}
                                        />
                                    )}
                                />
                                <ErrorMessage
                                    name={"finishDate"}
                                    errors={errors}
                                    render={({ message }) => (
                                        <span
                                            className={
                                                styles.projectTypeInputErrorMessage
                                            }
                                        >
                                            {message}
                                        </span>
                                    )}
                                />
                            </div>
                            <div className={styles.projectStatusWrapper}>
                                <span>{t("projects.status")}</span>
                                <FormControl fullWidth>
                                    <Controller
                                        rules={{
                                            required: t(
                                                "validation.requiredField"
                                            ) as string,
                                        }}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                input={
                                                    hasProjectStatusError ? (
                                                        <StyledSelectError />
                                                    ) : (
                                                        <StyledSelect />
                                                    )
                                                }
                                                sx={{
                                                    "& .MuiInputBase-input": {
                                                        padding:
                                                            " 0.575rem 0.75rem",
                                                        marginTop: "5px",
                                                        borderRadius: "0",
                                                    },
                                                }}
                                            >
                                                <MenuItem value={1}>
                                                    {t("projects.preparation")}
                                                </MenuItem>
                                                <MenuItem value={2}>
                                                    {t("projects.inProgress")}
                                                </MenuItem>
                                                <MenuItem value={3}>
                                                    {t("projects.onHold")}
                                                </MenuItem>
                                                <MenuItem value={4}>
                                                    {t("projects.cancelled")}
                                                </MenuItem>
                                                <MenuItem value={5}>
                                                    {t("projects.completed")}
                                                </MenuItem>
                                            </Select>
                                        )}
                                        name="projectStatus"
                                        control={control}
                                    />
                                </FormControl>
                                <ErrorMessage
                                    name={"projectStatus"}
                                    errors={errors}
                                    render={({ message }) => (
                                        <span
                                            className={
                                                styles.projectTypeInputErrorMessage
                                            }
                                        >
                                            {message}
                                        </span>
                                    )}
                                />
                            </div>
                        </div>
                        <ConfirmationModal
                            isModalOpen={isUnsavedChangesModalOpen}
                            setIsModalOpen={() =>
                                setIsUnsavedChangesModalOpen(false)
                            }
                            actionName={"Close"}
                            instanceName={""}
                            message={"closeFormWithoutSaving"}
                            handleAction={() => {
                                if (isDirty) {
                                    navigate("/projects");
                                }
                            }}
                        />
                    </React.Fragment>
                )}
            </div>
        </div>
    );
};

export default GeneralInfo;
