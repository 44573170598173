import { FC, useEffect, useState } from 'react';
import { CreateIcon, EditIcon } from 'assets/icons';
import Guard from 'features/Guard';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useTranslation } from 'react-i18next';
import { activateDataByCatalogName, deactivateDataByCatalogName, } from 'store';
import { createOrEditBtn } from 'styles/MUIStyles';

import Button from '@mui/material/Button';

import ConfirmationModal from '../../../features/ConfirmationModal';
import ListItemActions from '../../../features/ListItemActions';

import { TimeZoneForm } from './TimeZoneForm';

import styles from '../CatalogsLayout.module.scss';

export interface TimeZone {
    nameZone: string;
    offset: string;
    id: number;
}

const TimeZones: FC<{
    name: string;
    createOrUpdate: null | string;
    setCreateOrUpdate: Function;
    fulfilledStatusHandler: Function;
}> = ({
          name,
          createOrUpdate,
          setCreateOrUpdate,
          fulfilledStatusHandler,
      }): JSX.Element => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const timeZones = useAppSelector((state) => state.catalogs.timeZones);
    const allowedPermissions = useAppSelector(
        (state) => state.auth.allowedPermissions,
    );

    const [activeTimeZone, setActiveTimeZone] = useState<null | TimeZone>(null);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState<{
        isOpen: boolean;
        actionName: string;
    }>({ isOpen: false, actionName: '' });

    const activateItem = async () => {
        if (activeTimeZone?.id) {
            const response = await dispatch(
                activateDataByCatalogName({
                    catalogName: name,
                    catalogNameId: activeTimeZone?.id,
                }),
            );
            if (response.meta.requestStatus === 'fulfilled') {
                fulfilledStatusHandler('notifications.successfullyActivated');
            }
        }
    };

    const deactivateItem = async () => {
        if (activeTimeZone?.id) {
            const response = await dispatch(
                deactivateDataByCatalogName({
                    catalogName: name,
                    catalogNameId: activeTimeZone?.id,
                }),
            );

            if (
                response.meta.requestStatus === 'fulfilled' &&
                response.payload
            ) {
                fulfilledStatusHandler(
                    t('notifications.recordInUseNotice'),
                    'warn',
                );
                setConfirmationModalOpen({ isOpen: false, actionName: '' });
                return;
            }

            if (response.meta.requestStatus === 'fulfilled') {
                fulfilledStatusHandler('notifications.successfullyDeactivated');
            }
        }
    };

    const onCreateItem = () => {
        setIsDrawerOpen(true);
        setCreateOrUpdate('create');
        setActiveTimeZone(null);
    };

    const onEditItem = () => {
        setIsDrawerOpen(true);
        setCreateOrUpdate('update');
    };

    const confirmActionHandler = () => {
        switch (confirmationModalOpen.actionName) {
            case 'Activate':
                activateItem();
                break;
            case 'Deactivate':
                deactivateItem();
                break;
        }
    };

    useEffect(() => {
        setActiveTimeZone(null);
    }, [dispatch, name]);

    useEffect(() => {
        setActiveTimeZone(null);
    }, [dispatch, name]);

    // useEffect(() => {
    //     if (timeZoneErrors === null) {
    //         clearErrors();
    //     }
    //
    //     if (typeof timeZoneErrors === 'string') {
    //         sendNotification(timeZoneErrors, 'error');
    //     }
    //
    //     if (typeof timeZoneErrors !== 'string' && timeZoneErrors && 'nameZone' in timeZoneErrors) {
    //         setError('nameZone', {
    //             type: 'custom',
    //             message: timeZoneErrors.nameZone[0],
    //         });
    //     }
    //
    //     if (typeof timeZoneErrors !== 'string' && timeZoneErrors && 'offset' in timeZoneErrors) {
    //         setError('offset', {
    //             type: 'custom',
    //             message: timeZoneErrors.offset[0],
    //         });
    //     }
    // }, [timeZoneErrors]);

    useEffect(() => {
        setActiveTimeZone(null);
    }, [dispatch, name]);

    return (
        <div>
            <div className={styles.crudButtonWrapper}>
                <Guard
                    allowedPermissions={allowedPermissions}
                    permissionName="Create"
                >
                    <Button
                        startIcon={<CreateIcon/>}
                        variant="text"
                        disabled={false}
                        onClick={() => onCreateItem()}
                        sx={createOrEditBtn}
                    >
                        {t('buttons.Create')}
                    </Button>
                </Guard>
                <Guard
                    allowedPermissions={allowedPermissions}
                    permissionName="Edit"
                >
                    <Button
                        startIcon={<EditIcon/>}
                        disabled={!activeTimeZone}
                        variant="text"
                        onClick={() => onEditItem()}
                        sx={createOrEditBtn}
                    >
                        {t('buttons.Edit')}
                    </Button>
                </Guard>
            </div>
            <div className={styles.tableHeader}>
                <span className={styles.column + ' ' + styles.timeZoneColumn}>
                    {t('data.name')}
                </span>
                <span className={styles.column + ' ' + styles.timeZoneColumn}>
                    {t('data.offset')}
                </span>
                <span className={styles.column + ' ' + styles.timeZoneColumn}>
                    {t('data.actions')}
                </span>
            </div>
            <ul className={styles.catalogNameDataList}>
                {timeZones.map((item) => (
                    <li
                        key={item.id}
                        className={
                            activeTimeZone?.id === item.id
                                ? `${styles.catalogNameDataListItem} ${styles.catalogNameDataListItemActive}`
                                : `${styles.catalogNameDataListItem}`
                        }
                        onClick={() => {
                            setActiveTimeZone({ ...item });
                        }}
                    >
                        <span className={styles.timeZoneName}>
                            {item.nameZone}
                        </span>
                        <span className={styles.timeZoneOffset}>
                            {item.offset}
                        </span>
                        <span style={{ textAlign: 'center' }}>
                            <ListItemActions
                                itemId={item.id}
                                isItemActive={true}
                                activationAvailable={false}
                                setConfirmationModalOpen={
                                    setConfirmationModalOpen
                                }
                                editItemClick={onEditItem}
                            />
                        </span>
                    </li>
                ))}
            </ul>

            <TimeZoneForm
                name={name}
                activeTimeZone={activeTimeZone}
                setActiveTimeZone={setActiveTimeZone}
                createOrUpdate={createOrUpdate}
                setCreateOrUpdate={setCreateOrUpdate}
                isDrawerOpen={isDrawerOpen}
                setIsDrawerOpen={setIsDrawerOpen}
                fulfilledStatusHandler={fulfilledStatusHandler}
            />
            <ConfirmationModal
                isModalOpen={confirmationModalOpen.isOpen}
                setIsModalOpen={setConfirmationModalOpen}
                actionName={confirmationModalOpen.actionName}
                instanceName={name}
                handleAction={confirmActionHandler}
            />
        </div>
    );
};

export default TimeZones;
