import { FC } from "react";
import { useTranslation } from "react-i18next";

import Drawer from "@mui/material/Drawer";

import closeIcon from "../../../assets/icons/closeIcon.svg";
import { drawer } from "../../../styles/MUIStyles";

import styles from "../../../views/Catalogs/CatalogsLayout.module.scss";

const Index: FC<{
    name: string;
    isDrawerOpen: boolean;
    createOrUpdate: null | string;
    closeFormDrawer: () => void;
    children: JSX.Element;
}> = ({ name, isDrawerOpen, createOrUpdate, closeFormDrawer, children }) => {
    const { t } = useTranslation();

    return (
        <div>
            <Drawer
                sx={drawer}
                variant="temporary"
                anchor="right"
                open={isDrawerOpen}
                onClose={() => closeFormDrawer()}
            >
                <div className={styles.drawerTop}>
                    <h3 className={styles.drawerTopTitle}>
                        {name
                            ? createOrUpdate === "create"
                                ? t("buttons.Create") +
                                  " " +
                                  t(`catalogActions.${name}`)
                                : createOrUpdate === "view"
                                  ? t("catalogActions.generalInfo")
                                  : t("buttons.Edit") +
                                    " " +
                                    t(`catalogActions.${name}`)
                            : ""}
                    </h3>
                    <img
                        onClick={() => closeFormDrawer()}
                        src={closeIcon}
                        alt="close icon"
                    />
                </div>
                {children}
            </Drawer>
        </div>
    );
};

export default Index;
