import React, { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ErrorMessage } from "@hookform/error-message";
import { SelectChangeEvent } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import FormButtons from "../../../../features/FormButtons";
import { useAppSelector } from "../../../../hooks";
import { StyledSelect, StyledSelectError } from "../../../../ui/Select";

import { TextFieldRate } from "./TextFieldRate";

import styles from "../../CatalogsLayout.module.scss";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

interface Props {
    currencyId: number | null;
    isRateValid: boolean;
    newRateValue: string;
    rateValue: string;
    rateIsLoading: boolean;
    isNewRateValid: boolean;
    createNewRate: () => void;
    closeDrawer: () => void;
    setNewIsRateValid: (newIsRateValid: boolean) => void;
    setNewRateValue: (newRateValue: string) => void;
    handleChange: (event: SelectChangeEvent<number | null>) => void;
}

export const CreateNewRate: FC<Props> = ({
    newRateValue,
    setNewRateValue,
    setNewIsRateValid,
    handleChange,
    currencyId,
    rateValue,
    isRateValid,
    isNewRateValid,
    rateIsLoading,
    createNewRate,
    closeDrawer,
}) => {
    const { t } = useTranslation();
    const currencies = useAppSelector((state) => state.lookups.currencies);

    const {
        handleSubmit,
        formState: { errors },
        setError,
        control,
    } = useForm<any>();

    const onSubmit = async () => {
        const [integerPart, decimalPart] = rateValue.split(".");

        if (integerPart?.length > 8 || decimalPart?.length > 4) {
            setError("rateValue", {
                type: "required",
                message: t("rates.editValidate") || "Required",
            });
            return;
        }

        if (!currencyId) {
            setError("currencyId", {
                type: "required",
                message: t("validation.requiredField") || "Required",
            });
        }

        if (!rateValue) {
            setError("rateValue", {
                type: "required",
                message: t("validation.requiredField") || "Required",
            });
            return;
        }

        if (!rateIsLoading && isNewRateValid && currencyId) {
            createNewRate();
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div style={{ height: "calc(92.5vh - 8.8rem)" }}>
                <div>
                    <Controller
                        name="currencyId"
                        defaultValue=""
                        control={control}
                        render={() => (
                            <>
                                <label
                                    htmlFor="countryName"
                                    className={styles.drawerTopSubTitle}
                                >
                                    {t("rates.name")}
                                </label>
                                <Select
                                    placeholder={"123"}
                                    sx={{
                                        width: "100%",
                                        border: errors.currencyId
                                            ? "1px solid red"
                                            : "",
                                        borderRadius: "4px",
                                    }}
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    value={currencyId || ""}
                                    onChange={handleChange}
                                    input={
                                        errors?.regionId ? (
                                            <StyledSelectError />
                                        ) : (
                                            <StyledSelect />
                                        )
                                    }
                                    MenuProps={MenuProps}
                                >
                                    {/*@ts-ignore*/}
                                    {currencies.map(({ currencyCode, id }) => (
                                        <MenuItem key={currencyCode} value={id}>
                                            {currencyCode}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </>
                        )}
                    />
                    <ErrorMessage
                        name="currencyId"
                        errors={errors}
                        render={({ message }) => (
                            <span className={styles.selectErrorMsg}>
                                {message}
                            </span>
                        )}
                    />
                </div>
                <div
                    style={{
                        marginTop: "8px",
                        fontSize: "12px",
                        color: "#af4040",
                    }}
                >
                    {!isRateValid &&
                        (rateValue.length > 8 || rateValue.includes(".")) &&
                        t("rates.editValidate")}
                </div>
                <Controller
                    name="rateValue"
                    defaultValue=""
                    control={control}
                    render={() => (
                        <TextFieldRate
                            errors={errors}
                            rateValue={newRateValue}
                            setRateValue={setNewRateValue}
                            setIsRateValid={setNewIsRateValid}
                        />
                    )}
                />
                <ErrorMessage
                    name="rateValue"
                    errors={errors}
                    render={({ message }) => (
                        <span className={styles.selectErrorMsg}>{message}</span>
                    )}
                />
            </div>
            <FormButtons
                isLoading={rateIsLoading}
                createOrUpdate={"create"}
                cancelHandler={closeDrawer}
            />
        </form>
    );
};
