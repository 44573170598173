import axios from "axios";

import { createAsyncThunk } from "@reduxjs/toolkit";

import axiosApi from "../../core/request";
import { sendNotification } from "../../ui/Toast";

export const getAllPermissions = createAsyncThunk(
    "roles/allPermissions",
    async (_, { rejectWithValue }) => {
        try {
            const response = await axiosApi.get("/roles/permissions/all");
            return response.data;
        } catch (error) {
            //TODO
            return rejectWithValue("Error");
        }
    },
);

export const getListOfPermissionsByRoleName = createAsyncThunk(
    "roles/permissionsByRoleName",
    async (roleName: string, { rejectWithValue }) => {
        try {
            const response = await axiosApi.get(
                `/roles/permissions?roleName=${roleName}`,
            );
            return response.data;
        } catch (error) {
            //TODO
            return rejectWithValue("Error");
        }
    },
);

export const updateRolePermissions = createAsyncThunk(
    "roles/updateRolePermissions",
    async (
        permissionsData: { roleName: string; permissionIds: number[] },
        { rejectWithValue },
    ) => {
        try {
            const response = await axiosApi.post("/roles/permissions", {
                roleName: permissionsData.roleName,
                permissionIds: permissionsData.permissionIds,
            });
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                if (error.response.status === 400) {
                    sendNotification(
                        Object.values(
                            error.response.data.errors,
                        ).flat()[0] as string,
                        "error",
                    );
                    return rejectWithValue(error.response.data.errors);
                } else {
                    return rejectWithValue(
                        "Что-то пошло не так... Попробуйте обновить страницу",
                    );
                }
            }

            if (!navigator.onLine) {
                return rejectWithValue("Проверьте подключение к интернету");
            }

            return rejectWithValue(
                "Что-то пошло не так... Попробуйте обновить страницу",
            );
        }
    },
);
