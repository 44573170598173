import React, { useState } from "react";
import { ShowMoreIcon } from "assets/icons";
import { useTranslation } from "react-i18next";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { langs } from "../../i18n";

const LanguageDropDown = () => {
    const { i18n } = useTranslation();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                style={{
                    height: '7.5vh',
                    color: '#0b4d88',
                    fontFamily: 'Source Sans Pro',
                    textTransform: 'capitalize',
                    fontSize: '1.3rem',
                    display: 'flex',
                    justifyContent: 'space-around'
                }}
            >
                {i18n.language.substring(0, 2)}
                <ShowMoreIcon/>
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {langs.map(lng =>
                    <MenuItem key={lng} onClick={() => {
                        i18n.changeLanguage(lng);
                        handleClose();
                    }}>
                        {i18n.t(`languages.${lng}`)}
                    </MenuItem>
                )}
            </Menu>
        </div>
    );
};

export default LanguageDropDown;
