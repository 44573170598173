import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ErrorMessage } from '@hookform/error-message';
import { Checkbox } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';

import CatalogItemActionsComponent from '../../../features/Catalogs/CatalogItemActionsComponent';
import ConfirmationModal from '../../../features/ConfirmationModal';
import FormButtons from '../../../features/FormButtons';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import useSetServerError from '../../../hooks/useSetServerError';
import { currency } from '../../../models/catalogs';
import { clearCatalogTextFieldErrors, createCurrency, editCurrency } from '../../../store';

import styles from '../CatalogsLayout.module.scss';

interface IFormInput {
    name: string;
    currencyCode: string;
    isPrimary: boolean;
}

export const CurrencyForm: FC<{
    name: string;
    activeCurrency: currency | null;
    setActiveCurrency: (activeCurrency: currency | null) => void;
    createOrUpdate: string | null;
    isDrawerOpen: boolean;
    setCreateOrUpdate: Function;
    setIsDrawerOpen: Dispatch<SetStateAction<boolean>>;
    fulfilledStatusHandler: Function;
}> = ({
          name,
          activeCurrency,
          setActiveCurrency,
          createOrUpdate,
          isDrawerOpen,
          setCreateOrUpdate,
          setIsDrawerOpen,
          fulfilledStatusHandler,
      }): JSX.Element => {
    const dispatch = useAppDispatch();

    const currencyErrors = useAppSelector(
        (state) => state.catalogs.currencyErrors,
    );
    const isCreatingOrUpdatingLoading = useAppSelector(
        (state) => state.catalogs.isCreatingOrUpdatingLoading,
    );
    const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        setError,
        control,
        clearErrors,
        formState: { errors },
    } = useForm<IFormInput>();

    const { t } = useTranslation();

    const onSubmit: SubmitHandler<IFormInput> = async (
        formData: IFormInput,
    ) => {
        if (createOrUpdate === 'create') {
            const response = await dispatch(
                createCurrency({
                    name: formData.name,
                    currencyCode: formData.currencyCode,
                    isPrimary: formData.isPrimary,
                }),
            );

            if (response.meta.requestStatus === 'fulfilled') {
                fulfilledStatusHandler('notifications.successfullyCreated');
                setActiveCurrency(null);
                setIsDrawerOpen(false);
            }
            return;
        }

        if (activeCurrency && createOrUpdate === 'update') {
            const response = await dispatch(
                editCurrency({
                    id: activeCurrency.id,
                    name: formData.name,
                    currencyCode: formData.currencyCode,
                    isPrimary: formData.isPrimary,
                }),
            );

            if (response.meta.requestStatus === 'fulfilled') {
                fulfilledStatusHandler('notifications.successfullySaved');
                setActiveCurrency(null);
                setIsDrawerOpen(false);
            }
        }
    };

    const setFormData = (fields: IFormInput | null) => {
        setValue('name', fields?.name || '');
        setValue('currencyCode', fields?.currencyCode || '');
        setValue('isPrimary', fields?.isPrimary || false);
    };

    const closeFormDrawer = () => {
        setCreateOrUpdate(null);
        setIsDrawerOpen(false);
    };

    // returns true, if form was updated
    const formChangesChecking = () => {
        if (createOrUpdate === 'create') {
            return Boolean(
                getValues('name') ||
                getValues('currencyCode') ||
                getValues('isPrimary'),
            );
        } else {
            return Boolean(
                getValues('name') !== activeCurrency?.name ||
                getValues('currencyCode') !==
                activeCurrency?.currencyCode ||
                getValues('isPrimary') !== activeCurrency?.isPrimary,
            );
        }
    };

    const closeModalConfirm = () => {
        setFormData(null);
        setIsDrawerOpen(false);
        setConfirmationModalOpen(false);
    };

    const formClose = () => {
        if (formChangesChecking()) setConfirmationModalOpen(true);
        else closeFormDrawer();
    };

    useSetServerError({
        serverError: currencyErrors,
        setError,
        clearErrors,
        errorNames: ['name', 'currencyCode'],
    });

    useEffect(() => {
        if (isDrawerOpen) {
            setFormData(activeCurrency);
        } else {
            clearErrors();
            dispatch(clearCatalogTextFieldErrors());
        }
    }, [dispatch, isDrawerOpen]);

    return (
        <>
            <CatalogItemActionsComponent
                name={name}
                isDrawerOpen={isDrawerOpen}
                createOrUpdate={createOrUpdate}
                closeFormDrawer={formClose}
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={styles.formInputWrapper}>
                        <label
                            htmlFor="name"
                            className={styles.drawerTopSubTitle}
                        >
                            {t('data.name')}
                        </label>
                        <div>
                            <input
                                {...register('name', {
                                    required: t(
                                        'validation.requiredField',
                                    ) as string,
                                    maxLength: {
                                        value: 30,
                                        message: t('validation.maxLength30'),
                                    },
                                })}
                                id="name"
                                type="text"
                                autoComplete="off"
                                placeholder={t('data.name') as string}
                                className={
                                    errors?.name
                                        ? styles.catalogsDrawerInputError
                                        : styles.catalogsDrawerInput
                                }
                            />
                            <ErrorMessage
                                name="name"
                                errors={errors}
                                render={({ message }) => <span>{message}</span>}
                            />
                        </div>
                        <label
                            htmlFor="currencyCode"
                            className={styles.drawerTopSubTitle}
                        >
                            {t('data.currencyCode')}
                        </label>
                        <div>
                            <input
                                {...register('currencyCode', {
                                    required: t(
                                        'validation.requiredField',
                                    ) as string,
                                    maxLength: {
                                        value: 3,
                                        message: t('validation.maxLength3'),
                                    },
                                })}
                                id="currencyCode"
                                type="text"
                                autoComplete="off"
                                placeholder={t('data.currencyCode') as string}
                                className={
                                    errors?.currencyCode
                                        ? styles.catalogsDrawerInputError
                                        : styles.catalogsDrawerInput
                                }
                            />
                            <ErrorMessage
                                name="currencyCode"
                                errors={errors}
                                render={({ message }) => <span>{message}</span>}
                            />
                            <div>
                                <Controller
                                    name="isPrimary"
                                    defaultValue={false}
                                    control={control}
                                    render={({ field }) => (
                                        <FormControlLabel
                                            sx={{
                                                marginLeft: '0',
                                                '& span': {
                                                    fontFamily:
                                                        'Source Sans Pro',
                                                },
                                            }}
                                            label={t('data.isPrimary')}
                                            labelPlacement="end"
                                            control={
                                                <Checkbox
                                                    sx={{
                                                        padding:
                                                            '5px 5px 5px 0',
                                                    }}
                                                    {...field}
                                                    checked={field.value}
                                                />
                                            }
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    <FormButtons
                        isLoading={isCreatingOrUpdatingLoading}
                        createOrUpdate={createOrUpdate}
                        cancelHandler={formClose}
                    />
                </form>
            </CatalogItemActionsComponent>

            <ConfirmationModal
                isModalOpen={isConfirmationModalOpen}
                setIsModalOpen={setConfirmationModalOpen}
                actionName={'Close'}
                instanceName=""
                message={'closeFormWithoutSaving'}
                handleAction={() => closeModalConfirm()}
            />
        </>
    );
};
