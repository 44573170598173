import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';

import { materialReactTableHeader } from '../styles/MUIStyles';

export interface HeadComponents {
    customActions: JSX.Element;
    type: JSX.Element;
    amountMonth: JSX.Element;
    contractAmount: JSX.Element;
    status: JSX.Element;
    agreement: JSX.Element;
    id: JSX.Element;
    currency: JSX.Element;
    conclusionDate: JSX.Element;
    endDate: JSX.Element;
    note: JSX.Element;
    currencyCode: JSX.Element;
    rate: JSX.Element;
    rateDate: JSX.Element;
}

export const useHeadComponents = (): HeadComponents => {
    const { t } = useTranslation();

    return {
        customActions: (
            <Box sx={materialReactTableHeader}>
                <span>{t('data.actions')}</span>
            </Box>
        ),
        type: (
            <Box sx={materialReactTableHeader}>
                <span>{t('data.type')}</span>
            </Box>
        ),
        amountMonth: (
            <Box sx={materialReactTableHeader}>
                <span>{t('data.amountMonth')}</span>
            </Box>
        ),
        contractAmount: (
            <Box sx={materialReactTableHeader}>
                <span>{t('data.contractAmount')}</span>
            </Box>
        ),
        status: (
            <Box sx={materialReactTableHeader}>
                <span>{t('data.status')}</span>
            </Box>
        ),
        agreement: (
            <Box sx={materialReactTableHeader}>
                <span>{t('data.agreement')}</span>
            </Box>
        ),
        id: (
            <Box sx={materialReactTableHeader}>
                <span>№</span>
            </Box>
        ),
        currency: (
            <Box sx={materialReactTableHeader}>
                <span>{t('data.currency')}</span>
            </Box>
        ),
        conclusionDate: (
            <Box sx={materialReactTableHeader}>
                <span>{t('data.conclusionDate')}</span>
            </Box>
        ),
        endDate: (
            <Box sx={materialReactTableHeader}>
                <span>{t('data.endDate')}</span>
            </Box>
        ),
        note: (
            <Box sx={materialReactTableHeader}>
                <span>{t('data.note')}</span>
            </Box>
        ),
        currencyCode: (
            <Box sx={materialReactTableHeader}>
                <span>{t('data.currencyCode')}</span>
            </Box>
        ),
        rate: (
            <Box sx={materialReactTableHeader}>
                <span>{t('rates.rate')}</span>
            </Box>
        ),
        rateDate: (
            <Box sx={materialReactTableHeader}>
                <span>{t('rates.rateDate')}</span>
            </Box>
        )
    };
};
