import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';

import userLogo from '../../../assets/logos/user.png';
import { useAppSelector } from '../../../hooks';
import { cancelBtn, saveBtn } from '../../../styles/MUIStyles';
import ButtonWithProgress from '../../../ui/ButtonWithProgress';
import Guard from '../../Guard';

import styles from './styles.module.scss';

interface Props {
    id?: string;
    personnelNumber?: number;
    avatar: string;
    firstName: string;
    lastName: string;
    middleName: string;
    position: string;
    organization: string;
    costCenter: string;
    userStatus: string;
    country: string;
    city: string;
    address: string;
    link: string;
    email: string;
    aboutMe: string;
    phone: string;
    resourceManager: string;
    resourceManagerId: string;
    fte: number;
    grade: string;
    allowedPermissions: any[];
    editLink?: string;
    showCloseButton?: boolean;
}

export const EmployeeGeneralInfo: FC<Props> = (props): JSX.Element => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const allowedPermissions = useAppSelector(
        (state) => state.auth.allowedPermissions,
    );

    return (
        <>
            <div style={{ display: "flex", gap: "20px", margin: "15px 0" }}>
                <div style={{ width: "70%" }}>
                    <div className={styles.employeeDetailsTop}>
                        <img
                            src={
                                props.avatar
                                    ? process.env.REACT_APP_STATIC_FILES_URL &&
                                      process.env.REACT_APP_STATIC_FILES_URL +
                                          props.avatar
                                    : userLogo
                            }
                            alt=""
                        />
                        <div>
                            <span
                                className={
                                    (props.userStatus === "Active" &&
                                        styles.userStatusActive) ||
                                    (props.userStatus === "Inactive" &&
                                        styles.userStatusInactive) ||
                                    styles.userStatus
                                }
                            >
                                {t(`statuses.${props.userStatus}`)}
                            </span>
                            <h3 className={styles.userFullName}>
                                {props.firstName} {props.middleName}{" "}
                                {props.lastName}
                            </h3>
                            <h4 className={styles.userPosition}>
                                {props.position}
                            </h4>
                            <h4 className={styles.userPosition}>
                                {props.grade}
                            </h4>
                            <span className={styles.userOrganization}>
                                {props.personnelNumber} {props.organization}{" "}
                                {props.costCenter ? "," : ""} {props.costCenter}
                            </span>
                        </div>
                    </div>
                    <div className={styles.employeeDetailsBottom}>
                        <div>
                            <span>{props.country}</span>
                            <span>{props.city}</span>
                            <span>{props.address}</span>
                            <span>{props.phone}</span>
                            {props?.email?.split(",").map((item) => (
                                <a
                                    key={item}
                                    className="hyperLink"
                                    href={`mailto:${item}`}
                                >
                                    {item}
                                </a>
                            ))}
                            {props?.link?.split(",").map((item) => (
                                <Link
                                    key={item}
                                    className="hyperLink"
                                    to={item.trim()}
                                    target="_blank"
                                >
                                    {item.length > 40
                                        ? item.slice(0, 39) + "..."
                                        : item}
                                </Link>
                            ))}
                        </div>
                        <div>
                            <h4>{t("employees.aboutMe")}</h4>
                            <p className={styles.aboutMe}>{props.aboutMe}</p>
                        </div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            gap: "20px",
                            margin: "15px 15px",
                        }}
                    >
                        <b>{t("data.resourceManager")}: </b>
                        <Link
                            className="hyperLink"
                            to={`/employees/${props.resourceManagerId}`}
                        >
                            {props.resourceManager}
                        </Link>
                    </div>
                    <hr style={{ marginLeft: "15px" }} />
                    <div
                        style={{
                            display: "flex",
                            gap: "20px",
                            margin: "15px 15px",
                        }}
                    >
                        <b>
                            {t("data.fte")} : {props.fte}
                        </b>
                    </div>
                </div>
                <div>
                    <Guard
                        allowedPermissions={allowedPermissions}
                        permissionName="Edit"
                    >
                        <ButtonWithProgress
                            loading={false}
                            disabled={false}
                            variant="contained"
                            sx={saveBtn}
                            onClick={() => navigate(props.editLink ?? "/")}
                        >
                            {t("buttons.Edit")}
                        </ButtonWithProgress>
                    </Guard>
                    {props.showCloseButton && (
                        <Button
                            variant="text"
                            onClick={() => navigate(-1)}
                            sx={cancelBtn}
                        >
                            {t("buttons.Close")}
                        </Button>
                    )}
                </div>
            </div>
        </>
    );
};
