import React, { useEffect } from "react";
import hoverEditIcon from "assets/icons/hoverEditIcon.svg";
import { pluralize } from "core/utils";
import { useAppDispatch, useAppSelector } from "hooks";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getUserRoles } from "store";
import BootstrapTooltip from "ui/Tooltip";

import Loader from "../../../ui/Loader";

import styles from "./Roles.module.scss";

const RoleList = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const userRoles = useAppSelector((state) => state.users.roles);
    const isLoading = useAppSelector((state) => state.users.isLoading);

    useEffect(() => {
        dispatch(getUserRoles());
    }, [dispatch, t]);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <section className={styles.rolePermissionsWrapper}>
            <h3 className={styles.title}>{t("roles.rolesTitle")}</h3>
            <div className={styles.rolesListWrapper}>
                <div className={styles.roleListHeader}>
                    <h4 className={styles.roleListTitle}>
                        {t("roles.rolesCount", {
                            rolesCount: userRoles.length,
                        })}
                    </h4>
                    <p>
                        {t("permissions.roleInfoMessage")}{" "}
                        {userRoles
                            .map((userRole) => t(`roles.${userRole.roleName}`))
                            .join(", ")}
                    </p>
                </div>
                <div className={styles.roleListBody}>
                    {userRoles.map((userRole) => (
                        <div
                            key={userRole.roleName}
                            className={styles.roleListCard}
                        >
                            <div className={styles.roleListCardHeader}>
                                <h5 className={styles.roleNameTitle}>
                                    {t(`roles.${userRole.roleName}`)}
                                </h5>
                                {userRole.roleName.toLowerCase() !==
                                    "administrator" && (
                                    <Link to={`${userRole.roleName}`}>
                                        <BootstrapTooltip
                                            title={t("buttons.Edit")}
                                            placement="top"
                                        >
                                            <img
                                                src={hoverEditIcon}
                                                alt="edit icon"
                                            />
                                        </BootstrapTooltip>
                                    </Link>
                                )}
                            </div>
                            <span className={styles.usersCount}>
                                {userRole.usersCount}{" "}
                                {pluralize(userRole.usersCount, [
                                    t("permissions.accountSigular"),
                                    t("permissions.accountPlural"),
                                    t("permissions.accountPluralGenitive"),
                                ])}
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default RoleList;
