import axios from "axios";
import axiosApi from "core/request";

import { createAsyncThunk } from "@reduxjs/toolkit";

import { ISkillsArgs, ISkillsErrResponse, ISkillsLevelArgs } from "../../models/skills";

// skills
export const getSkills = createAsyncThunk(
    'catalogs/getSkills', async (countryEndpointPath: string, { rejectWithValue }) => {
        try {
            const response = await axiosApi.get(`/${countryEndpointPath}`);
            return response.data;
        } catch (error) {
            //TODO
            return rejectWithValue('Error that i need to handle');
        }
    }
);

export const createSkill = createAsyncThunk<any, ISkillsArgs, { rejectValue: ISkillsErrResponse | string }>(
    'catalogs/createSkill', async (skill, { rejectWithValue }) => {
        try {
            const formattedData = { ...skill };
            delete formattedData.skillsEndpointPath;
            const response = await axiosApi.post(`/${skill.skillsEndpointPath}`, { ...formattedData });
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                if (error.response.status === 400) {
                    return rejectWithValue(error.response.data.errors);
                } else {
                    return rejectWithValue('Что-то пошло не так... Попробуйте обновить страницу');
                }
            }

            if (!navigator.onLine) {
                return rejectWithValue('Проверьте подключение к интернету');
            }

            return rejectWithValue('Что-то пошло не так... Попробуйте обновить страницу');
        }
    }
);

export const editSkill = createAsyncThunk<any, ISkillsArgs, {
    rejectValue: ISkillsErrResponse | string
}>("catalogs/editSkill",
    async (skill: ISkillsArgs, { rejectWithValue }) => {
        try {
            const formattedData = { ...skill };
            delete formattedData.skillsEndpointPath;

            const response = await axiosApi.put(`/${skill.skillsEndpointPath}`, { ...formattedData });
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                if (error.response.status === 400) {
                    return rejectWithValue(error.response.data.errors);
                } else {
                    return rejectWithValue('Что-то пошло не так... Попробуйте обновить страницу');
                }
            }

            if (!navigator.onLine) {
                return rejectWithValue('Проверьте подключение к интернету');
            }

            return rejectWithValue('Что-то пошло не так... Попробуйте обновить страницу');
        }
    }
);


// skill level
export const getSkillLevels = createAsyncThunk(
    'catalogs/getSkillLevels', async (skillsEndpointPath: string, { rejectWithValue }) => {
        try {
            const response = await axiosApi.get(`/${skillsEndpointPath}`);
            return response.data;
        } catch (error) {
            //TODO
            return rejectWithValue('Error that i need to handle');
        }
    }
);

export const createSkillLevels = createAsyncThunk<any, ISkillsLevelArgs, {
    rejectValue: ISkillsErrResponse | string
}>("catalogs/createSkillLevels",
    async (skillLevel: {
        name: string,
        skillSetId: number | string,
        indexLevel: number,
        description: string
    }, { rejectWithValue }) => {
        try {
            //TODO TYPE THE RESPONSE FROM THE SERVER
            const response = await axiosApi.post('/skilllevels', { ...skillLevel });
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                if (error.response.status === 400) {
                    return rejectWithValue(error.response.data.errors);
                } else {
                    return rejectWithValue('Что-то пошло не так... Попробуйте обновить страницу');
                }
            }

            if (!navigator.onLine) {
                return rejectWithValue('Проверьте подключение к интернету');
            }

            return rejectWithValue('Что-то пошло не так... Попробуйте обновить страницу');
        }
    }
);

export const editSkillLevels = createAsyncThunk<any, ISkillsLevelArgs, {
    rejectValue: ISkillsErrResponse | string
}>("catalogs/editSkillLevels",
    async (skillLevel, { rejectWithValue }) => {
        try {
            const response = await axiosApi.put('/skilllevels', { ...skillLevel });
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                if (error.response.status === 400) {
                    return rejectWithValue(error.response.data.errors);
                } else {
                    return rejectWithValue('Что-то пошло не так... Попробуйте обновить страницу');
                }
            }

            if (!navigator.onLine) {
                return rejectWithValue('Проверьте подключение к интернету');
            }

            return rejectWithValue('Что-то пошло не так... Попробуйте обновить страницу');
        }
    }
);
