import {
    IClientsLookUp,
    IDetailedProject,
    IEmployeeLookUp,
    IProject,
    IProjectDataFromServer,
    IProjectEmployees,
} from "models/projects";
import { getEmployeesLookUpThunk } from "store/thunks";

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { revertAll } from "../store";
import {
    activateProject,
    addDocumentToProject,
    addEmployeeToProject,
    createProject,
    deactivateProject,
    deleteEmployee,
    deleteProjectDocument,
    editEmployeeInProject,
    editGeneralInfo,
    editProjectDocument,
    getClientsLookUp,
    getEmployeesByProjectId,
    getEmployeesLookUp,
    getProjectByIdForUpdate,
    getProjectByIdToView,
    getProjects,
} from "../thunks/projectsThunk";

interface ProjectsSlice {
    resourceManagers: any;
    projects: IProject[];
    projectId: number | null;
    projectDataToView: null | IDetailedProject;
    employeesLookUp: IEmployeeLookUp[];
    projectDataFromServer: IProjectDataFromServer | null;
    activeProject: IProject | null;
    projectEmployees: IProjectEmployees[];
    clients: IClientsLookUp[];
    isProjectEmployeeCreatingOrEditing: boolean;
    isGeneralInfoCreatingOrEditingLoading: boolean;
    isProjectViewLoading: boolean;
    isProjectEditLoading: boolean;
    isGettingProjectsLoading: boolean;
    isActivateOrDeactivateProjectLoading: boolean;
    isCreateOrEditOrDeleteDocumentLoading: boolean;
    isEmployeeDeleteLoading: boolean;
    totalProjectsCount: number | null;
    activeTab: number;
    isProjectEmployeesLoading: boolean;
    generalInfoError: null | string | string[];
    projectsSearch: string;
    employeeSearch: string;
    activeProjectEditEmployee: IProjectEmployees | null;
    activeProjectViewEmployee: IProject | null;
}

const initialState: ProjectsSlice = {
    projects: [],
    projectId: null,
    projectDataToView: null,
    employeesLookUp: [],
    projectDataFromServer: null,
    activeProject: null,
    projectEmployees: [],
    clients: [],
    isProjectEmployeeCreatingOrEditing: false,
    isGeneralInfoCreatingOrEditingLoading: false,
    isGettingProjectsLoading: false,
    isActivateOrDeactivateProjectLoading: false,
    isCreateOrEditOrDeleteDocumentLoading: false,
    isEmployeeDeleteLoading: false,
    totalProjectsCount: null,
    activeTab: 0,
    isProjectViewLoading: false,
    isProjectEditLoading: false,
    isProjectEmployeesLoading: false,
    generalInfoError: null,
    projectsSearch: "",
    employeeSearch: "",
    activeProjectEditEmployee: null,
    activeProjectViewEmployee: null,
    resourceManagers: [],
};

const projectsSlice = createSlice({
    name: "projects",
    initialState,
    reducers: {
        clearProjectData: (state) => {
            state.projectId = null;
            state.projectDataFromServer = null;
        },
        setProject: (state, action: PayloadAction<IProject | null>) => {
            state.activeProject = action.payload;
        },
        setActiveProjectEditEmployees: (state, action) => {
            state.activeProjectEditEmployee = action.payload;
        },
        setActiveProjectViewEmployee: (state, action) => {
            state.activeProjectViewEmployee = action.payload;
        },
        clearProjectEmployees: (state) => {
            state.projectEmployees = [];
        },
        changeActiveTabInEditProject: (state, action) => {
            state.activeTab = action.payload;
        },
        clearTextFieldErrors: (state) => {
            state.generalInfoError = null;
        },
        setEmployeeSearch: (state, action) => {
            state.employeeSearch = action.payload;
        },
        setProjectsSearch: (state, action) => {
            state.projectsSearch = action.payload;
        },
        clearEmployeesSearch: (state) => {
            state.employeeSearch = "";
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(revertAll, () => initialState)
            .addCase(getProjects.pending, (state) => {
                state.isGettingProjectsLoading = true;
            })
            .addCase(getEmployeesLookUpThunk.fulfilled, (state, action) => {
                state.resourceManagers = action.payload;
            })
            .addCase(getProjects.fulfilled, (state, action) => {
                state.projects = action.payload.data;
                state.totalProjectsCount =
                    action.payload.metadata.totalItemCount;
                state.isGettingProjectsLoading = false;
            })
            .addCase(getProjects.rejected, (state) => {
                state.isGettingProjectsLoading = false;
            })
            .addCase(getProjectByIdToView.pending, (state) => {
                state.isProjectViewLoading = true;
            })
            .addCase(getProjectByIdToView.fulfilled, (state, action) => {
                state.projectDataToView = action.payload;
                state.isProjectViewLoading = false;
            })
            .addCase(getProjectByIdToView.rejected, (state) => {
                state.isProjectViewLoading = false;
            })
            .addCase(createProject.pending, (state) => {
                state.isProjectEmployeeCreatingOrEditing = true;
            })
            .addCase(createProject.fulfilled, (state, action) => {
                state.projectId = action.payload;
                state.isProjectEmployeeCreatingOrEditing = false;
            })
            .addCase(createProject.rejected, (state, action) => {
                state.isProjectEmployeeCreatingOrEditing = false;
                if (action.payload) {
                    state.generalInfoError = action.payload;
                }
            })
            .addCase(editGeneralInfo.pending, (state) => {
                state.isProjectEmployeeCreatingOrEditing = true;
            })
            .addCase(editGeneralInfo.fulfilled, (state) => {
                state.isProjectEmployeeCreatingOrEditing = false;
            })
            .addCase(editGeneralInfo.rejected, (state) => {
                state.isProjectEmployeeCreatingOrEditing = false;
            })
            .addCase(getEmployeesLookUp.fulfilled, (state, action) => {
                state.employeesLookUp = action.payload;
            })
            .addCase(getProjectByIdForUpdate.pending, (state) => {
                state.isProjectEditLoading = true;
            })
            .addCase(getProjectByIdForUpdate.fulfilled, (state, action) => {
                state.projectDataFromServer = action.payload;
                state.isProjectEditLoading = false;
            })
            .addCase(getProjectByIdForUpdate.rejected, (state) => {
                state.isProjectEditLoading = false;
            })
            .addCase(getEmployeesByProjectId.pending, (state) => {
                state.isProjectViewLoading = true;
                state.isProjectEmployeesLoading = true;
            })
            .addCase(getEmployeesByProjectId.fulfilled, (state, action) => {
                state.projectEmployees = action.payload;
                state.isProjectViewLoading = false;
                state.isProjectEmployeesLoading = false;
            })
            .addCase(getEmployeesByProjectId.rejected, (state) => {
                state.isProjectViewLoading = false;
                state.isProjectEmployeesLoading = false;
            })
            .addCase(getClientsLookUp.fulfilled, (state, action) => {
                state.clients = action.payload;
            })
            .addCase(addEmployeeToProject.pending, (state) => {
                state.isProjectEmployeeCreatingOrEditing = true;
            })
            .addCase(addEmployeeToProject.fulfilled, (state) => {
                state.isProjectEmployeeCreatingOrEditing = false;
            })
            .addCase(addEmployeeToProject.rejected, (state) => {
                state.isProjectEmployeeCreatingOrEditing = false;
            })
            .addCase(editEmployeeInProject.pending, (state) => {
                state.isProjectEmployeeCreatingOrEditing = true;
            })
            .addCase(editEmployeeInProject.fulfilled, (state) => {
                state.isProjectEmployeeCreatingOrEditing = false;
            })
            .addCase(editEmployeeInProject.rejected, (state) => {
                state.isProjectEmployeeCreatingOrEditing = false;
            })
            .addCase(activateProject.pending, (state) => {
                state.isActivateOrDeactivateProjectLoading = true;
            })
            .addCase(activateProject.fulfilled, (state) => {
                state.isActivateOrDeactivateProjectLoading = false;
            })
            .addCase(activateProject.rejected, (state) => {
                state.isActivateOrDeactivateProjectLoading = false;
            })
            .addCase(deactivateProject.pending, (state) => {
                state.isActivateOrDeactivateProjectLoading = true;
            })
            .addCase(deactivateProject.fulfilled, (state) => {
                state.isActivateOrDeactivateProjectLoading = false;
            })
            .addCase(deactivateProject.rejected, (state) => {
                state.isActivateOrDeactivateProjectLoading = false;
            })
            .addCase(addDocumentToProject.pending, (state) => {
                state.isCreateOrEditOrDeleteDocumentLoading = true;
            })
            .addCase(addDocumentToProject.fulfilled, (state) => {
                state.isCreateOrEditOrDeleteDocumentLoading = false;
            })
            .addCase(addDocumentToProject.rejected, (state) => {
                state.isCreateOrEditOrDeleteDocumentLoading = false;
            })
            .addCase(editProjectDocument.pending, (state) => {
                state.isCreateOrEditOrDeleteDocumentLoading = true;
            })
            .addCase(editProjectDocument.fulfilled, (state) => {
                state.isCreateOrEditOrDeleteDocumentLoading = false;
            })
            .addCase(editProjectDocument.rejected, (state) => {
                state.isCreateOrEditOrDeleteDocumentLoading = false;
            })
            .addCase(deleteProjectDocument.pending, (state) => {
                state.isCreateOrEditOrDeleteDocumentLoading = true;
            })
            .addCase(deleteProjectDocument.fulfilled, (state) => {
                state.isCreateOrEditOrDeleteDocumentLoading = false;
            })
            .addCase(deleteProjectDocument.rejected, (state) => {
                state.isCreateOrEditOrDeleteDocumentLoading = false;
            })
            .addCase(deleteEmployee.pending, (state) => {
                state.isEmployeeDeleteLoading = true;
            })
            .addCase(deleteEmployee.fulfilled, (state) => {
                state.isEmployeeDeleteLoading = false;
            })
            .addCase(deleteEmployee.rejected, (state) => {
                state.isEmployeeDeleteLoading = false;
            });
    },
});

export const {
    clearProjectData,
    setProject,
    clearProjectEmployees,
    changeActiveTabInEditProject,
    setActiveProjectEditEmployees,
    setActiveProjectViewEmployee,
    clearTextFieldErrors,
    clearEmployeesSearch,
} = projectsSlice.actions;

export default projectsSlice.reducer;
