import React, { FC, useEffect, useState } from 'react';
import { CreateIcon, DeleteIcon, EditIcon, FilterIcon, ViewIcon, } from 'assets/icons';
import deleteIcon from 'assets/icons/delete.svg';
import editIcon from 'assets/icons/hoverEditIcon.svg';
import viewIcon from 'assets/icons/view.svg';
import userLogo from 'assets/logos/user.png';
import dayjs from 'dayjs';
import ConfirmationModal from 'features/ConfirmationModal';
import Guard from 'features/Guard';
import { useAppDispatch, useAppSelector } from 'hooks';
import { IFormEmployee } from 'models/projects';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { EMPLOYEES_PATH } from 'router/constants';
import {
    addEmployeeToProject,
    deleteEmployee,
    editEmployeeInProject,
    getEmployeesByProjectId,
    getEmployeesLookUp,
} from 'store/thunks/projectsThunk';
import {
    activateOrDeactivateBtn as saveEmployeeBtn,
    activateOrDeactivateModal,
    cancelBtn,
    createOrEditBtn,
    materialReactTableCell,
    materialReactTableHeader,
} from 'styles/MUIStyles';
import ButtonWithProgress from 'ui/ButtonWithProgress';
import { sendNotification } from 'ui/Toast';
import BootstrapTooltip from 'ui/Tooltip';
import {
    handleAddEmployeeToProject,
    handleEditEmployee,
} from 'views/Projects/ProjectsManagement/ProjectManagementOnSubmitHandler';

import { ErrorMessage } from '@hookform/error-message';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Autocomplete, Fade, Modal } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers';

import { getFromLocalStorage } from '../../../../core/utils';
import AgGrid from '../../../../features/AgGrid/AgGrid';
import { clearProjectEmployees, setActiveProjectEditEmployees } from '../../../../store';
import ProjectEmployeesFilter from '../../ProjectsFilter/ProjectEmployeesFilter';

import styles from './Employees.module.scss';

interface Props {
    activeTab: number;
    sortConfig: {
        column: string | null;
        direction: string | null;
    };
    handleColumnClick: any;
    search: string;
    setSearch: (search: string) => void;
}

const Employees: FC<Props> = ({
                                  activeTab,
                                  sortConfig,
                                  handleColumnClick,
                                  search,
                              }): JSX.Element => {
    const {
        register,
        control,
        handleSubmit,
        watch,
        setError,
        reset,
        clearErrors,
        formState: { errors, isDirty },
    } = useForm<IFormEmployee>({
        defaultValues: {
            employee: {
                id: '',
                name: '',
            },
            isManager: false,
            startDate: null,
            finishDate: null,
            fte: '',
            id: '',
        },
    });

    const dispatch = useAppDispatch();
    const params = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const projectEmployees = useAppSelector(
        (state) => state.projects.projectEmployees,
    );
    const employees = useAppSelector((state) => state.projects.employeesLookUp);
    const projectId = useAppSelector((state) => state.projects.projectId);
    const projectDataFromServer = useAppSelector(
        (state) => state.projects.projectDataFromServer,
    );

    const isProjectEmployeeCreatingOrEditing = useAppSelector(
        (state) => state.projects.isProjectEmployeeCreatingOrEditing,
    );

    const isEmployeeDeleteLoading = useAppSelector(
        (state) => state.projects.isEmployeeDeleteLoading,
    );
    const isProjectEmployeesLoading = useAppSelector(
        (state) => state.projects.isProjectEmployeesLoading,
    );
    const activeProjectEditEmployee = useAppSelector(
        (state) => state.projects.activeProjectEditEmployee,
    );

    const [isEmployeeModalOpen, setIsEmployeeModalOpen] = useState(false);
    const [isDeleteEmployeeModalOpen, setIsDeleteEmployeeModalOpen] =
        useState(false);
    const [isCreatingOrEditing, setIsCreationOrEditing] = useState<null | string>(null);
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [isUnsavedChangesModalOpen, setIsUnsavedChangesModalOpen] =
        useState(false);

    const onSubmit = async (data: IFormEmployee) => {
        if (projectId && isCreatingOrEditing === 'create') {
            const projectEmployee = handleAddEmployeeToProject(data, projectId);
            const response = await dispatch(
                addEmployeeToProject(projectEmployee),
            );

            if (response.meta.requestStatus === 'fulfilled' && projectId) {
                dispatch(setActiveProjectEditEmployees(null));
                dispatch(getEmployeesByProjectId({ projectId, pageSize: 100 }));
                setIsEmployeeModalOpen(false);
                sendNotification(
                    t('notifications.successfullyAddedToProject'),
                    'success',
                );
            }
        }

        if (projectId && isCreatingOrEditing === 'edit') {
            const projectEmployee = handleEditEmployee(data);
            const response = await dispatch(
                editEmployeeInProject({ ...projectEmployee }),
            );

            if (response.meta.requestStatus === 'fulfilled' && projectId) {
                dispatch(setActiveProjectEditEmployees(null));
                dispatch(getEmployeesByProjectId({ projectId, pageSize: 100 }));
                setIsEmployeeModalOpen(false);
                sendNotification(
                    t('notifications.successfullySaved'),
                    'success',
                );
            }
        }

        if (params?.projectId && isCreatingOrEditing === 'create') {
            const projectEmployee = handleAddEmployeeToProject(
                data,
                +params.projectId,
            );
            const response = await dispatch(
                addEmployeeToProject(projectEmployee),
            );

            if (
                response.meta.requestStatus === 'fulfilled' &&
                params?.projectId
            ) {
                dispatch(setActiveProjectEditEmployees(null));
                dispatch(
                    getEmployeesByProjectId({ projectId: +params.projectId, pageSize: 100 }),
                );
                setIsEmployeeModalOpen(false);
                sendNotification(
                    t('notifications.successfullyAddedToProject'),
                    'success',
                );
            }
        }

        if (params?.projectId && isCreatingOrEditing === 'edit') {
            const projectEmployee = handleEditEmployee(data);

            const response = await dispatch(
                editEmployeeInProject({ ...projectEmployee }),
            );
            if (
                response.meta.requestStatus === 'fulfilled' &&
                params?.projectId
            ) {
                dispatch(setActiveProjectEditEmployees(null));
                dispatch(
                    getEmployeesByProjectId({ projectId: +params?.projectId, pageSize: 100 }),
                );
                setIsEmployeeModalOpen(false);
                sendNotification(
                    t('notifications.successfullySaved'),
                    'success',
                );
            }
        }
    };

    const fillEmployeeData = () => {
        setIsCreationOrEditing('edit');
        setIsEmployeeModalOpen(true);
        if (activeProjectEditEmployee) {
            reset({
                employee: {
                    id: activeProjectEditEmployee.employeeId,
                    name: activeProjectEditEmployee.fullName,
                },
                isManager: activeProjectEditEmployee.isManager,
                startDate: activeProjectEditEmployee.startDate,
                finishDate: activeProjectEditEmployee.finishDate,
                fte: activeProjectEditEmployee.fte,
                id: activeProjectEditEmployee.id,
            });
        }
    };

    const deleteEmployeeHandler = async (employeeId: number) => {
        const response = await dispatch(deleteEmployee(employeeId));

        if (response.meta.requestStatus === 'fulfilled' && params?.projectId) {
            sendNotification(t('notifications.successfullyDeleted'), 'success');
            dispatch(getEmployeesByProjectId({ projectId: +params.projectId, pageSize: 100 }));
            setIsDeleteEmployeeModalOpen(false);
        }
    };

    const getArrowForSortDirection = (column: string) => {
        if (sortConfig.column !== column) {
            return null;
        }

        return sortConfig.direction === 'asc' ? (
            <ArrowUpwardIcon sx={{ color: '#605E5C' }}/>
        ) : (
            <ArrowDownwardIcon sx={{ color: '#605E5C' }}/>
        );
    };

    useEffect(() => {
        if (projectId && activeTab === 1 && !employees.length) {
            dispatch(getEmployeesLookUp());
        }

        return () => {
            dispatch(setActiveProjectEditEmployees(null));
        };
    }, [dispatch, params, activeTab, projectId, employees]);

    useEffect(() => {
        if (!isEmployeeModalOpen) {
            reset({
                employee: {
                    id: '',
                    name: '',
                },
                isManager: false,
                startDate: null,
                finishDate: null,
                fte: '',
            });
        }
    }, [isEmployeeModalOpen, reset]);

    useEffect(() => {
        if (isCreatingOrEditing === 'edit' && activeProjectEditEmployee) {
            reset({
                employee: {
                    id: activeProjectEditEmployee.employeeId,
                    name: activeProjectEditEmployee.fullName,
                },
                isManager: activeProjectEditEmployee.isManager,
                startDate: activeProjectEditEmployee.startDate,
                finishDate: activeProjectEditEmployee.finishDate,
                fte: activeProjectEditEmployee.fte,
                id: activeProjectEditEmployee.id,
            });
        }
    }, [isCreatingOrEditing, activeProjectEditEmployee, reset, setError, t]);

    useEffect(() => {
        if (
            activeTab === 1 &&
            !employees.length
        ) {
            dispatch(getEmployeesLookUp());
        }
    }, [dispatch, params, activeTab, employees]);

    const allowedPermissions = useAppSelector(
        (state) => state.auth.allowedPermissions,
    );

    const HeadComponent = (p: any) => {
        switch (p.column.colId) {
            case 'customActions':
                return (
                    <Box sx={materialReactTableHeader}>
                        <span>{t('data.actions')}</span>
                    </Box>
                );
            case 'avatar':
                return (
                    <Box sx={materialReactTableHeader}>
                        <span>{t('data.photo')}</span>
                    </Box>
                );
            case 'personnelNumber':
                return (
                    <Box sx={materialReactTableHeader}>
                        <span>{t('data.tabNo')}</span>
                    </Box>
                );
            case 'fullName':
                return (
                    <Box
                        sx={materialReactTableHeader}
                        onClick={() => handleColumnClick('fullName')}
                    >
                        <span>
                            {t('data.firstName') + '/' + t('data.position')}
                        </span>
                        {getArrowForSortDirection('fullName')}
                    </Box>
                );
            case 'position':
                return (
                    <Box
                        sx={materialReactTableHeader}
                        onClick={() => handleColumnClick('position')}
                    >
                        <span>{t('data.mainSkill')}</span>
                        {getArrowForSortDirection('position')}
                    </Box>
                );
            case 'skillLevel':
                return (
                    <Box
                        sx={materialReactTableHeader}
                        onClick={() => handleColumnClick('skillLevel')}
                    >
                        {t('data.level')}
                        {getArrowForSortDirection('skillLevel')}
                    </Box>
                );
            case 'fte':
                return (
                    <Box
                        sx={materialReactTableHeader}
                        onClick={() => handleColumnClick('fte')}
                    >
                        {t('data.fte')}
                        {getArrowForSortDirection('fte')}
                    </Box>
                );
            case 'organization':
                return (
                    <Box sx={materialReactTableHeader}>
                        <span>
                            {t('projects.vendor') + '/' + t('projects.company')}
                        </span>
                    </Box>
                );
            case 'startDate':
                return (
                    <Box
                        sx={materialReactTableHeader}
                        onClick={() => handleColumnClick('startDate')}
                    >
                        {t('data.startDate')}
                        {getArrowForSortDirection('startDate')}
                    </Box>
                );
            case 'finishDate':
                return (
                    <Box
                        sx={materialReactTableHeader}
                        onClick={() => handleColumnClick('finishDate')}
                    >
                        {t('data.finishDate')}
                        {getArrowForSortDirection('finishDate')}
                    </Box>
                );
            case 'email':
                return (
                    <Box sx={materialReactTableHeader}>
                        {t('data.contacts')}
                    </Box>
                );
            case 'status':
                return (
                    <Box
                        sx={materialReactTableHeader}
                        onClick={() => handleColumnClick('status')}
                    >
                        {t('data.status')}
                        {getArrowForSortDirection('status')}
                    </Box>
                );
        }
    };

    const RowComponent = (p: any) => {
        switch (p.colDef.field) {
            case 'customActions':
                return (
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-evenly',
                            alignItems: 'center',
                        }}
                    >
                        <Guard
                            allowedPermissions={allowedPermissions}
                            permissionName="View"
                        >
                            <BootstrapTooltip
                                sx={{ margin: 'auto 0' }}
                                title={t('buttons.View')}
                                onClick={() =>
                                    navigate(
                                        `/${EMPLOYEES_PATH}/${p.data?.employeeId}`,
                                    )
                                }
                                placement="top"
                            >
                                <img src={viewIcon} alt="view icon"/>
                            </BootstrapTooltip>
                        </Guard>

                        <Guard
                            allowedPermissions={allowedPermissions}
                            permissionName="Edit"
                        >
                            <BootstrapTooltip
                                title={t('buttons.Edit')}
                                onClick={() => {
                                    dispatch(
                                        setActiveProjectEditEmployees(p.data),
                                    );
                                    setIsCreationOrEditing('edit');
                                    setIsEmployeeModalOpen(true);
                                }}
                                placement="top"
                            >
                                <img src={editIcon} alt="edit icon"/>
                            </BootstrapTooltip>
                        </Guard>
                        <Guard
                            allowedPermissions={allowedPermissions}
                            permissionName="Delete"
                        >
                            <BootstrapTooltip
                                title={t('buttons.Delete')}
                                onClick={() => {
                                    dispatch(
                                        setActiveProjectEditEmployees(p.data),
                                    );
                                    setIsDeleteEmployeeModalOpen(true);
                                    dispatch(setActiveProjectEditEmployees(null));
                                }}
                                placement="top"
                            >
                                <img src={deleteIcon} alt="delete icon"/>
                            </BootstrapTooltip>
                        </Guard>
                    </Box>
                );
            case 'avatar':
                return (
                    <Box sx={materialReactTableCell}>
                        <img
                            style={{
                                borderRadius: '50%',
                                width: '35px',
                                height: '35px',
                                visibility: 'visible',
                                padding: '0',
                            }}
                            src={
                                p.data?.avatar
                                    ? process.env.REACT_APP_STATIC_FILES_URL +
                                    p.data?.avatar
                                    : userLogo
                            }
                            alt=""
                        />
                    </Box>
                );
            case 'personnelNumber':
                return (
                    <Box sx={materialReactTableCell}>
                        {p.data?.personnelNumber}
                    </Box>
                );
            case 'fullName':
                return (
                    <Box sx={materialReactTableCell}>
                        <Link to={`/${EMPLOYEES_PATH}/${p.data?.employeeId}`}>
                            {p.data?.fullName}
                        </Link>
                        <span>{p.data?.position}</span>
                    </Box>
                );
            case 'position':
                return <Box sx={materialReactTableCell}>{p.data?.skill}</Box>;
            case 'skillLevel':
                return (
                    <Box sx={materialReactTableCell}>{p.data?.skillLevel}</Box>
                );
            case 'fte':
                return <Box sx={materialReactTableCell}>{p.data?.fte}</Box>;
            case 'organization':
                return (
                    <Box sx={materialReactTableCell}>
                        {p.data?.organization}
                    </Box>
                );
            case 'startDate':
                return (
                    <Box sx={materialReactTableCell}>
                        {p.data?.startDate
                            ? dayjs(p.data?.startDate).format('DD.MM.YYYY')
                            : null}
                    </Box>
                );
            case 'finishDate':
                return (
                    <Box sx={materialReactTableCell}>
                        {p.data?.finishDate
                            ? dayjs(p.data?.finishDate).format('DD.MM.YYYY')
                            : null}
                    </Box>
                );
            case 'email':
                return (
                    <Box sx={materialReactTableCell}>
                        <span>{p.data?.email}</span>
                        <span>{p.data?.phone}</span>
                    </Box>
                );
            case 'status':
                return (
                    <Box
                        sx={{
                            ...materialReactTableCell,
                            '& > span': {
                                color:
                                    (p.data?.status === 1 && '#237657') ||
                                    (p.data?.status === 2 && '#FA0E00') ||
                                    '#201f1e',
                            },
                            fontWeight: 600,
                        }}
                    >
                        <span>
                            {p.data?.status === 1 && t('statuses.Active')}
                            {p.data?.status === 2 && t('statuses.Inactive')}
                            {p.data?.status === 3 &&
                                t('statuses.MarkedForDeletion')}
                        </span>
                    </Box>
                );
        }
    };

    const generateColumnDefs = () => {
        const savedColumnState = getFromLocalStorage('agGridProjectsEditTable');

        let updatedColumnDefs: any[] = [
            {
                field: 'customActions',
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 110,
            },
            {
                field: 'avatar',
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 90,
            },
            {
                field: 'personnelNumber',
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 90,
            },
            {
                field: 'fullName',
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 170,
            },
            {
                field: 'position',
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 170,
            },
            {
                field: 'skillLevel',
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 140,
            },
            {
                field: 'fte',
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 80,
            },
            {
                field: 'organization',
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 170,
            },
            {
                field: 'startDate',
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 130,
            },
            {
                field: 'finishDate',
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 160,
            },
            {
                field: 'email',
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 140,
            },
            {
                field: 'status',
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 120,
            },
        ];

        if (Array.isArray(savedColumnState)) {
            updatedColumnDefs = savedColumnState.map((columnState: any) => {
                return {
                    field: columnState.colId,
                    width: columnState.width,
                    pinned: columnState.pinned,
                    headerComponent: HeadComponent,
                    cellRenderer: RowComponent,
                    resizable: true,
                };
            });
        }

        return updatedColumnDefs;
    };

    const saveColumnState = (columnState: any) => {
        localStorage.setItem(
            'agGridProjectsEditTable',
            JSON.stringify(columnState),
        );
    };

    const columnDefs: any[] = generateColumnDefs();

    return (
        <div>
            <div className={styles.crudButtonWrapper}>
                <Guard
                    allowedPermissions={allowedPermissions}
                    permissionName="Create"
                >
                    <Button
                        startIcon={<CreateIcon/>}
                        variant="text"
                        onClick={() => {
                            setIsCreationOrEditing('create');
                            setIsEmployeeModalOpen(true);
                        }}
                        sx={createOrEditBtn}
                    >
                        {t('buttons.Create')}
                    </Button>
                </Guard>
                <Guard
                    allowedPermissions={allowedPermissions}
                    permissionName="Edit"
                >
                    <Button
                        startIcon={<EditIcon/>}
                        variant="text"
                        disabled={!activeProjectEditEmployee}
                        onClick={fillEmployeeData}
                        sx={createOrEditBtn}
                    >
                        {t('buttons.Edit')}
                    </Button>
                </Guard>

                <Guard
                    allowedPermissions={allowedPermissions}
                    permissionName="Delete"
                >
                    <Button
                        startIcon={<DeleteIcon/>}
                        variant="text"
                        disabled={!activeProjectEditEmployee}
                        onClick={() => {
                            if (activeProjectEditEmployee) {
                                setIsDeleteEmployeeModalOpen(true);
                            }
                        }}
                        sx={createOrEditBtn}
                    >
                        {t('buttons.Delete')}
                    </Button>
                </Guard>
                <Guard
                    allowedPermissions={allowedPermissions}
                    permissionName="View"
                >
                    <Button
                        startIcon={<ViewIcon/>}
                        variant="text"
                        sx={createOrEditBtn}
                        disabled={!activeProjectEditEmployee}
                        onClick={() =>
                            activeProjectEditEmployee &&
                            navigate(
                                `/${EMPLOYEES_PATH}/${activeProjectEditEmployee.employeeId}`,
                            )
                        }
                    >
                        {t('buttons.View')}
                    </Button>
                </Guard>
                <Button
                    startIcon={<FilterIcon/>}
                    type="button"
                    variant="text"
                    onClick={() => setIsFilterOpen(true)}
                    sx={{ ...createOrEditBtn, marginLeft: 'auto' }}
                >
                    {t('buttons.Filters')}
                </Button>
            </div>
            <AgGrid
                rowData={projectEmployees}
                columnDefs={columnDefs}
                saveColumnState={saveColumnState}
                activeRow={activeProjectEditEmployee}
                setActiveRow={setActiveProjectEditEmployees}
                height={'65vh'}
            />
            <Modal
                open={isEmployeeModalOpen}
                onClose={() => setIsEmployeeModalOpen(false)}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={isEmployeeModalOpen}>
                    <Box sx={{ ...activateOrDeactivateModal, width: '650px' }}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <React.Fragment>
                                <Box
                                    sx={{
                                        borderBottom: '1px solid #8A8886',
                                        marginBottom: '1rem',
                                    }}
                                >
                                    <h2 className={styles.projectName}>
                                        {projectDataFromServer?.fullName ||
                                            projectDataFromServer?.shortName}
                                    </h2>
                                </Box>
                                <Box
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Box sx={{ width: '70%' }}>
                                        <span className={styles.fullName}>
                                            {t('data.FIO')}
                                        </span>
                                        <Controller
                                            render={({ field }) => (
                                                <Autocomplete
                                                    disabled={
                                                        isCreatingOrEditing ===
                                                        'edit'
                                                    }
                                                    {...field}
                                                    ListboxProps={{
                                                        style: {
                                                            maxHeight: '200px',
                                                        },
                                                    }}
                                                    isOptionEqualToValue={() =>
                                                        true
                                                    }
                                                    options={employees.filter(
                                                        (employee) => {
                                                            return !projectEmployees.some(
                                                                (
                                                                    projectEmployee,
                                                                ) =>
                                                                    projectEmployee.employeeId ===
                                                                    employee.id,
                                                            );
                                                        },
                                                    )}
                                                    getOptionLabel={(
                                                        option,
                                                    ) => {
                                                        return option.name
                                                            ? option.name
                                                            : '';
                                                    }}
                                                    renderOption={(
                                                        props: any,
                                                        option,
                                                    ) => {
                                                        return (
                                                            <span
                                                                {...props}
                                                                className={
                                                                    styles.dropDownItem
                                                                }
                                                            >
                                                                {option.name}
                                                            </span>
                                                        );
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            sx={{
                                                                '& .MuiOutlinedInput-notchedOutline':
                                                                    {
                                                                        borderRadius:
                                                                            '0',
                                                                        border: errors.employee
                                                                            ? '1px solid #dc3545'
                                                                            : '1px solid #8A8886',
                                                                    },
                                                                '& .MuiOutlinedInput-root':
                                                                    {
                                                                        '&:hover fieldset':
                                                                            {
                                                                                border: errors?.employee
                                                                                    ? '1px solid #dc3545'
                                                                                    : '1px solid #8A8886',
                                                                            },
                                                                        '&.Mui-focused fieldset':
                                                                            {
                                                                                border: errors?.employee
                                                                                    ? '1px solid #dc3545'
                                                                                    : '1px solid #8A8886',
                                                                                boxShadow:
                                                                                    errors?.employee
                                                                                        ? '0 0 0 0.2rem rgba(220,53,69,.25)'
                                                                                        : '0 0 0 0.2rem rgba(0, 123, 255, .25)',
                                                                            },
                                                                    },
                                                                padding:
                                                                    '5px 0',
                                                            }}
                                                            size="small"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                    onChange={(_, data) =>
                                                        field.onChange(data)
                                                    }
                                                    value={field.value}
                                                />
                                            )}
                                            name={'employee'}
                                            rules={{
                                                validate: (value) => {
                                                    if (
                                                        !value?.name &&
                                                        !value?.id
                                                    ) {
                                                        return t(
                                                            'validation.requiredField',
                                                        ) as string;
                                                    }
                                                },
                                            }}
                                            control={control}
                                        />
                                        <ErrorMessage
                                            name={'employee'}
                                            errors={errors}
                                            render={({ message }) => (
                                                <span
                                                    className={
                                                        styles.employeeErrorMsg
                                                    }
                                                >
                                                    {message}
                                                </span>
                                            )}
                                        />
                                    </Box>
                                    <Box sx={{ width: '25%' }}>
                                        <Controller
                                            name={'isManager'}
                                            control={control}
                                            render={({ field }) => (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            onChange={(e) =>
                                                                field.onChange(
                                                                    e.target
                                                                        .checked,
                                                                )
                                                            }
                                                            checked={
                                                                field.value
                                                            }
                                                        />
                                                    }
                                                    label={t('data.manager')}
                                                />
                                            )}
                                        />
                                    </Box>
                                </Box>
                                <Box sx={{ width: '40%' }}>
                                    <span className={styles.startDate}>
                                        {t('projects.start')}
                                    </span>
                                    <Controller
                                        control={control}
                                        name={'startDate'}
                                        render={({ field }) => (
                                            <DesktopDatePicker
                                                {...field}
                                                inputFormat="DD/MM/YYYY"
                                                maxDate={watch('finishDate')}
                                                onError={(
                                                    reason,
                                                    inputValue,
                                                ) => {
                                                    if (reason === 'minDate') {
                                                        const currentEnteredDate =
                                                            new Date(
                                                                dayjs(
                                                                    inputValue,
                                                                ).format(
                                                                    'DD.MM.YYYY',
                                                                ),
                                                            ).getTime();
                                                        const minMUIDate =
                                                            new Date(
                                                                dayjs(
                                                                    '01/01/1900',
                                                                ).format(
                                                                    'DD.MM.YYYY',
                                                                ),
                                                            ).getTime();

                                                        if (
                                                            currentEnteredDate <
                                                            minMUIDate
                                                        ) {
                                                            setError(
                                                                'startDate',
                                                                {
                                                                    type: 'custom',
                                                                    message: t(
                                                                        'validation.minDateErrorMessage',
                                                                    ) as string,
                                                                },
                                                            );
                                                        }
                                                    }

                                                    if (reason === 'maxDate') {
                                                        setError('startDate', {
                                                            type: 'custom',
                                                            message: t(
                                                                'validation.startDateGreaterThanEndDateErrorMessage',
                                                            ) as string,
                                                        });
                                                    }

                                                    if (
                                                        reason === 'invalidDate'
                                                    ) {
                                                        setError('startDate', {
                                                            type: 'custom',
                                                            message: t(
                                                                'validation.invalidDate',
                                                            ) as string,
                                                        });
                                                    }

                                                    if (reason === null) {
                                                        clearErrors(
                                                            'startDate',
                                                        );
                                                    }
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        size="small"
                                                        {...params}
                                                        sx={{
                                                            '& .MuiOutlinedInput-notchedOutline':
                                                                {
                                                                    borderRadius:
                                                                        '0',
                                                                    border: '1px solid #8A8886',
                                                                },
                                                            '& .MuiOutlinedInput-root':
                                                                {
                                                                    '&:hover fieldset':
                                                                        {
                                                                            border: errors?.startDate
                                                                                ? '1px solid rgb(255, 0, 0)'
                                                                                : '1px solid #8A8886',
                                                                        },
                                                                    '&.Mui-focused fieldset':
                                                                        {
                                                                            borderColor:
                                                                                errors?.startDate
                                                                                    ? 'rgb(255, 0, 0)'
                                                                                    : '#80bdff',
                                                                            boxShadow:
                                                                                errors?.startDate
                                                                                    ? '0 0 0 0.2rem rgba(0, 255, 0, 0)'
                                                                                    : '0 0 0 0.2rem rgba(0, 123, 255, .25)',
                                                                            borderWidth:
                                                                                '1px',
                                                                        },
                                                                },
                                                            padding: '5px 0',
                                                        }}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            placeholder: t(
                                                                'projects.dayMonthYear',
                                                            ) as string,
                                                            autoComplete: 'off',
                                                        }}
                                                    />
                                                )}
                                            />
                                        )}
                                    />
                                    <ErrorMessage
                                        name="startDate"
                                        errors={errors}
                                        render={({ message }) => (
                                            <span
                                                className={
                                                    styles.employeeDatesErrorMsg
                                                }
                                            >
                                                {message}
                                            </span>
                                        )}
                                    />
                                </Box>
                                <Box sx={{ width: '40%' }}>
                                    <span className={styles.finishDate}>
                                        {t('projects.end')}
                                    </span>
                                    <Controller
                                        control={control}
                                        name={'finishDate'}
                                        render={({ field }) => (
                                            <DesktopDatePicker
                                                {...field}
                                                inputFormat="DD/MM/YYYY"
                                                minDate={watch('startDate')}
                                                onError={(
                                                    reason,
                                                    inputValue,
                                                ) => {
                                                    if (reason === 'minDate') {
                                                        const currentEnteredDate =
                                                            new Date(
                                                                dayjs(
                                                                    inputValue,
                                                                ).format(
                                                                    'DD.MM.YYYY',
                                                                ),
                                                            ).getTime();
                                                        const minMUIDate =
                                                            new Date(
                                                                dayjs(
                                                                    '01/01/1900',
                                                                ).format(
                                                                    'DD.MM.YYYY',
                                                                ),
                                                            ).getTime();

                                                        if (
                                                            currentEnteredDate <
                                                            minMUIDate
                                                        ) {
                                                            setError(
                                                                'finishDate',
                                                                {
                                                                    type: 'custom',
                                                                    message: t(
                                                                        'validation.minDateErrorMessage',
                                                                    ) as string,
                                                                },
                                                            );
                                                        }
                                                    }

                                                    if (
                                                        reason === 'invalidDate'
                                                    ) {
                                                        setError('finishDate', {
                                                            type: 'custom',
                                                            message: t(
                                                                'validation.invalidDate',
                                                            ) as string,
                                                        });
                                                    }

                                                    if (reason === null) {
                                                        clearErrors(
                                                            'finishDate',
                                                        );
                                                    }
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        size="small"
                                                        {...params}
                                                        sx={{
                                                            '& .MuiOutlinedInput-notchedOutline':
                                                                {
                                                                    borderRadius:
                                                                        '0',
                                                                    border: '1px solid #8A8886',
                                                                },
                                                            '& .MuiOutlinedInput-root':
                                                                {
                                                                    '&:hover fieldset':
                                                                        {
                                                                            border: errors?.finishDate
                                                                                ? '1px solid rgb(255, 0, 0)'
                                                                                : '1px solid #8A8886',
                                                                        },
                                                                    '&.Mui-focused fieldset':
                                                                        {
                                                                            borderColor:
                                                                                errors?.finishDate
                                                                                    ? 'rgb(255, 0, 0)'
                                                                                    : '#80bdff',
                                                                            boxShadow:
                                                                                errors?.finishDate
                                                                                    ? '0 0 0 0.2rem rgba(0, 255, 0, 0)'
                                                                                    : '0 0 0 0.2rem rgba(0, 123, 255, .25)',
                                                                            borderWidth:
                                                                                '1px',
                                                                        },
                                                                },
                                                            padding: '5px 0',
                                                        }}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            placeholder: t(
                                                                'projects.dayMonthYear',
                                                            ) as string,
                                                            autoComplete: 'off',
                                                        }}
                                                    />
                                                )}
                                            />
                                        )}
                                    />
                                    <ErrorMessage
                                        name="finishDate"
                                        errors={errors}
                                        render={({ message }) => (
                                            <span
                                                className={
                                                    styles.employeeDatesErrorMsg
                                                }
                                            >
                                                {message}
                                            </span>
                                        )}
                                    />
                                </Box>
                                <Box sx={{ width: '20%' }}>
                                    <span className={styles.FTE}>FTE</span>
                                    <input
                                        {...register('fte', {
                                            pattern: {
                                                value: /^\d{1}$|^\d{2}$|^\d{1}\.\d{1,2}$/,
                                                message: t(
                                                    'validation.numericInputValidationErrorMessage',
                                                ),
                                            },
                                        })}
                                        autoComplete="off"
                                        type="text"
                                        className={
                                            errors.fte
                                                ? styles.employeeFTError
                                                : styles.employeeFTE
                                        }
                                    />
                                    <ErrorMessage
                                        name="fte"
                                        errors={errors}
                                        render={({ message }) => (
                                            <span>{message}</span>
                                        )}
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        alignItems: 'center',
                                        gap: '10px',
                                    }}
                                >
                                    <ButtonWithProgress
                                        variant="contained"
                                        type="submit"
                                        loading={
                                            isProjectEmployeeCreatingOrEditing
                                        }
                                        disabled={
                                            isProjectEmployeeCreatingOrEditing ||
                                            !!errors?.startDate ||
                                            !!errors?.finishDate
                                        }
                                        sx={{ ...saveEmployeeBtn }}
                                    >
                                        {t('buttons.Save')}
                                    </ButtonWithProgress>
                                    <Button
                                        variant="text"
                                        onClick={() => {
                                            if (isDirty) {
                                                setIsUnsavedChangesModalOpen(
                                                    true,
                                                );
                                            } else {
                                                setIsEmployeeModalOpen(false);
                                                setIsCreationOrEditing(null);
                                            }
                                        }}
                                        sx={cancelBtn}
                                    >
                                        {t('buttons.Close')}
                                    </Button>
                                </Box>
                            </React.Fragment>
                        </form>
                    </Box>
                </Fade>
            </Modal>

            <Backdrop
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isProjectEmployeesLoading}
            >
                <CircularProgress sx={{ color: '#fff' }}/>
            </Backdrop>
            <ProjectEmployeesFilter
                params={params}
                isFilterOpen={isFilterOpen}
                setIsFilterOpen={setIsFilterOpen}
                activeTab={activeTab}
                debouncedSearch={search}
            />
            <ConfirmationModal
                isModalOpen={isUnsavedChangesModalOpen}
                setIsModalOpen={() => setIsUnsavedChangesModalOpen(false)}
                actionName={'Close'}
                instanceName={''}
                message={'closeFormWithoutSaving'}
                handleAction={() => {
                    if (isDirty) {
                        setIsUnsavedChangesModalOpen(false);
                        setIsEmployeeModalOpen(false);
                        setIsCreationOrEditing(null);
                    }
                }}
            />
            <ConfirmationModal
                isModalOpen={isDeleteEmployeeModalOpen}
                setIsModalOpen={() => setIsDeleteEmployeeModalOpen(false)}
                actionName={'Delete'}
                instanceName={'employees'}
                loading={isEmployeeDeleteLoading}
                handleAction={() => {
                    if (activeProjectEditEmployee) {
                        deleteEmployeeHandler(activeProjectEditEmployee.id);
                    }
                }}
            />
        </div>
    );
};

export default Employees;
