// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_userInfo__ux41j {\n  font-family: Source Sans Pro;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 20px 0;\n}\n.styles_userInfo__ux41j .styles_fullName__qqw7x {\n  font-weight: 600;\n  font-size: 1.2rem;\n  color: #2d3748;\n  margin-bottom: 5px;\n  text-align: center;\n}\n.styles_userInfo__ux41j .styles_userRole__Q7qmu {\n  font-weight: 300;\n  font-size: 1rem;\n  color: #4a5567;\n  margin-bottom: 0;\n}", "",{"version":3,"sources":["webpack://./src/views/Layout/UserInfo/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,4BAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,eAAA;AACF;AACE;EACE,gBAAA;EACA,iBAAA;EACA,cAAA;EACA,kBAAA;EACA,kBAAA;AACJ;AACE;EACE,gBAAA;EACA,eAAA;EACA,cAAA;EACA,gBAAA;AACJ","sourcesContent":[".userInfo {\n  font-family: Source Sans Pro;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 20px 0;\n\n  .fullName {\n    font-weight: 600;\n    font-size: 1.2rem;\n    color: #2d3748;\n    margin-bottom: 5px;\n    text-align: center;\n  }\n  .userRole {\n    font-weight: 300;\n    font-size: 1rem;\n    color: #4a5567;\n    margin-bottom: 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"userInfo": "styles_userInfo__ux41j",
	"fullName": "styles_fullName__qqw7x",
	"userRole": "styles_userRole__Q7qmu"
};
export default ___CSS_LOADER_EXPORT___;
