import React, { FC } from "react";
import Guard from "features/Guard";
import { IAllSkillLevels, IAllSkills, IEmployeeSkill } from "models/employees";
import { IDetailedProject, IProject } from "models/projects";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";

import { EMPLOYEES_PATH } from "../../../router/constants";
import { cancelBtn, saveBtn } from "../../../styles/MUIStyles";
import ButtonWithProgress from "../../../ui/ButtonWithProgress";

import styles from "../EmployeeView/Employees.module.scss";

interface Props {
    id: string;
    showCloseButton?: boolean;
    projects: Array<IDetailedProject>;
}
const EmployeeProjects: FC<Props> = (props): JSX.Element => {
    const { projects } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <div style={{ padding: "10px 0px" }}>
            <div className={styles.employeeFullNameWrapper}>
                <h2 className={styles.employeeFullName}></h2>
                <div>
                    {props.showCloseButton && (
                        <Button
                            variant="text"
                            onClick={() => navigate(-1)}
                            sx={cancelBtn}
                        >
                            {t("buttons.Close")}
                        </Button>
                    )}
                </div>
            </div>
            <div className={styles.employeeProjects}>
                <h3>{t("employees.projects")}</h3>
                <ul>
                    {projects.map((project) => (
                        <li key={project.id}>
                            <h4>{project.shortName}</h4>
                            <h4>{project.fullName}</h4>
                            <p>
                                {t("projects.description")}:{" "}
                                {project.description}
                            </p>
                            <p>
                                {t("projects.start")}: {project.startDate}
                            </p>
                            <p>
                                {t("projects.end")}:
                                {project.finishDate || t("projects.ongoing")}
                            </p>
                            <p>FTE: {project.fte}</p>
                            <p>
                                {t("projects.isManager")}:{" "}
                                {project.managers
                                    ? t("projects.yes")
                                    : t("projects.no")}
                            </p>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default EmployeeProjects;
