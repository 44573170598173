import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

export const parseDate = (date: Date) => {
    const utcTime = dayjs.utc(date);
    const localTime = utcTime.tz(dayjs.tz.guess());
    return localTime.format('DD.MM.YYYY HH:mm:ss');
};
