import React, { useEffect } from 'react';
import editIcon from 'assets/icons/hoverEditIcon.svg';
import dayjs from 'dayjs';
import Guard from 'features/Guard';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Link as ReactLink, useNavigate, useParams } from 'react-router-dom';
import {
    getAllowedGroupsByMenuId,
    getAllowedPermissionsByGroupId,
    getEmployeeById,
    setActiveEmployeeSalary,
} from 'store';
import { getSalary } from 'store/thunks/salariesThunk';
import { cancelBtn, materialReactTableCell, materialReactTableHeader, saveBtn, } from 'styles/MUIStyles';
import BootstrapTooltip from 'ui/Tooltip';

import { Box, Breadcrumbs, Button } from '@mui/material';
import Typography from "@mui/material/Typography";

import { getFromLocalStorage } from '../../../core/utils';
import AgGrid from '../../../features/AgGrid/AgGrid';
import { SALARIES_PATH } from "../../../router/constants";

import styles from './EmployeeSalaries.module.scss';


function EmployeeSalaries() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const params = useParams();

    const salary = useAppSelector((state) => state.salaries.salary);
    const employeeInfo = useAppSelector(
        (state) => state.employees.employeeDetails
    );
    const allowedPermissions = useAppSelector(
        (state) => state.auth.allowedPermissions
    );
    const activeEmployeeSalary = useAppSelector(
        (state) => state.salaries.activeEmployeeSalary
    );

    const allowedGroups = useAppSelector((state) => state.auth.allowedGroups);
    const allowedMenus = useAppSelector((state) => state.auth.allowedMenus);

    useEffect(() => {
        const menu = allowedMenus.find((menu) => menu.name === 'Salaries');
        typeof menu?.id === 'number' &&
        dispatch(getAllowedGroupsByMenuId(menu.id));
    }, [allowedMenus, dispatch]);

    useEffect(() => {
        const group = allowedGroups.find(
            (menu) => menu.name === `api/salaries`
        );
        group?.id && dispatch(getAllowedPermissionsByGroupId(group.id));
    }, [dispatch, allowedGroups]);

    useEffect(() => {
        dispatch(getSalary(String(params.employeeId)));
        dispatch(getEmployeeById(String(params.employeeId)));
    }, [dispatch, params.employeeId, t]);

    const HeadComponent = (p: any) => {
        switch (p.column.colId) {
            case 'customActions':
                return (
                    <Box sx={materialReactTableHeader}>
                        <span>{t('data.actions')}</span>
                    </Box>
                );
            case 'fromDate':
                return (
                    <Box sx={materialReactTableHeader}>
                        <span>{t('salaries.FromDate')}</span>
                    </Box>
                );
            case 'currency':
                return (
                    <Box sx={materialReactTableHeader}>
                        <span>{t('salaries.Currency')}</span>
                    </Box>
                );
            case 'rateMonth':
                return (
                    <Box sx={materialReactTableHeader}>
                        <span>{t('salaries.RateMonth')}</span>
                    </Box>
                );
            case 'rateWeek':
                return (
                    <Box sx={materialReactTableHeader}>
                        <span>{t('salaries.RateWeek')}</span>
                    </Box>
                );
            case 'rateDay':
                return (
                    <Box sx={materialReactTableHeader}>
                        <span>{t('salaries.RateDay')}</span>
                    </Box>
                );
            case 'rateHour':
                return (
                    <Box sx={materialReactTableHeader}>
                        <span>{t('salaries.RateHour')}</span>
                    </Box>
                );
            case 'remark':
                return (
                    <Box sx={materialReactTableHeader}>
                        <span>{t('salaries.Note')}</span>
                    </Box>
                );
        }
    };

    const RowComponent = (p: any) => {
        switch (p.colDef.field) {
            case 'customActions':
                return (
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            height: '100%',
                            justifyContent: 'space-evenly',
                            alignItems: 'center',
                            '& img': {
                                cursor: 'pointer',
                            },
                        }}
                    >
                        <Guard
                            allowedPermissions={allowedPermissions}
                            permissionName="Edit"
                        >
                            {p.data.isCurrent && (
                                <BootstrapTooltip
                                    sx={{ margin: 'auto 0' }}
                                    title={t('buttons.Edit')}
                                    onClick={() =>
                                        navigate(`edit/${p.data?.id}`)
                                    }
                                    placement="top"
                                >
                                    <img src={editIcon} alt="edit icon"/>
                                </BootstrapTooltip>
                            )}
                        </Guard>
                    </Box>
                );
            case 'fromDate':
                return (
                    <Box sx={materialReactTableCell}>
                        {p.data.fromDate
                            ? dayjs(p.data.fromDate).format('DD.MM.YYYY')
                            : null}
                    </Box>
                );
            case 'currency':
                return (
                    <Box sx={materialReactTableCell}>{p.data?.currency}</Box>
                );
            case 'rateMonth':
                return (
                    <Box sx={materialReactTableCell}>
                        {p.data?.rateMonth ? p.data?.rateMonth : '-'}
                    </Box>
                );
            case 'rateWeek':
                return (
                    <Box sx={materialReactTableCell}>
                        {p.data?.rateWeek ? p.data.rateWeek : '-'}
                    </Box>
                );
            case 'rateDay':
                return (
                    <Box sx={materialReactTableCell}>
                        {p.data?.rateDay ? p.data?.rateDay : '-'}
                    </Box>
                );
            case 'rateHour':
                return (
                    <Box sx={materialReactTableCell}>
                        {p.data?.rateHour ? p.data?.rateHour : '-'}
                    </Box>
                );
            case 'remark':
                return <Box sx={materialReactTableCell}>{p.data?.remark}</Box>;
        }
    };

    const generateColumnDefs = () => {
        const savedColumnState = getFromLocalStorage(
            'agGridEmployeeSalariesTable'
        );

        let updatedColumnDefs: any[] = [
            {
                field: 'customActions',
                resizable: true,
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 110,
            },
            {
                field: 'fromDate',
                resizable: true,
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 110,
            },
            {
                field: 'currency',
                resizable: true,
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 110,
            },
            {
                field: 'rateMonth',
                resizable: true,
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 140,
            },
            {
                field: 'rateWeek',
                resizable: true,
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 140,
            },
            {
                field: 'rateDay',
                resizable: true,
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 140,
            },
            {
                field: 'rateHour',
                resizable: true,
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 140,
            },
            {
                field: 'remark',
                resizable: true,
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 130,
            },
        ];

        if (Array.isArray(savedColumnState)) {
            updatedColumnDefs = savedColumnState.map((columnState: any) => {
                return {
                    field: columnState.colId,
                    width: columnState.width,
                    pinned: columnState.pinned,
                    headerComponent: HeadComponent,
                    cellRenderer: RowComponent,
                    resizable: true,
                };
            });
        }

        return updatedColumnDefs;
    };

    const saveColumnState = (columnState: any) => {
        localStorage.setItem(
            'agGridEmployeeSalariesTable',
            JSON.stringify(columnState)
        );
    };

    const columnDefs: any[] = generateColumnDefs();

    return (
        <main
            className={`${styles.container} + ${styles.scrollContainerHeight} + ${styles.scrollContainer}`}
        >
            <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: '1rem' }}>
                <ReactLink className={styles.breadScrumbsLink} color="inherit" to="/">
                    Impulse
                </ReactLink>
                <ReactLink className={styles.breadScrumbsLink} color="inherit" to={`/${SALARIES_PATH}`}>
                    {t('salaries.Title')}
                </ReactLink>
                <Typography
                    sx={{
                        color: '#201f1e',
                        fontFamily: 'Source Sans Pro',
                    }}
                >
                    {employeeInfo?.lastName} {employeeInfo?.firstName}
                </Typography>
            </Breadcrumbs>
            <div className={styles.box_title}>
                <div>
                    <h3>{t('salaries.Salary')}</h3>
                    <h2>
                        {employeeInfo?.lastName} {employeeInfo?.firstName}{' '}
                        {t('data.tabNo')}{' '}
                        {employeeInfo?.personnelNumber
                            ? employeeInfo?.personnelNumber
                            : '-'}
                    </h2>
                </div>
                <div className={styles.box_btns}>
                    <Guard
                        allowedPermissions={allowedPermissions}
                        permissionName="Create"
                    >
                        <ReactLink to={'create'}>
                            <Button
                                variant="contained"
                                sx={saveBtn}
                            >
                                {t('buttons.Add')}
                            </Button>
                        </ReactLink>
                    </Guard>
                    <ReactLink to={'/salaries'}>
                        <Button
                            variant="text"
                            sx={cancelBtn}
                        >
                            {t('buttons.Close')}
                        </Button>
                    </ReactLink>
                </div>
            </div>
            <AgGrid
                rowData={salary}
                columnDefs={columnDefs}
                saveColumnState={saveColumnState}
                activeRow={activeEmployeeSalary}
                setActiveRow={setActiveEmployeeSalary}
                height={'60vh'}
            />
        </main>
    );
}

export default EmployeeSalaries;
