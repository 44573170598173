import { FC } from "react";
import { useTranslation } from "react-i18next";

import Drawer from "@mui/material/Drawer";

import closeIcon from "../../../assets/icons/closeIcon.svg";
import { filterDrawer } from "../../../styles/MUIStyles";

import styles from "../../../views/Catalogs/CatalogsLayout.module.scss";

const Index: FC<{
    name: string;
    isDrawerOpen: boolean;
    createOrUpdate: null | string;
    closeFormDrawer: Function;
    children: JSX.Element;
}> = ({ name, isDrawerOpen, closeFormDrawer, children }) => {
    const { t } = useTranslation();

    return (
        <div>
            <Drawer
                sx={filterDrawer}
                anchor="right"
                open={isDrawerOpen}
                onClose={() => closeFormDrawer()}
            >
                <div className={styles.drawerTop}>
                    <h3 className={styles.drawerTopTitle}>
                        {name ? t(`catalogActions.${name}`) : ""}
                    </h3>
                    <img
                        onClick={() => closeFormDrawer()}
                        src={closeIcon}
                        alt="close icon"
                    />
                </div>
                {children}
            </Drawer>
        </div>
    );
};

export default Index;
