import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector, useUpdateEffect } from 'hooks';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line @typescript-eslint/no-var-requires
import { NumericFormat } from 'react-number-format';
import { Link, useNavigate } from 'react-router-dom';
import { CONTRACT_EMPLOYEES_PATH, CONTRACTS_PATH } from 'router/constants';
import { createContract, editContract } from 'store/thunks/contractsEmployees';
import { getEmployeesLookup, getLookupByName } from 'store/thunks/lookupsThunk';
import ButtonWithProgress from 'ui/ButtonWithProgress';
import { sendNotification } from 'ui/Toast';

import { ErrorMessage } from '@hookform/error-message';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Autocomplete, Breadcrumbs, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import styles from './ContractCreate.module.scss';

interface IContractInput {
    number: string,
    employee?: { id: string, name: string }
    name: string,
    description: string,
    contractType?: { id: string, name: string },
    conclusionDate: string | null,
    endDate: string | null,
    currency: { currencyCode: string, id: string, name: string },
    contractAmount: number | string | null,
    amountMonth: number | string | null,
    status: string,
    note: string,
    parentId: number | null
}

const ContractCreate = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const {
        register,
        handleSubmit,
        control,
        watch,
        setValue,
        reset,
        getValues,
        clearErrors,
        setError,
        formState: { errors, isDirty }
    } = useForm<IContractInput>({
        defaultValues: {
            number: '',
            employee: { id: '', name: '' },
            name: '',
            status: '',
            conclusionDate: null,
            endDate: null,
            contractType: { id: '', name: '' },
            currency: { currencyCode: '', id: '', name: '' },
            contractAmount: null,
            amountMonth: null,
            description: '',
            note: '',
        },
    });

    const amountMonth = watch('amountMonth');
    const contractAmount = watch('contractAmount');
    const createdContractId = localStorage.getItem('createdContractId');
    const conclusionDate = watch('conclusionDate');
    const endDate = watch('endDate');

    const employees = useAppSelector(state => state.lookups.employees);
    const typeOfContracts = useAppSelector(state => state.lookups.typeOfContract);
    const currencies = useAppSelector(state => state.lookups.currencies);
    const contractStatuses = useAppSelector((state: any) => state.lookups.contractStatuses);
    const isCreatingContractLoading = useAppSelector(state => state.contracts.isCreatingContractLoading);
    const isEditingContractLoading = useAppSelector(state => state.contracts.isEditingContractLoading);

    const onSubmit: SubmitHandler<IContractInput> = async (contractData) => {
        const contractStatus = contractData.status === 'Active' ? 1 : contractData.status === 'Suspended' ? 2 : 3;

        if (createdContractId) {
            const response = await dispatch(editContract({
                name: contractData.name,
                description: contractData.description || null,
                typeId: contractData.contractType?.id || null,
                conclusionDate: contractData.conclusionDate ? dayjs(contractData.conclusionDate).format('YYYY-MM-DDT00:00:00.000') + 'Z' : null,
                endDate: contractData.endDate ? dayjs(contractData.endDate).format('YYYY-MM-DDT00:00:00.000') + 'Z' : null,
                currencyId: contractData.currency?.id ? contractData.currency.id : null,
                contractAmount: contractData?.contractAmount ?? null,
                amountMonth: contractData?.amountMonth ?? null,
                status: contractStatus,
                note: contractData.note,
                id: +createdContractId,
                parentId: null,
            }));

            if (response.meta.requestStatus === 'fulfilled') {
                sendNotification(t('notifications.successfullySaved'), 'success');
            }

            return;
        }

        const response = await dispatch(createContract({
            number: contractData.number,
            employeeId: contractData.employee?.id,
            name: contractData.name,
            description: contractData.description || null,
            typeId: contractData.contractType?.id || null,
            conclusionDate: contractData.conclusionDate ? dayjs(contractData.conclusionDate).format('YYYY-MM-DDT00:00:00.000') + 'Z' : null,
            endDate: contractData.endDate ? dayjs(contractData.endDate).format('YYYY-MM-DDT00:00:00.000') + 'Z' : null,
            currencyId: contractData.currency?.id ? contractData.currency.id : null,
            contractAmount: contractData?.contractAmount ?? null,
            amountMonth: contractData?.amountMonth ?? null,
            status: contractStatus,
            note: contractData.note,
            parentId: null,
        }));

        if (response.meta.requestStatus === 'fulfilled' && response?.payload >= 0) {
            sendNotification(t('notifications.successfullyCreated'), 'success');
            localStorage.setItem('createdContractId', response.payload);
            reset(getValues());
        }
    };

    useEffect(() => {
        dispatch(getEmployeesLookup());
        dispatch(getLookupByName('typeofcontract'));
        dispatch(getLookupByName('currencies'));
        dispatch(getLookupByName('contractstatuses'));
    }, []);

    useUpdateEffect(() => {
        if (amountMonth || contractAmount || amountMonth === 0 || contractAmount === 0) {
            clearErrors('contractAmount');
            clearErrors('amountMonth');
        }
    }, [amountMonth, contractAmount]);

    useEffect(() => {
        const handleBeforeUnload = () => {
            localStorage.removeItem('createdContractId');
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            localStorage.removeItem('createdContractId');
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    return (
        <div className={styles.contractCreateContainer}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Breadcrumbs sx={{ padding: '.3rem 0 .3rem 0', width: '80%', margin: '0 auto' }}>
                    <Typography sx={{ color: '#201f1e', fontFamily: 'Source Sans Pro' }}>
                        Impulse
                    </Typography>
                    <Link to={`/${CONTRACTS_PATH}/${CONTRACT_EMPLOYEES_PATH}`} className={styles.breadScrumbsLink}>
                        {t('contracts.contractsEmployees')}
                    </Link>
                </Breadcrumbs>
                <div className={styles.contractCreateFormContainer}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={styles.buttonsContainer}>
                            <ButtonWithProgress
                                loading={isCreatingContractLoading || isEditingContractLoading}
                                disabled={
                                    (isCreatingContractLoading) || (isEditingContractLoading) ||
                                    (Boolean(createdContractId && !isDirty)) || Boolean(Object.keys(errors).length)
                                }
                                variant="contained"
                                type="submit"
                                sx={{
                                    fontFamily: 'Source Sans Pro',
                                    backgroundColor: '#205493',
                                    textTransform: 'capitalize',
                                    fontSize: '1rem',
                                    lineHeight: '1.333rem',
                                    color: '#fff',
                                    fontWeight: '400',
                                    padding: '0 0.7rem 0.1rem 0.7rem',
                                    height: '2.5rem',
                                }}>
                                {t('buttons.Save')}
                            </ButtonWithProgress>
                            <Button
                                startIcon={<CloseOutlinedIcon/>}
                                variant="contained"
                                onClick={() => navigate(-1)}
                                sx={{
                                    fontFamily: 'Source Sans Pro',
                                    backgroundColor: '#205493',
                                    textTransform: 'capitalize',
                                    fontSize: '1rem',
                                    lineHeight: '1.333rem',
                                    color: '#fff',
                                    fontWeight: '400',
                                    padding: '0 0.7rem 0.1rem 0.7rem',
                                    height: '2.5rem',
                                }}>
                                {t('buttons.Close')}
                            </Button>
                        </div>
                        <div className={styles.superTest}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.7rem' }}>
                                <label htmlFor="number">{t('contracts.number')}</label>
                                <Box sx={{ display: 'flex', flexDirection: 'column', width: '85%' }}>
                                    <input
                                        {...register('number', {
                                            required: t('validation.requiredField') as string,
                                            pattern: {
                                                value: /^[A-Za-z0-9 " '`.,()\/\\+\-*#№:]*$/,
                                                message: t('validation.invalidContractNumber')
                                            },
                                            maxLength: {
                                                value: 25,
                                                message: t('validation.maxLength25')
                                            },
                                        })}
                                        disabled={Boolean(createdContractId)}
                                        type="text"
                                        autoComplete="off"
                                        placeholder={t('contracts.enterContractNumber') as string}
                                        id="number"
                                        className={errors?.number ? styles.contractNumberError : styles.contractNumber}
                                    />
                                    <ErrorMessage
                                        name={'number'}
                                        errors={errors}
                                        render={({ message }) => <span>{message}</span>}
                                    />
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.7rem' }}>
                                <span className={styles.grey_label}>{t('contracts.employee')}</span>
                                <Box sx={{ display: 'flex', flexDirection: 'column', width: '85%' }}>
                                    <Controller
                                        render={({ field }) => (
                                            <Autocomplete
                                                {...field}
                                                disabled={Boolean(createdContractId)}
                                                ListboxProps={{ style: { maxHeight: '200px' } }}
                                                isOptionEqualToValue={() => true}
                                                options={employees}
                                                getOptionLabel={(option) => option.name ? option.name : ''}
                                                renderOption={(props: any, option) =>
                                                    <span {...props}
                                                          className={styles.dropDownItem}>{option.name}</span>}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        disabled={Boolean(createdContractId)}
                                                        placeholder={t('contracts.selectEmployee') as string}
                                                        sx={{
                                                            '& input::placeholder': {
                                                                fontFamily: 'Source Sans Pro',
                                                                fontSize: '1rem',
                                                                fontWeight: '400',
                                                                lineHeight: 1.5,
                                                                color: '#495057',
                                                                opacity: .8,
                                                            },
                                                            '& .MuiOutlinedInput-notchedOutline': {
                                                                borderRadius: '0',
                                                                border: errors.employee ? '1px solid #dc3545' : '1px solid #8A8886',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '&:hover fieldset': {
                                                                    border: errors?.employee ? '1px solid #dc3545' : createdContractId ? '1px solid rgba(0, 0, 0, 0.28)' : '1px solid #8A8886',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    border: errors?.employee ? '1px solid #dc3545' : '1px solid #80bdff',
                                                                    boxShadow: errors?.employee ?
                                                                        '0 0 0 0.2rem rgba(220,53,69,.25)' :
                                                                        '0 0 0 0.2rem rgba(0, 123, 255, .25)'
                                                                }
                                                            },
                                                        }}
                                                        size="small"
                                                        variant="outlined"
                                                    />
                                                )}
                                                onChange={(_, data) => field.onChange(data)}
                                                value={field.value}
                                            />
                                        )}
                                        name={'employee'}
                                        rules={{
                                            validate: (value) => {
                                                if (!value?.name && !value?.id) {
                                                    return t('validation.requiredField') as string;
                                                }
                                            }
                                        }}
                                        control={control}
                                    />
                                    <ErrorMessage
                                        name={'employee'}
                                        errors={errors}
                                        render={({ message }) => (
                                            <span className={styles.contractEmployeeErrorMsg}>{message}</span>
                                        )}
                                    />
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.7rem' }}>
                                <label htmlFor="name">{t('contracts.name')}</label>
                                <Box sx={{ display: 'flex', flexDirection: 'column', width: '85%' }}>
                                    <input
                                        {...register('name', {
                                            required: t('validation.requiredField') as string,
                                            maxLength: {
                                                value: 150,
                                                message: t('validation.maxLength150')
                                            },
                                        })}
                                        type="text"
                                        autoComplete="off"
                                        placeholder={t('contracts.enterContractName') as string}
                                        id="name"
                                        className={errors?.name ? styles.contractNameError : styles.contractName}
                                    />
                                    <ErrorMessage
                                        name={'name'}
                                        errors={errors}
                                        render={({ message }) => <span>{message}</span>}
                                    />
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.8rem' }}>
                                <label htmlFor="description">{t('contracts.description')}</label>
                                <Box sx={{ display: 'flex', flexDirection: 'column', width: '85%' }}>
                                    <textarea
                                        {...register('description', {
                                            maxLength: {
                                                value: 2048,
                                                message: t('validation.maxLength2048')
                                            },
                                        })}
                                        autoComplete="off"
                                        placeholder={t('contracts.enterContractDescription') as string}
                                        id="description"
                                        className={errors?.description ?
                                            styles.contractDescriptionError : styles.contractDescription}
                                        rows={3}
                                    />
                                    <ErrorMessage
                                        name={'description'}
                                        errors={errors}
                                        render={({ message }) => <span>{message}</span>}
                                    />
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.8rem' }}>
                                <span className={styles.grey_label}>{t('contracts.type')}</span>
                                <Box sx={{ width: '85%' }}>
                                    <Controller
                                        render={({ field }) => (
                                            <Autocomplete
                                                {...field}
                                                ListboxProps={{ style: { maxHeight: '200px' } }}
                                                isOptionEqualToValue={() => true}
                                                options={typeOfContracts}
                                                getOptionLabel={(option) => option.name ? option.name : ''}
                                                renderOption={(props: any, option) =>
                                                    <span {...props}
                                                          className={styles.dropDownItem}>{option.name}</span>}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        placeholder={t('contracts.selectContractType') as string}
                                                        sx={{
                                                            '& input::placeholder': {
                                                                fontFamily: 'Source Sans Pro',
                                                                fontSize: '1rem',
                                                                fontWeight: '400',
                                                                lineHeight: 1.5,
                                                                color: '#495057',
                                                                opacity: .8,
                                                            },
                                                            '& .MuiOutlinedInput-notchedOutline': {
                                                                borderRadius: '0',
                                                                border: errors?.contractType ? '1px solid #dc3545' : '1px solid #8A8886',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '&:hover fieldset': {
                                                                    border: errors?.contractType ? '1px solid #dc3545' : '1px solid #8A8886',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    border: errors?.contractType ? '1px solid #dc3545' : '1px solid #80bdff',
                                                                    boxShadow: errors?.contractType ?
                                                                        '0 0 0 0.2rem rgba(220,53,69,.25)' :
                                                                        '0 0 0 0.2rem rgba(0, 123, 255, .25)'
                                                                }
                                                            },
                                                        }}
                                                        size="small"
                                                        variant="outlined"
                                                    />
                                                )}
                                                onChange={(_, data) => field.onChange(data)}
                                                value={field.value}
                                            />
                                        )}
                                        name={'contractType'}
                                        control={control}
                                    />
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.8rem' }}>
                                <span
                                    style={{ color: 'grey', fontWeight: '700' }}
                                    className={errors?.conclusionDate ? styles.startDateError : styles.startDate}>
                                    {t('contracts.conclusionDate')}
                                </span>
                                <Box sx={{ display: 'flex', flexDirection: 'column', width: '85%' }}>
                                    <Controller
                                        control={control}
                                        name={'conclusionDate'}
                                        render={({ field }) => (
                                            <DesktopDatePicker
                                                {...field}
                                                maxDate={watch('endDate')}
                                                minDate={'01/01/2000'}
                                                onError={(reason, inputValue) => {
                                                    if (dayjs(inputValue).isValid() && dayjs(inputValue).isBefore('01/01/2000')) {
                                                        setError('conclusionDate', {
                                                            type: 'custom',
                                                            message: t('validation.minDateErrorMessage') as string
                                                        });
                                                    }

                                                    if (dayjs(endDate).isValid() && dayjs(inputValue).isAfter(endDate)) {
                                                        setError('conclusionDate',
                                                            {
                                                                type: 'custom',
                                                                message: t('validation.contractConclusionMaxDateError') as string
                                                            });
                                                    }

                                                    if (!dayjs(inputValue).isValid()) {
                                                        setError('conclusionDate', {
                                                            type: 'custom',
                                                            message: t('validation.invalidDate') as string
                                                        });
                                                    }

                                                    if (reason === null) {
                                                        clearErrors('conclusionDate');
                                                    }
                                                }}
                                                inputFormat="DD/MM/YYYY"
                                                PopperProps={{
                                                    placement: 'auto',
                                                }}
                                                renderInput={(params) =>
                                                    <TextField
                                                        size="small"
                                                        {...params}
                                                        sx={{
                                                            '& input::placeholder': {
                                                                fontFamily: 'Source Sans Pro',
                                                                fontSize: '1rem',
                                                                fontWeight: '400',
                                                                lineHeight: 1.5,
                                                                color: '#495057',
                                                                opacity: .8,
                                                            },
                                                            '& .MuiOutlinedInput-notchedOutline': {
                                                                borderRadius: '0',
                                                                border: errors?.conclusionDate ? '1px solid #dc3545' : '1px solid #8A8886',
                                                                transition: 'border-color .15s ease-in-out, box-shadow .15s ease-in-out',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '&:hover fieldset': {
                                                                    border: errors?.conclusionDate ? '1px solid #dc3545' : '1px solid #8A8886',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: errors?.conclusionDate ? '#dc3545' : '#80bdff',
                                                                    boxShadow: errors?.conclusionDate ? '0 0 0 0.2rem rgba(220, 53, 69, .25)' : '0 0 0 0.2rem rgba(0, 123, 255, .25)',
                                                                    borderWidth: '1px',
                                                                },
                                                            },
                                                        }}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            placeholder: t('projects.dayMonthYear') as string,
                                                            autoComplete: 'off',
                                                        }}
                                                    />}
                                            />
                                        )}
                                        rules={{
                                            required: t('validation.requiredField') as string,
                                            validate: value => {
                                                if (dayjs(value).isValid() && dayjs(value).isBefore('01/01/2000')) {
                                                    return t('validation.minDateErrorMessage') as string;
                                                }

                                                if (dayjs(endDate).isValid() && dayjs(value).isAfter(endDate)) {
                                                    return t('validation.contractConclusionMaxDateError') as string;
                                                }

                                                if (!dayjs(value).isValid()) {
                                                    return t('validation.invalidDate') as string;
                                                }
                                            }
                                        }}
                                    />
                                    <ErrorMessage
                                        name="conclusionDate"
                                        errors={errors}
                                        render={({ message }) => <span
                                            className={styles.contractConclusionDateErrorMsg}>{message}</span>}
                                    />
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.8rem' }}>
                                <span
                                    style={{ color: 'grey', fontWeight: '700' }}
                                    className={styles.startDate}>
                                    {t('contracts.endDate')}
                                </span>
                                <Box sx={{ display: 'flex', flexDirection: 'column', width: '85%' }}>
                                    <Controller
                                        control={control}
                                        name={'endDate'}
                                        render={({ field }) => (
                                            <DesktopDatePicker
                                                {...field}
                                                minDate={watch('conclusionDate')}
                                                inputFormat="DD/MM/YYYY"
                                                onError={(reason, inputValue) => {
                                                    if (dayjs(inputValue).isValid() && dayjs(inputValue).isBefore('01/01/2000')) {
                                                        setError('endDate', {
                                                            type: 'custom',
                                                            message: t('validation.minDateErrorMessage') as string
                                                        });
                                                    }

                                                    if (dayjs(inputValue).isValid() && dayjs(inputValue).isBefore(conclusionDate)) {
                                                        setError('endDate', {
                                                            type: 'custom',
                                                            message: t('validation.contractEndMinDateError') as string
                                                        });
                                                    }

                                                    if (!dayjs(inputValue).isValid()) {
                                                        setError('endDate', {
                                                            type: 'custom',
                                                            message: t('validation.invalidDate') as string
                                                        });
                                                    }

                                                    if (reason === null) {
                                                        clearErrors('endDate');
                                                    }
                                                }}
                                                PopperProps={{
                                                    placement: 'auto',
                                                }}
                                                renderInput={(params) =>
                                                    <TextField
                                                        size="small"
                                                        {...params}
                                                        sx={{
                                                            flex: 1,
                                                            '& input::placeholder': {
                                                                fontFamily: 'Source Sans Pro',
                                                                fontSize: '1rem',
                                                                fontWeight: '400',
                                                                lineHeight: 1.5,
                                                                color: '#495057',
                                                                opacity: .8,
                                                            },
                                                            '& .MuiOutlinedInput-notchedOutline': {
                                                                borderRadius: '0',
                                                                border: errors?.endDate ? '1px solid #dc3545' : '1px solid #8A8886',
                                                                transition: 'border-color .15s ease-in-out, box-shadow .15s ease-in-out',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '&:hover fieldset': {
                                                                    border: errors?.endDate ? '1px solid #dc3545' : '1px solid #8A8886',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: errors?.endDate ? '#dc3545' : '#80bdff',
                                                                    boxShadow: errors?.endDate ? '0 0 0 0.2rem rgba(220, 53, 69, .25)' : '0 0 0 0.2rem rgba(0, 123, 255, .25)',
                                                                    borderWidth: '1px',
                                                                },
                                                            },
                                                        }}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            placeholder: t('projects.dayMonthYear') as string,
                                                            autoComplete: 'off',
                                                        }}
                                                    />}
                                            />

                                        )}
                                        rules={{
                                            validate: value => {
                                                if (dayjs(value).isValid() && dayjs(value).isBefore('01/01/2000')) {
                                                    return t('validation.minDateErrorMessage') as string;
                                                }

                                                if (dayjs(value).isValid() && dayjs(value).isBefore(conclusionDate)) {
                                                    return t('validation.contractEndMinDateError') as string;
                                                }

                                                if (value && !dayjs(value).isValid()) {
                                                    return t('validation.invalidDate') as string;
                                                }
                                            }
                                        }}
                                    />
                                    <ErrorMessage
                                        name="endDate"
                                        errors={errors}
                                        render={({ message }) => <span
                                            className={styles.contractEndDateErrorMsg}>{message}</span>}
                                    />
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.8rem' }}>
                                <span className={styles.grey_label}>{t('contracts.currency')}</span>
                                <Box sx={{ width: '85%' }}>
                                    <Controller
                                        render={({ field }) => (
                                            <Autocomplete
                                                {...field}
                                                ListboxProps={{ style: { maxHeight: '200px' } }}
                                                isOptionEqualToValue={() => true}
                                                options={currencies}
                                                getOptionLabel={(option) => option.name ? option.name : ''}
                                                renderOption={(props: any, option) =>
                                                    <span {...props}
                                                          className={styles.dropDownItem}>{option.name}</span>}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        placeholder={t('contracts.selectCurrency') as string}
                                                        sx={{
                                                            '& input::placeholder': {
                                                                fontFamily: 'Source Sans Pro',
                                                                fontSize: '1rem',
                                                                fontWeight: '400',
                                                                lineHeight: 1.5,
                                                                color: '#495057',
                                                                opacity: .8,
                                                            },
                                                            '& .MuiOutlinedInput-notchedOutline': {
                                                                borderRadius: '0',
                                                                border: errors.currency ? '1px solid #dc3545' : '1px solid #8A8886',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '&:hover fieldset': {
                                                                    border: errors?.currency ?
                                                                        '1px solid #dc3545' : '1px solid #8A8886',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    border: errors?.currency ? '1px solid #dc3545' : '1px solid #80bdff',
                                                                    boxShadow: errors?.currency ?
                                                                        '0 0 0 0.2rem rgba(220,53,69,.25)' :
                                                                        '0 0 0 0.2rem rgba(0, 123, 255, .25)'
                                                                }
                                                            },
                                                        }}
                                                        size="small"
                                                        variant="outlined"
                                                    />
                                                )}
                                                onChange={(_, data) => field.onChange(data)}
                                                value={field.value}
                                            />
                                        )}
                                        name={'currency'}
                                        control={control}
                                    />
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.8rem' }}>
                                <label htmlFor="contractAmount">{t('contracts.contractAmount')}</label>
                                <Box sx={{ display: 'flex', flexDirection: 'column', width: '85%' }}>
                                    <Controller
                                        render={({ field: { name, onChange, onBlur, value, ref } }) => (
                                            <NumericFormat
                                                // getInputRef={ref}
                                                size="small"
                                                variant="outlined"
                                                sx={{
                                                    '& input::placeholder': {
                                                        fontFamily: 'Source Sans Pro',
                                                        fontSize: '1rem',
                                                        fontWeight: '400',
                                                        lineHeight: 1.5,
                                                        color: '#495057',
                                                        opacity: .8,
                                                    },
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        borderRadius: '0',
                                                        border: errors.contractAmount ? '1px solid #dc3545' : '1px solid #8A8886',
                                                    },
                                                    '& .MuiOutlinedInput-root': {
                                                        '&:hover fieldset': {
                                                            border: errors?.contractAmount ?
                                                                '1px solid #dc3545' : '1px solid #8A8886',
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            border: errors?.contractAmount ? '1px solid #dc3545' : '1px solid #80bdff',
                                                            boxShadow: errors?.contractAmount ?
                                                                '0 0 0 0.2rem rgba(220,53,69,.25)' :
                                                                '0 0 0 0.2rem rgba(0, 123, 255, .25)'
                                                        }
                                                    },
                                                }}
                                                value={value ?? ''}
                                                onBlur={onBlur}
                                                name={name}
                                                onValueChange={(values) => {
                                                    onChange(values.floatValue);
                                                    setValue('amountMonth', null);
                                                }}
                                                className={errors?.contractAmount ?
                                                    styles.contractAmountError : styles.contractAmount}
                                                thousandSeparator=" "
                                                decimalScale={2}
                                                fixedDecimalScale
                                                allowNegative={false}
                                                //TODO: сумма должна быть больше нуля
                                                isAllowed={(values) => {
                                                    const { formattedValue } = values;
                                                    return formattedValue.length <= 18;
                                                }}
                                                customInput={TextField}
                                            />
                                        )}
                                        name={'contractAmount'}
                                        control={control}
                                        rules={{
                                            required: (contractAmount || amountMonth
                                                || contractAmount === 0 || amountMonth === 0)
                                                ? false : t('validation.contractAmountOrAmountMonth') as string
                                        }}
                                    />
                                    <ErrorMessage
                                        name={'contractAmount'}
                                        errors={errors}
                                        render={({ message }) => <span>{message}</span>}
                                    />
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.7rem' }}>
                                <label htmlFor="amountMonth">{t('contracts.amountMonth')}</label>
                                <Box sx={{ display: 'flex', flexDirection: 'column', width: '85%' }}>
                                    <Controller
                                        render={({ field: { name, onChange, onBlur, value, ref } }) => (
                                            <NumericFormat
                                                // getInputRef={ref}
                                                size="small"
                                                variant="outlined"
                                                sx={{
                                                    '& input::placeholder': {
                                                        fontFamily: 'Source Sans Pro',
                                                        fontSize: '1rem',
                                                        fontWeight: '400',
                                                        lineHeight: 1.5,
                                                        color: '#495057',
                                                        opacity: .8,
                                                    },
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        borderRadius: '0',
                                                        border: errors.amountMonth ? '1px solid #dc3545' : '1px solid #8A8886',
                                                    },
                                                    '& .MuiOutlinedInput-root': {
                                                        '&:hover fieldset': {
                                                            border: errors?.amountMonth ?
                                                                '1px solid #dc3545' : '1px solid #8A8886',
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            border: errors?.amountMonth ? '1px solid #dc3545' : '1px solid #80bdff',
                                                            boxShadow: errors?.amountMonth ?
                                                                '0 0 0 0.2rem rgba(220,53,69,.25)' :
                                                                '0 0 0 0.2rem rgba(0, 123, 255, .25)'
                                                        }
                                                    },
                                                }}
                                                value={value ?? ''}
                                                onBlur={onBlur}
                                                name={name}
                                                onValueChange={(values) => {
                                                    onChange(values.floatValue);
                                                    setValue('contractAmount', null);
                                                }}
                                                className={errors?.amountMonth ?
                                                    styles.contractAmountError : styles.contractAmountMonth}
                                                thousandSeparator=" "
                                                decimalScale={2}
                                                fixedDecimalScale
                                                allowNegative={false}
                                                //TODO: сумма должна быть больше нуля
                                                isAllowed={(values) => {
                                                    const { formattedValue } = values;
                                                    return formattedValue.length <= 18;
                                                }}
                                                customInput={TextField}
                                            />
                                        )}
                                        name={'amountMonth'}
                                        control={control}
                                        rules={{
                                            required: (contractAmount || amountMonth
                                                || contractAmount === 0 || amountMonth === 0)
                                                ? false : t('validation.contractAmountOrAmountMonth') as string
                                        }}
                                    />
                                    <ErrorMessage
                                        name={'amountMonth'}
                                        errors={errors}
                                        render={({ message }) => <span>{message}</span>}
                                    />
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.7rem' }}>
                                <span className={styles.grey_label}>{t('contracts.status')}</span>
                                <Box sx={{ display: 'flex', flexDirection: 'column', width: '85%' }}>
                                    <Controller
                                        render={({ field }) => (
                                            <Autocomplete
                                                {...field}
                                                ListboxProps={{ style: { maxHeight: '200px' } }}
                                                isOptionEqualToValue={() => true}
                                                options={contractStatuses}
                                                getOptionLabel={(option) => option ? t(`statuses.${option}`) : ''}
                                                renderOption={(props: any, option) => <span {...props}
                                                                                            className={styles.dropDownItem}>{t(`statuses.${option}`)}</span>}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        placeholder={t('contracts.selectStatus') as string}
                                                        sx={{
                                                            '& input::placeholder': {
                                                                fontFamily: 'Source Sans Pro',
                                                                fontSize: '1rem',
                                                                fontWeight: '400',
                                                                lineHeight: 1.5,
                                                                color: '#495057',
                                                                opacity: .8,
                                                            },
                                                            '& .MuiOutlinedInput-notchedOutline': {
                                                                borderRadius: '0',
                                                                border: errors.status ? '1px solid #dc3545' : '1px solid #8A8886',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '&:hover fieldset': {
                                                                    border: errors?.status ?
                                                                        '1px solid #dc3545' : '1px solid #8A8886',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    border: errors?.status ? '1px solid #dc3545' : '1px solid #80bdff',
                                                                    boxShadow: errors?.status ?
                                                                        '0 0 0 0.2rem rgba(220,53,69,.25)' :
                                                                        '0 0 0 0.2rem rgba(0, 123, 255, .25)'
                                                                }
                                                            },
                                                        }}
                                                        size="small"
                                                        variant="outlined"
                                                    />
                                                )}
                                                onChange={(_, data) => field.onChange(data)}
                                                value={field.value as any}
                                            />
                                        )}
                                        name={'status'}
                                        rules={{
                                            validate: (value) => {
                                                if (!value) {
                                                    return t('validation.requiredField') as string;
                                                }
                                            }
                                        }}
                                        control={control}
                                    />
                                    <ErrorMessage
                                        name={'status'}
                                        errors={errors}
                                        render={({ message }) => (
                                            <span className={styles.contractStatusErrorMsg}>{message}</span>
                                        )}
                                    />
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.8rem' }}>
                                <label htmlFor="note">{t('contracts.note')}</label>
                                <Box sx={{ display: 'flex', flexDirection: 'column', width: '85%' }}>
                                    <textarea
                                        {...register('note', {
                                            maxLength: {
                                                value: 512,
                                                message: t('validation.maxLength512')
                                            },
                                        })}
                                        autoComplete="off"
                                        placeholder={t('contracts.enterNote') as string}
                                        id="note"
                                        className={errors?.note ? styles.contractNoteError : styles.contractNote}
                                        rows={3}
                                    />
                                    <ErrorMessage
                                        name={'note'}
                                        errors={errors}
                                        render={({ message }) => <span>{message}</span>}
                                    />
                                </Box>
                            </Box>
                        </div>
                    </form>
                </div>
            </LocalizationProvider>
        </div>
    );
};

export default ContractCreate;
