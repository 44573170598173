import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";

export const StyledSelect = styled(InputBase)(({ theme }) => ({
    "& .MuiInputBase-input": {
        border: "1px solid #8A8886",
        padding: "0.375rem 0.75rem",
        color: "#000000",
        backgroundColor: "#fff",
        fontSize: "1rem",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            color: "#495057",
            borderColor: "#80bdff",
            boxShadow: "0 0 0 0.2rem rgba(0, 123, 255, .25)",
        },
    },
}));

export const StyledSelectError = styled(InputBase)(() => ({
    "& .MuiInputBase-input": {
        border: "1px solid #8A8886",
        padding: "0.375rem 0.75rem",
        color: "#495057",
        backgroundColor: "#fff",
        fontSize: "1rem",
        marginBottom: 0,
        borderColor: "#dc3545",
        transition: "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
        "&:focus": {
            color: "#495057",
            borderColor: "#dc3545",
            boxShadow: "0 0 0 0.2rem rgba(220,53,69,.25)",
        },
    },
}));
