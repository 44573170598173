// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_text__l\\+Z80 {\n  font-optical-sizing: auto;\n  font-style: normal;\n}\n\n.styles_small__tAgul {\n  font-weight: 400;\n}\n\n.styles_regular__q6t7y {\n  font-weight: 400;\n}\n\n.styles_medium__QHy0f {\n  font-weight: 600;\n}\n\n.styles_bold__ZbIld {\n  font-weight: 700;\n}\n", "",{"version":3,"sources":["webpack://./src/ui/Text/styles.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".text {\n  font-optical-sizing: auto;\n  font-style: normal;\n}\n\n.small {\n  font-weight: 400;\n}\n\n.regular {\n  font-weight: 400;\n}\n\n.medium {\n  font-weight: 600;\n}\n\n.bold {\n  font-weight: 700;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "styles_text__l+Z80",
	"small": "styles_small__tAgul",
	"regular": "styles_regular__q6t7y",
	"medium": "styles_medium__QHy0f",
	"bold": "styles_bold__ZbIld"
};
export default ___CSS_LOADER_EXPORT___;
