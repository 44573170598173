import React, { FC, useEffect, useState } from "react";
import dayjs from "dayjs";
import { IFormData } from "models/tracking";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";

import { ErrorMessage } from "@hookform/error-message";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Box, Breadcrumbs, Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";

import { useAppDispatch, useAppSelector } from "../../../hooks";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { TRACKING_PATH } from "../../../router/constants";
import {
    createAndUpdateTracking,
    getTrackingByEmployeeId,
    getTrackingById,
} from "../../../store/thunks/trackingThunk";
import { getDaysOffByEmployee } from "../../../store/thunks/vacationsSickDaysThunk";
import {
    activateOrDeactivateBtn as addDocumentBtn,
    cancelBtn,
} from "../../../styles/MUIStyles";
import ButtonWithProgress from "../../../ui/ButtonWithProgress";
import { sendNotification } from "../../../ui/Toast";

import styles from "../Tracking.module.scss";

const TrackingManagement: FC = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const params = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isSubmitSuccessful },
    } = useForm<IFormData>({
        defaultValues: {
            id: null,
            employeeId: "",
            projectId: 0,
            year: 0,
            month: 0,
            totalTime: 0,
            overTime: 0,
        },
    });
    const currentDate = new Date();
    const activeTracking = useAppSelector(
        (state) => state.tracking.activeTracking,
    );
    const editAndViewTrackingData = useAppSelector(
        (state) => state.tracking.editAndViewTrackingData,
    );
    const { daysOff } = useAppSelector(
        (state) => state.vacationsSickDays.daysOffByEmployee,
    );
    const { role } = useAppSelector((state) => state.auth);
    const { userId, userName } = useAppSelector((state) => state.auth);
    const [isView, setIsView] = useState<boolean>(false);
    const hasTotalTimeError = errors?.totalTime;
    const hasOverTimeError = errors?.overTime;

    const [year, setYear] = useLocalStorage<number>(
        "trackingFilterYear",
        currentDate.getFullYear(),
    );
    const [monthOfTracking, setMonthOfTracking] = useLocalStorage<number>(
        "trackingFilterMonth",
        currentDate.getMonth() + 1,
    );

    // @ts-ignore
    const filteredDaysOff = daysOff?.filter((vac) => {
        const startDateMonth = dayjs(vac.startDate).month() + 1;
        return startDateMonth === monthOfTracking;
    });

    const handleChangeYear = (year: number): void => {
        if (year <= currentDate.getFullYear()) {
            setYear(year);
        }
    };

    const handleChangeMonth = (newMonth: number): void => {
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1;
        if (newMonth === 13) setMonthOfTracking(1);

        if (newMonth < 1) {
            // Если новый месяц меньше января, перейти на декабрь предыдущего года
            setMonthOfTracking(12);
            setYear(year - 1);
        } else if (newMonth > 12) {
            // Если новый месяц больше декабря, перейти на январь следующего года
            setMonthOfTracking(1);
            setYear(year + 1);
        } else if (newMonth >= currentMonth && year === currentYear) {
            setMonthOfTracking(currentMonth);
        } else {
            setMonthOfTracking(newMonth);
        }
    };

    useEffect(() => {
        if (params.employeeId) {
            // dispatch(getTrackingByEmployeeId({
            //     employeeId: params.employeeId,
            //     year: year,
            //     month: monthOfTracking
            // }));
            dispatch(getTrackingById(+params.employeeId));
        } else {
            dispatch(
                getTrackingByEmployeeId({
                    employeeId: userId,
                    year: year,
                    month: monthOfTracking,
                }),
            );
        }
    }, [dispatch, params, monthOfTracking, userId, year]);

    const onSubmit = async (data: IFormData) => {
        if (params?.operation === "view") {
            navigate(
                `/tracking/management/edit/${editAndViewTrackingData?.timekeepingId}`,
            );
            setIsView(false); // Добавляем это здесь
            // navigate(`management/edit/${props.data.projectId}`)
        }

        if (params?.operation === "edit" && editAndViewTrackingData) {
            const response = await dispatch(
                createAndUpdateTracking({
                    id: editAndViewTrackingData?.timekeepingId || null,
                    employeeId: userId,
                    projectId: editAndViewTrackingData.projectId,
                    year: year,
                    month: monthOfTracking,
                    totalTime: parseInt(data.totalTime.toString()) || 0,
                    overtime: parseInt(data.overTime.toString()) || 0,
                }),
            );

            if (response.meta.requestStatus === "fulfilled") {
                sendNotification(
                    t("notifications.successfullySaved"),
                    "success",
                );
                reset(editAndViewTrackingData);
                navigate(-1);
                setIsView(false); // Добавляем это здесь
            }
            if (response.meta.requestStatus === "rejected") {
                sendNotification(t("notifications.failedToSave"), "error");
            }
        }
    };

    useEffect(() => {
        if (
            (params?.operation === "edit" || params?.operation === "view") &&
            editAndViewTrackingData
        ) {
            if (params?.operation === "view") {
                setIsView(true);
            }
            reset(editAndViewTrackingData);
        }
    }, [params, editAndViewTrackingData, reset, activeTracking]);

    useEffect(() => {
        if (isSubmitSuccessful) {
            reset({}, { keepValues: true });
        }
    }, [dispatch, isSubmitSuccessful, reset, params]);

    useEffect(() => {
        dispatch(
            getDaysOffByEmployee({
                year: year,
                employeeId: userId || "",
                role,
            }),
        );
    }, [dispatch, userId, year]);

    return (
        <div style={{ height: "92.5vh", overflow: "auto" }}>
            <Box sx={{ width: "100%" }}>
                <Breadcrumbs sx={{ padding: "10px 10px 10px 35px" }}>
                    <Link
                        to={"/" + TRACKING_PATH}
                        className={styles.breadScrumbsLink}
                    >
                        {t("menu.Tracking")}
                    </Link>
                    <Typography
                        sx={{ color: "#201f1e", fontFamily: "Source Sans Pro" }}
                    >
                        {params?.operation === "create" && ""}
                        {(params?.operation === "edit" ||
                            params?.operation === "view") &&
                            userName}
                    </Typography>
                </Breadcrumbs>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <div className={styles.formWrapper}>
                            <React.Fragment>
                                <div className={styles.projectNameWrapper}>
                                    <Box>
                                        <Typography
                                            sx={{
                                                color: "#BEBEBE",
                                                fontWeight: 600,
                                                width: "50%",
                                                fontFamily: "Source Sans Pro",
                                                fontSize: "18px",
                                            }}
                                        >
                                            {params?.operation === "create" &&
                                                ""}
                                            {params?.operation === "edit" &&
                                                ("Трекинг" || "Трекинг")}
                                        </Typography>
                                        <h3 className={styles.projectName}>
                                            {params?.operation === "create" &&
                                                ""}
                                            {(params?.operation === "edit" ||
                                                params?.operation === "view") &&
                                                userName}
                                        </h3>
                                    </Box>

                                    <div className={styles.generalInfoTopBtns}>
                                        <ButtonWithProgress
                                            variant="contained"
                                            type="submit"
                                            loading={false}
                                            disabled={false}
                                            sx={{ ...addDocumentBtn }}
                                        >
                                            {params?.operation === "edit"
                                                ? t("buttons.Save")
                                                : t("buttons.Edit")}
                                        </ButtonWithProgress>
                                        <Button
                                            variant="text"
                                            sx={cancelBtn}
                                            onClick={() => {
                                                navigate("/tracking");
                                            }}
                                        >
                                            {t("buttons.Close")}
                                        </Button>
                                    </div>
                                </div>

                                <div className={styles.formInnerWrapper}>
                                    <div className={styles.box_year}>
                                        <ArrowLeftIcon
                                            htmlColor="gray"
                                            sx={{
                                                color: "green",
                                                fontSize: "40px",
                                            }}
                                            onClick={() =>
                                                handleChangeYear(year - 1)
                                            }
                                        />
                                        <p>{year}</p>
                                        <ArrowRightIcon
                                            htmlColor="gray"
                                            sx={{
                                                color:
                                                    year ===
                                                    currentDate.getFullYear()
                                                        ? "gray"
                                                        : "green",
                                                fontSize: "40px",
                                            }}
                                            onClick={() =>
                                                handleChangeYear(year + 1)
                                            }
                                        />
                                    </div>
                                    <div className={styles.box_year}>
                                        <ArrowLeftIcon
                                            htmlColor="gray"
                                            sx={{
                                                color: "green",
                                                fontSize: "40px",
                                            }}
                                            onClick={() =>
                                                handleChangeMonth(
                                                    monthOfTracking - 1,
                                                )
                                            }
                                        />
                                        <p>{t(`months.${monthOfTracking}`)}</p>
                                        <ArrowRightIcon
                                            htmlColor="gray"
                                            sx={{
                                                color:
                                                    monthOfTracking ===
                                                    currentDate.getMonth() + 1
                                                        ? "gray"
                                                        : "green",
                                                fontSize: "40px",
                                            }}
                                            onClick={() =>
                                                handleChangeMonth(
                                                    monthOfTracking + 1,
                                                )
                                            }
                                        />
                                    </div>

                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            margin: "1rem 0",
                                            borderBottom: "2px solid #BEBEBE",
                                        }}
                                    >
                                        {!!editAndViewTrackingData?.projectCode && (
                                            <Typography
                                                sx={{
                                                    color: "#BEBEBE",
                                                    fontWeight: 600,
                                                    fontFamily:
                                                        "Source Sans Pro",
                                                    fontSize: "18px",
                                                }}
                                            >
                                                {
                                                    editAndViewTrackingData.projectCode
                                                }
                                            </Typography>
                                        )}
                                        {!!editAndViewTrackingData?.shortName && (
                                            <Typography
                                                sx={{
                                                    color: "#BEBEBE",
                                                    fontWeight: 600,
                                                    fontFamily:
                                                        "Source Sans Pro",
                                                    fontSize: "18px",
                                                    marginRight: "auto",
                                                    marginLeft: "12px",
                                                }}
                                            >
                                                {
                                                    editAndViewTrackingData.shortName
                                                }
                                            </Typography>
                                        )}
                                    </Box>
                                    <Grid
                                        container
                                        spacing={2}
                                        alignItems="center"
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            sm={4}
                                            sx={{ textAlign: "right" }}
                                        >
                                            <label htmlFor="totalTime">
                                                <b>Всего часов</b>
                                            </label>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={3}
                                            sx={{ textAlign: "center" }}
                                        >
                                            <input
                                                {...register("totalTime", {
                                                    required: t(
                                                        "validation.requiredField",
                                                    ) as string,
                                                })}
                                                disabled={isView}
                                                style={{ textAlign: "center" }}
                                                id="totalTime"
                                                type="number"
                                                defaultValue={0}
                                                autoComplete="off"
                                                className={
                                                    hasTotalTimeError
                                                        ? styles.projectCodeInputErrorError
                                                        : styles.projectCodeInput
                                                }
                                            />
                                            <ErrorMessage
                                                name={"totalTime"}
                                                errors={errors}
                                                render={({ message }) => (
                                                    <span>{message}</span>
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={5}></Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        spacing={2}
                                        alignItems="center"
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            sm={4}
                                            sx={{ textAlign: "right" }}
                                        >
                                            <label htmlFor="overTime">
                                                <b>Из них сверхурочно</b>
                                            </label>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={3}
                                            sx={{ textAlign: "center" }}
                                        >
                                            <input
                                                {...register("overTime", {
                                                    required: t(
                                                        "validation.requiredField",
                                                    ) as string,
                                                })}
                                                disabled={isView}
                                                style={{ textAlign: "center" }}
                                                id="overTime"
                                                type="number"
                                                defaultValue={0}
                                                autoComplete="off"
                                                className={
                                                    hasOverTimeError
                                                        ? styles.projectCodeInputErrorError
                                                        : styles.projectCodeInput
                                                }
                                            />
                                            <ErrorMessage
                                                name={"overTime"}
                                                errors={errors}
                                                render={({ message }) => (
                                                    <span>{message}</span>
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={5}></Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        spacing={2}
                                        alignItems="center"
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            sm={4}
                                            sx={{
                                                textAlign: "right",
                                                paddingRight: "1rem",
                                            }}
                                        >
                                            <b>Всего дней отпуска</b>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={3}
                                            sx={{ textAlign: "center" }}
                                        >
                                            <p>
                                                {filteredDaysOff &&
                                                filteredDaysOff.length > 0
                                                    ? filteredDaysOff.map(
                                                          (
                                                              partialOffDay: any,
                                                              index: number,
                                                          ) => {
                                                              if (
                                                                  partialOffDay.sign ===
                                                                  1
                                                              ) {
                                                                  return (
                                                                      <span
                                                                          key={
                                                                              index
                                                                          }
                                                                      >
                                                                          {partialOffDay.numberOfDaysOff ||
                                                                              0}
                                                                      </span>
                                                                  );
                                                              }
                                                              return null;
                                                          },
                                                      )
                                                    : 0}
                                            </p>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={5}
                                            sx={{
                                                textAlign: "left",
                                                paddingLeft: "1rem",
                                            }}
                                        >
                                            <p>
                                                {filteredDaysOff?.map(
                                                    (
                                                        partialOffDay: any,
                                                        index: number,
                                                    ) => {
                                                        if (
                                                            partialOffDay.sign ===
                                                            1
                                                        ) {
                                                            return (
                                                                <span
                                                                    key={index}
                                                                >
                                                                    {dayjs(
                                                                        partialOffDay.startDate,
                                                                    ).format(
                                                                        "DD.MM.YYYY",
                                                                    )}{" "}
                                                                    -
                                                                    {dayjs(
                                                                        partialOffDay.finishDate,
                                                                    ).format(
                                                                        "DD.MM.YYYY",
                                                                    )}
                                                                </span>
                                                            );
                                                        }
                                                        return null;
                                                    },
                                                )}
                                            </p>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        spacing={2}
                                        alignItems="center"
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            sm={4}
                                            sx={{
                                                textAlign: "right",
                                                paddingRight: "1rem",
                                            }}
                                        >
                                            <b>Всего больничных</b>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={3}
                                            sx={{ textAlign: "center" }}
                                        >
                                            <p>
                                                {filteredDaysOff &&
                                                filteredDaysOff.length > 0
                                                    ? filteredDaysOff.map(
                                                          (
                                                              partialOffDay: any,
                                                              index: number,
                                                          ) => {
                                                              if (
                                                                  partialOffDay.sign ===
                                                                  2
                                                              ) {
                                                                  return (
                                                                      <span
                                                                          key={
                                                                              index
                                                                          }
                                                                      >
                                                                          {partialOffDay.numberOfDaysOff ||
                                                                              0}
                                                                      </span>
                                                                  );
                                                              }
                                                              return null;
                                                          },
                                                      )
                                                    : 0}
                                            </p>
                                        </Grid>
                                        <Grid item xs={12} sm={5}>
                                            <p>
                                                {filteredDaysOff?.map(
                                                    (
                                                        partialOffDay: any,
                                                        index: number,
                                                    ) => {
                                                        if (
                                                            partialOffDay.sign ===
                                                            2
                                                        ) {
                                                            return (
                                                                <span
                                                                    key={index}
                                                                >
                                                                    {dayjs(
                                                                        partialOffDay.startDate,
                                                                    ).format(
                                                                        "DD.MM.YYYY",
                                                                    )}{" "}
                                                                    -
                                                                    {dayjs(
                                                                        partialOffDay.finishDate,
                                                                    ).format(
                                                                        "DD.MM.YYYY",
                                                                    )}
                                                                </span>
                                                            );
                                                        }
                                                        return null;
                                                    },
                                                )}
                                            </p>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        spacing={2}
                                        alignItems="center"
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            sm={4}
                                            sx={{
                                                textAlign: "right",
                                                paddingRight: "1rem",
                                            }}
                                        >
                                            <b>Всего отгулов</b>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={3}
                                            sx={{ textAlign: "center" }}
                                        >
                                            <p>
                                                {filteredDaysOff &&
                                                filteredDaysOff.length > 0
                                                    ? filteredDaysOff.map(
                                                          (
                                                              partialOffDay: any,
                                                              index: number,
                                                          ) => {
                                                              if (
                                                                  partialOffDay.sign ===
                                                                  3
                                                              ) {
                                                                  return (
                                                                      <span
                                                                          key={
                                                                              index
                                                                          }
                                                                      >
                                                                          {partialOffDay.numberOfDaysOff ||
                                                                              0}
                                                                      </span>
                                                                  );
                                                              }
                                                              return null;
                                                          },
                                                      )
                                                    : 0}
                                            </p>
                                        </Grid>
                                        <Grid item xs={12} sm={5}>
                                            <p>
                                                {filteredDaysOff?.map(
                                                    (
                                                        partialOffDay: any,
                                                        index: number,
                                                    ) => {
                                                        if (
                                                            partialOffDay.sign ===
                                                            3
                                                        ) {
                                                            return (
                                                                <span
                                                                    key={index}
                                                                >
                                                                    {dayjs(
                                                                        partialOffDay.startDate,
                                                                    ).format(
                                                                        "DD.MM.YYYY",
                                                                    )}{" "}
                                                                    -
                                                                    {dayjs(
                                                                        partialOffDay.finishDate,
                                                                    ).format(
                                                                        "DD.MM.YYYY",
                                                                    )}
                                                                </span>
                                                            );
                                                        }
                                                        return null;
                                                    },
                                                ) || 0}
                                            </p>
                                        </Grid>
                                    </Grid>
                                </div>
                            </React.Fragment>
                        </div>
                    </div>
                </form>
            </Box>
        </div>
    );
};

export default TrackingManagement;
