import { useEffect, useState } from 'react';
import { useAppDispatch } from 'hooks';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import routes from 'router/routes';
import { getAllowedMenus, getUserInfo } from 'store/thunks/authThunk';
import Loader from 'ui/Loader';

function App() {
    const dispatch = useAppDispatch();
    const router = createBrowserRouter(routes);

    const [isInitialized, setIsInitialized] = useState(false);

    const getData = async () => {
        if (localStorage.getItem('accessToken')) {
            await dispatch(getUserInfo());
            await dispatch(getAllowedMenus());
        }
        setIsInitialized(true);
    };

    useEffect(() => {
        getData();
    }, []);

    if (!isInitialized) {
        return <Loader/>;
    }

    return <RouterProvider router={router}/>;
}

export default App;
