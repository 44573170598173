export function pluralize(count: number, words: [string, string, string]): string {
    const cases = [2, 0, 1, 1, 1, 2];
    return words[
        count % 100 > 4 && count % 100 < 20 ? 2 : cases[count % 10 < 5 ? count % 10 : 5]
        ];
}

export const getFromLocalStorage = <T>(key: string): T | null => {
    try {
        const item = localStorage.getItem(key);
        return item ? (JSON.parse(item) as T) : null;
    } catch (error) {
        return null;
    }
};

export const permissionCheck = (action: string, allowedPermissions: any[]) => {
    //временно
    if (!allowedPermissions) {
        return true;
    }

    return !!allowedPermissions.filter(e => e.name === action).length;
};

export const getCalculatedFiltersCount = (filters: Record<string, any>) => {
    return filters && Object.values(filters).filter(filter => {
        if (Array.isArray(filter)) {
            return [...filter];
        }

        return filter;
    }).flat().length;
};

export const formatNumberWithSpaces = (number: number) => {
    return number.toLocaleString('ru-RU', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).replace(',', '.');
};


