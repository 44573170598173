import React, { FC } from 'react';

import { parseDate } from '../../../../helpers/parseDate';
import { ETextVariant, Text } from '../../../../ui/Text/Text';

import styles from '../styles.module.css';

interface LogsProps {
    logs: {
        stageName: string | number;
        startTimeUtc: Date;
        error?: any;
    }[];
}

export const Logs: FC<LogsProps> = ({ logs }) => {
    return (
        <>
            {logs.map((log, index) => (
                <div style={{ display: 'flex', justifyContent: 'space-around', margin: '8px 0' }}
                     key={`${log.stageName}_${index}`}>
                    <Text className={styles.firstLogItem} variant={ETextVariant.REGULAR}>
                        {parseDate(log.startTimeUtc)}
                    </Text>
                    <Text className={styles.secondLogItem}
                          variant={ETextVariant.REGULAR}>
                        {/*@ts-ignore*/}
                        {/*{t(`menu.${log.stageName.charAt(0).toLowerCase() + log.stageName.slice(1)}`)}*/}
                        {log.stageName}
                    </Text>
                    {log.error ? (
                        <Text className={styles.thirdLogItem} variant={ETextVariant.REGULAR}>
                            {log.error}
                        </Text>
                    ) : <div style={{
                        width: 200
                    }}></div>}
                </div>
            ))}
        </>
    );
};
