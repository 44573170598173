import React, { FC, useEffect } from "react";
import { getFromLocalStorage } from "core/utils";
import {
    useAppDispatch,
    useAppSelector,
    useDebounce,
    useLocalStorage,
    useUpdateEffect,
} from "hooks";
import { IProjectsFilters } from "models/projects";
import { ISortConfig, IUsersPermissionsFilters } from "models/usersPermissions";
import { getLookupByName } from "store";
import { getAllUsers } from "store/thunks/usersPermissionsThunk";

import SearchIcon from "@mui/icons-material/Search";
import { Autocomplete } from "@mui/material";
import TextField from "@mui/material/TextField";

import styles from "./UsersPermissions.module.scss";

interface Props {
    usersPermissionsPageNumber: number;
    setUsersPermissionsPageNumber: (pageNumber: number) => void;
}

const UsersPermissionsFilters: FC<Props> = (props) => {
    const dispatch = useAppDispatch();

    const roles = useAppSelector((state) => state.lookups.roles);
    const countries = useAppSelector((state) => state.lookups.countries);

    const [usersPermissionsFilters, setUsersPermissionsFilter] =
        useLocalStorage<IUsersPermissionsFilters>("usersPermissionsFilters", {
            role: null,
            country: null,
        });

    const [userName, setUserName] = useLocalStorage(
        "usersPermissionsSearchByName",
        "",
    );
    const debouncedUserName = useDebounce(userName);
    const [userEmail, setUserEmail] = useLocalStorage(
        "usersPermissionsByEmail",
        "",
    );
    const debouncedUserEmail = useDebounce(userEmail);

    useEffect(() => {
        dispatch(getLookupByName("roles"));
        dispatch(getLookupByName("countries"));
    }, []);

    useUpdateEffect(() => {
        if (props.usersPermissionsPageNumber > 1) {
            props.setUsersPermissionsPageNumber(1);
        } else {
            const pageSize = getFromLocalStorage<number>(
                "usersPermissionsPageSize",
            );
            const sortConfig = getFromLocalStorage<ISortConfig>(
                "usersPermissionsTableSortConfig",
            );
            dispatch(
                getAllUsers({
                    sortBy: sortConfig,
                    pageSize,
                    userName: debouncedUserName,
                    email: debouncedUserEmail,
                    pageNumber: 1,
                    ...usersPermissionsFilters,
                }),
            );
        }
    }, [usersPermissionsFilters, debouncedUserName, debouncedUserEmail]);

    return (
        <div className={styles.usersPermissionsFiltersWrapper}>
            <div className={styles.searchWrapper}>
                <input
                    name="search"
                    type="text"
                    autoComplete="off"
                    value={userEmail}
                    onChange={(e) => setUserEmail(e.target.value)}
                    placeholder={"Поиск по почте"}
                    className={styles.search}
                />
                <button type="button" className={styles.searchIconWrapper}>
                    <SearchIcon sx={{ color: "#fff" }} />
                </button>
            </div>
            <div className={styles.searchWrapper}>
                <input
                    name="search"
                    type="text"
                    autoComplete="off"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    placeholder={"Поиск по имени"}
                    className={styles.search}
                />
                <button type="button" className={styles.searchIconWrapper}>
                    <SearchIcon sx={{ color: "#fff" }} />
                </button>
            </div>
            <Autocomplete
                ListboxProps={{ style: { maxHeight: "250px" } }}
                value={usersPermissionsFilters.role}
                onChange={(_, newValue) => {
                    if (newValue) {
                        setUsersPermissionsFilter(
                            (prevState: IUsersPermissionsFilters) => ({
                                ...prevState,
                                role: newValue,
                            }),
                        );
                    } else {
                        setUsersPermissionsFilter(
                            (prevState: IUsersPermissionsFilters) => ({
                                ...prevState,
                                role: null,
                            }),
                        );
                    }
                }}
                isOptionEqualToValue={(option, value) =>
                    value === "" ? true : option === value
                }
                options={roles}
                getOptionLabel={(option) => (option ? option : "")}
                renderOption={(props: any, option) => {
                    return (
                        <span {...props} className={styles.dropDownItem}>
                            {option}
                        </span>
                    );
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        sx={{
                            "& .MuiOutlinedInput-notchedOutline": {
                                borderRadius: "0",
                                border: "1px solid #8A8886",
                            },
                            "& .MuiOutlinedInput-root": {
                                "&:hover fieldset": {
                                    border: "1px solid #8A8886",
                                },
                                "&.Mui-focused fieldset": {
                                    borderColor: "#80bdff",
                                    boxShadow:
                                        "0 0 0 0.2rem rgba(0, 123, 255, .25)",
                                },
                            },
                            padding: "5px 0",
                        }}
                        size="small"
                        variant="outlined"
                        placeholder={"Выбрать роль"}
                    />
                )}
            />
            <Autocomplete
                ListboxProps={{ style: { maxHeight: "250px" } }}
                value={usersPermissionsFilters.country}
                onChange={(event: any, newValue) => {
                    if (newValue) {
                        setUsersPermissionsFilter(
                            (prevState: IProjectsFilters) => ({
                                ...prevState,
                                country: newValue,
                            }),
                        );
                    } else {
                        setUsersPermissionsFilter(
                            (prevState: IProjectsFilters) => ({
                                ...prevState,
                                country: null,
                            }),
                        );
                    }
                }}
                isOptionEqualToValue={(option, value) =>
                    value.name === "" ? true : option.id === value.id
                }
                options={countries}
                getOptionLabel={(option) => (option.name ? option.name : "")}
                renderOption={(props: any, option) => {
                    return (
                        <span {...props} className={styles.dropDownItem}>
                            {option.name}
                        </span>
                    );
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        sx={{
                            "& .MuiOutlinedInput-notchedOutline": {
                                borderRadius: "0",
                                border: "1px solid #8A8886",
                            },
                            "& .MuiOutlinedInput-root": {
                                "&:hover fieldset": {
                                    border: "1px solid #8A8886",
                                },
                                "&.Mui-focused fieldset": {
                                    borderColor: "#80bdff",
                                    boxShadow:
                                        "0 0 0 0.2rem rgba(0, 123, 255, .25)",
                                },
                            },
                            padding: "5px 0",
                        }}
                        size="small"
                        variant="outlined"
                        placeholder={"Выбрать страну"}
                    />
                )}
            />
        </div>
    );
};

export default UsersPermissionsFilters;
