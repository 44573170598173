import { useState } from "react";
import axiosApi from "core/request";

const useDocumentDownloader = () => {
    const [isLoading, setIsLoading] = useState<number[]>([]);

    const downloadDocument = async (documentId: number, endpoint: string) => {
        if (documentId) {
            setIsLoading((prevLoading) => [...prevLoading, documentId]);
            try {
                const response = await axiosApi.get(
                    `${endpoint}/attachmentdocument/download?fileId=${documentId}`,
                    { responseType: "blob" },
                );
                const fileNameWithExtension = response.headers[
                    "content-disposition"
                ]
                    .split("filename=")[1]
                    .split(";")[0]
                    .replace(/"/g, "");
                const href = URL.createObjectURL(response.data);
                const link = document.createElement("a");
                link.href = href;
                link.setAttribute("download", decodeURI(fileNameWithExtension));
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                setIsLoading((prevLoading) =>
                    prevLoading.filter((id) => id !== documentId),
                );
            } catch (error) {
                setIsLoading((prevLoading) =>
                    prevLoading.filter((id) => id !== documentId),
                );
            }
        }
    };

    return { isLoading, downloadDocument };
};

export default useDocumentDownloader;
