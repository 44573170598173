import axiosApi from 'core/request';

import { createAsyncThunk } from '@reduxjs/toolkit';

const BASE_URL = '/import';

export const importStart = createAsyncThunk('import/start',
    async (_, { rejectWithValue }) => {
        try {
            const res = axiosApi.post(`${BASE_URL}/start`);
            return res;

        } catch (e) {
            return rejectWithValue('Error');
        }
    }
);

export const getLogs = createAsyncThunk('import/getLogs',
    async (_, { rejectWithValue }) => {
        try {
            const res = axiosApi.get(`${BASE_URL}/logs`);
            return res;

        } catch (e) {
            return rejectWithValue('Error');
        }
    }
);

export const importIsStarted = createAsyncThunk('import/is-started',
    async (_, { rejectWithValue }) => {
        try {
            const res = axiosApi.get(`${BASE_URL}/is-started`);
            return res;

        } catch (e) {
            return rejectWithValue('Error');
        }
    }
);

export const importGetLast = createAsyncThunk('import/get-last',
    async (_, { rejectWithValue }) => {
        try {
            const res = axiosApi.get(`${BASE_URL}/sessions/last`);
            return res;

        } catch (e) {
            return rejectWithValue('Error');
        }
    }
);
