import { FC, useEffect, useMemo, useState, } from 'react';
import { AscSortIcon, CreateIcon, DefaultSortIcon, DescSortIcon, EditIcon, } from 'assets/icons';
import Guard from 'features/Guard';
import { useAppDispatch, useAppSelector } from 'hooks';
import { ICountry } from 'models/countries';
import { useTranslation } from 'react-i18next';
import { activateDataByCatalogName, deactivateDataByCatalogName, getLookupByName, } from 'store';
import { createOrEditBtn } from 'styles/MUIStyles';

import Button from '@mui/material/Button';

import ConfirmationModal from '../../../features/ConfirmationModal';
import ListItemActions from '../../../features/ListItemActions';

import { CountryForm } from './CountryForm';

import styles from '../CatalogsLayout.module.scss';

const Countries: FC<{
    name: string;
    createOrUpdate: null | string;
    setCreateOrUpdate: Function;
    fulfilledStatusHandler: Function;
}> = ({
          name,
          createOrUpdate,
          setCreateOrUpdate,
          fulfilledStatusHandler,
      }): JSX.Element => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const currentLanguage = localStorage.getItem('i18nextLng');

    const countries = useAppSelector((state) => state.catalogs.countries);
    const allowedPermissions = useAppSelector(
        (state) => state.auth.allowedPermissions,
    );

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [activeCountry, setActiveCountry] = useState<null | ICountry>(null);

    const [confirmationModalOpen, setConfirmationModalOpen] = useState<{
        isOpen: boolean;
        actionName: string;
    }>({ isOpen: false, actionName: '' });
    const [sortConfig, setSortConfig] = useState<{
        column: null | string;
        direction: null | string;
    }>({ column: null, direction: null });
    const [searchCountry, setSearchCountry] = useState('');

    const activateItem = async () => {
        if (activeCountry?.id) {
            const response = await dispatch(
                activateDataByCatalogName({
                    catalogName: name,
                    catalogNameId: activeCountry?.id,
                }),
            );
            if (response.meta.requestStatus === 'fulfilled') {
                fulfilledStatusHandler('notifications.successfullyActivated');
                setConfirmationModalOpen({ isOpen: false, actionName: '' });
            }
            return;
        }
    };

    const onCreateItem = () => {
        setIsDrawerOpen(true);
        setCreateOrUpdate('create');
        setActiveCountry(null);
    };

    const onEditItem = () => {
        setIsDrawerOpen(true);
        setCreateOrUpdate('update');
    };

    const deactivateItem = async () => {
        if (activeCountry?.id) {
            const response = await dispatch(
                deactivateDataByCatalogName({
                    catalogName: name,
                    catalogNameId: activeCountry?.id,
                }),
            );

            if (
                response.meta.requestStatus === 'fulfilled' &&
                response.payload
            ) {
                fulfilledStatusHandler(
                    t('notifications.recordInUseNotice'),
                    'warn',
                );
                setConfirmationModalOpen({ isOpen: false, actionName: '' });
                return;
            }

            if (response.meta.requestStatus === 'fulfilled') {
                fulfilledStatusHandler('notifications.successfullyDeactivated');
                setConfirmationModalOpen({ isOpen: false, actionName: '' });
            }
        }
    };

    const confirmActionHandler = () => {
        switch (confirmationModalOpen.actionName) {
            case 'Activate':
                activateItem();
                break;
            case 'Deactivate':
                deactivateItem();
                break;
        }
    };
    const getArrowForSortDirection = (column: string) => {
        if (sortConfig.column !== column) {
            return <DefaultSortIcon/>;
        }

        return sortConfig.direction === 'asc' ? (
            <AscSortIcon/>
        ) : (
            <DescSortIcon/>
        );
    };

    const handleSort = (column: string) => {
        if (sortConfig.column === column) {
            setSortConfig({
                column,
                direction: sortConfig.direction === 'asc' ? 'desc' : 'asc',
            });
            return;
        }

        setSortConfig({ column, direction: 'asc' });
    };

    const sortedCountries = useMemo(() => {
        const copyCountries = [...countries];

        if (sortConfig.column) {
            copyCountries.sort((a: ICountry, b: ICountry) => {
                const isAscending = sortConfig.direction === 'asc';
                const compareResult =
                    sortConfig.column &&
                    a[sortConfig.column].localeCompare(
                        b[sortConfig.column],
                        currentLanguage === 'en-US' ? 'en' : 'ru',
                        {
                            sensitivity: 'accent',
                        },
                    );

                return isAscending ? compareResult : -compareResult;
            });
        }

        return copyCountries;
    }, [countries, sortConfig, currentLanguage]);

    useEffect(() => {
        setActiveCountry(null);
        dispatch(getLookupByName('regions'));
    }, [dispatch, name]);

    return (
        <div>
            <div className={styles.crudButtonWrapper}>
                <Guard
                    allowedPermissions={allowedPermissions}
                    permissionName="Create"
                >
                    <Button
                        startIcon={<CreateIcon/>}
                        variant="text"
                        disabled={false}
                        onClick={() => onCreateItem()}
                        sx={createOrEditBtn}
                    >
                        {t('buttons.Create')}
                    </Button>
                </Guard>
                <Guard
                    allowedPermissions={allowedPermissions}
                    permissionName="Edit"
                >
                    <Button
                        startIcon={<EditIcon/>}
                        variant="text"
                        disabled={!activeCountry?.isActive}
                        onClick={() => onEditItem()}
                        sx={createOrEditBtn}
                    >
                        {t('buttons.Edit')}
                    </Button>
                </Guard>
            </div>

            <div className={styles.searchWrapper}>
                <input
                    type="text"
                    placeholder={t('filters.searchByCountryName') as string}
                    className={styles.searchCountryInput}
                    value={searchCountry}
                    onChange={(e) => setSearchCountry(e.target.value)}
                />
            </div>
            <div className={styles.tableHeader}>
                <span
                    className={styles.column}
                    onClick={() => handleSort('code')}
                >
                    {t('data.abbreviation')} {getArrowForSortDirection('code')}
                </span>
                <span
                    onClick={() => handleSort('name')}
                    className={styles.column}
                >
                    {t('data.name')} {getArrowForSortDirection('name')}
                </span>
                <span
                    onClick={() => handleSort('regionName')}
                    className={styles.column}
                >
                    {t('data.region')} {getArrowForSortDirection('regionName')}
                </span>
                <span className={styles.column}>{t('data.actions')}</span>
            </div>
            <ul className={styles.catalogNameDataList}>
                {sortedCountries
                    .filter((country) =>
                        country.name
                            .toLowerCase()
                            .includes(searchCountry.toLowerCase()),
                    )
                    .map((item) => (
                        <li
                            key={item.id}
                            className={
                                activeCountry?.id === item.id
                                    ? `${styles.catalogNameDataListItem} ${styles.catalogNameDataListItemActive}`
                                    : `${styles.catalogNameDataListItem}`
                            }
                            onClick={() =>
                                setActiveCountry({
                                    id: item.id,
                                    name: item.name,
                                    code: item.code,
                                    isActive: item.isActive,
                                    regionId: item.regionId,
                                    regionName: item.regionName,
                                })
                            }
                        >
                            <span>{item.code}</span>
                            <span>{item.name}</span>
                            <span>
                                {
                                    countries.find(
                                        (country) =>
                                            country.regionId === item.regionId,
                                    )?.regionName
                                }
                            </span>
                            <span>
                                <ListItemActions
                                    itemId={item.id}
                                    isItemActive={item.isActive}
                                    activationAvailable={true}
                                    setConfirmationModalOpen={
                                        setConfirmationModalOpen
                                    }
                                    editItemClick={onEditItem}
                                />
                            </span>
                        </li>
                    ))}
            </ul>

            <CountryForm
                name={name}
                activeCountry={activeCountry}
                setActiveCountry={setActiveCountry}
                createOrUpdate={createOrUpdate}
                setCreateOrUpdate={setCreateOrUpdate}
                isDrawerOpen={isDrawerOpen}
                setIsDrawerOpen={setIsDrawerOpen}
                fulfilledStatusHandler={fulfilledStatusHandler}
            />

            <ConfirmationModal
                isModalOpen={confirmationModalOpen.isOpen}
                setIsModalOpen={setConfirmationModalOpen}
                actionName={confirmationModalOpen.actionName}
                instanceName={name}
                handleAction={confirmActionHandler}
            />
        </div>
    );
};

export default Countries;
