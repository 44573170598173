import React, { FC, ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useReadLocalStorage } from 'usehooks-ts';

import { Breadcrumbs, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { useAppDispatch, useAppSelector, useDebounce, useLocalStorage, useSortColumn, } from '../../../hooks';
import { IProjectEmployeesFilters } from '../../../models/projects';
import { PROJECTS_PATH } from '../../../router/constants';
import {
    changeActiveTabInEditProject,
    getAllowedGroupsByMenuId,
    getAllowedPermissionsByGroupId,
} from '../../../store';
import { getEmployeesByProjectId, getProjectByIdToView, } from '../../../store/thunks/projectsThunk';

import Documents from './Documents/Documents';
import Employees from './Employees/Employees';
import GeneralInfo from './GeneralInfo/GeneralInfo';

import styles from 'views/Projects/Projects.module.scss';

interface TabPanelProps {
    children?: ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

const ProjectsView: FC = (): JSX.Element => {
    const params = useParams();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const activeTab = useAppSelector((state) => state.projects.activeTab);
    const projectDataToView = useAppSelector(
        (state) => state.projects.projectDataToView
    );

    const [search, setSearch] = useLocalStorage(
        'MRTProjectsViewEmployeeSearch',
        ''
    );
    const debouncedSearch = useDebounce(search, 1000);
    const { sortConfig, handleColumnClick } = useSortColumn(
        'MRTProjectViewTableSort'
    );

    const handleActiveTab = (event: React.SyntheticEvent, newValue: number) => {
        dispatch(changeActiveTabInEditProject(newValue));
    };

    const projectEmployeesFilter =
        useReadLocalStorage<IProjectEmployeesFilters>('projectEmployeesFilter');

    useEffect(() => {
        // const projectEmployeesFilter = getFromLocalStorage('projectEmployeesFilter');
        if (params?.projectId) {
            dispatch(
                getEmployeesByProjectId({
                    projectId: +params.projectId,
                    fullName: debouncedSearch,
                    position: projectEmployeesFilter?.position,
                    organization: projectEmployeesFilter?.organization,
                    skill: projectEmployeesFilter?.skill,
                    startFTE: projectEmployeesFilter?.startFTE,
                    endFTE: projectEmployeesFilter?.endFTE,
                    fromStartDate: projectEmployeesFilter?.fromStartDate,
                    beforeStartDate: projectEmployeesFilter?.beforeStartDate,
                    fromEndDate: projectEmployeesFilter?.fromEndDate,
                    beforeEndDate: projectEmployeesFilter?.beforeEndDate,
                    employeeStatus: projectEmployeesFilter?.employeeStatus,
                    sortBy: sortConfig,
                    pageSize: 100
                })
            );
        }
    }, [dispatch, params, t, debouncedSearch, sortConfig]);

    const allowedGroups = useAppSelector((state) => state.auth.allowedGroups);
    const allowedPermissions = useAppSelector(
        (state) => state.auth.allowedPermissions
    );
    const allowedMenus = useAppSelector((state) => state.auth.allowedMenus);

    useEffect(() => {
        const menu = allowedMenus.find((menu) => menu.name === 'Projects');
        typeof menu?.id === 'number' &&
        dispatch(getAllowedGroupsByMenuId(menu.id));
    }, [allowedMenus, dispatch]);

    useEffect(() => {
        const group = allowedGroups.find(
            (menu) => menu.name === `api/projects`
        );
        group?.id && dispatch(getAllowedPermissionsByGroupId(group.id));
    }, [dispatch, allowedGroups]);

    useEffect(() => {
        if (params?.projectId) {
            dispatch(getProjectByIdToView(+params.projectId));
        }
    }, [dispatch, params, t]);

    return (
        <div style={{ height: '92.5vh', overflow: 'auto' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box sx={{ width: '100%' }}>
                    <Breadcrumbs
                        sx={{
                            padding: '10px 10px 10px 35px',
                            fontFamily: 'Source Sans Pro, sans-serif',
                        }}
                    >
                        <Link
                            to={'/' + PROJECTS_PATH}
                            className={styles.breadScrumbsLink}
                        >
                            {t('projects.projects')}
                        </Link>
                        <Typography
                            sx={{
                                color: '#201f1e',
                                fontFamily: 'Source Sans Pro',
                            }}
                        >
                            {projectDataToView?.shortName ||
                                projectDataToView?.fullName}
                        </Typography>
                    </Breadcrumbs>

                    <Box
                        sx={{
                            borderBottom: 1,
                            borderColor: 'divider',
                            paddingLeft: '20px',
                        }}
                    >
                        <Box sx={{ width: '40%' }}>
                            <Tabs value={activeTab} onChange={handleActiveTab}>
                                <Tab
                                    sx={{
                                        textTransform: 'none',
                                        fontSize: '14px',
                                        fontFamily: 'Source Sans Pro',
                                        color: '#201f1e',
                                    }}
                                    label={t('tabs.generalInfo')}
                                />
                                <Tab
                                    sx={{
                                        textTransform: 'none',
                                        fontSize: '14px',
                                        fontFamily: 'Source Sans Pro',
                                        color: '#201f1e',
                                    }}
                                    label={t('tabs.employees')}
                                />
                                <Tab
                                    sx={{
                                        textTransform: 'none',
                                        fontSize: '14px',
                                        fontFamily: 'Source Sans Pro',
                                        color: '#201f1e',
                                    }}
                                    label={t('tabs.docs')}
                                />
                                <Tab
                                    sx={{
                                        textTransform: 'none',
                                        fontSize: '14px',
                                        fontFamily: 'Source Sans Pro',
                                        color: '#201f1e',
                                    }}
                                    label={t('tabs.history')}
                                />
                            </Tabs>
                        </Box>
                    </Box>

                    <TabPanel index={0} value={activeTab}>
                        <GeneralInfo
                            allowedPermissions={allowedPermissions}
                            activeTab={activeTab}
                        />
                    </TabPanel>
                    <TabPanel index={1} value={activeTab}>
                        <Employees
                            activeTab={activeTab}
                            sortConfig={sortConfig}
                            handleColumnClick={handleColumnClick}
                            search={debouncedSearch}
                            setSearch={setSearch}
                        />
                    </TabPanel>
                    <TabPanel index={2} value={activeTab}>
                        <Documents
                            allowedPermissions={allowedPermissions}
                            activeTab={activeTab}
                        />
                    </TabPanel>
                </Box>
            </LocalizationProvider>
        </div>
    );
};

export default ProjectsView;
