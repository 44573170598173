import { IContract, IContractDetailsWithAdditions } from "models/contracts";

import { createSlice } from "@reduxjs/toolkit";

import {
    addAdditionToContract,
    createContract,
    editContract,
    editContractAddition,
    getContractById,
    getContractsDetailsToView,
    getContractsEmployees,
    getMainContractById,
} from "../thunks/contractsEmployees";

interface ContractsSlice {
    contractEmployees: IContract[];
    pageCount: number | null;
    isGettingContractsEmployeesLoading: boolean;
    activeContractEmployee: IContract | null;
    contractsDetailsWithAdditions: IContractDetailsWithAdditions[];
    isGettingContractsDetailsWithAdditionLoading: boolean;
    activeContractsDetailsWithAdditions: IContractDetailsWithAdditions | null;
    editAndViewContractData: IContractDetailsWithAdditions | null;
    mainContractData: IContractDetailsWithAdditions | null;
    isGettingEditOrViewContractDataLoading: boolean;
    isCreatingContractLoading: boolean;
    isEditingContractLoading: boolean;
    isCreatingContractAdditionLoading: boolean;
    isEditingContractAdditionLoading: boolean;
}

const initialState: ContractsSlice = {
    contractEmployees: [],
    isGettingContractsEmployeesLoading: false,
    pageCount: null,
    activeContractEmployee: null,
    contractsDetailsWithAdditions: [],
    isGettingContractsDetailsWithAdditionLoading: false,
    activeContractsDetailsWithAdditions: null,
    editAndViewContractData: null,
    mainContractData: null,
    isGettingEditOrViewContractDataLoading: false,
    isCreatingContractLoading: false,
    isEditingContractLoading: false,
    isCreatingContractAdditionLoading: false,
    isEditingContractAdditionLoading: false,
};

const contractsSlice = createSlice({
    name: "contracts",
    initialState,
    reducers: {
        setActiveContractEmployee: (state, action) => {
            state.activeContractEmployee = action.payload;
        },
        setActiveContractsDetailsWithAdditions: (state, action) => {
            state.activeContractsDetailsWithAdditions = action.payload;
        },
        setDefaultValuesForContractData: (state, action) => {
            state.editAndViewContractData = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getContractsEmployees.pending, (state) => {
                state.isGettingContractsEmployeesLoading = true;
            })
            .addCase(getContractsEmployees.fulfilled, (state, action) => {
                state.isGettingContractsEmployeesLoading = false;
                state.contractEmployees = action.payload.data;
                state.pageCount = action.payload.metadata.pageCount;
            })
            .addCase(getContractsEmployees.rejected, (state) => {
                state.isGettingContractsEmployeesLoading = false;
            })
            .addCase(getContractsDetailsToView.pending, (state) => {
                state.isGettingContractsDetailsWithAdditionLoading = true;
            })
            .addCase(getContractsDetailsToView.fulfilled, (state, action) => {
                state.isGettingContractsDetailsWithAdditionLoading = false;
                state.contractsDetailsWithAdditions = action.payload;
            })
            .addCase(getContractsDetailsToView.rejected, (state) => {
                state.isGettingContractsDetailsWithAdditionLoading = false;
            })
            .addCase(getContractById.pending, (state) => {
                state.isGettingEditOrViewContractDataLoading = true;
            })
            .addCase(getContractById.fulfilled, (state, action) => {
                state.isGettingEditOrViewContractDataLoading = false;
                state.editAndViewContractData = action.payload;
            })
            .addCase(getContractById.rejected, (state) => {
                state.isGettingEditOrViewContractDataLoading = false;
            })
            .addCase(getMainContractById.pending, (state) => {
                state.isGettingEditOrViewContractDataLoading = true;
            })
            .addCase(getMainContractById.fulfilled, (state, action) => {
                state.isGettingEditOrViewContractDataLoading = false;
                state.mainContractData = action.payload;
            })
            .addCase(getMainContractById.rejected, (state) => {
                state.isGettingEditOrViewContractDataLoading = false;
            })
            .addCase(createContract.pending, (state) => {
                state.isCreatingContractLoading = true;
            })
            .addCase(createContract.fulfilled, (state) => {
                state.isCreatingContractLoading = false;
            })
            .addCase(createContract.rejected, (state) => {
                state.isCreatingContractLoading = false;
            })
            .addCase(editContract.pending, (state) => {
                state.isEditingContractLoading = true;
            })
            .addCase(editContract.fulfilled, (state) => {
                state.isEditingContractLoading = false;
            })
            .addCase(editContract.rejected, (state) => {
                state.isEditingContractLoading = false;
            })
            .addCase(addAdditionToContract.pending, (state) => {
                state.isCreatingContractAdditionLoading = true;
            })
            .addCase(addAdditionToContract.fulfilled, (state) => {
                state.isCreatingContractAdditionLoading = false;
            })
            .addCase(addAdditionToContract.rejected, (state) => {
                state.isCreatingContractAdditionLoading = false;
            })
            .addCase(editContractAddition.pending, (state) => {
                state.isEditingContractAdditionLoading = true;
            })
            .addCase(editContractAddition.fulfilled, (state) => {
                state.isEditingContractAdditionLoading = false;
            })
            .addCase(editContractAddition.rejected, (state) => {
                state.isEditingContractAdditionLoading = false;
            });
    },
});

export const {
    setActiveContractEmployee,
    setActiveContractsDetailsWithAdditions,
    setDefaultValuesForContractData,
} = contractsSlice.actions;

export default contractsSlice.reducer;
