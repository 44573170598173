import { ChangeEvent, FC } from "react";
import { useAppDispatch, useLocalStorage, useUpdateEffect } from "hooks";
import { useTranslation } from "react-i18next";
import { getOrganizations } from "store";
import { cancelBtn as resetFiltersBtn } from "styles/MUIStyles";

import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";

import { getCalculatedFiltersCount } from "../../core/utils";

type FilterPanelProps = {
    organizationTypes: string[];
    page: number;
    pageSize: number;
    setPage: (page: number) => void;
    searchOrganizationByCode: string;
    searchOrganizationByName: string;
    searchManager: string;
    setFiltersCount: (filterCount: number) => void;
};

const FilterPanel: FC<FilterPanelProps> = (props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [organizationsFilters, setOrganizaionsFilters] = useLocalStorage<{
        types: string[];
    }>("organizationsFilters", {
        types: [],
    });

    const resetFilters = () => {
        setOrganizaionsFilters({
            types: [],
        });
    };

    const handleFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value, checked } = event.target;

        setOrganizaionsFilters((prevState: { types: string[] }) => {
            if (checked) {
                if (!prevState.types.includes(value)) {
                    return {
                        ...prevState,
                        types: [...prevState.types, value],
                    };
                }
            } else {
                return {
                    ...prevState,
                    types: prevState.types.filter((type) => type !== value),
                };
            }
            return prevState;
        });
    };

    useUpdateEffect(() => {
        props.setFiltersCount(getCalculatedFiltersCount(organizationsFilters));
        if (props.page > 1) {
            props.setPage(1);
        } else {
            dispatch(
                getOrganizations({
                    pageNumber: props.page,
                    pageSize: props.pageSize,
                    types: organizationsFilters.types,
                    code: props.searchOrganizationByCode,
                    name: props.searchOrganizationByName,
                    managerName: props.searchManager,
                }),
            );
        }
    }, [organizationsFilters]);

    return (
        <div style={{ padding: "20px" }}>
            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    margin: "10px 0",
                }}
            >
                <Button
                    variant="text"
                    onClick={resetFilters}
                    sx={resetFiltersBtn}
                >
                    {t("buttons.Reset")}
                </Button>
            </div>
            <p>
                <b>{t("employees.show")}</b>
            </p>
            <FormGroup>
                {props.organizationTypes?.map((type, index) => (
                    <FormControlLabel
                        key={index}
                        control={
                            <Checkbox
                                value={String(index + 1)}
                                checked={organizationsFilters?.types?.includes(
                                    String(index + 1),
                                )}
                                onChange={handleFilterChange}
                            />
                        }
                        label={t(`organizationTypes.${type}`)}
                    />
                ))}
            </FormGroup>
        </div>
    );
};

export default FilterPanel;
