import React, { FC, useEffect } from "react";
import editIcon from "assets/icons/hoverEditIcon.svg";
import viewIcon from "assets/icons/view.svg";
import dayjs from "dayjs";
import Guard from "features/Guard";
import { useAppDispatch, useAppSelector } from "hooks";
import { ISortConfig } from "models/user";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import {
    materialReactTableCell,
    materialReactTableHeader,
} from "styles/MUIStyles";
import BootstrapTooltip from "ui/Tooltip";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Box } from "@mui/material";

import { getFromLocalStorage } from "../../../core/utils";
import AgGrid from "../../../features/AgGrid/AgGrid";
import { resetPermissions } from "../../../store";
import { setActiveVacationSickDay } from "../../../store/slices/vacationsSickDaysSlice";

interface IProps {
    sortConfig: ISortConfig;
    handleChangeSorting: (sortName: string) => void;
    search: string;
    setSearch: (search: string) => void;
    year: number;
    handleChangeYear: (year: number | undefined) => void;
}

const VacationsSickDaysTable: FC<IProps> = ({
    sortConfig,
    handleChangeSorting,
    year,
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const dayOffAll = useAppSelector(
        (state) => state.vacationsSickDays.dayOffAll,
    );
    const activeVacationSickDay = useAppSelector(
        (state) => state.vacationsSickDays.activeVacationSickDay,
    );
    const allowedMenus = useAppSelector((state) => state.auth.allowedMenus);

    // const isGettingDaysOffAllLoading = useAppSelector(
    //     (state) => state.vacationsSickDays.isGettingDaysOffAllLoding
    // );

    const getArrowForSortDirection = (column: string) => {
        if (sortConfig.column !== column) {
            return null;
        }

        return sortConfig.direction === "asc" ? (
            <ArrowUpwardIcon sx={{ color: "#605E5C" }} />
        ) : (
            <ArrowDownwardIcon sx={{ color: "#605E5C" }} />
        );
    };

    const HeadComponent = (p: any) => {
        switch (p.column.colId) {
            case "customActions":
                return (
                    <Box sx={materialReactTableHeader}>
                        <span>{t("data.actions")}</span>
                    </Box>
                );
            case "personnelNumber":
                return (
                    <Box sx={materialReactTableHeader}>
                        <span>{t("data.tabNo")}</span>
                    </Box>
                );
            case "fullName":
                return (
                    <Box
                        sx={materialReactTableHeader}
                        onClick={() => handleChangeSorting("fullName")}
                    >
                        <span>{t("data.nameAndPosition")}</span>
                        {getArrowForSortDirection("fullName")}
                    </Box>
                );
            case "organization":
                return (
                    <Box
                        sx={materialReactTableHeader}
                        onClick={() => handleChangeSorting("organization")}
                    >
                        <span>{t("data.vendor")}</span>
                        {getArrowForSortDirection("organization")}
                    </Box>
                );
            case "vacationFromDate":
                return (
                    <Box sx={materialReactTableHeader}>
                        <span>{t("vacationsSickDays.VacationFromDate")}</span>
                    </Box>
                );
            case "vacationByDate":
                return (
                    <Box sx={materialReactTableHeader}>
                        <span>{t("vacationsSickDays.VacationByDate")}</span>
                    </Box>
                );
            case "vacationByDays":
                return (
                    <Box sx={materialReactTableHeader}>
                        <span>{t("vacationsSickDays.Days")}</span>
                    </Box>
                );
            case "sickLeaveFromDate":
                return (
                    <Box sx={materialReactTableHeader}>
                        <span>{t("vacationsSickDays.SickLeaveFromDate")}</span>
                    </Box>
                );
            case "sickLeaveFromDay":
                return (
                    <Box sx={materialReactTableHeader}>
                        <span>{t("vacationsSickDays.SickLeaveByDate")}</span>
                    </Box>
                );
            case "sickLeaveFromDayDiff":
                return (
                    <Box sx={materialReactTableHeader}>
                        <span>{t("vacationsSickDays.Days")}</span>
                    </Box>
                );
            case "daysOff":
                return (
                    <Box sx={materialReactTableHeader}>
                        <span>{t("vacationsSickDays.DaysOff")}</span>
                    </Box>
                );
            case "vacationsSickDaysDaysOffDiff":
                return (
                    <Box sx={materialReactTableHeader}>
                        <span>{t("vacationsSickDays.Days")}</span>
                    </Box>
                );
        }
    };

    const allowedPermissions = useAppSelector(
        (state) => state.auth.allowedPermissions,
    );

    useEffect(() => {
        dispatch(resetPermissions());
    }, []);

    const RowComponent = (p: any) => {
        switch (p.colDef.field) {
            case "customActions":
                return (
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            height: "100%",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                        }}
                    >
                        <Guard
                            allowedPermissions={allowedPermissions}
                            permissionName="View"
                        >
                            <BootstrapTooltip
                                title={t("buttons.View")}
                                onClick={() =>
                                    navigate(`${year}/${p.data?.employeeId}`)
                                }
                                placement="top"
                            >
                                <img src={viewIcon} alt="view icon" />
                            </BootstrapTooltip>
                        </Guard>

                        <Guard
                            allowedPermissions={allowedPermissions}
                            permissionName="Edit"
                        >
                            <BootstrapTooltip
                                title={t("buttons.Edit")}
                                onClick={() =>
                                    navigate(
                                        `${year}/${p.data?.employeeId}/edit`,
                                    )
                                }
                                placement="top"
                            >
                                <img src={editIcon} alt="edit icon" />
                            </BootstrapTooltip>
                        </Guard>
                    </Box>
                );
            case "personnelNumber":
                return (
                    <Box sx={materialReactTableCell}>
                        {p.data?.personnelNumber}
                    </Box>
                );
            case "fullName":
                return (
                    <Box sx={materialReactTableCell}>
                        <span>{p.data?.fullName}</span>
                        <span>{p.data?.position}</span>
                    </Box>
                );
            case "organization":
                return (
                    <Box
                        sx={materialReactTableCell}
                        onClick={() =>
                            localStorage.setItem(
                                "redirectOrganizationId",
                                p.data?.organizationId,
                            )
                        }
                    >
                        {allowedMenus.some(
                            (menu) => menu.name === "Organizations",
                        ) ? (
                            <Link to="/organizations">
                                {p.data?.organization}
                            </Link>
                        ) : (
                            <span>p.data?.organization</span>
                        )}
                    </Box>
                );
            case "vacationFromDate":
                return (
                    <Box sx={materialReactTableCell}>
                        {p.data?.partialListOfDaysOffDto.map(
                            (partialOffDay: any, index: number) => {
                                if (partialOffDay.sign === 1) {
                                    return (
                                        <span key={index}>
                                            {dayjs(
                                                partialOffDay.startDate,
                                            ).format("DD.MM.YYYY")}
                                        </span>
                                    );
                                }

                                return null;
                            },
                        )}
                    </Box>
                );
            case "vacationByDate":
                return (
                    <Box sx={materialReactTableCell}>
                        {p.data?.partialListOfDaysOffDto.map(
                            (partialOffDay: any, index: number) => {
                                if (partialOffDay.sign === 1) {
                                    return (
                                        <span key={index}>
                                            {dayjs(
                                                partialOffDay.finishDate,
                                            ).format("DD.MM.YYYY")}
                                        </span>
                                    );
                                }

                                return null;
                            },
                        )}
                    </Box>
                );
            case "vacationByDays":
                return (
                    <Box sx={materialReactTableCell}>
                        {p.data?.partialListOfDaysOffDto.map(
                            (partialOffDay: any, index: number) => {
                                if (partialOffDay.sign === 1) {
                                    return (
                                        <span key={index}>
                                            {dayjs(
                                                partialOffDay.finishDate,
                                            ).diff(
                                                partialOffDay.startDate,
                                                "day",
                                            ) + 1}
                                        </span>
                                    );
                                }

                                return null;
                            },
                        )}
                    </Box>
                );
            case "sickLeaveFromDate":
                return (
                    <Box sx={materialReactTableCell}>
                        {p.data?.partialListOfDaysOffDto.map(
                            (partialOffDay: any, index: number) => {
                                if (partialOffDay.sign === 2) {
                                    return (
                                        <span key={index}>
                                            {dayjs(
                                                partialOffDay.startDate,
                                            ).format("DD.MM.YYYY")}
                                        </span>
                                    );
                                }

                                return null;
                            },
                        )}
                    </Box>
                );
            case "sickLeaveFromDay":
                return (
                    <Box sx={materialReactTableCell}>
                        {p.data?.partialListOfDaysOffDto.map(
                            (partialOffDay: any, index: number) => {
                                if (partialOffDay.sign === 2) {
                                    return (
                                        <span key={index}>
                                            {dayjs(
                                                partialOffDay.finishDate,
                                            ).format("DD.MM.YYYY")}
                                        </span>
                                    );
                                }

                                return null;
                            },
                        )}
                    </Box>
                );
            case "sickLeaveFromDayDiff":
                return (
                    <Box sx={materialReactTableCell}>
                        {p.data?.partialListOfDaysOffDto.map(
                            (partialOffDay: any, index: number) => {
                                if (partialOffDay.sign === 2) {
                                    return (
                                        <span key={index}>
                                            {dayjs(
                                                partialOffDay.finishDate,
                                            ).diff(
                                                partialOffDay.startDate,
                                                "day",
                                            ) + 1}
                                        </span>
                                    );
                                }

                                return null;
                            },
                        )}
                    </Box>
                );
            case "daysOff":
                return (
                    <Box sx={materialReactTableCell}>
                        {p.data?.partialListOfDaysOffDto.map(
                            (partialOffDay: any, index: number) => {
                                if (partialOffDay.sign === 3) {
                                    return (
                                        <span key={index}>
                                            {dayjs(
                                                partialOffDay.finishDate,
                                            ).format("DD.MM.YYYY")}
                                        </span>
                                    );
                                }

                                return null;
                            },
                        )}
                    </Box>
                );
            case "vacationsSickDaysDaysOffDiff":
                return (
                    <Box sx={materialReactTableCell}>
                        <span>
                            {p.data?.partialListOfDaysOffDto.filter(
                                (item: any) => item.sign === 3,
                            ).length || null}
                        </span>
                    </Box>
                );
        }
    };

    const generateColumnDefs = () => {
        const savedColumnState = getFromLocalStorage(
            "agGridVacationSickDaysTable",
        );

        let updatedColumnDefs: any[] = [
            {
                field: "customActions",
                resizable: true,
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 110,
            },
            {
                field: "personnelNumber",
                resizable: true,
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 80,
            },
            {
                field: "fullName",
                resizable: true,
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 150,
            },
            {
                field: "organization",
                resizable: true,
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 170,
            },
            {
                field: "vacationFromDate",
                resizable: true,
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 140,
            },
            {
                field: "vacationByDate",
                resizable: true,
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 140,
            },
            {
                field: "vacationByDays",
                resizable: true,
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 80,
            },
            {
                field: "sickLeaveFromDate",
                resizable: true,
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 180,
            },
            {
                field: "sickLeaveFromDay",
                resizable: true,
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 180,
            },
            {
                field: "sickLeaveFromDayDiff",
                resizable: true,
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 80,
            },
            {
                field: "daysOff",
                resizable: true,
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 100,
            },
            {
                field: "vacationsSickDaysDaysOffDiff",
                resizable: true,
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 80,
            },
        ];

        if (Array.isArray(savedColumnState)) {
            updatedColumnDefs = savedColumnState.map((columnState: any) => {
                return {
                    field: columnState.colId,
                    width: columnState.width,
                    pinned: columnState.pinned,
                    headerComponent: HeadComponent,
                    cellRenderer: RowComponent,
                    resizable: true,
                };
            });
        }

        return updatedColumnDefs;
    };

    const saveColumnState = (columnState: any) => {
        localStorage.setItem(
            "agGridVacationSickDaysTable",
            JSON.stringify(columnState),
        );
    };

    const columnDefs: any[] = generateColumnDefs();

    return (
        <AgGrid
            rowData={dayOffAll}
            columnDefs={columnDefs}
            saveColumnState={saveColumnState}
            activeRow={activeVacationSickDay}
            setActiveRow={setActiveVacationSickDay}
            height={"63vh"}
            page="vacationSickDays"
        />
    );
};

export default VacationsSickDaysTable;
