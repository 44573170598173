import { createSlice } from '@reduxjs/toolkit';

import { ICommonDocument } from '../../models/commonDocument';
import { getDocuments } from '../thunks/commonDocumentsThunk';

type CommonDocumentsSlice = {
    documents: ICommonDocument[];
    isLoading: boolean;
};

const initialState: CommonDocumentsSlice = {
    documents: [],
    isLoading: false
};

const commonDocumentsSlice = createSlice({
    name: 'common-documents',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getDocuments.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getDocuments.fulfilled, (state, action) => {
                state.isLoading = false;
                state.documents = action.payload.data;
            });
    },
});

// eslint-disable-next-line no-empty-pattern
export const {} = commonDocumentsSlice.actions;

export default commonDocumentsSlice.reducer;
