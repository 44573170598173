import { useEffect } from "react";
import { UseFormClearErrors, UseFormSetError } from "react-hook-form";

import { sendNotification } from "../ui/Toast";

export type Error = {
    serverError: any
    setError: UseFormSetError<any>
    clearErrors: UseFormClearErrors<any>
    errorNames: string[]
}

const useSetServerError = (props: Error) => {
    useEffect(() => {
        if (props.serverError === null) {
            props.clearErrors();
        }

        if (typeof props.serverError === 'string') {
            sendNotification(props.serverError, 'error');
        }

        if (typeof props.serverError !== 'string' && props.serverError) {
            for (let i = 0; i < Object.keys(props.serverError).length; i++) {
                const fieldNameError = Object.keys(props.serverError)[i];
                if (fieldNameError in props.serverError) {
                    props.setError(fieldNameError, {
                        type: 'custom',
                        message: props.serverError[fieldNameError][0],
                    });
                }
            }
        }
    }, [props.serverError]);
};

export default useSetServerError;
