import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { CreateIcon, EditIcon } from "assets/icons";
import Guard from "features/Guard";
import { useAppDispatch, useAppSelector } from "hooks";
import useSetServerError from "hooks/useSetServerError";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
    activateDataByCatalogName,
    clearCatalogTextFieldErrors,
    createDataWithParentByCatalogName,
    deactivateDataByCatalogName,
    editDataWithParentByCatalogName,
} from "store";
import { createOrEditBtn } from "styles/MUIStyles";

import { ErrorMessage } from "@hookform/error-message";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import CatalogItemActionsComponent from "../../../features/Catalogs/CatalogItemActionsComponent";
import ConfirmationModal from "../../../features/ConfirmationModal";
import FormButtons from "../../../features/FormButtons";
import ListItemActions from "../../../features/ListItemActions";
import { baseCatalogItem } from "../../../models/catalogs";
import { StyledSelect, StyledSelectError } from "../../../ui/Select";

import styles from "../CatalogsLayout.module.scss";

interface IFormInput {
    name: string;
    description: string;
    parentId: number | null | string | undefined;
}

const BaseCatalogWithParentId: FC<{
    name: string;
    createOrUpdate: null | string;
    setCreateOrUpdate: Function;
    fulfilledStatusHandler: Function;
}> = ({
    name,
    createOrUpdate,
    setCreateOrUpdate,
    fulfilledStatusHandler,
}): JSX.Element => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const dataByCatalogName = useAppSelector(
        (state) => state.catalogs.dataByCatalogName,
    );
    const allowedPermissions = useAppSelector(
        (state) => state.auth.allowedPermissions,
    );

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [activeCatalogItem, setActiveCatalogItem] =
        useState<null | baseCatalogItem>(null);

    const [confirmationModalOpen, setConfirmationModalOpen] = useState<{
        isOpen: boolean;
        actionName: string;
    }>({ isOpen: false, actionName: "" });

    const activateItem = async () => {
        if (activeCatalogItem?.id) {
            const response = await dispatch(
                activateDataByCatalogName({
                    catalogName: name,
                    catalogNameId: activeCatalogItem?.id,
                }),
            );
            if (response.meta.requestStatus === "fulfilled") {
                fulfilledStatusHandler("notifications.successfullyActivated");
                setConfirmationModalOpen({ isOpen: false, actionName: "" });
            }
            return;
        }
    };

    const deactivateItem = async () => {
        if (activeCatalogItem?.id) {
            const response = await dispatch(
                deactivateDataByCatalogName({
                    catalogName: name,
                    catalogNameId: activeCatalogItem?.id,
                }),
            );

            if (
                response.meta.requestStatus === "fulfilled" &&
                response.payload
            ) {
                fulfilledStatusHandler(
                    t("notifications.recordInUseNotice"),
                    "warn",
                );
                setConfirmationModalOpen({ isOpen: false, actionName: "" });
                return;
            }

            if (response.meta.requestStatus === "fulfilled") {
                fulfilledStatusHandler("notifications.successfullyDeactivated");
                setConfirmationModalOpen({ isOpen: false, actionName: "" });
            }
        }
    };

    const confirmActionHandler = () => {
        switch (confirmationModalOpen.actionName) {
            case "Activate":
                activateItem();
                break;
            case "Deactivate":
                deactivateItem();
                break;
        }
    };

    const onCreateItem = () => {
        setIsDrawerOpen(true);
        setCreateOrUpdate("create");
        setActiveCatalogItem(null);
    };

    const onEditItem = () => {
        setIsDrawerOpen(true);
        setCreateOrUpdate("update");
    };

    useEffect(() => {
        setActiveCatalogItem(null);
    }, [dispatch, name]);

    return (
        <div>
            <div className={styles.crudButtonWrapper}>
                <Guard
                    allowedPermissions={allowedPermissions}
                    permissionName="Create"
                >
                    <Button
                        startIcon={<CreateIcon />}
                        variant="text"
                        disabled={false}
                        onClick={() => onCreateItem()}
                        sx={createOrEditBtn}
                    >
                        {t("buttons.Create")}
                    </Button>
                </Guard>
                <Guard
                    allowedPermissions={allowedPermissions}
                    permissionName="Edit"
                >
                    <Button
                        startIcon={<EditIcon />}
                        variant="text"
                        disabled={!activeCatalogItem?.isActive}
                        onClick={() => onEditItem()}
                        sx={createOrEditBtn}
                    >
                        {t("buttons.Edit")}
                    </Button>
                </Guard>
            </div>
            <div className={styles.tableHeader}>
                <span className={styles.column}>{t("data.name")}</span>
                <span className={styles.column}>{t("data.description")}</span>
                <span className={styles.column}>{t("data.parent")}</span>
                <span className={styles.column}>{t("data.actions")}</span>
            </div>
            <ul className={styles.catalogNameDataList}>
                {dataByCatalogName.map((item) => (
                    <li
                        key={item.id}
                        className={
                            activeCatalogItem?.id === item.id
                                ? `${styles.catalogNameDataListItem} ${styles.catalogNameDataListItemActive}`
                                : `${styles.catalogNameDataListItem}`
                        }
                        onClick={() =>
                            setActiveCatalogItem({
                                id: item.id,
                                name: item.name,
                                description: item.description,
                                parentId: item.parentId,
                                isActive: item.isActive,
                            })
                        }
                    >
                        <span>{item.name}</span>
                        <span>{item.description}</span>
                        <span>
                            {item.parentId
                                ? dataByCatalogName.find(
                                      (el) => el.id === item.parentId,
                                  )?.name
                                : ""}
                        </span>
                        <span>
                            <ListItemActions
                                itemId={item.id}
                                isItemActive={item.isActive}
                                activationAvailable={true}
                                setConfirmationModalOpen={
                                    setConfirmationModalOpen
                                }
                                editItemClick={onEditItem}
                            />
                        </span>
                    </li>
                ))}
            </ul>

            <BaseFormWithParent
                name={name}
                activeCatalogItem={activeCatalogItem}
                createOrUpdate={createOrUpdate}
                setCreateOrUpdate={setCreateOrUpdate}
                isDrawerOpen={isDrawerOpen}
                setIsDrawerOpen={setIsDrawerOpen}
                setActiveCatalogItem={setActiveCatalogItem}
                fulfilledStatusHandler={fulfilledStatusHandler}
            />

            <ConfirmationModal
                isModalOpen={confirmationModalOpen.isOpen}
                setIsModalOpen={setConfirmationModalOpen}
                actionName={confirmationModalOpen.actionName}
                instanceName={name}
                handleAction={confirmActionHandler}
            />
        </div>
    );
};

const BaseFormWithParent: FC<{
    name: string;
    activeCatalogItem: baseCatalogItem | null;
    createOrUpdate: string | null;
    isDrawerOpen: boolean;
    setCreateOrUpdate: Function;
    setIsDrawerOpen: Dispatch<SetStateAction<boolean>>;
    setActiveCatalogItem: Dispatch<SetStateAction<baseCatalogItem | null>>;
    fulfilledStatusHandler: Function;
}> = ({
    name,
    activeCatalogItem,
    createOrUpdate,
    isDrawerOpen,
    setCreateOrUpdate,
    setIsDrawerOpen,
    setActiveCatalogItem,
    fulfilledStatusHandler,
}): JSX.Element => {
    const dispatch = useAppDispatch();

    const dataByCatalogName = useAppSelector(
        (state) => state.catalogs.dataByCatalogName,
    );
    const isCreatingOrUpdatingLoading = useAppSelector(
        (state) => state.catalogs.isCreatingOrUpdatingLoading,
    );
    const catalogsDataByNameErrors = useAppSelector(
        (state) => state.catalogs.catalogsDataByNameErrors,
    );

    const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);

    const { t } = useTranslation();

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors },
        setError,
        control,
        clearErrors,
    } = useForm<IFormInput>();

    const onSubmit: SubmitHandler<IFormInput> = async (formData: {
        name: string;
        description: string;
        parentId: number | null | string | undefined;
    }) => {
        if (createOrUpdate === "create") {
            const response = await dispatch(
                createDataWithParentByCatalogName({
                    catalogName: name,
                    name: formData.name,
                    description: formData.description,
                    parentId: formData.parentId || null,
                }),
            );

            if (response.meta.requestStatus === "fulfilled") {
                fulfilledStatusHandler("notifications.successfullyCreated");
                setIsDrawerOpen(false);
            }

            return;
        }

        if (activeCatalogItem && createOrUpdate === "update") {
            const response = await dispatch(
                editDataWithParentByCatalogName({
                    catalogNameId: activeCatalogItem.id,
                    catalogName: name,
                    name: formData.name,
                    description: formData.description,
                    parentId: formData.parentId || null,
                }),
            );

            if (response.meta.requestStatus === "fulfilled") {
                fulfilledStatusHandler("notifications.successfullySaved");
                setActiveCatalogItem(null);
                setIsDrawerOpen(false);
            }
        }
    };

    const setFormData = (fields: baseCatalogItem | null) => {
        setValue("name", fields?.name || "");
        setValue("description", fields?.description || "");
        setValue("parentId", fields?.parentId || "");
    };

    const formClose = () => {
        if (formChangesChecking()) setConfirmationModalOpen(true);
        else closeFormDrawer();
    };

    // returns true, if form was updated
    const formChangesChecking = () => {
        if (createOrUpdate === "create") {
            return Boolean(
                getValues("name") ||
                    getValues("description") ||
                    getValues("parentId"),
            );
        } else {
            return Boolean(
                getValues("name") !== activeCatalogItem?.name ||
                    getValues("description") !==
                        activeCatalogItem?.description ||
                    getValues("parentId") !== activeCatalogItem?.parentId,
            );
        }
    };

    const closeFormDrawer = () => {
        setCreateOrUpdate(null);
        setIsDrawerOpen(false);
    };

    const closeModalConfirm = () => {
        setFormData(null);
        setIsDrawerOpen(false);
        setConfirmationModalOpen(false);
    };

    useSetServerError({
        serverError: catalogsDataByNameErrors,
        setError,
        clearErrors,
        errorNames: ["name", "description", "parentId"],
    });

    useEffect(() => {
        if (isDrawerOpen) {
            setFormData(activeCatalogItem);
        } else {
            clearErrors();
            dispatch(clearCatalogTextFieldErrors());
        }
    }, [dispatch, isDrawerOpen]);

    return (
        <>
            <CatalogItemActionsComponent
                name={name}
                isDrawerOpen={isDrawerOpen}
                createOrUpdate={createOrUpdate}
                closeFormDrawer={formClose}
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={styles.formInputWrapper}>
                        <label
                            htmlFor="name"
                            className={styles.drawerTopSubTitle}
                        >
                            {t("data.name")}
                        </label>
                        <div>
                            <input
                                {...register("name", {
                                    required: t(
                                        "validation.requiredField",
                                    ) as string,
                                    maxLength: {
                                        value: 30,
                                        message: t("validation.maxLength30"),
                                    },
                                })}
                                id="name"
                                type="text"
                                autoComplete="off"
                                placeholder={t("data.name") as string}
                                className={
                                    errors?.name
                                        ? styles.catalogsDrawerInputError
                                        : styles.catalogsDrawerInput
                                }
                            />
                            <ErrorMessage
                                name="name"
                                errors={errors}
                                render={({ message }) => <span>{message}</span>}
                            />
                        </div>

                        <label
                            htmlFor="description"
                            className={styles.drawerTopSubTitle}
                        >
                            {t("data.description")}
                        </label>
                        <div>
                            <input
                                {...register("description", {
                                    maxLength: {
                                        value: 150,
                                        message: t("validation.maxLength150"),
                                    },
                                })}
                                id="description"
                                type="text"
                                autoComplete="off"
                                placeholder={t("data.description") as string}
                                className={
                                    errors?.description
                                        ? styles.catalogsDrawerInputError
                                        : styles.catalogsDrawerInput
                                }
                            />
                            <ErrorMessage
                                name="description"
                                errors={errors}
                                render={({ message }) => <span>{message}</span>}
                            />
                        </div>

                        <FormControl
                            fullWidth
                            sx={{ margin: ".4rem 0 1.7rem 0" }}
                        >
                            <span className={styles.drawerTopSubTitle}>
                                {t("data.parent")}
                            </span>
                            <Controller
                                name="parentId"
                                defaultValue=""
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        input={
                                            errors?.parentId ? (
                                                <StyledSelectError />
                                            ) : (
                                                <StyledSelect />
                                            )
                                        }
                                    >
                                        {dataByCatalogName.map((item) => (
                                            <MenuItem
                                                key={item.id}
                                                value={item.id}
                                            >
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            />
                            <ErrorMessage
                                name="parentId"
                                errors={errors}
                                render={({ message }) => (
                                    <span className={styles.selectErrorMsg}>
                                        {message}
                                    </span>
                                )}
                            />
                        </FormControl>
                    </div>
                    <FormButtons
                        isLoading={isCreatingOrUpdatingLoading}
                        createOrUpdate={createOrUpdate}
                        cancelHandler={formClose}
                    />
                </form>
            </CatalogItemActionsComponent>

            <ConfirmationModal
                isModalOpen={isConfirmationModalOpen}
                setIsModalOpen={setConfirmationModalOpen}
                actionName={"Close"}
                instanceName=""
                message={"closeFormWithoutSaving"}
                handleAction={() => closeModalConfirm()}
            />
        </>
    );
};

export default BaseCatalogWithParentId;
