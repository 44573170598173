import axios from "axios";
import axiosApi from "core/request";
import { errorNotification } from "core/utils/errorNotification";
import { Dayjs } from "dayjs";

import { createAsyncThunk } from "@reduxjs/toolkit";

interface EmployeeTrakingQuery {
    pageNumber: number;
    pageSize?: number;
    year?: number | string | null;
    month?: number | string | null;
    organizationId?: number | string | null;
    positionId?: number | string | null;
    fullName?: string | null;
    beforeStartDate: Dayjs;
    skillId: number;
    skillLevelId: number;
    status: boolean;
    typeOfEmploymentId: number;
    costCenterId: number;
    fromStartDate: Dayjs;
    sortBy?: { column: string | null; direction: string | null };
}

export const getAllTimeKeepingOfEmployees = createAsyncThunk(
    "tracking/alltimekeepingofemployees",
    async (queryParams: EmployeeTrakingQuery, { rejectWithValue }) => {
        try {
            let params = {};

            if (queryParams) {
                const {
                    fullName,
                    organizationId,
                    positionId,
                    skillId,
                    skillLevelId,
                    costCenterId,
                    typeOfEmploymentId,
                    status,
                    fromStartDate,
                    beforeStartDate,
                    sortBy,
                    pageNumber,
                    pageSize,
                    year,
                    month
                } = queryParams;

                params = {
                    ...params,
                    ...(year && {
                        "filter.year": year,
                    }),
                    ...(month && {
                        "filter.month": month,
                    }),
                    ...(organizationId && {
                        "filter.organizationId": organizationId,
                    }),
                    ...(typeOfEmploymentId && {
                        "filter.typeOfEmploymentId": typeOfEmploymentId,
                    }),
                    ...(fullName && { "filter.fullName": fullName }),
                    ...(positionId && { "filter.positionId": positionId }),
                    ...(skillId && { "filter.skillId": skillId }),
                    ...(skillLevelId && {
                        "filter.skillLevelId": skillLevelId,
                    }),
                    ...(costCenterId && {
                        "filter.costCenterId": costCenterId,
                    }),
                    ...(status && { "filter.status": status }),
                    ...(fromStartDate && {
                        "filter.fromStartDate": fromStartDate,
                    }),
                    ...(beforeStartDate && {
                        "filter.beforeStartDate": beforeStartDate,
                    }),
                    ...(pageNumber && { pageNumber: pageNumber }),
                    ...(pageSize && { pageSize: pageSize }),
                    ...(sortBy?.column &&
                        sortBy?.direction && {
                            sortBy: `${sortBy.column}.${sortBy.direction}`,
                        }),
                };
            }

            const { data } = await axiosApi.get("/tracking/alltimekeepingofemployees", { params });
            return data;
        } catch (error) {
            let errorMessage = error;

            if (axios.isAxiosError(error)) {
                errorMessage = error.response?.data?.errors?.raw?.[0];
                errorNotification(
                    "Произошла ошибка, перезагрузите страницу..."
                );
            } else {
                console.error(error);
            }

            return rejectWithValue(errorMessage);
        }
    }
);

export const getAllTimeKeepingOfProjects = createAsyncThunk(
    "tracking/alltimekeepingofprojects",
    async (queryParams: EmployeeTrakingQuery, { rejectWithValue }) => {
        try {
            let params = {};

            if (queryParams) {
                const {
                    fullName,
                    organizationId,
                    positionId,
                    skillId,
                    skillLevelId,
                    costCenterId,
                    typeOfEmploymentId,
                    status,
                    fromStartDate,
                    beforeStartDate,
                    sortBy,
                    pageNumber,
                    pageSize,
                    year,
                    month,
                } = queryParams;

                params = {
                    ...params,
                    ...(organizationId && {
                        "filter.organizationId": organizationId,
                    }),
                    ...(year && {
                        "filter.year": year,
                    }),
                    ...(month && {
                        "filter.month": month,
                    }),
                    ...(typeOfEmploymentId && {
                        "filter.typeOfEmploymentId": typeOfEmploymentId,
                    }),
                    ...(fullName && { "filter.fullName": fullName }),
                    ...(positionId && { "filter.positionId": positionId }),
                    ...(skillId && { "filter.skillId": skillId }),
                    ...(skillLevelId && {
                        "filter.skillLevelId": skillLevelId,
                    }),
                    ...(costCenterId && {
                        "filter.costCenterId": costCenterId,
                    }),
                    ...(status && { "filter.status": status }),
                    ...(fromStartDate && {
                        "filter.fromStartDate": fromStartDate,
                    }),
                    ...(beforeStartDate && {
                        "filter.beforeStartDate": beforeStartDate,
                    }),
                    ...(pageNumber && { pageNumber: pageNumber }),
                    ...(pageSize && { pageSize: pageSize }),
                    ...(sortBy?.column &&
                        sortBy?.direction && {
                            sortBy: `${sortBy.column}.${sortBy.direction}`,
                        }),
                };
            }

            const response = await axiosApi.get(
                "/tracking/alltimekeepingofprojects",
                { params }
            );
            return response.data;
        } catch (error) {
            let errorMessage = error;

            if (axios.isAxiosError(error)) {
                errorMessage = error.response?.data?.errors?.raw?.[0];
                errorNotification(
                    "Произошла ошибка, перезагрузите страницу..."
                );
            } else {
                console.error(error);
            }

            return rejectWithValue(errorMessage);
        }
    }
);

export const getTrackingByEmployeeId = createAsyncThunk(
    "tracking/timetrackingbyemployeeid",
    async (
        trackingQuery: {
            employeeId?: string | null;
            year?: number | null;
            month?: number | null;
        },
        { rejectWithValue }
    ) => {
        try {
            const params = new URLSearchParams();

            if (trackingQuery.year) {
                params.append("year", String(trackingQuery.year));
            }

            if (trackingQuery.month) {
                params.append("month", String(trackingQuery.month));
            }

            if (trackingQuery.employeeId) {
                params.append("employeeId", String(trackingQuery.employeeId));
            }

            const response = await axiosApi.get(
                "/tracking/timetrackingbyemployeeid",
                { params }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue("Error");
        }
    }
);

export const getTrackingById = createAsyncThunk('tracking/getTrackingById',
    async (trackingId: number | string, { rejectWithValue }) => {
        try {
            const response = await axiosApi.get(`tracking/gettimekeepingbyid?trackingId=${trackingId}`);
            return response.data;
        } catch (error) {
            return rejectWithValue('Error');
        }
    }
);

export const createAndUpdateTracking = createAsyncThunk(
    "tracking/createAndUpdateTracking",
    async (trackingData: any, { rejectWithValue }) => {
        try {
            const response = await axiosApi.post("/tracking", trackingData);
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                if (error.response.status === 400) {
                    return rejectWithValue(error.response.data.errors);
                } else {
                    return rejectWithValue(
                        "Что-то пошло не так... Попробуйте обновить страницу"
                    );
                }
            }

            if (!navigator.onLine) {
                return rejectWithValue("Проверьте подключение к интернету");
            }

            return rejectWithValue(
                "Что-то пошло не так... Попробуйте обновить страницу"
            );
        }
    }
);
