import axios from "axios";
import axiosApi from "core/request";

import { createAsyncThunk } from "@reduxjs/toolkit";

import { IPasswordArgs, IUserPostArgs, IUserPutArgs } from "../../models/user";

// usersThunk

export const getUsers = createAsyncThunk('users/getUsers',
    async (searchQuery: {
        userName: string,
        pageNumber: null | number,
        email: string,
        userStatus: number | string,
        pageSize: number,
        sortBy?: { column: string | null, direction: string | null },
    } | void, { rejectWithValue }) => {
        try {
            const params = new URLSearchParams();

            if (searchQuery) {
                const { userName, email, userStatus, pageNumber, pageSize } = searchQuery;

                if (pageNumber) params.append('pageNumber', String(pageNumber));
                if (pageSize) params.append('pageSize', String(pageSize));
                if (userName) params.append('filter.userName', String(userName));
                if (email) params.append('filter.email', String(email));
                if (userStatus) params.append('filter.status', String(userStatus));
                if (searchQuery.sortBy?.column && searchQuery.sortBy.direction) {
                    const column = searchQuery.sortBy?.column;
                    const direction = searchQuery.sortBy.direction;
                    params.append('sortBy', `${column}.${direction}`);
                }
            }

            const response = await axiosApi.get('/users', { params });
            return response.data;
        } catch (err) {
            //TODO
            return rejectWithValue('Error');
        }
    });

export const createUser = createAsyncThunk<any, IUserPostArgs, { rejectValue: any | string }>("users/createUser",
    async (user: IUserPostArgs, { rejectWithValue }) => {
        try {
            // add isLoading
            const response = await axiosApi.post('/users', { ...user });
            return response.data;
        } catch (error) {

            // queryErrorHandler(error, rejectWithValue)

            if (axios.isAxiosError(error) && error.response) {
                if (error.response.status === 400) {
                    return rejectWithValue(error.response.data.errors);
                } else {
                    return rejectWithValue('Что-то пошло не так... Попробуйте обновить страницу');
                }
            }

            if (!navigator.onLine) {
                return rejectWithValue('Проверьте подключение к интернету');
            }

            return rejectWithValue('Что-то пошло не так... Попробуйте обновить страницу');
        }
    }
);

export const editUser = createAsyncThunk('users/editUser',
    async (user: IUserPutArgs, { rejectWithValue }) => {
        try {
            //TODO TYPE THE RESPONSE FROM THE SERVER
            const response = await axiosApi.put('/users', { ...user });
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                if (error.response.status === 400) {
                    return rejectWithValue(error.response.data.errors);
                } else {
                    return rejectWithValue('Что-то пошло не так... Попробуйте обновить страницу');
                }
            }

            if (!navigator.onLine) {
                return rejectWithValue('Проверьте подключение к интернету');
            }

            return rejectWithValue('Что-то пошло не так... Попробуйте обновить страницу');
        }
    }
);

export const unblockUser = createAsyncThunk('users/unblockUser',
    async (id: string, { rejectWithValue }) => {
        try {
            //TODO TYPE THE RESPONSE FROM THE SERVER
            const response = await axiosApi.post('/users/unblockUser', { id });
            return response.data;
        } catch (error) {
            //TODO
            return rejectWithValue("Error that i need to handle");
        }
    }
);

export const blockUser = createAsyncThunk('users/blockUser',
    async (id: string, { rejectWithValue }) => {
        try {
            //TODO TYPE THE RESPONSE FROM THE SERVER
            const response = await axiosApi.post('/users/blockUser', { id });
            return response.data;
        } catch (error) {
            //TODO
            return rejectWithValue("Error that i need to handle");
        }
    }
);


export const confirmUser = createAsyncThunk('users/confirmUser',
    async (id: string, { rejectWithValue }) => {
        try {
            //TODO TYPE THE RESPONSE FROM THE SERVER
            const response = await axiosApi.post('/users/confirmUser', { id });
            return response.data;
        } catch (error) {
            //TODO
            return rejectWithValue("Error that i need to handle");
        }
    }
);

export const changePassword = createAsyncThunk('users/changePassword',
    async (data: IPasswordArgs, { rejectWithValue }) => {
        try {
            //TODO TYPE THE RESPONSE FROM THE SERVER
            const response = await axiosApi.post('/users/changepassword', { ...data });
            return response.data;
        } catch (error) {
            //TODO
            return rejectWithValue("Error that i need to handle");
        }
    }
);

export const deleteUser = createAsyncThunk('users/deleteUser',
    async (id: string, { rejectWithValue }) => {
        try {
            //TODO TYPE THE RESPONSE FROM THE SERVER
            const response = await axiosApi.delete(`/users/deleteuser/${id}`);
            return response.data;
        } catch (error) {
            //TODO
            return rejectWithValue("Error that i need to handle");
        }
    }
);

export const getUserRoles = createAsyncThunk('users/getUserRoles',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axiosApi.get('/roles/permissions/allroles');
            return response.data;
        } catch (err) {
            //TODO
            return rejectWithValue('Error');
        }
});

export const changeUserEmail = createAsyncThunk('users/changeUserEmail',
    async (userData: {id: string, userEmail: string}, { rejectWithValue }) => {
        try {
            const response = await axiosApi.post('/users/changeemailuser', userData);
            return response.data;
        } catch (error) {
            return rejectWithValue('Error');
        }
    }
);
