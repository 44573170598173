import React, { PropsWithChildren } from 'react';
import { useAppSelector } from "hooks";
import { Navigate, useLocation } from 'react-router-dom';

import { LOGIN_PATH } from "../../router/constants";

const RequireAuth: React.FC<PropsWithChildren> = ({ children }) => {
    const location = useLocation();
    const isAuth = useAppSelector(state => state.auth.isAuth);

    return isAuth ? (
        <>{children}</>
    ) : (
        <Navigate to={LOGIN_PATH} state={{ path: location.pathname }} replace />
    );
};

export default RequireAuth;
