// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_rowItem__JEOju {\n    padding: 4px;\n    margin: 2px 16px;\n}\n\n.styles_itemsContainer__VKmru {\n    display: flex;\n    align-items: center;\n}\n\n.styles_linkItem__5I38\\+ {\n    flex: 1 1;\n    color: blue;\n    text-decoration: underline;\n}\n\n.styles_icon__hxaft {\n    cursor: pointer;\n    padding: 0 4px;\n}\n", "",{"version":3,"sources":["webpack://./src/features/DocumentsList/styles.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,SAAO;IACP,WAAW;IACX,0BAA0B;AAC9B;;AAEA;IACI,eAAe;IACf,cAAc;AAClB","sourcesContent":[".rowItem {\n    padding: 4px;\n    margin: 2px 16px;\n}\n\n.itemsContainer {\n    display: flex;\n    align-items: center;\n}\n\n.linkItem {\n    flex: 1;\n    color: blue;\n    text-decoration: underline;\n}\n\n.icon {\n    cursor: pointer;\n    padding: 0 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rowItem": "styles_rowItem__JEOju",
	"itemsContainer": "styles_itemsContainer__VKmru",
	"linkItem": "styles_linkItem__5I38+",
	"icon": "styles_icon__hxaft"
};
export default ___CSS_LOADER_EXPORT___;
