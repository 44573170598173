import { ISalaries } from "models/salaries";
import { getSalaries, getSalary } from "store/thunks/salariesThunk";

import { createSlice } from "@reduxjs/toolkit";

import { revertAll } from "../store";

interface SalariesSlice {
    salaries: ISalaries[];
    isGettingSalariesLoading: boolean;
    salariesPageCount: number;
    salary: any[]; //backend !!!,
    isLoadingSalary: boolean;
    activeSalary: ISalaries | null;
    activeEmployeeSalary: any;
}

const initialState: SalariesSlice = {
    salaries: [],
    isGettingSalariesLoading: false,
    salariesPageCount: 1,
    salary: [],
    isLoadingSalary: false,
    activeSalary: null,
    activeEmployeeSalary: null,
};

const salariesSlice = createSlice({
    name: "salaries",
    initialState,
    reducers: {
        setActiveSalary: (state, action) => {
            state.activeSalary = action.payload;
        },
        setActiveEmployeeSalary: (state, action) => {
            state.activeEmployeeSalary = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(revertAll, () => initialState);
        builder.addCase(getSalaries.pending, (state) => {
            state.isGettingSalariesLoading = true;
        });
        builder.addCase(getSalaries.fulfilled, (state, action) => {
            state.salaries = action.payload.data;
            state.salariesPageCount = action.payload.metadata.pageCount;
            state.isGettingSalariesLoading = false;
        });
        builder.addCase(getSalaries.rejected, (state) => {
            state.isGettingSalariesLoading = false;
        });
        builder.addCase(getSalary.pending, (state) => {
            state.isLoadingSalary = true;
        });
        builder.addCase(getSalary.fulfilled, (state, action) => {
            state.salary = action.payload.sort(
                (a: any, b: any) => b.isCurrent - a.isCurrent,
            );
        });
        builder.addCase(getSalary.rejected, (state) => {
            state.isLoadingSalary = false;
        });
    },
});

export const { setActiveSalary, setActiveEmployeeSalary } =
    salariesSlice.actions;

export default salariesSlice.reducer;
