import React, { FC, useEffect, useState } from "react";
import { ICellRendererParams } from "ag-grid-community";
import AgGrid from "features/AgGrid/AgGrid";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { setTrackingProject, setTrackingProjectsSearch } from "store";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import SearchIcon from "@mui/icons-material/Search";
import { Box } from "@mui/material";

import editIcon from "../../assets/icons/hoverEditIcon.svg";
import viewIcon from "../../assets/icons/view.svg";
import { getFromLocalStorage } from "../../core/utils";
import { formatDate } from "../../helpers/formatDate";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { ITrackingFilters } from "../../models/tracking";
import { getAllTimeKeepingOfProjects } from "../../store/thunks/trackingThunk";
import {
    materialReactTableHeader,
    projectsListTableCell,
} from "../../styles/MUIStyles";
import BootstrapTooltip from "../../ui/Tooltip";

import { TotalTimeCellRenderer } from "./Rerenders/EmployeeCellRerenders";
import {
    ClientProjectCellRenderer,
    CustomProjectCellRenderer,
    FromDateProjectCellRenderer,
    FteProjectCellRenderer,
    MainSkillProjectCellRenderer,
    NamePositionProjectCellRenderer,
    PersonnelNumberProjectCellRenderer,
    ProjectProjectCellRenderer,
    VendorProjectCellRenderer,
} from "./Rerenders/ProjectCellRerenders";

import styles from "./Tracking.module.scss";

interface Props {
    setTrackingPageNumber: (pageNumber: number) => void;
    debouncedSearch: string;
    trackingPageNumber: number;
    isTrackingSearchingRef: {
        current: boolean | null;
    };
    trackingSortConfig: {
        column: string | null;
        direction: string | null;
    };
    trackingHandleColumnClick: any;
    year: number;
    month: number;
    trackingFilters: ITrackingFilters;
    setTrackingFilters: () => void;
    handleColumnClick: any;
    trackingPageSize: any;
}

const AccountantProjectsTrackingTable: FC<Props> = (props) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const {
        debouncedSearch,
        setTrackingPageNumber,
        isTrackingSearchingRef,
        trackingSortConfig,
        trackingHandleColumnClick,
        trackingPageNumber,
        handleColumnClick,
        year,
        month,
        trackingFilters,
        trackingPageSize,
    } = props;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [highlightedRows, setHighlightedRows] = useState<string>();
    // const trackings = useAppSelector(state => state.tracking.projectsTracking);
    const activeProjectTracking = useAppSelector(
        (state) => state.tracking.activeProjectTracking,
    );
    // const activeTracking = useAppSelector(state => state.tracking.activeTracking);
    const search = useAppSelector(
        (state) => state.tracking.trackingProjectsSearch,
    );
    const projectsTracking = useAppSelector(
        (state) => state.tracking.projectsTracking,
    );

    const getArrowForSortDirection = (column: string) => {
        if (handleColumnClick.column !== column) {
            return null;
        }
        return handleColumnClick.direction === "asc" ? (
            <ArrowUpwardIcon sx={{ color: "#605E5C" }} />
        ) : (
            <ArrowDownwardIcon sx={{ color: "#605E5C" }} />
        );
    };
    const styleHeaderColumn = {
        display: "flex",
        alignItems: "center",
        gap: "1rem",
    };

    const customHeaderFilterComponent = (p: any) => {
        switch (p.column.colId) {
            case "client":
                return (
                    <Box
                        sx={materialReactTableHeader}
                        onClick={() => trackingHandleColumnClick("client")}
                    >
                        <span>{t("tracking.client")}</span>
                        {getArrowForSortDirection("client")}
                    </Box>
                );
            case "project":
                return (
                    <Box
                        sx={materialReactTableHeader}
                        onClick={() => trackingHandleColumnClick("shortName")}
                    >
                        <span>{t("data.project")}</span>
                        {getArrowForSortDirection("project")}
                    </Box>
                );
            case "personnelNumber":
                return (
                    <Box
                        sx={materialReactTableHeader}
                        onClick={() => trackingHandleColumnClick("shortName")}
                    >
                        <span>{t("data.tabNo")}</span>
                    </Box>
                );
            case "nameAndPosition":
                return (
                    <Box
                        sx={materialReactTableHeader}
                        onClick={() => trackingHandleColumnClick("shortName")}
                    >
                        <span>{t("data.nameAndPosition")}</span>
                    </Box>
                );
            case "organization":
                return (
                    <Box
                        sx={materialReactTableHeader}
                        onClick={() => trackingHandleColumnClick("shortName")}
                    >
                        <span>{t("data.vendor")}</span>
                    </Box>
                );
            case "fromDate":
                return (
                    <Box
                        sx={materialReactTableHeader}
                        onClick={() => trackingHandleColumnClick("shortName")}
                    >
                        <span>{t("data.fromDate")}</span>
                    </Box>
                );
            case "fte":
                return (
                    <Box
                        sx={materialReactTableHeader}
                        onClick={() => trackingHandleColumnClick("shortName")}
                    >
                        <span>{t("data.fte")}</span>
                    </Box>
                );
            case "hours":
                return (
                    <Box
                        sx={materialReactTableHeader}
                        onClick={() => trackingHandleColumnClick("shortName")}
                    >
                        <span>{t("data.hours")}</span>
                    </Box>
                );
            case "actions":
                return (
                    <Box
                        sx={materialReactTableHeader}
                        onClick={() => trackingHandleColumnClick("shortName")}
                    >
                        <span>{t("data.actions")}</span>
                    </Box>
                );
            case "skill":
                return (
                    <Box
                        sx={styleHeaderColumn}
                        onClick={() => trackingHandleColumnClick("shortName")}
                    >
                        <span>{t("data.mainSkill")}</span>
                    </Box>
                );
            default:
                return <Box>{p.column.colDef.field}</Box>;
        }
    };

    const ActionsCellRenderer: React.FC<ICellRendererParams> = (value) => {
        const { id } = value.node;
        const isHighlighted = highlightedRows === id;
        return (
            <Box sx={projectsListTableCell}>
                {isHighlighted && (
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            width: "40%",
                            cursor: "pointer",
                        }}
                    >
                        <BootstrapTooltip
                            sx={{ margin: "auto 0" }}
                            title={t("buttons.View")}
                            onClick={() =>
                                navigate(
                                    `management/view/${value.data.projectId}`,
                                )
                            }
                            placement="top"
                        >
                            <img src={viewIcon} alt="view icon" />
                        </BootstrapTooltip>
                        <BootstrapTooltip
                            onClick={() =>
                                navigate(
                                    `management/edit/${value.data.projectId}`,
                                )
                            }
                            title={t("buttons.Edit")}
                            placement="top"
                        >
                            <img src={editIcon} alt="edit icon" />
                        </BootstrapTooltip>
                    </Box>
                )}
            </Box>
        );
    };

    const generateColumnDefs = () => {
        const savedColumnState = getFromLocalStorage(
            "agGridTrackingProjectTable",
        );

        let columnDefs = [
            {
                headerName: t("data.actions"),
                sortable: false,
                field: "actions",
                width: 100,
                headerComponent: customHeaderFilterComponent,
                cellRenderer: CustomProjectCellRenderer,
            },
            {
                headerName: t("data.client"),
                field: "client",
                sortable: true,
                headerComponent: customHeaderFilterComponent,
                width: 150,
                cellRenderer: CustomProjectCellRenderer,
            },
            {
                headerName: t("data.project"),
                field: "project",
                sortable: true,
                headerComponent: customHeaderFilterComponent,
                width: 200,
                cellRenderer: CustomProjectCellRenderer,
            },
            {
                headerName: t("data.fromDate"),
                field: "fromDate",
                sortable: true,
                width: 120,
                headerComponent: customHeaderFilterComponent,
                cellRenderer: CustomProjectCellRenderer,
            },
            {
                headerName: t("data.tabNo"),
                field: "personnelNumber",
                sortable: true,
                width: 120,
                headerComponent: customHeaderFilterComponent,
                cellRenderer: CustomProjectCellRenderer,
            },
            {
                headerName: t("data.nameAndPosition"),
                field: "nameAndPosition",
                sortable: true,
                width: 250,
                headerComponent: customHeaderFilterComponent,
                cellRenderer: CustomProjectCellRenderer,
            },
            {
                headerName: t("data.mainSkill"),
                field: "skill",
                sortable: true,
                width: 150,
                headerComponent: customHeaderFilterComponent,
                cellRenderer: CustomProjectCellRenderer,
            },
            {
                headerName: t("data.vendor"),
                field: "organization",
                sortable: true,
                width: 200,
                headerComponent: customHeaderFilterComponent,
                cellRenderer: CustomProjectCellRenderer,
            },
            {
                headerName: t("data.fte"),
                field: "fte",
                sortable: true,
                width: 100,
                headerComponent: customHeaderFilterComponent,
                cellRenderer: CustomProjectCellRenderer,
            },
            {
                headerName: t("data.hours"),
                field: "hours",
                sortable: true,
                width: 120,
                headerComponent: customHeaderFilterComponent,
                cellRenderer: CustomProjectCellRenderer,
            },
        ];
        if (Array.isArray(savedColumnState)) {
            columnDefs = savedColumnState.map((columnState: any) => {
                return {
                    // @ts-ignore
                    headerComponent: customHeaderFilterComponent,
                    // @ts-ignore
                    cellRenderer: CustomProjectCellRenderer,
                    field: columnState.colId,
                    width: columnState.width,
                    pinned: columnState.pinned,
                    displayName: columnState.displayName,
                    resizable: true,
                    ...columnState,
                };
            });
        }

        return columnDefs;
    };

    useEffect(() => {
        if (
            isTrackingSearchingRef.current &&
            trackingPageNumber > 1 &&
            search
        ) {
            setTrackingPageNumber(1);
        } else {
            const startDate = formatDate(trackingFilters, "fromStartDate");
            const endDate = formatDate(trackingFilters, "beforeStartDate");

            dispatch(
                getAllTimeKeepingOfProjects(
                    // @ts-ignore
                    {
                        pageNumber: trackingPageNumber,
                        pageSize: trackingPageSize || 0,
                        year: year,
                        month: month,
                        fullName: debouncedSearch,
                        sortBy: trackingSortConfig,
                        ...trackingFilters,
                        fromStartDate: startDate.fromStartDate,
                        beforeStartDate: endDate.beforeStartDate,
                    },
                ),
            );
        }
    }, [
        year,
        month,
        dispatch,
        debouncedSearch,
        setTrackingPageNumber,
        isTrackingSearchingRef,
        trackingPageNumber,
        trackingSortConfig,
        trackingFilters,
        trackingPageSize,
        search,
    ]);

    const saveColumnState = (columnState: any) => {
        localStorage.setItem(
            "agGridTrackingProjectTable",
            JSON.stringify(columnState),
        );
    };
    const headerComponents = {
        client: customHeaderFilterComponent,
        project: customHeaderFilterComponent,
        fromDate: customHeaderFilterComponent,
        personnelNumber: customHeaderFilterComponent,
        nameAndPosition: customHeaderFilterComponent,
        skill: customHeaderFilterComponent,
        organization: customHeaderFilterComponent,
        fte: customHeaderFilterComponent,
        hours: customHeaderFilterComponent,
        actions: customHeaderFilterComponent,
    };

    const cellRenderers = {
        client: ClientProjectCellRenderer,
        project: ProjectProjectCellRenderer,
        fromDate: FromDateProjectCellRenderer,
        personnelNumber: PersonnelNumberProjectCellRenderer,
        nameAndPosition: NamePositionProjectCellRenderer,
        skill: MainSkillProjectCellRenderer,
        organization: VendorProjectCellRenderer,
        fte: FteProjectCellRenderer,
        hours: TotalTimeCellRenderer,
        actions: ActionsCellRenderer,
    };
    const columnDefs: any[] = generateColumnDefs();
    return (
        <div style={{ marginTop: "20px" }}>
            <div className={styles.searchWrapper}>
                <input
                    name="search"
                    type="text"
                    autoComplete="off"
                    value={search}
                    onChange={(e) => {
                        dispatch(setTrackingProjectsSearch(e.target.value));
                        isTrackingSearchingRef.current = true;
                    }}
                    placeholder={t("filters.searchByProjectsName") as string}
                    className={styles.search}
                />
                <button type="button" className={styles.searchIconWrapper}>
                    <SearchIcon sx={{ color: "#fff" }} />
                </button>
            </div>
            <Box ml={1} mt={1}>
                <AgGrid
                    columnDefs={columnDefs}
                    gridOptions={{
                        rowClass: "rowClassTracking",
                    }}
                    components={{ ...headerComponents, ...cellRenderers }}
                    rowData={projectsTracking}
                    activeRow={activeProjectTracking}
                    height="60vh"
                    saveColumnState={saveColumnState}
                    setActiveRow={setTrackingProject}
                    page="trackingProject"
                />
            </Box>
        </div>
    );
};

export default AccountantProjectsTrackingTable;
