import axios from "axios";
import axiosApi from "core/request";
import { errorNotification } from "core/utils/errorNotification";
import { ISalariesFilter } from "models/salaries";
import { ISortConfig } from "models/user";

import { createAsyncThunk } from "@reduxjs/toolkit";

export const getSalaries = createAsyncThunk(
    "salaries/getSalaries",
    async (
        queryParams: ISalariesFilter & {
            sortBy?: ISortConfig;
            fullName?: string;
            pageNumber?: number;
            pageSize?: string;
        } | null,
        { rejectWithValue }
    ) => {
        try {
            let params = {};

            if (queryParams) {
                const {
                    fullName,
                    organizationId,
                    positionId,
                    skillId,
                    skillLevelId,
                    costCenterId,
                    typeOfEmploymentId,
                    status,
                    fromStartDate,
                    beforeStartDate,
                    sortBy,
                    pageNumber,
                    pageSize,
                } = queryParams;

                params = {
                    ...params,
                    ...(organizationId && {
                        "filter.organizationId": organizationId,
                    }),
                    ...(typeOfEmploymentId && {
                        "filter.typeOfEmploymentId": typeOfEmploymentId,
                    }),
                    ...(fullName && { "filter.fullName": fullName }),
                    ...(positionId && { "filter.positionId": positionId }),
                    ...(skillId && { "filter.skillId": skillId }),
                    ...(skillLevelId && {
                        "filter.skillLevelId": skillLevelId,
                    }),
                    ...(costCenterId && {
                        "filter.costCenterId": costCenterId,
                    }),
                    ...(status && { "filter.status": status }),
                    ...(fromStartDate && {
                        "filter.fromStartDate": fromStartDate,
                    }),
                    ...(beforeStartDate && {
                        "filter.beforeStartDate": beforeStartDate,
                    }),
                    ...(pageNumber && { pageNumber: pageNumber }),
                    ...(pageSize && { pageSize: pageSize }),
                    ...(sortBy?.column &&
                        sortBy?.direction && {
                            sortBy: `${sortBy.column}.${sortBy.direction}`,
                        }),
                };
            }

            const { data } = await axiosApi.get("salaries/all", { params });
            return data;
        } catch (error) {
            let errorMessage = error;

            if (axios.isAxiosError(error)) {
                errorMessage = error.response?.data?.errors?.raw?.[0];
                errorNotification(
                    "Произошла ошибка, перезагрузите страницу..."
                );
            } else {
                console.error(error);
            }

            return rejectWithValue(errorMessage);
        }
    }
);

export const getSalary = createAsyncThunk(
    "salaries/getSalary",
    async (employeeId: string, { rejectWithValue }) => {
        try {
            const params = new URLSearchParams();

            params.append("employeeId", employeeId);

            const { data } = await axiosApi.get(
                "salaries/salariesbyemployeeid",
                { params }
            );
            return data;
        } catch (error) {
            let errorMessage = error;

            if (axios.isAxiosError(error)) {
                errorMessage = error.response?.data?.errors?.raw?.[0];
                errorNotification(
                    "Произошла ошибка, перезагрузите страницу..."
                );
            } else {
                console.error(error);
            }

            return rejectWithValue(errorMessage);
        }
    }
);

export const createSalary = createAsyncThunk(
    "salaries/createSalaries",
    async (salaryData: any, { rejectWithValue }) => {
        try {
            const { data } = await axiosApi.post("salaries", salaryData);
            return data;
        } catch (error) {
            let errorMessage = error;

            if (axios.isAxiosError(error)) {
                errorMessage = error.response?.data?.errors?.raw?.[0];
                errorNotification(
                    "Произошла ошибка, перезагрузите страницу..."
                );
            } else {
                console.error(error);
            }

            return rejectWithValue(errorMessage);
        }
    }
);

export const editSalary = createAsyncThunk(
    "salaries/editSalaries",
    async (salaryData: any, { rejectWithValue }) => {
        try {
            const { data } = await axiosApi.put("salaries", salaryData);
            return data;
        } catch (error) {
            let errorMessage = error;

            if (axios.isAxiosError(error)) {
                errorMessage = error.response?.data?.errors?.raw?.[0];
                errorNotification(
                    "Произошла ошибка, перезагрузите страницу..."
                );
            } else {
                console.error(error);
            }

            return rejectWithValue(errorMessage);
        }
    }
);
