import React from "react";
import impulseLogo from "assets/logos/impulse_logo.png";
import timelySoftLogo from "assets/logos/timelysoft-logo.png";
import LanguageDropDown from "features/LanguageDropDown";

import styles from "./styles.module.scss";

const Header: React.FC<{ className: string }> = ({ className }) => {
    return (
        <header className={`${className} ${styles.header}`}>
            <div>
                <img
                    src={timelySoftLogo}
                    alt="TimelySoft"
                    className={styles.logo}
                />
            </div>
            <div>
                <img src={impulseLogo} alt="Impulse" className={styles.logo} />
            </div>
            <LanguageDropDown />
        </header>
    );
};

export default Header;
