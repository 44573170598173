import React, { FC, useEffect } from 'react';
import cn from 'classnames';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@mui/material';

import { cancelBtn, saveBtn } from '../../styles/MUIStyles';
import ButtonWithProgress from '../ButtonWithProgress';

import localStyles from '../../views/PersonalAccount/PersonalInfo/styles.module.scss';
import stylesInput from '../../views/Salaries/SalariesActions/SalariesActions.module.scss';
import styles from './styles.module.scss';

interface SelectFileProps {
    id?: number;
    fileInput: React.MutableRefObject<null>;
    handleUpload: (event: any) => void;
    save: (data: any) => void;
    handleCancelClick: () => void;
    name?: string;
    description?: string;
    file?: File | null;
    setName?: (name: string) => void;
    setFile?: (file: File | null) => void;
    setDescription?: (description: string) => void;
    originalFileName?: string;
}

export const SelectFile: FC<SelectFileProps> = ({
                                                    id,
                                                    name = '',
                                                    fileInput,
                                                    handleUpload,
                                                    save,
                                                    handleCancelClick,
                                                    file,
                                                    description = '',
                                                    setName,
                                                    setDescription,
                                                    setFile,
                                                    originalFileName = ''
                                                }) => {
    const { t } = useTranslation();
    const { control, handleSubmit, formState: { errors }, reset } = useForm({
        defaultValues: {
            id: id,
            name: name,
            description: description
        }
    });

    useEffect(() => {
        reset({
            name: name,
            description: description,
            id: id
        });
        setFile?.(null);
    }, [name, description, id, reset, setFile]);

    return (
        <div style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            textAlign: 'center',
            padding: '20px',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
            borderRadius: '8px',
        }} className={styles.container}>
            <div>
                <div className={styles.controllerWrapper}>
                    <label style={{ display: 'block', textAlign: 'start' }} htmlFor="name">{t('data.name')}</label>
                    <Controller
                        name="name"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                            <input
                                placeholder={t('projects.enterDocumentName') || ''}
                                {...field}
                                onChange={(e) => {
                                    field.onChange(e);
                                    setName?.(e.target.value);
                                }}
                                type="text"
                                className={cn(stylesInput.input, styles.label)}
                            />
                        )}
                    />
                </div>
                {errors.name && <div style={{ color: 'red', textAlign: 'start' }}>{t('validation.requiredField')}</div>}
                <div className={styles.controllerWrapper}>
                    <label style={{ display: 'block', textAlign: 'start' }}
                           htmlFor="description">{t('data.description')}</label>
                    <Controller
                        name="description"
                        control={control}
                        render={({ field }) => (
                            <input
                                placeholder={t('projects.enterDocumentDescription') || ''}
                                {...field}
                                onChange={(e) => {
                                    field.onChange(e);
                                    setDescription?.(e.target.value);
                                }}
                                type="text"
                                className={cn(stylesInput.input, styles.label)}
                            />
                        )}
                    />
                </div>
                <input
                    style={{ display: 'none' }}
                    id="customFileInput"
                    type="file"
                    ref={fileInput}
                    onChange={handleUpload}
                />
                <div className={styles.controllerWrapper}>
                    <label
                        htmlFor="customFileInput"
                        className={localStyles.fileInputLabel}>
                        {t('projects.chooseFile')}
                    </label>
                    <label
                        className={localStyles.fileInputDescription}
                        htmlFor="customFileInput">
                        {file?.name || originalFileName || t('projects.chooseFile')}
                    </label>
                </div>
            </div>
            <div>
                <ButtonWithProgress
                    onClick={handleSubmit((data) => {
                        save(data);
                    })}
                    loading={false}
                    disabled={false}
                    type="submit"
                    variant="contained"
                    sx={saveBtn}
                >
                    {t('buttons.Save')}
                </ButtonWithProgress>

                <Button
                    variant="text"
                    onClick={handleCancelClick}
                    sx={cancelBtn}
                >
                    {t('buttons.Cancel')}
                </Button>
            </div>
        </div>
    );
};
