import React, { FC } from "react";
import Guard from "features/Guard";
import { useAppDispatch, useAppSelector, useDocumentDownloader } from "hooks";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";

import { PROJECTS_PATH } from "../../../../router/constants";
import { changeActiveTabInEditProject } from "../../../../store";
import {
    activateOrDeactivateBtn as editProjectBtn,
    cancelBtn,
} from "../../../../styles/MUIStyles";

interface Props {
    activeTab: number;
    allowedPermissions: any[];
}

const Documents: FC<Props> = ({
    activeTab,
    allowedPermissions,
}): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const { isLoading, downloadDocument } = useDocumentDownloader();

    const projectDataToView = useAppSelector(
        (state) => state.projects.projectDataToView,
    );

    return (
        <Box sx={{ width: "50%" }}>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    margin: "1rem 0",
                    paddingLeft: "2rem",
                }}
            >
                <Typography
                    sx={{
                        color: "#BEBEBE",
                        fontWeight: 600,
                        width: "50%",
                        fontFamily: "Source Sans Pro",
                        fontSize: "18px",
                    }}
                >
                    {projectDataToView?.shortName}
                </Typography>
                <Box>
                    <Guard
                        allowedPermissions={allowedPermissions}
                        permissionName="Edit"
                    >
                        <Button
                            variant="contained"
                            type="submit"
                            sx={editProjectBtn}
                            onClick={() => {
                                dispatch(
                                    changeActiveTabInEditProject(activeTab),
                                );
                                navigate(
                                    `/projects/management/edit/${params.projectId}`,
                                );
                            }}
                        >
                            {t("buttons.Edit")}
                        </Button>
                    </Guard>
                    <Button
                        variant="text"
                        onClick={() => navigate("/" + PROJECTS_PATH)}
                        sx={cancelBtn}
                    >
                        {t("buttons.Close")}
                    </Button>
                </Box>
            </Box>
            <Box sx={{ paddingLeft: "2rem" }}>
                {projectDataToView?.documents.map((document) => (
                    <Box
                        key={document.id}
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: "1rem",
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 600,
                                fontFamily: "Source Sans Pro",
                            }}
                        >
                            {document.name}
                        </Typography>
                        <Typography sx={{ fontFamily: "Source Sans Pro" }}>
                            {document.description}
                        </Typography>
                        <Guard
                            allowedPermissions={allowedPermissions}
                            permissionName="DownloadFile"
                        >
                            <Button
                                sx={{
                                    marginRight: "auto",
                                    padding: "5px 0",
                                    textDecoration: "underline",
                                    textUnderlinePosition: "under",
                                    textTransform: "none",
                                    "&:hover": {
                                        backgroundColor: "transparent",
                                        textDecoration: "underline",
                                    },
                                    position: "relative",
                                    right: "4px",
                                }}
                                variant="text"
                                disabled={isLoading.includes(document.id)}
                                onClick={() =>
                                    downloadDocument(document.id, "/projects")
                                }
                            >
                                Открыть
                            </Button>
                        </Guard>
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default Documents;
