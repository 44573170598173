import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { useAppDispatch } from 'hooks';
import { useTranslation } from 'react-i18next';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'styles/agGrid.css';

interface Props {
    rowData: any[];
    columnDefs: any[];
    saveColumnState?: (columnState: any) => void;
    activeRow: any;
    setActiveRow?: (activeRow: any) => any;
    height: string;
    page?: string;
    gridOptions?: {};
    components?: {};
    onSortChanged?: (params: any) => void;
    headerHeight?: number;
}

const AgGrid: FC<Props> = (props) => {
    const setActiveRow = props.setActiveRow || function () {
    };
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const gridRef = useRef<any>();

    const getLocaleText = useCallback((params: any) => {
        switch (params.key) {
            case 'noRowsToShow':
                return t('data.noRecordsToShow');
            default:
                return '';
        }
    }, []);

    const getRowStyle = (params: any) => {


        if (props.page === 'employeeProject') {
            return {
                background:
                    props?.activeRow?.employeeId === params.data.employeeId
                        ? '#f4f3f2'
                        : 'transparent',
            };
        }

        if (props.page === 'trackingProject') {
            return {
                background:
                    props?.activeRow?.projectId === params.data.projectId
                        ? '#f4f3f2'
                        : 'transparent',
            };
        }
        if (
            (props.page && props.page === 'salaries') ||
            props.page === 'vacationSickDays'
        ) {
            return {
                background:
                    params.data?.employeeId === props.activeRow?.employeeId
                        ? '#f4f3f2'
                        : 'transparent',
            };
        }
        if (props.page && props.page === 'trackingEmployee') {
            return {
                background:
                    params.data?.projectId === props.activeRow?.projectId
                        ? '#f4f3f2'
                        : 'transparent',
            };
        }

        return {
            background:
                params.data.id === props.activeRow?.id
                    ? '#f4f3f2 '
                    : 'transparent',
        };
    };

    useEffect(() => {
        if (gridRef.current && props.activeRow) {
            gridRef.current.api?.redrawRows();
        }
    }, [props.activeRow]);

    const defaultColDef = useMemo(
        () => ({
            resizable: true,
            autoHeight: true,
        }),
        []
    );

    return (
        <div
            className="ag-theme-alpine"
            style={{
                height: props.height,
                width: '100%',
                padding: '5px',
                boxShadow: `rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px`,
            }}
        >
            <AgGridReact
                headerHeight={props.headerHeight}
                ref={gridRef}
                rowData={props.rowData}
                columnDefs={props.columnDefs}
                gridOptions={props.gridOptions}
                components={props.components}
                onSortChanged={props.onSortChanged}
                onColumnMoved={(e: any) =>
                    props.saveColumnState?.(e.columnApi.getColumnState())
                }
                onColumnResized={(e: any) =>
                    props.saveColumnState?.(e.columnApi.getColumnState())
                }
                onColumnPinned={(e: any) =>
                    props.saveColumnState?.(e.columnApi.getColumnState())
                }
                onRowClicked={props.setActiveRow ? (e: any) => dispatch(setActiveRow(e.data)) : undefined}
                suppressDragLeaveHidesColumns={true}
                getLocaleText={getLocaleText}
                getRowStyle={getRowStyle}
                defaultColDef={defaultColDef}
            />
        </div>
    );
};

export default AgGrid;
