import axios from "axios";
import axiosApi from "core/request";
import dayjs, { Dayjs } from "dayjs";
import {
    EmployeeStatuses,
    IAddDocumentToProject,
    IEditEmployee,
    IEditGeneralInfo,
    IEditProjectDocument,
    Status,
} from "models/projects";

import { createAsyncThunk } from "@reduxjs/toolkit";

import { ILookupItem } from "../../models/lookups";
import { sendNotification } from "../../ui/Toast";

export const getProjects = createAsyncThunk(
    "projects/getProjects",
    async (
        projectsQuery: {
            pageNumber: number;
            pageSize?: number;
            searchProject?: string;
            selectedStatuses?: Status[];
            client?: ILookupItem | null;
            employee?: ILookupItem | null;
            startFTE?: string;
            endFTE?: string;
            fromStartDate?: Dayjs | null;
            beforeStartDate?: Dayjs | null;
            fromEndDate?: Dayjs | null;
            beforeEndDate?: Dayjs | null;
            sortBy?: { column: string | null; direction: string | null };
        },
        { rejectWithValue },
    ) => {
        try {
            const params = new URLSearchParams();

            if (
                projectsQuery.selectedStatuses &&
                projectsQuery.selectedStatuses.length > 0
            ) {
                projectsQuery.selectedStatuses.forEach((status) => {
                    params.append("filter.statuses", status);
                });
            }

            if (projectsQuery.pageNumber) {
                params.append("pageNumber", String(projectsQuery.pageNumber));
            }

            if (projectsQuery.pageSize) {
                params.append("pageSize", String(projectsQuery.pageSize));
            }

            if (projectsQuery.searchProject) {
                params.append("filter.name", projectsQuery.searchProject);
            }

            if (projectsQuery.client) {
                params.append(
                    "filter.clientId",
                    String(projectsQuery.client.id),
                );
            }

            if (projectsQuery.employee) {
                params.append(
                    "filter.managerId",
                    String(projectsQuery.employee.id),
                );
            }

            if (projectsQuery.startFTE) {
                params.append("filter.startFTE", projectsQuery.startFTE);
            }

            if (projectsQuery.endFTE) {
                params.append("filter.endFTE", projectsQuery.endFTE);
            }

            if (projectsQuery.fromStartDate) {
                params.append(
                    "filter.fromStartDate",
                    dayjs(projectsQuery.fromStartDate).format("MM.DD.YYYY"),
                );
            }

            if (projectsQuery.beforeStartDate) {
                params.append(
                    "filter.beforeStartDate",
                    dayjs(projectsQuery.beforeStartDate).format("MM.DD.YYYY"),
                );
            }

            if (projectsQuery.fromEndDate) {
                params.append(
                    "filter.fromEndDate",
                    dayjs(projectsQuery.fromEndDate).format("MM.DD.YYYY"),
                );
            }

            if (projectsQuery.beforeEndDate) {
                params.append(
                    "filter.beforeEndDate",
                    dayjs(projectsQuery.beforeEndDate).format("MM.DD.YYYY"),
                );
            }

            if (
                projectsQuery.sortBy?.column &&
                projectsQuery.sortBy.direction
            ) {
                const column = projectsQuery.sortBy?.column;
                const direction = projectsQuery.sortBy.direction;
                params.append("sortBy", `${column}.${direction}`);
            }

            const response = await axiosApi.get("/projects/all", { params });
            return response.data;
        } catch (error) {
            return rejectWithValue("Error");
        }
    },
);

export const getEmployeesLookUp = createAsyncThunk(
    "projects/getEmployeesLookUp",
    async (_, { rejectWithValue }) => {
        try {
            const response = await axiosApi.get("employees/employeeslookup");
            return response.data;
        } catch (error) {
            return rejectWithValue("Error");
        }
    },
);

export const getProjectByIdForUpdate = createAsyncThunk(
    "projects/getProjectByIdForUpdate",
    async (projectId: number, { rejectWithValue }) => {
        try {
            const response = await axiosApi.get(
                `projects/details?id=${projectId}`,
            );
            return response.data;
        } catch (error) {
            return rejectWithValue("Error");
        }
    },
);

export const getProjectByIdToView = createAsyncThunk(
    "projects/getProjectByIdToView",
    async (projectId: number, { rejectWithValue }) => {
        try {
            const response = await axiosApi.get(
                `/projects/partial?id=${projectId}`,
            );
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                if (error.response.status === 400) {
                    return rejectWithValue(error.response.data.errors);
                } else {
                    return rejectWithValue(
                        "Что-то пошло не так... Попробуйте обновить страницу",
                    );
                }
            }

            if (!navigator.onLine) {
                return rejectWithValue("Проверьте подключение к интернету");
            }

            return rejectWithValue(
                "Что-то пошло не так... Попробуйте обновить страницу",
            );
        }
    },
);

export const getClientsLookUp = createAsyncThunk(
    "projects/getClientsLookUp",
    async (_, { rejectWithValue }) => {
        try {
            const response = await axiosApi.get("/lookups/organizations");
            return response.data;
        } catch (error) {
            return rejectWithValue("Error");
        }
    },
);

export const getEmployeesByProjectId = createAsyncThunk(
    "projects/getEmployeesByProjectId",
    async (
        employeeQuery: {
            projectId: number;
            fullName?: string;
            position?: ILookupItem | null;
            organization?: ILookupItem | null;
            skill?: ILookupItem | null;
            startFTE?: string;
            pageSize?: number;
            endFTE?: string;
            fromStartDate?: Dayjs | null;
            beforeStartDate?: Dayjs | null;
            fromEndDate?: Dayjs | null;
            beforeEndDate?: Dayjs | null;
            employeeStatus?: EmployeeStatuses | "";
            sortBy?: { column: string | null; direction: string | null };
        },
        { rejectWithValue },
    ) => {
        try {
            const params = new URLSearchParams();

            if (employeeQuery.fullName) {
                params.append("filter.fullName", employeeQuery.fullName);
            }

            if (employeeQuery.pageSize) {
                params.append("pageSize", String(employeeQuery.pageSize));
            }

            if (employeeQuery.projectId) {
                params.append("projectId", String(employeeQuery.projectId));
            }

            if (employeeQuery.position) {
                params.append(
                    "filter.positionId",
                    String(employeeQuery.position.id),
                );
            }

            if (employeeQuery.organization) {
                params.append(
                    "filter.organizationId",
                    String(employeeQuery.organization.id),
                );
            }

            if (employeeQuery.skill) {
                params.append("filter.skillId", String(employeeQuery.skill.id));
            }

            if (employeeQuery.startFTE) {
                params.append(
                    "filter.startFTE",
                    String(employeeQuery.startFTE),
                );
            }

            if (employeeQuery.endFTE) {
                params.append("filter.endFTE", String(employeeQuery.endFTE));
            }

            if (employeeQuery.employeeStatus) {
                params.append(
                    "filter.status",
                    String(employeeQuery.employeeStatus),
                );
            }

            if (
                employeeQuery.sortBy?.column &&
                employeeQuery.sortBy.direction
            ) {
                const column = employeeQuery.sortBy?.column;
                const direction = employeeQuery.sortBy.direction;
                params.append("sortBy", `${column}.${direction}`);
            }

            if (employeeQuery.fromStartDate) {
                params.append(
                    "filter.fromStartDate",
                    dayjs(employeeQuery.fromStartDate).format("MM.DD.YYYY"),
                );
            }

            if (employeeQuery.beforeStartDate) {
                params.append(
                    "filter.beforeStartDate",
                    dayjs(employeeQuery.beforeStartDate).format("MM.DD.YYYY"),
                );
            }

            if (employeeQuery.fromEndDate) {
                params.append(
                    "filter.fromEndDate",
                    dayjs(employeeQuery.fromEndDate).format("MM.DD.YYYY"),
                );
            }

            if (employeeQuery.beforeEndDate) {
                params.append(
                    "filter.beforeEndDate",
                    dayjs(employeeQuery.beforeEndDate).format("MM.DD.YYYY"),
                );
            }

            const response = await axiosApi.get(
                `projects/employeesbyprojectid`,
                { params },
            );
            return response.data.data;
        } catch (error) {
            return rejectWithValue("Error");
        }
    },
);

export const createProject = createAsyncThunk<
    any,
    any,
    { rejectValue: string[] | string }
>("projects/createProject", async (projectData: any, { rejectWithValue }) => {
    try {
        const response = await axiosApi.post("/projects", projectData);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            if (error.response.status === 400) {
                return rejectWithValue(error.response.data.errors);
            } else {
                return rejectWithValue(
                    "Что-то пошло не так... Попробуйте обновить страницу",
                );
            }
        }

        if (!navigator.onLine) {
            return rejectWithValue("Проверьте подключение к интернету");
        }

        return rejectWithValue(
            "Что-то пошло не так... Попробуйте обновить страницу",
        );
    }
});

export const addEmployeeToProject = createAsyncThunk<
    any,
    any,
    { rejectValue: string[] | string }
>(
    "projects/addEmployeeToProject",
    async (projectData: any, { rejectWithValue }) => {
        try {
            const response = await axiosApi.post(
                "/projects/employee",
                projectData,
            );
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                if (error.response.status === 400) {
                    if (
                        error.response.data.errors?.projectId[0] ===
                            "Нельзя добавить сотрудника, если проект не активен" ||
                        error.response.data.errors?.projectId[0] ===
                            "You cannot add an employee if the project is not active"
                    ) {
                        sendNotification(
                            error.response.data.errors?.projectId[0],
                            "error",
                        );
                    }
                    return rejectWithValue(error.response.data.errors);
                } else {
                    return rejectWithValue(
                        "Что-то пошло не так... Попробуйте обновить страницу",
                    );
                }
            }

            if (!navigator.onLine) {
                return rejectWithValue("Проверьте подключение к интернету");
            }

            return rejectWithValue(
                "Что-то пошло не так... Попробуйте обновить страницу",
            );
        }
    },
);

export const addDocumentToProject = createAsyncThunk(
    "projects/addDocumentToProject",
    async (document: IAddDocumentToProject, { rejectWithValue }) => {
        try {
            const formData = new FormData();
            formData.append("projectId", String(document.projectId));
            formData.append("name", document.name);
            formData.append("description", document.description);
            if (document.file instanceof FileList) {
                formData.append("attachment", document.file[0]);
            }

            const response = await axiosApi.post(
                "/projects/attachmentdocument",
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                },
            );

            return response.data;
        } catch (error) {
            return rejectWithValue("Error");
        }
    },
);

export const editGeneralInfo = createAsyncThunk(
    "projects/editGeneralInfo",
    async (projectData: IEditGeneralInfo, { rejectWithValue }) => {
        try {
            const response = await axiosApi.put("/projects", projectData);
            return response.data;
        } catch (error) {
            return rejectWithValue("Error");
        }
    },
);

export const editProjectDocument = createAsyncThunk(
    "projects/editProjectDocument",
    async (document: IEditProjectDocument, { rejectWithValue }) => {
        try {
            const formData = new FormData();
            formData.append("id", String(document.id));
            formData.append("name", document.name);
            formData.append("description", document.description);
            if (document.file instanceof FileList) {
                formData.append("attachment", document.file[0]);
            }

            const response = await axiosApi.put(
                "/projects/attachmentdocument",
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                },
            );

            return response.data;
        } catch (error) {
            return rejectWithValue("Error");
        }
    },
);

export const deleteProjectDocument = createAsyncThunk(
    "projects/deleteProjectDocument",
    async (documentId: number, { rejectWithValue }) => {
        try {
            const response = await axiosApi.delete(
                `/projects/attachmentdocument?id=${documentId}`,
            );
            return response.data;
        } catch (error) {
            return rejectWithValue("Error");
        }
    },
);

export const editEmployeeInProject = createAsyncThunk(
    "projects/editEmployeeInProject",
    async (employee: IEditEmployee, { rejectWithValue }) => {
        try {
            const response = await axiosApi.put("/projects/employee", employee);
            return response.data;
        } catch (error) {
            return rejectWithValue("Error");
        }
    },
);

export const activateProject = createAsyncThunk(
    "projects/activateProject",
    async (projectId: number, { rejectWithValue }) => {
        try {
            const response = await axiosApi.put(
                `/projects/activate?id=${projectId}`,
            );
            return response.data;
        } catch (error) {
            return rejectWithValue("Error");
        }
    },
);

export const deactivateProject = createAsyncThunk(
    "projects/deactivateProject",
    async (projectId: number, { rejectWithValue }) => {
        try {
            const response = await axiosApi.put(
                `/projects/deactivate?id=${projectId}`,
            );
            return response.data;
        } catch (error) {
            return rejectWithValue("Error");
        }
    },
);

export const deleteEmployee = createAsyncThunk(
    "projects/deleteEmployee",
    async (employeeId: number, { rejectWithValue }) => {
        try {
            const response = await axiosApi.delete(
                `projects/employee?id=${employeeId}`,
            );
            return response.data;
        } catch (error) {
            return rejectWithValue("Error");
        }
    },
);
