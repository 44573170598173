import React, { useEffect, useState } from "react";
import alertError from "assets/icons/alertError.svg";
import closedEye from "assets/icons/closedEye.svg";
import microsoftLogo from "assets/icons/microsoftLogo.svg";
import openedEye from "assets/icons/openedEye.svg";
import { useAppDispatch, useAppSelector } from "hooks";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { login } from "store/thunks/authThunk";

import { ErrorMessage } from "@hookform/error-message";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";

import { langs } from "../../i18n";
import Toast from "../../ui/Toast";

import styles from "./LoginForm.module.scss";

type Inputs = {
    email: string;
    password: string;
};

export function LoginForm() {
    const dispatch = useAppDispatch();
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm<Inputs>();
    const { t, i18n } = useTranslation();
    const hasEmailError = errors?.email?.type;
    const hasPasswordError = errors?.password?.type;

    const loginErrors = useAppSelector((state) => state.auth.loginErrors);
    const isUserLoginLoading = useAppSelector(
        (state) => state.auth.isUserLoginLoading,
    );

    const [checked, setChecked] = useState(true);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const isLanguageDropDownOpen = Boolean(anchorEl);

    const onSubmit: SubmitHandler<Inputs> = (userData) => {
        dispatch(
            login({ username: userData.email, password: userData.password }),
        );
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (loginErrors && loginErrors.errors) {
            if ("userName" in loginErrors.errors) {
                setError(
                    "email",
                    {
                        type: "custom",
                        message:
                            loginErrors.errors?.userName?.[0] === "Required"
                                ? "This field is required."
                                : "Это поле обязательно для заполнения.",
                    },
                    { shouldFocus: true },
                );
            }

            if ("password" in loginErrors.errors) {
                setError(
                    "password",
                    {
                        type: "custom",
                        message:
                            loginErrors.errors?.userName?.[0] === "Required"
                                ? "This field is required."
                                : "Это поле обязательно для заполнения.",
                    },
                    { shouldFocus: true },
                );
            }

            if ("loginError" in loginErrors.errors) {
                setError(
                    "email",
                    {
                        type: "custom",
                        message: loginErrors?.errors?.loginError?.[0],
                    },
                    { shouldFocus: true },
                );

                setError(
                    "password",
                    {
                        type: "custom",
                        message: loginErrors?.errors?.loginError?.[0],
                    },
                    { shouldFocus: true },
                );
            }
        }
    }, [loginErrors, setError]);

    useEffect(() => {
        if (errors?.email?.type === "required") {
            setError(
                "email",
                {
                    type: "required",
                    message: t("validation.requiredField") as string,
                },
                { shouldFocus: true },
            );
        }

        if (errors?.password?.type === "required") {
            setError(
                "password",
                {
                    type: "required",
                    message: t("validation.requiredField") as string,
                },
                { shouldFocus: true },
            );
        }
    }, [t, errors, setError]);

    return (
        <div className={styles.loginContainer}>
            <div className={styles.loginCentralBlock}>
                <div className={styles.test}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={styles.loginFormInner}>
                            <div className={styles.loginFormInnerTop}>
                                <h1 className={styles.loginFormInnerTopTitle}>
                                    {t("loginForm.signIn")}
                                </h1>
                                <Button
                                    variant="text"
                                    sx={{
                                        textTransform: "capitalize",
                                        fontSize: "1.1rem",
                                        color: "#0b4d88",
                                    }}
                                    id="basic-button"
                                    aria-controls={
                                        isLanguageDropDownOpen
                                            ? "basic-menu"
                                            : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={
                                        isLanguageDropDownOpen
                                            ? "true"
                                            : undefined
                                    }
                                    onClick={handleClick}
                                >
                                    {t("loginForm.dropDownLabel")}
                                </Button>
                                <Menu
                                    id="basic-menu"
                                    sx={{
                                        boxShadow:
                                            "0 4.8px 14.4px rgba(0,0,0,18)",
                                    }}
                                    anchorEl={anchorEl}
                                    open={isLanguageDropDownOpen}
                                    onClose={handleClose}
                                >
                                    {langs.map((lng) => (
                                        <MenuItem
                                            key={lng}
                                            onClick={() => {
                                                i18n.changeLanguage(lng);
                                                handleClose();
                                            }}
                                        >
                                            {i18n.t(`languages.${lng}`)}
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </div>
                            <div
                                className={
                                    hasEmailError
                                        ? styles.loginFormInnerEmailContainer +
                                          " " +
                                          styles.loginFormInnerEmailContainerError
                                        : styles.loginFormInnerEmailContainer
                                }
                            >
                                <input
                                    {...register("email", {
                                        required: t(
                                            "loginForm.requiredField",
                                        ) as string,
                                    })}
                                    className={
                                        hasEmailError
                                            ? styles.loginFormInnerEmailInput +
                                              " " +
                                              styles.loginFormInnerEmailInputError
                                            : styles.loginFormInnerEmailInput
                                    }
                                    type="text"
                                    autoComplete="username"
                                    placeholder={t("loginForm.login") as string}
                                />
                                {hasEmailError && (
                                    <img
                                        src={alertError}
                                        alt=""
                                        className={
                                            styles.loginFormInnerEmailAlertError
                                        }
                                    />
                                )}
                            </div>
                            <ErrorMessage
                                name="email"
                                errors={errors}
                                render={({ message }) => (
                                    <span
                                        className={
                                            styles.loginFormInnerEmailInputErrorMsg
                                        }
                                    >
                                        {message}
                                    </span>
                                )}
                            />
                            <div
                                className={
                                    styles.loginFormInnerPasswordContainer
                                }
                            >
                                <input
                                    {...register("password", {
                                        required: t(
                                            "loginForm.requiredField",
                                        ) as string,
                                    })}
                                    className={
                                        hasPasswordError
                                            ? styles.loginFormInnerPasswordInput +
                                              " " +
                                              styles.loginFormInnerPasswordInputError
                                            : styles.loginFormInnerPasswordInput
                                    }
                                    type={
                                        isPasswordVisible ? "text" : "password"
                                    }
                                    autoComplete="current-password"
                                    placeholder={
                                        t("loginForm.password") as string
                                    }
                                />
                                {isPasswordVisible ? (
                                    <img
                                        className={
                                            hasPasswordError
                                                ? styles.loginFormInnerPasswordEye +
                                                  " " +
                                                  styles.loginFormInnerPasswordEyeError
                                                : styles.loginFormInnerPasswordEye
                                        }
                                        src={openedEye}
                                        onClick={() =>
                                            setIsPasswordVisible(
                                                (prevState) => !prevState,
                                            )
                                        }
                                        alt="password eye"
                                    />
                                ) : (
                                    <img
                                        className={
                                            hasPasswordError
                                                ? styles.loginFormInnerPasswordEye +
                                                  " " +
                                                  styles.loginFormInnerPasswordEyeError
                                                : styles.loginFormInnerPasswordEye
                                        }
                                        src={closedEye}
                                        onClick={() =>
                                            setIsPasswordVisible(
                                                (prevState) => !prevState,
                                            )
                                        }
                                        alt="password eye"
                                    />
                                )}
                                {hasPasswordError && (
                                    <img
                                        src={alertError}
                                        alt="alert error"
                                        className={
                                            styles.loginFormInnerPasswordAlertError
                                        }
                                    />
                                )}
                            </div>
                            <ErrorMessage
                                name="password"
                                errors={errors}
                                render={({ message }) => (
                                    <span
                                        className={
                                            styles.loginFormInnerPasswordInputErrorMsg
                                        }
                                    >
                                        {message}
                                    </span>
                                )}
                            />
                            <div className={styles.loginFormInnerRememberMe}>
                                <Switch
                                    size="small"
                                    checked={checked}
                                    onChange={handleChange}
                                    inputProps={{ "aria-label": "controlled" }}
                                />
                                <span>{t("loginForm.rememberMe")}</span>
                            </div>
                            <div
                                className={styles.loginFormInnerButtonContainer}
                            >
                                <button
                                    className={styles.loginFormInnerButton}
                                    type="submit"
                                    disabled={isUserLoginLoading}
                                >
                                    <span>
                                        {isUserLoginLoading ? (
                                            <CircularProgress
                                                size={25}
                                                sx={{ color: "#fff" }}
                                            />
                                        ) : (
                                            t("loginForm.signIn")
                                        )}
                                    </span>
                                </button>
                            </div>
                            <div className={styles.loginFormDivider}>
                                <span>{t("loginForm.or")}</span>
                            </div>
                            <div className={styles.loginFormMicrosoft}>
                                <img src={microsoftLogo} alt="microsoft logo" />
                                <span>
                                    {t("loginForm.signInWithMicrosoft")}
                                </span>
                            </div>
                        </div>
                    </form>
                    {/*{loginErrors && <Toast message={loginErrors as string} type="error" />}*/}
                </div>
            </div>
        </div>
    );
}
