import React, { FC, memo, useEffect, useMemo, useRef, useState } from "react";
import { CreateIcon, FilterIcon, ViewIcon } from "assets/icons";
import { getCalculatedFiltersCount } from "core/utils";
import FiltersComponent from "features/Catalogs/FiltersComponent";
import Guard from "features/Guard";
import {
    useAppDispatch,
    useAppSelector,
    useSortColumn,
    useUpdateEffect,
} from "hooks";
import useLocalStorage from "hooks/useLocalStorage";
import { ISalariesFilter } from "models/salaries";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
    getAllowedGroupsByMenuId,
    getAllowedPermissionsByGroupId,
    getDataByCatalogName,
    getLookupByName,
    getSkillLevels,
    getSkills,
    getTypeofemployments,
} from "store";
import { getSalaries } from "store/thunks/salariesThunk";
//redux toolkit
//models
//components
//images
//styles
import { createOrEditBtn } from "styles/MUIStyles";
import { useDebounce } from "usehooks-ts";

import SearchIcon from "@mui/icons-material/Search";
import {
    Badge,
    Button,
    FormControl,
    MenuItem,
    Pagination,
    Select,
    SelectChangeEvent,
    Stack,
} from "@mui/material";

import SalariesFilterPanel from "./SalariesFilterPanel";
import SalariesTable from "./SalariesTable";

import styles from "./Salaries.module.scss";

const Salaries: FC = memo(() => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const activeSalary = useAppSelector((state) => state.salaries.activeSalary);
    const salariesPageCount = useAppSelector(
        (state) => state.salaries.salariesPageCount,
    );
    const allowedPermissions = useAppSelector(
        (state) => state.auth.allowedPermissions,
    );
    const allowedGroups = useAppSelector((state) => state.auth.allowedGroups);
    const allowedMenus = useAppSelector((state) => state.auth.allowedMenus);

    const [salariesPageNumber, setSalariesPageNumber] = useLocalStorage<number>(
        "salariesPageNumber",
        1,
    );

    const [salariesPageSize, setSalariesPageSize] = useLocalStorage<number>(
        "salariesPageSize",
        10,
    );
    const [filtersCount, setFiltersCount] = useLocalStorage(
        "salariesFiltersBadge",
        0,
    );
    const [fullNameSearch, setFullNameSearch] = useLocalStorage<string>(
        "salariesFullNameSearch",
        "",
    );

    const initialStateSalariesFilter: ISalariesFilter = {
        organizationId: null,
        positionId: null,
        skillId: null,
        skillLevelId: null,
        costCenterId: null,
        status: null,
        typeOfEmploymentId: null,
        fromStartDate: null,
        beforeStartDate: null,
    };

    const [salariesFilter, setSalariesFilter] =
        useLocalStorage<ISalariesFilter>(
            "salariesFilters",
            initialStateSalariesFilter,
        );

    const { sortConfig, handleColumnClick } =
        useSortColumn("salariesTableSort");
    const [createOrUpdate, setCreateOrUpdate] = useState<string | null>(null);
    const [isFiltersOpen, setIsFiltersOpen] = useState<boolean>(false);

    const prevSalariesFilterRef = useRef<ISalariesFilter | null>(null);
    const prevSalariesFilterFromStartDateRef = useRef<Date | null>(
        salariesFilter.fromStartDate,
    );
    const prevSalariesFilterBeforeStartDateRef = useRef<Date | null>(
        salariesFilter.beforeStartDate,
    );

    const debouncedFullNameSearch = useDebounce(fullNameSearch, 1000);

    const prevSalariesFilter = prevSalariesFilterRef.current;
    // const prevSalariesFilterFromStartDate =
    //     prevSalariesFilterFromStartDateRef.current;
    // const prevSalariesFilterBeforeStartDate =
    //     prevSalariesFilterFromStartDateRef.current;

    //To avoid duplicate requests and reset pageNumber
    const checkPageNumber = useMemo(() => {
        return (prevSalariesFilter && salariesFilter !== prevSalariesFilter) ||
            debouncedFullNameSearch
            ? 1
            : salariesPageNumber;

        // eslint-disable-next-line
    }, [salariesFilter, debouncedFullNameSearch, salariesPageNumber]);

    useEffect(() => {
        prevSalariesFilterRef.current = salariesFilter;
        prevSalariesFilterFromStartDateRef.current =
            salariesFilter.fromStartDate;
        prevSalariesFilterBeforeStartDateRef.current =
            salariesFilter.beforeStartDate;
    });

    useEffect(() => {
        // const fromStartDate = salariesFilter.fromStartDate;
        // const beforeStartDate = salariesFilter.beforeStartDate;

        const isCheckFullnessFilterDate = true;
        // (fromStartDate === prevSalariesFilterFromStartDate ||
        // dayjs(fromStartDate).isValid() ||
        // fromStartDate === null) && (beforeStartDate === prevSalariesFilterBeforeStartDate ||
        //     dayjs(beforeStartDate).isValid() ||
        //     beforeStartDate === null)

        if (isCheckFullnessFilterDate) {
            dispatch(
                getSalaries({
                    ...salariesFilter,
                    sortBy: sortConfig,
                    fullName: debouncedFullNameSearch,
                    pageNumber: checkPageNumber,
                    pageSize: salariesPageSize,
                }),
            );
        }

        setSalariesPageNumber(checkPageNumber);

        // eslint-disable-next-line
    }, [
        t,
        dispatch,
        salariesFilter,
        debouncedFullNameSearch,
        handleColumnClick,
        checkPageNumber,
        salariesPageSize,
    ]);

    useEffect(() => {
        //Getting data for filtering salaries
        dispatch(getLookupByName("organizations"));
        dispatch(getSkills("skills"));
        dispatch(getSkillLevels("skillLevels"));
        dispatch(getDataByCatalogName("positions"));
        dispatch(getDataByCatalogName("guilds"));
        dispatch(getDataByCatalogName("costCenters"));
        dispatch(getTypeofemployments());

        const menu = allowedMenus.find((menu) => menu.name === "Salaries");
        typeof menu?.id === "number" &&
            dispatch(getAllowedGroupsByMenuId(menu.id));

        // eslint-disable-next-line
    }, [dispatch, t]);

    useEffect(() => {
        const group = allowedGroups.find(
            (menu) => menu.name === `api/salaries`,
        );
        group?.id && dispatch(getAllowedPermissionsByGroupId(group.id));
    }, [dispatch, allowedGroups]);

    useUpdateEffect(() => {
        setFiltersCount(getCalculatedFiltersCount(salariesFilter));
    }, [salariesFilter]);

    const closeBackdrop = (): void => {
        setCreateOrUpdate(null);
        setIsFiltersOpen(false);
    };

    //Pafination
    const handleChangeSalariesPageSize = async (e: SelectChangeEvent) => {
        setSalariesPageSize(e.target.value);
        setSalariesPageNumber(1);
    };

    const handleChangeSalariesPageNumber = async (
        event: React.ChangeEvent<unknown>,
        page: number,
    ) => {
        setSalariesPageNumber(page);
    };

    return (
        <main>
            <div className={styles.box_actions}>
                <div>
                    <Guard
                        allowedPermissions={allowedPermissions}
                        permissionName="Create"
                    >
                        <Button
                            startIcon={<CreateIcon />}
                            variant="text"
                            onClick={() => navigate(`create`)}
                            sx={createOrEditBtn}
                        >
                            {t("buttons.Create")}
                        </Button>
                    </Guard>
                    <Guard
                        allowedPermissions={allowedPermissions}
                        permissionName="View"
                    >
                        <Button
                            startIcon={<ViewIcon />}
                            variant="text"
                            onClick={() =>
                                navigate(`${activeSalary?.employeeId}`)
                            }
                            disabled={!activeSalary}
                            sx={createOrEditBtn}
                        >
                            {t("buttons.View")}
                        </Button>
                    </Guard>
                </div>
                <Stack direction="row" spacing={1} alignItems="center">
                    <p>{t("filters.elementsOnPage")}</p>
                    <FormControl variant="standard" sx={{ m: 1 }}>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={salariesPageSize}
                            onChange={handleChangeSalariesPageSize}
                        >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={25}>25</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                            <MenuItem value={100}>100</MenuItem>
                        </Select>
                    </FormControl>
                    <Badge
                        color="primary"
                        badgeContent={filtersCount}
                        sx={{ "& span": { fontSize: "12px" } }}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                    >
                        <Button
                            startIcon={<FilterIcon />}
                            variant="text"
                            onClick={() => {
                                setIsFiltersOpen(!isFiltersOpen);
                            }}
                            sx={createOrEditBtn}
                        >
                            {t("buttons.Filters")}
                        </Button>
                    </Badge>
                </Stack>
            </div>
            <div
                className={`${styles.scrollContainerHeight} + ${styles.scrollContainer}`}
            >
                <div className={styles.searchWrapper}>
                    <input
                        type="text"
                        placeholder={
                            t("filters.searchByNameAndSurname") as string
                        }
                        value={fullNameSearch}
                        onChange={(e) => {
                            setFullNameSearch(e.target.value);
                        }}
                    />
                    <button type="button" className={styles.searchWrapper_icon}>
                        <SearchIcon sx={{ color: "#fff" }} />
                    </button>
                </div>
                <SalariesTable
                    sortConfig={sortConfig}
                    handleColumnClick={handleColumnClick}
                    search={debouncedFullNameSearch}
                    setSearch={setFullNameSearch}
                />
            </div>
            <Stack spacing={2} sx={{ mt: "10px" }} alignItems="center">
                <Pagination
                    sx={{ margin: "auto" }}
                    count={salariesPageCount as number}
                    page={salariesPageNumber}
                    size="medium"
                    onChange={handleChangeSalariesPageNumber}
                    shape="rounded"
                    color="primary"
                    variant="outlined"
                />
            </Stack>
            <FiltersComponent
                name={"filters"}
                isDrawerOpen={isFiltersOpen}
                createOrUpdate={createOrUpdate}
                closeFormDrawer={closeBackdrop}
            >
                <SalariesFilterPanel
                    salariesFilter={salariesFilter}
                    setSalariesFilter={setSalariesFilter}
                    initialStateSalariesFilter={initialStateSalariesFilter}
                />
            </FiltersComponent>
        </main>
    );
});

export default Salaries;
