import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@mui/material';

import { useAppDispatch } from '../../../hooks';
import { deletePersonalDocument, getPersonalDocuments } from '../../../store';
import { deleteDocument, getDocuments } from '../../../store/thunks/commonDocumentsThunk';
import { cancelBtn, saveBtn } from '../../../styles/MUIStyles';
import ButtonWithProgress from '../../../ui/ButtonWithProgress';
import { ETextVariant, Text } from '../../../ui/Text/Text';
import { sendNotification } from '../../../ui/Toast';

import styles from './styles.module.css';

interface DeleteFileProps {
    id: number;
    originalFileName: string;
    isPersonal: boolean;
    setIsOpenDeleteFile: (isOpenEditFile: boolean) => void;
}

export const DeleteFile: FC<DeleteFileProps> = ({ id, setIsOpenDeleteFile, originalFileName, isPersonal }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const save = async () => {
        try {
            dispatch(isPersonal ? deletePersonalDocument({ id }) : deleteDocument({ id }))
                .then(() => {
                    setIsOpenDeleteFile(false);
                    dispatch(isPersonal ? getPersonalDocuments() : getDocuments());
                    sendNotification(t('statuses.Success'), 'success');
                });
        } catch (e) {
            console.log(e);
            sendNotification(t('statuses.Error'), 'error');
        }
    };

    return (
        <div>
            <div style={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: 'white',
                textAlign: 'center',
                padding: '20px',
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                borderRadius: '8px',
            }}>
                <Text
                    style={{
                        marginBottom: 24
                    }}
                    variant={ETextVariant.REGULAR}>
                    {`
                    ${t('notifications.areYouSure')}
                    ${t('buttons.DeleteDoc').toLowerCase()}
                    ${originalFileName}?
                    `}
                </Text>
                <ButtonWithProgress
                    onClick={save}
                    loading={false}
                    disabled={false}
                    type="submit"
                    variant="contained"
                    sx={saveBtn}
                >
                    {t('buttons.Delete')}
                </ButtonWithProgress>

                <Button
                    variant="text"
                    onClick={() => setIsOpenDeleteFile(false)}
                    sx={cancelBtn}
                >
                    {t('buttons.Cancel')}
                </Button>
            </div>
        </div>
    );
};
