import React, { FC, useEffect, useRef, useState } from 'react';
import closeIcon from 'assets/icons/closeIcon.svg';
import DeleteIcon from 'assets/icons/delete.svg';
import DocumentIcon from 'assets/icons/document.svg';
import DownloadIcon from 'assets/icons/download.svg';
import hoverEditIcon from 'assets/icons/hoverEditIcon.svg';
import ConfirmationModal from 'features/ConfirmationModal';
import Guard from 'features/Guard';
import { useAppDispatch, useAppSelector, useDocumentDownloader } from 'hooks';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
    addDocumentToProject,
    deleteProjectDocument,
    editProjectDocument,
    getProjectByIdForUpdate,
} from 'store/thunks/projectsThunk';
import {
    activateOrDeactivateBtn as addDocumentBtn,
    activateOrDeactivateBtn as saveEmployeeBtn,
    activateOrDeactivateModal,
    cancelBtn,
} from 'styles/MUIStyles';
import ButtonWithProgress from 'ui/ButtonWithProgress';
import { sendNotification } from 'ui/Toast';

import { ErrorMessage } from '@hookform/error-message';
import { Fade, Modal, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import FileInput from '../../../../features/FileInput/FileInput';

import styles from './Documents.module.scss';


interface IFormData {
    name: string;
    description: string;
    file: File | null;
    id?: number | string;
}

const Documents: FC<{ allowedPermissions: any[] }> = ({
                                                          allowedPermissions,
                                                      }): JSX.Element => {
    const params = useParams();

    const {
        register,
        handleSubmit,
        setValue,
        control,
        reset,
        clearErrors,
        formState: { errors, isDirty, isSubmitSuccessful },
    } = useForm<IFormData>({
        defaultValues: {
            name: '',
            description: '',
            file: null,
            id: '',
        },
    });
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { isLoading, downloadDocument } = useDocumentDownloader();
    const [isAddDocumentModalOpen, setIsAddDocumentModalOpen] = useState(false);
    const [isDeleteDocumentModalOpen, setIsDeleteDocumentModalOpen] =
        useState(false);
    const [isUnsavedChangesModalOpen, setIsUnsavedChangesModalOpen] =
        useState(false);
    const [removableDocumentId, setRemovableDocumentId] = useState<number | null>(null);
    const [isCreatingOrEditing, setIsCreatingOrEditing] = useState<'create' | 'edit' | null>(null);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [fileName, setFileName] = useState<string | null>(null);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const fileRef = useRef<HTMLInputElement | null>(null);

    const projectDataFromServer = useAppSelector(
        (state) => state.projects.projectDataFromServer
    );
    const isCreateOrEditOrDeleteDocumentLoading = useAppSelector(
        (state) => state.projects.isCreateOrEditOrDeleteDocumentLoading
    );
    const projectId = useAppSelector((state) => state.projects.projectId);

    const onSubmit = async (data: IFormData) => {
        if (params?.operation === 'create' && projectId) {
            const response = await dispatch(
                addDocumentToProject({
                    projectId,
                    name: data.name,
                    description: data.description,
                    file: data.file,
                })
            );

            if (response.meta.requestStatus === 'fulfilled') {
                setIsAddDocumentModalOpen(false);
                sendNotification(
                    t('notifications.successfullyCreated'),
                    'success'
                );
            }
        }

        if (
            params?.operation === 'edit' &&
            params?.projectId &&
            isCreatingOrEditing === 'create'
        ) {
            const response = await dispatch(
                addDocumentToProject({
                    projectId: +params?.projectId,
                    name: data.name,
                    description: data.description,
                    file: data.file,
                })
            );

            if (response.meta.requestStatus === 'fulfilled') {
                setIsAddDocumentModalOpen(false);
                sendNotification(
                    t('notifications.successfullySaved'),
                    'success'
                );
                dispatch(getProjectByIdForUpdate(+params.projectId));
            }
        }

        if (
            params?.operation === 'edit' &&
            params?.projectId &&
            data.id &&
            isCreatingOrEditing === 'edit'
        ) {
            const response = await dispatch(
                editProjectDocument({
                    id: data.id,
                    name: data.name,
                    description: data.description,
                    file: data.file,
                })
            );

            if (response.meta.requestStatus === 'fulfilled') {
                setIsAddDocumentModalOpen(false);
                sendNotification(
                    t('notifications.successfullySaved'),
                    'success'
                );
                dispatch(getProjectByIdForUpdate(+params.projectId));
            }
        }
    };

    const fillDocumentFormData = (document: {
        name: string;
        description: string;
        id: number;
    }) => {
        setIsAddDocumentModalOpen(true);
        setValue('name', document.name);
        setValue('description', document.description);
        setValue('id', document.id);
    };

    const clearDocumentFormData = () => {
        setValue('name', '');
        setValue('description', '');
    };

    const handleDeleteDocument = async (documentId: number) => {
        const response = await dispatch(deleteProjectDocument(documentId));
        if (response.meta.requestStatus === 'fulfilled' && params?.projectId) {
            setIsDeleteDocumentModalOpen(false);
            sendNotification(t('notifications.successfullyDeleted'), 'success');
            dispatch(getProjectByIdForUpdate(+params.projectId));
        }
    };

    useEffect(() => {
        if (isSubmitSuccessful) {
            reset({}, { keepValues: true });
        }
    }, [dispatch, isSubmitSuccessful, reset, params]);

    useEffect(() => {
        if (!isAddDocumentModalOpen) {
            clearErrors();
        }
    }, [isAddDocumentModalOpen, clearErrors]);

    useEffect(() => {
        if (isAddDocumentModalOpen) {
            setFileName(null);
        }
    }, [isAddDocumentModalOpen]);

    return (
        <Box sx={{ width: '46.5%', paddingLeft: '2rem' }}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    margin: '1rem 0',
                    paddingLeft: '2rem',
                    paddingBottom: '1rem',
                    borderBottom: '2px solid #BEBEBE',
                }}
            >
                <Typography
                    sx={{
                        color: '#BEBEBE',
                        fontWeight: 600,
                        width: '50%',
                        fontFamily: 'Source Sans Pro',
                        fontSize: '18px',
                    }}
                >
                    {params?.operation === 'create' && ''}
                    {params?.operation === 'edit' &&
                        (projectDataFromServer?.fullName ||
                            projectDataFromServer?.shortName)}
                </Typography>
                <Box>
                    <Button
                        variant="text"
                        onClick={() => {
                            if (isDirty) {
                                setIsUnsavedChangesModalOpen(true);
                            } else {
                                navigate(-1);
                            }
                        }}
                        sx={cancelBtn}
                    >
                        {t('buttons.Close')}
                    </Button>
                </Box>
            </Box>
            <Modal
                open={isAddDocumentModalOpen}
                onClose={() => setIsAddDocumentModalOpen(false)}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={isAddDocumentModalOpen}>
                    <Box sx={activateOrDeactivateModal}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <h3 className={styles.modalTitle}>
                                {isCreatingOrEditing === 'edit'
                                    ? t('projects.editDocument')
                                    : t('projects.addDocument')}
                            </h3>
                            <img
                                src={closeIcon}
                                alt="close icon"
                                onClick={() => setIsAddDocumentModalOpen(false)}
                            />
                        </Box>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <label htmlFor="name" className={styles.inputLabel}>
                                {t('data.name')}
                            </label>
                            <input
                                {...register('name', {
                                    required: t(
                                        'validation.requiredField'
                                    ) as string,
                                    maxLength: {
                                        value: 200,
                                        message: t('validation.maxLength200'),
                                    },
                                })}
                                type="text"
                                id="name"
                                autoComplete="off"
                                placeholder={
                                    t('projects.enterDocumentName') as string
                                }
                                className={
                                    errors?.name
                                        ? styles.documentsInputNameError
                                        : styles.documentsInputName
                                }
                            />
                            <ErrorMessage
                                errors={errors}
                                name="name"
                                render={({ message }) => (
                                    <span style={{ display: 'block' }}>
                                        {message}
                                    </span>
                                )}
                            />
                            <label
                                htmlFor="description"
                                className={styles.inputLabel}
                            >
                                {t('data.description')}
                            </label>
                            <input
                                {...register('description', {
                                    maxLength: {
                                        value: 200,
                                        message: t('validation.maxLength200'),
                                    },
                                })}
                                type="text"
                                id="description"
                                autoComplete="off"
                                placeholder={
                                    t(
                                        'projects.enterDocumentDescription'
                                    ) as string
                                }
                                className={styles.documentsInputDescription}
                            />
                            <FileInput
                                control={control}
                                errors={errors}
                                isOpen={isAddDocumentModalOpen}
                                isCreateOrEditing={isCreatingOrEditing}
                                name="file"
                            />
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                }}
                            >
                                <Button
                                    variant="text"
                                    type="button"
                                    onClick={() => {
                                        if (isDirty) {
                                            setIsUnsavedChangesModalOpen(true);
                                        } else {
                                            setIsAddDocumentModalOpen(false);
                                        }
                                    }}
                                    sx={cancelBtn}
                                >
                                    {t('buttons.Cancel')}
                                </Button>
                                <ButtonWithProgress
                                    variant="contained"
                                    type="submit"
                                    loading={
                                        isCreateOrEditOrDeleteDocumentLoading
                                    }
                                    disabled={
                                        isCreateOrEditOrDeleteDocumentLoading
                                    }
                                    sx={{ ...saveEmployeeBtn }}
                                >
                                    {t('buttons.Save')}
                                </ButtonWithProgress>
                            </Box>
                        </form>
                    </Box>
                </Fade>
            </Modal>
            {projectDataFromServer &&
                projectDataFromServer.documents.map((document) => (
                    <Box
                        key={document.id}
                        sx={{
                            marginBottom: '20px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box sx={{ display: 'flex', gap: '10px' }}>
                            <Box sx={{ '& > img': { marginTop: '5px' } }}>
                                <img src={DocumentIcon} alt="document"/>
                            </Box>
                            <Box>
                                <h3>{document.name}</h3>
                                <p>{document.description}</p>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '12px',
                                '& img': { cursor: 'pointer' },
                            }}
                        >
                            <Guard
                                allowedPermissions={allowedPermissions}
                                permissionName="Delete"
                            >
                                <img
                                    onClick={() => {
                                        setIsDeleteDocumentModalOpen(true);
                                        setRemovableDocumentId(document.id);
                                    }}
                                    src={DeleteIcon}
                                    alt="delete document icon"
                                />
                            </Guard>
                            <Guard
                                allowedPermissions={allowedPermissions}
                                permissionName="DownloadFile"
                            >
                                {isLoading.includes(document.id) ? (
                                    <CircularProgress size={20}/>
                                ) : (
                                    <img
                                        onClick={() => {
                                            downloadDocument(
                                                document.id,
                                                '/projects'
                                            );
                                        }}
                                        src={DownloadIcon}
                                        alt="download document icon"
                                    />
                                )}
                            </Guard>
                            <img
                                onClick={() => {
                                    setIsCreatingOrEditing('edit');
                                    fillDocumentFormData(document);
                                }}
                                src={hoverEditIcon}
                                alt="edit document icon"
                            />
                        </Box>
                    </Box>
                ))}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    variant="contained"
                    type="button"
                    sx={{ ...addDocumentBtn, textTransform: 'none' }}
                    onClick={() => {
                        setIsCreatingOrEditing('create');
                        setIsAddDocumentModalOpen(true);
                        clearDocumentFormData();
                    }}
                >
                    {t('buttons.AddDocument')}
                </Button>
            </Box>
            <ConfirmationModal
                isModalOpen={isUnsavedChangesModalOpen}
                setIsModalOpen={() => setIsUnsavedChangesModalOpen(false)}
                actionName={'Close'}
                instanceName={''}
                message={'closeFormWithoutSaving'}
                handleAction={() => {
                    if (isDirty) {
                        setIsUnsavedChangesModalOpen(false);
                        setIsAddDocumentModalOpen(false);
                        reset({}, { keepValues: true });
                    }
                }}
            />
            <ConfirmationModal
                isModalOpen={isDeleteDocumentModalOpen}
                setIsModalOpen={() => setIsDeleteDocumentModalOpen(false)}
                actionName={'Delete'}
                instanceName={'documents'}
                loading={isCreateOrEditOrDeleteDocumentLoading}
                handleAction={() => {
                    if (removableDocumentId) {
                        handleDeleteDocument(removableDocumentId);
                    }
                }}
            />
        </Box>
    );
};

export default Documents;
