import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Modal } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';

import close from '../../../assets/icons/closeIcon.svg';
import { DeleteFile } from '../../../features/DocumentsList/components/DeleteFile';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
    addPersonalDocument,
    editPersonalDocument,
    getPersonalDocuments
} from '../../../store';
import { saveBtn } from '../../../styles/MUIStyles';
import ButtonWithProgress from '../../../ui/ButtonWithProgress';
import { sendNotification } from '../../../ui/Toast';

import styles from '../../../ui/SelectFile/styles.module.scss';
import stylesInput from '../../Salaries/SalariesActions/SalariesActions.module.scss';
import localStyles from './styles.module.scss';

const DocumentForm = ({
                          control,
                          errors,
                          index,
                          fileInput,
                          handleUpload,
                          handleSubmit,
                          save,
                          fileName,
                          name: initialName,
                          description: initialDescription,
                          originalFileName,
                          documentId
                      }: any) => {
    const [file, setFile] = useState<File | null>(null);
    const [isOpenDeleteFile, setIsOpenDeleteFile] = useState(false);
    const [name, setName] = useState(initialName || '');
    const [description, setDescription] = useState(initialDescription || '');
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const handleUploadLocal = (event: any) => {
        setFile(event.target.files[0]);
    };

    const {
        control: controlLocal,
        handleSubmit: handleSubmitLocal,
        formState: { errors: errorsLocal },
        reset,
    } = useForm({
        defaultValues: {
            description: initialDescription || '',
            name: initialName || '',
        }
    });

    useEffect(() => {
        if (index === 0) {
            reset({
                name: initialName || '',
                description: initialDescription || ''
            }, {
                keepDefaultValues: true,
                keepErrors: true
            });
        }
    }, [index, initialName, initialDescription, reset]);

    const saveLocal = () => {
        dispatch(editPersonalDocument({ id: documentId, name, description, file }))
            .then(() => {
                dispatch(getPersonalDocuments());
            });
    };

    return (
        <div style={{ position: 'relative' }}>
            <div className={styles.controllerWrapper}>
                <label style={{ display: 'block', textAlign: 'start' }} htmlFor={`documents[${index}].name`}>
                    {t('data.name')}
                </label>
                <Controller
                    name={`documents[${index}].name`}
                    control={index === 0 ? control : controlLocal}
                    rules={{ required: index === 0 ? true : (name.length <= 0) }}
                    render={({ field }) => (
                        <input
                            {...field}
                            value={name}
                            onChange={(e) => {
                                setName(e.target.value);
                                field.onChange(e);
                            }}
                            placeholder={t('projects.enterDocumentName') || ''}
                            type="text"
                            className={cn(stylesInput.input, styles.label)}
                        />
                    )}
                />
            </div>
            {(index === 0 ? errors : errorsLocal).documents?.[index]?.name && (
                <div style={{ color: 'red', textAlign: 'start' }}>{t('validation.requiredField')}</div>
            )}
            <div className={styles.controllerWrapper}>
                <label style={{ display: 'block', textAlign: 'start' }} htmlFor={`documents[${index}].description`}>
                    {t('data.description')}
                </label>
                <Controller
                    name={`documents[${index}].description`}
                    control={index === 0 ? control : controlLocal}
                    render={({ field }) => (
                        <input
                            {...field}
                            value={description}
                            onChange={(e) => {
                                setDescription(e.target.value);
                                field.onChange(e);
                            }}
                            placeholder={t('projects.enterDocumentDescription') || ''}
                            type="text"
                            className={cn(stylesInput.input, styles.label)}
                        />
                    )}
                />
            </div>
            {(index === 0 ? errors : errorsLocal).documents?.[index]?.description && (
                <div style={{ color: 'red', textAlign: 'start' }}>{t('validation.requiredField')}</div>
            )}
            <>
                <div>
                    <input
                        style={{ display: 'none' }}
                        id={`customFileInput${index}`}
                        type="file"
                        ref={fileInput}
                        onChange={index === 0 ? handleUpload : handleUploadLocal}
                    />
                    <div className={styles.controllerWrapper}>
                        <label
                            htmlFor={`customFileInput${index}`}
                            className={localStyles.fileInputLabel}>
                            {t('projects.chooseFile')}
                        </label>
                        <label
                            className={localStyles.fileInputDescription}
                            htmlFor={`customFileInput${index}`}>
                            {file?.name || (originalFileName || (index === 0 ? fileName : file?.name)) || t('projects.chooseFile')}
                        </label>
                        {index !== 0 && (
                            <img className={localStyles.closeIcon} onClick={() => setIsOpenDeleteFile(true)} src={close} alt=""/>
                        )}
                        <Modal
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            open={isOpenDeleteFile}
                            onClose={() => setIsOpenDeleteFile(false)}
                            closeAfterTransition
                            slots={{ backdrop: Backdrop }}
                            slotProps={{
                                backdrop: {
                                    timeout: 500,
                                },
                            }}
                        >
                            <Box>
                        <DeleteFile
                            id={documentId}
                            isPersonal
                            setIsOpenDeleteFile={setIsOpenDeleteFile}
                            originalFileName={`${name} ${originalFileName}`}
                        />
                            </Box>
                        </Modal>
                    </div>
                </div>
                <div style={{
                    paddingBottom: 16,
                    borderBottom: '1px solid #dedbdb'
                }} className={localStyles.saveButtonWrapper}>
                    <ButtonWithProgress
                        onClick={index === 0 ? handleSubmit(save) : handleSubmitLocal(saveLocal)}
                        loading={false}
                        disabled={false}
                        type="submit"
                        variant="contained"
                        sx={saveBtn}
                    >
                        {index === 0 ? t('buttons.Create') : t('buttons.Save')}
                    </ButtonWithProgress>
                </div>
            </>
        </div>
    );
};

export const AddDocument = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const fileInput = useRef(null);
    const [file, setFile] = useState<File | null>(null);
    const [fileError, setFileError] = useState<string | null>(null);
    const personalDocuments = useAppSelector((state) => state.employees.personalDocuments);

    const { control, handleSubmit, formState: { errors }, reset } = useForm({
        defaultValues: {
            documents: [{ name: '', description: '' }, ...personalDocuments?.myDocuments || []]
        }
    });

    const { fields } = useFieldArray({
        control,
        name: 'documents'
    });

    const handleUpload = (event: any) => {
        setFile(event.target.files[0]);
        setFileError(null);
    };

    useEffect(() => {
        reset({
            documents: [{ name: '', description: '' }, ...personalDocuments?.myDocuments || []]
        });
    }, [personalDocuments, reset]);

    const save = async (data: any) => {
        try {
            await dispatch(addPersonalDocument({
                name: data.documents[0].name,
                description: data.documents[0].description,
                file
            }));

            dispatch(getPersonalDocuments());

            sendNotification(t('statuses.Success'), 'success');

            setFile(null);
        } catch (e) {
            console.log(e);
            sendNotification(t('statuses.Error'), 'error');
        }
    };

    return (
        <div className={localStyles.container}>
            {fields.map((field, index) => (
                <DocumentForm
                    {...field}
                    fileName={file?.name}
                    handleSubmit={handleSubmit}
                    save={save}
                    fileInput={fileInput}
                    handleUpload={handleUpload}
                    key={field.id}
                    documentId={personalDocuments?.myDocuments?.[index - 1]?.id}
                    control={control}
                    errors={errors}
                    fileError={fileError}
                    index={index}
                />
            ))}
        </div>
    );
};
