import React, { ChangeEvent, FC, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';

import { ErrorMessage } from '@hookform/error-message';
import {
    Autocomplete,
    Box,
    Breadcrumbs,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Tab,
    Tabs,
    TextField,
    Typography
} from '@mui/material';

import DeleteIcon from '../../assets/icons/delete.svg';
import hoverEditIcon from '../../assets/icons/hoverEditIcon.svg';
import SkillIcon from '../../assets/icons/skill.svg';
import userLogo from '../../assets/logos/user.png';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { IDocument } from '../../models/documents';
import { IDetailedEmployee, IEmployeePutArgs, IEmployeeSkill } from '../../models/employees';
import { ILookupItem } from '../../models/lookups';
import {
    editEmployee,
    getAllowedGroupsByMenuId,
    getAllowedPermissionsByGroupId,
    getEmployeeById,
    getEmployeesLookup,
    getEmployeesLookUpThunk,
    getLookupByName,
    getSkills,
    uploadAvatar
} from '../../store';
import { cancelBtn, saveBtn } from '../../styles/MUIStyles';
import ButtonWithProgress from '../../ui/ButtonWithProgress';
import { sendNotification } from '../../ui/Toast';
import ConfirmationModal from '../ConfirmationModal';
import DocumentsComponent from '../Documents';
import DocumentModal from '../Documents/DocumentModal';
import SkillsModal from '../Forms/SkillsModal';

import styles from './styles.module.scss';

interface EditEmployeeProps {
    id: string;
    isMyAccount?: boolean;
}

// HELP

export const EditEmployee: FC<EditEmployeeProps> = ({ id, isMyAccount }) => {
    const fileInput = useRef(null);

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        reset,
        formState: { errors, isDirty },
        control
    } = useForm<IEmployeePutArgs>();

    const { t } = useTranslation();
    const currentLanguage = localStorage.getItem('i18nextLng');
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [activeEmployee, setActiveEmployee] = useState<IDetailedEmployee | null>(null);
    const [activeSkill, setActiveSkill] = useState<IEmployeeSkill | null>(null);
    const [skillModalOpen, setSkillModalOpen] = useState<{ isOpen: boolean; actionName: string }>({
        isOpen: false,
        actionName: ''
    });
    const [activeDocument, setActiveDocument] = useState<IDocument | null>(null);
    const [employeesActiveTab, setEmployeesActiveTab] = useLocalStorage('employeesActiveTab', 0);
    const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);

    const [isOpenDeleteModalSkill, setIsOpenDeleteModalSkill] = useState<boolean>(false);
    const [skillId, setSkillId] = useState<number | null>();
    const [documentModalOpen, setDocumentModalOpen] = useState<{ isOpen: boolean; actionName: string }>({
        isOpen: false,
        actionName: ''
    });

    const [avatar, setAvatar] = useState<string>();

    const allowedGroups = useAppSelector(state => state.auth.allowedGroups);
    const allowedMenus = useAppSelector(state => state.auth.allowedMenus);
    const typeOfEmployment = useAppSelector(state => state.lookups.typeOfEmployments);
    const positions = useAppSelector(state => state.lookups.positions);
    const grades = useAppSelector(state => state.lookups.grades);
    const skillList = useAppSelector(state => state.catalogs.skills);
    const skillLevels = useAppSelector(state => state.lookups.skillLevels);
    const organizations = useAppSelector(state => state.lookups.organizations);
    const costCenters = useAppSelector(state => state.lookups.costCenters);
    const countries = useAppSelector(state => state.lookups.countries);
    const guilds = useAppSelector(state => state.lookups.guilds);
    const resourceManagers = useAppSelector(state => state.lookups.resourceManagers);
    const timeBelts = useAppSelector(state => state.lookups.timeBelts);

    const closeModalConfirm = () => {
        if (isDirty) {
            navigate(-1);
            setConfirmationModalOpen(false);
            reset({}, { keepValues: true });
        }
    };

    const onSubmit: SubmitHandler<any> = async (formData: IEmployeePutArgs) => {
        const body = {
            id: id!,
            firstName: formData.firstName,
            middleName: formData.middleName,
            lastName: formData.lastName,
            timeBeltId: formData.timeBeltId,
            positionId: formData.positionId,
            gradeId: formData.gradeId,
            guildId: formData.guildId,
            typeOfEmploymentId: formData.typeOfEmploymentId,
            organizationId: formData.organizationId,
            costCenterId: formData.costCenterId,
            resourceManagerId: formData.resourceManagerId,
            isResourceManager: formData.isResourceManager,
            status: formData.status,
            countryId: formData.countryId,
            city: formData.city,
            email: formData.email,
            phone: formData.phone,
            address: formData.address,
            link: formData.link,
            aboutMe: formData.aboutMe,
            skills: formData.skills.length ? formData.skills.map(function (el) { // todo ID of skills
                return { skillId: el.skillId, skillLevelId: el.skillLevelId, isBasicSkill: el.isBasicSkill };
            }) : [],
        };

        if (activeEmployee) {
            const response = await dispatch(editEmployee({ ...body }));
            if (response.meta.requestStatus === 'fulfilled') {
                sendNotification(t('notifications.successfullySaved'), 'success');
                if (isMyAccount) {
                    navigate(-1);
                }
            }
        }
    };

    const employeeAvatarSave = async (image: File) => {
        if (image) {
            const response = await dispatch(uploadAvatar({ employeeId: id, avatar: image }));
            if (response.meta.requestStatus === 'fulfilled') {
                setAvatar(response.payload);
            }
            if (response.meta.requestStatus === 'rejected') {
                sendNotification(response.payload.avatar[0], 'error');
            }
        }
    };

    const tabChangeHandler = (event: SyntheticEvent, newValue: number) => {
        setEmployeesActiveTab(newValue);
    };

    const onStatusChange = (event: ChangeEvent<HTMLInputElement>) => {
        setValue('status', event.target.checked ? 1 : 2);
    };

    const handleClick = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
    };

    const clearSkillForm = () => {
        setSkillModalOpen({ isOpen: false, actionName: '' });
        setActiveSkill(null);
    };

    const addSkill = () => {
        setSkillModalOpen({ isOpen: true, actionName: 'Create' });
    };

    const editSkill = (skill: IEmployeeSkill) => {
        setActiveSkill(skill);
        setSkillModalOpen({ isOpen: true, actionName: 'Edit' });
    };

    const deleteSkill = async () => {
        const newSkills = getValues('skills');
        setValue('skills', newSkills.filter(el => el.id !== skillId));
        setIsOpenDeleteModalSkill(false);
    };

    const editDocumentsList = (document: IDocument, action: string) => {
        setActiveEmployee(prevState => {
            if (!prevState) return null;
            switch (action) {
                case 'add':
                    return {
                        ...prevState, documents: prevState.documents.length ?
                            [...prevState.documents, document] : [document]
                    };
                case 'edit':
                    // eslint-disable-next-line no-case-declarations
                    const index = prevState.documents.findIndex(el => el.id === document.id);
                    return {
                        ...prevState, documents: [...prevState.documents.slice(0, index), document,
                            ...prevState.documents.slice(index + 1, prevState.documents.length)]
                    };
                case 'delete':
                    return { ...prevState, documents: prevState.documents.filter(el => el.id !== document.id) };
                default:
                    return prevState;
            }
        });
    };


    const handleImageUploadClick = () => {
        // @ts-ignore
        fileInput?.current?.click();
    };

    const handleImageUpload = (event: any) => {
        employeeAvatarSave(event.target.files[0]);
    };

// todo функция заполнения формы значениями
    const setFormData = (fields: IDetailedEmployee | null) => {
        // Object.entries(fields).forEach(([key, value]) => form.setValue(key, value)

        setValue('firstName', fields?.firstName || '');
        setValue('middleName', fields?.middleName || '');
        setValue('lastName', fields?.lastName || '');
        setValue('timeBeltId', fields?.timeBeltId || null);
        setValue('positionId', fields?.positionId || null);
        setValue('gradeId', fields?.gradeId || null);
        setValue('guildId', fields?.guildId || null);
        setValue('typeOfEmploymentId', fields?.typeOfEmploymentId || null);
        setValue('organizationId', fields?.organizationId || null);
        setValue('costCenterId', fields?.costCenterId || null);
        setValue('resourceManagerId', fields?.resourceManagerId || null);
        setValue('isResourceManager', fields?.isResourceManager || false);
        setValue('status', fields?.userStatus === 'Active' ? 1 : 0);
        setValue('countryId', fields?.countryId || null);
        setValue('city', fields?.city || '');
        setValue('email', fields?.email || '');
        setValue('phone', fields?.phone || '');
        setValue('address', fields?.address || '');
        setValue('link', fields?.link || '');
        setValue('aboutMe', fields?.aboutMe || '');
        // eslint-disable-next-line no-unsafe-optional-chaining
        setValue('skills', fields?.skills?.length ? [...fields?.skills] : []);
    };

    useEffect(() => {
        const menu = allowedMenus.find(menu => menu.name === 'Employees');
        typeof menu?.id === 'number' && dispatch(getAllowedGroupsByMenuId(menu.id));

        dispatch(getLookupByName('typeOfEmployments'));
        dispatch(getLookupByName('positions'));
        dispatch(getLookupByName('grades'));
        dispatch(getLookupByName('guilds'));
        dispatch(getLookupByName('costCenter'));
        dispatch(getLookupByName('countries'));
        // dispatch(getLookupByName('skills'));
        dispatch(getSkills('skills'));
        dispatch(getLookupByName('skillLevels'));
        dispatch(getLookupByName('organizations'));
        dispatch(getLookupByName('timeBelts'));
        dispatch(getEmployeesLookup());
        dispatch(getEmployeesLookUpThunk());
    }, [dispatch, t, allowedMenus]);

    useEffect(() => {
        const group = allowedGroups.find(menu => menu.name === `api/employees`);
        group?.id && dispatch(getAllowedPermissionsByGroupId(group.id));
    }, [dispatch, allowedGroups]);

    useEffect(() => {
        if (id) {
            const getEmpl = async () => {
                const employee = await dispatch(getEmployeeById(id!));
                setActiveEmployee(employee.payload);
                setFormData(employee.payload);
                // setDocumentsData(employee?.payload.documents?.length ? employee?.payload.documents.map(function (el: IDocument) {return {...el, isSaved: true}}) : null);
                setAvatar(employee?.payload?.avatar);
                setFormData(employee.payload);
            };
            getEmpl();

        }
    }, [id, t]);

    useEffect(() => {
        setAvatar('');
        setFormData(null);

        return () => {
            setEmployeesActiveTab(0);
        };
    }, []);
    return (
        <div style={{ padding: '10px 30px' }}>

            {!isMyAccount && (
                <div role="presentation" onClick={handleClick} style={{ marginBottom: '20px' }}>
                    <Breadcrumbs sx={{ padding: '10px 10px' }}>
                        <Link to="/employees" className={styles.breadScrumbsLink}>
                            {t('employees.employees')}
                        </Link>
                        <Typography sx={{ color: '#201f1e', fontFamily: 'Source Sans Pro' }}>
                            {activeEmployee?.firstName} {activeEmployee?.lastName}
                        </Typography>
                    </Breadcrumbs>

                </div>
            )}

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={employeesActiveTab} onChange={tabChangeHandler}>
                    <Tab label={t('tabs.general')} value={0}/>
                    <Tab label={t('tabs.skills')} value={1}/>
                    {!isMyAccount && <Tab label={t('tabs.docs')} value={2}/>}
                    <Tab label={t('tabs.projects')} value={3}/>
                    <Tab label={t('tabs.history')} value={4}/>
                </Tabs>
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div style={{ display: 'flex', gap: '20px', justifyContent: 'space-around', margin: '15px 0' }}>
                    <h3>{activeEmployee?.firstName} {activeEmployee?.lastName} {activeEmployee?.middleName} </h3>

                    <div>
                        <ButtonWithProgress
                            loading={false}
                            disabled={false}
                            type="submit"
                            variant="contained"
                            sx={saveBtn}
                        >
                            {t('buttons.Save')}
                        </ButtonWithProgress>

                        <Button
                            variant="text"
                            onClick={() => {
                                if (isDirty) {
                                    setConfirmationModalOpen(true);
                                } else {
                                    navigate(-1);
                                }
                            }}
                            sx={cancelBtn}
                        >
                            {t('buttons.Cancel')}
                        </Button>
                    </div>
                </div>

                {employeesActiveTab === 0 &&
                    <div>
                        <div style={{ display: 'flex', gap: '20px' }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Controller
                                    name="status"
                                    control={control}
                                    render={() => (
                                        <FormGroup sx={{ display: 'inline-block', mb: '5px' }}>
                                            <FormControlLabel
                                                control={<Checkbox checked={getValues('status') === 1}
                                                                   onChange={(event) => onStatusChange(event)}/>}
                                                label={<span
                                                    className={styles.checkboxLabel}>{t('data.isActive')}</span>}/>
                                        </FormGroup>
                                    )}
                                />

                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                    gap: '10px',
                                    alignItems: 'flex-start'
                                }}>
                                    <img src={avatar ? process.env.REACT_APP_STATIC_FILES_URL + avatar : userLogo}
                                         alt="" style={{ maxWidth: '170px' }}/>
                                    <input
                                        accept="image/*"
                                        type="file"
                                        ref={fileInput}
                                        onChange={handleImageUpload}
                                        style={{ display: 'none' }}
                                    />
                                    <img src={hoverEditIcon} alt="" onClick={handleImageUploadClick}
                                         style={{ cursor: 'pointer' }}/>
                                </div>

                            </div>
                            <div>
                                <p>{t('data.personnelNumber')}: {activeEmployee?.personnelNumber} </p>
                                {/*<p>{t('titles.aboutMe')}: {activeEmployee?.aboutMe}</p>*/}
                                <label htmlFor="aboutMe" className={styles.drawerTopSubTitle}
                                       style={{ marginTop: '8px' }}>{t('employees.aboutMe')}</label>
                                <textarea
                                    {...register('aboutMe', {
                                        maxLength: {
                                            value: 1000,
                                            message: t('validation.maxLength1000')
                                        }
                                    })}
                                    id="aboutMe"
                                    autoComplete="off"
                                    rows={6}
                                    cols={50}
                                    placeholder={t('employees.aboutMe') as string}
                                    className={styles.catalogsDrawerInput}
                                />
                            </div>
                        </div>

                        <div style={{ display: 'flex', gap: '40px', marginTop: '20px' }}>
                            <div style={{ width: '500px' }}>
                                <label htmlFor="firstName"
                                       className={styles.drawerTopSubTitle}>{t('data.firstName')}</label>
                                <div>
                                    <input
                                        {...register('firstName', {
                                            required: t('validation.requiredField') as string,
                                            maxLength: {
                                                value: 30,
                                                message: t('validation.maxLength30')
                                            }
                                        })}
                                        id="firstName"
                                        type="text"
                                        autoComplete="off"
                                        placeholder={t('data.firstName') as string}
                                        className={
                                            errors.firstName ?
                                                styles.catalogsDrawerInputError : styles.catalogsDrawerInput
                                        }
                                    />
                                    <ErrorMessage
                                        name="firstName"
                                        errors={errors}
                                        render={({ message }) => <span>{message}</span>}
                                    />
                                </div>
                                <label htmlFor="middleName"
                                       className={styles.drawerTopSubTitle}>{t('data.middleName')}</label>
                                <div>
                                    <input
                                        {...register('middleName', {
                                            maxLength: {
                                                value: 30,
                                                message: t('validation.maxLength30')
                                            }
                                        })}
                                        id="middleName"
                                        type="text"
                                        autoComplete="off"
                                        placeholder={t('data.middleName') as string}
                                        className={
                                            errors.middleName ?
                                                styles.catalogsDrawerInputError : styles.catalogsDrawerInput
                                        }
                                    />
                                    <ErrorMessage
                                        name="middleName"
                                        errors={errors}
                                        render={({ message }) => <span>{message}</span>}
                                    />
                                </div>
                                <label htmlFor="lastName"
                                       className={styles.drawerTopSubTitle}>{t('data.lastName')}</label>
                                <div>
                                    <input
                                        {...register('lastName', {
                                            required: t('validation.requiredField') as string,
                                            maxLength: {
                                                value: 30,
                                                message: t('validation.maxLength30')
                                            }
                                        })}
                                        id="lastName"
                                        type="text"
                                        autoComplete="off"
                                        placeholder={t('data.lastName') as string}
                                        className={
                                            errors.lastName ?
                                                styles.catalogsDrawerInputError : styles.catalogsDrawerInput
                                        }
                                    />
                                    <ErrorMessage
                                        name="lastName"
                                        errors={errors}
                                        render={({ message }) => <span>{message}</span>}
                                    />
                                </div>

                                <FormControl fullWidth sx={{ margin: '.4rem 0 1rem 0' }}>
                                    <span className={styles.drawerTopSubTitle}>{t('data.typeOfEmployment')}</span>
                                    <Controller
                                        name="typeOfEmploymentId"
                                        defaultValue={-1}
                                        control={control}
                                        rules={{ required: t('validation.requiredField') as string }}
                                        render={({ field, fieldState }) =>
                                            <Autocomplete
                                                {...field}
                                                renderInput={(params) =>
                                                    <TextField
                                                        {...params}
                                                        size="small"
                                                        placeholder={t('data.typeOfEmployment') || ''}
                                                        error={!!fieldState.error}
                                                    />}
                                                options={[...typeOfEmployment].sort(
                                                    (a: ILookupItem, b: ILookupItem) => {
                                                        return a.name.localeCompare(b.name, currentLanguage === 'en-US' ? 'en' : 'ru');
                                                    })}
                                                getOptionLabel={(option) => option.name}
                                                onChange={(event, value: ILookupItem | null) =>
                                                    field.onChange(value?.id)}
                                                value={typeOfEmployment.filter(el => el?.id === getValues('typeOfEmploymentId'))[0] || null}
                                            />
                                        }
                                    />
                                    <ErrorMessage
                                        name="typeOfEmploymentId"
                                        errors={errors}
                                        render={({ message }) => <span className={styles.selectErrorMsg}>{message}</span>}
                                    />
                                </FormControl>
                                <FormControl fullWidth sx={{ margin: '.4rem 0 1rem 0' }}>
                                    <span className={styles.drawerTopSubTitle}>{t('data.guild')}</span>
                                    <Controller
                                        name="guildId"
                                        defaultValue={-1}
                                        control={control}
                                        // rules={{required: createOrUpdate === 'create' ? t('validation.requiredField') as string : false}}
                                        render={({ field, fieldState }) =>
                                            <Autocomplete
                                                {...field}
                                                renderInput={(params) =>
                                                    <TextField
                                                        {...params}
                                                        size="small"
                                                        placeholder={t('data.guild') || ''}
                                                        error={!!fieldState.error}
                                                    />}
                                                options={[...guilds].sort((a: ILookupItem, b: ILookupItem) => {
                                                    return a.name.localeCompare(b.name, currentLanguage === 'en-US' ? 'en' : 'ru');
                                                })}
                                                getOptionLabel={(option) => option.name}
                                                onChange={(event, value: ILookupItem | null) =>
                                                    field.onChange(value?.id)}
                                                value={guilds.filter(el => el?.id === getValues('guildId'))[0] || null}
                                            />
                                        }
                                    />
                                    <ErrorMessage
                                        name="guildId"
                                        errors={errors}
                                        render={({ message }) => <span className={styles.selectErrorMsg}>{message}</span>}
                                    />
                                </FormControl>
                                <FormControl fullWidth sx={{ margin: '.4rem 0 1rem 0' }}>
                                    <span className={styles.drawerTopSubTitle}>{t('data.position')}</span>
                                    <Controller
                                        name="positionId"
                                        defaultValue={-1}
                                        control={control}
                                        // rules={{required: createOrUpdate === 'create' ? t('validation.requiredField') as string : false}}
                                        render={({ field, fieldState }) =>
                                            <Autocomplete
                                                {...field}
                                                renderInput={(params) =>
                                                    <TextField
                                                        {...params}
                                                        size="small"
                                                        placeholder={t('data.position') || ''}
                                                        error={!!fieldState.error}
                                                    />}
                                                options={[...positions].sort((a: ILookupItem, b: ILookupItem) => {
                                                    return a.name.localeCompare(b.name, currentLanguage === 'en-US' ? 'en' : 'ru');
                                                })}
                                                getOptionLabel={(option) => option.name}
                                                onChange={(event, value: ILookupItem | null) =>
                                                    field.onChange(value?.id)}
                                                value={positions.filter(el => el?.id === getValues('positionId'))[0] || null}
                                            />
                                        }
                                    />
                                    <ErrorMessage
                                        name="positionId"
                                        errors={errors}
                                        render={({ message }) => <span className={styles.selectErrorMsg}>{message}</span>}
                                    />
                                </FormControl>
                                {/*checked={isResourceManager}*/}
                                <FormGroup sx={{ display: 'inline-block', mb: '5px' }}>
                                    <Controller
                                        name="isResourceManager"
                                        defaultValue={false}
                                        control={control}
                                        render={({ field }) => (
                                            <FormControlLabel
                                                {...field}
                                                control={<Checkbox
                                                    checked={field.value}
                                                    onChange={(event) => field.onChange(event.target.checked)}/>}
                                                label={<span
                                                    className={styles.checkboxLabel}>{t('titles.isResourceManager')}</span>}/>
                                        )}/>

                                </FormGroup>
                                <FormControl fullWidth sx={{ margin: '.4rem 0 1rem 0' }}>
                                    <span className={styles.drawerTopSubTitle}>{t('data.grade')}</span>
                                    <Controller
                                        name="gradeId"
                                        defaultValue={-1}
                                        control={control}
                                        // rules={{required: createOrUpdate === 'create' ? t('validation.requiredField') as string : false}}
                                        render={({ field, fieldState }) =>
                                            <Autocomplete
                                                {...field}
                                                renderInput={(params) =>
                                                    <TextField
                                                        {...params}
                                                        size="small"
                                                        placeholder={t('data.grade') || ''}
                                                        error={!!fieldState.error}
                                                    />}
                                                options={[...grades].sort((a: ILookupItem, b: ILookupItem) => {
                                                    return a.name.localeCompare(b.name, currentLanguage === 'en-US' ? 'en' : 'ru');
                                                })}
                                                getOptionLabel={(option) => option.name}
                                                onChange={
                                                    (event, value: ILookupItem | null) =>
                                                        field.onChange(value?.id)
                                                }
                                                value={grades.filter(el => el?.id === getValues('gradeId'))[0] || null}
                                            />
                                        }
                                    />
                                    <ErrorMessage
                                        name="gradeId"
                                        errors={errors}
                                        render={({ message }) => <span className={styles.selectErrorMsg}>{message}</span>}
                                    />
                                </FormControl>
                                <FormControl fullWidth sx={{ margin: '.4rem 0 1rem 0' }}>
                                    <span className={styles.drawerTopSubTitle}>{t('data.organization')}</span>
                                    <Controller
                                        name="organizationId"
                                        defaultValue={-1}
                                        control={control}
                                        rules={{ required: t('validation.requiredField') as string }}
                                        render={({ field, fieldState }) =>
                                            <Autocomplete
                                                {...field}
                                                renderInput={(params) =>
                                                    <TextField
                                                        {...params}
                                                        size="small"
                                                        placeholder={t('data.organization') || ''}
                                                        error={!!fieldState.error}
                                                    />}
                                                options={[...organizations].sort((a: ILookupItem, b: ILookupItem) => {
                                                    return a.name.localeCompare(b.name, currentLanguage === 'en-US' ? 'en' : 'ru');
                                                })}
                                                getOptionLabel={(option) => option.name}
                                                onChange={
                                                    (event, value: ILookupItem | null) =>
                                                        field.onChange(value?.id)
                                                }
                                                value={organizations.filter(el => el?.id === getValues('organizationId'))[0] || null}
                                            />
                                        }
                                    />
                                    <ErrorMessage
                                        name="organizationId"
                                        errors={errors}
                                        render={({ message }) => <span className={styles.selectErrorMsg}>{message}</span>}
                                    />
                                </FormControl>
                                <FormControl fullWidth sx={{ margin: '.4rem 0 1rem 0' }}>
                                    <span className={styles.drawerTopSubTitle}>{t('data.costCenter')}</span>
                                    <Controller
                                        name="costCenterId"
                                        defaultValue={-1}
                                        control={control}
                                        // rules={{required: createOrUpdate === 'create' ? t('validation.requiredField') as string : false}}
                                        render={({ field, fieldState }) =>
                                            <Autocomplete
                                                {...field}
                                                renderInput={(params) =>
                                                    <TextField
                                                        {...params}
                                                        size="small"
                                                        placeholder={t('data.costCenter') || ''}
                                                        error={!!fieldState.error}
                                                    />}
                                                options={[...costCenters].sort((a: ILookupItem, b: ILookupItem) => {
                                                    return a.name.localeCompare(b.name, currentLanguage === 'en-US' ? 'en' : 'ru');
                                                })}
                                                getOptionLabel={(option) => option.name}
                                                onChange={
                                                    (event, value: ILookupItem | null) =>
                                                        field.onChange(value?.id)
                                                }
                                                value={costCenters.filter(el => el?.id === getValues('costCenterId'))[0] || null}
                                            />
                                        }
                                    />
                                    <ErrorMessage
                                        name="costCenterId"
                                        errors={errors}
                                        render={({ message }) => <span className={styles.selectErrorMsg}>{message}</span>}
                                    />
                                </FormControl>

                                <FormControl fullWidth sx={{ margin: '.4rem 0 1rem 0' }}>
                                    <span className={styles.drawerTopSubTitle}>{t('data.resourceManager')}</span>
                                    <Controller
                                        name="resourceManagerId"
                                        defaultValue=""
                                        control={control}
                                        // rules={{required: createOrUpdate === 'create' ? t('validation.requiredField') as string : false}}
                                        render={({ field, fieldState }) =>
                                            <Autocomplete
                                                {...field}
                                                renderInput={(params) =>
                                                    <TextField
                                                        {...params}
                                                        size="small"
                                                        placeholder={t('data.resourceManager') || ''}
                                                        error={!!fieldState.error}
                                                    />}
                                                options={[...resourceManagers].filter(el => el.id !== id)
                                                    .sort((a: ILookupItem, b: ILookupItem) => {
                                                        return a.name.localeCompare(b.name, currentLanguage === 'en-US' ? 'en' : 'ru');
                                                    })}
                                                getOptionLabel={(option) => option.name}
                                                onChange={
                                                    (event, value: ILookupItem | null) =>
                                                        field.onChange(value?.id)
                                                }
                                                value={resourceManagers.filter(el => el?.id === getValues('resourceManagerId'))[0] || null}
                                            />
                                        }
                                    />
                                    <ErrorMessage
                                        name="resourceManagerId"
                                        errors={errors}
                                        render={({ message }) => <span className={styles.selectErrorMsg}>{message}</span>}
                                    />
                                </FormControl>

                            </div>

                            <div style={{ width: '500px' }}>
                                <FormControl fullWidth sx={{ margin: '.4rem 0 1rem 0' }}>
                                    <span className={styles.drawerTopSubTitle}>{t('data.country')}</span>
                                    <Controller
                                        name="countryId"
                                        defaultValue={-1}
                                        control={control}
                                        rules={{ required: t('validation.requiredField') as string }}
                                        render={({ field, fieldState }) =>
                                            <Autocomplete
                                                {...field}
                                                renderInput={(params) =>
                                                    <TextField
                                                        {...params}
                                                        size="small"
                                                        placeholder={t('data.country') || ''}
                                                        error={!!fieldState.error}
                                                    />}
                                                options={[...countries].sort((a: ILookupItem, b: ILookupItem) => {
                                                    return a.name.localeCompare(b.name, currentLanguage === 'en-US' ? 'en' : 'ru');
                                                })}
                                                getOptionLabel={(option) => option.name}
                                                onChange={
                                                    (event, value: ILookupItem | null) =>
                                                        field.onChange(value?.id)
                                                }
                                                value={countries.filter(el => el?.id === getValues('countryId'))[0] || null}
                                            />
                                        }
                                    />
                                    <ErrorMessage
                                        name="countryId"
                                        errors={errors}
                                        render={({ message }) => <span className={styles.selectErrorMsg}>{message}</span>}
                                    />
                                </FormControl>
                                <label htmlFor="city" className={styles.drawerTopSubTitle}>{t('data.city')}</label>
                                <div>
                                    <input
                                        {...register('city', {
                                            maxLength: {
                                                value: 30,
                                                message: t('validation.maxLength30')
                                            }
                                        })}
                                        id="city"
                                        type="text"
                                        autoComplete="off"
                                        placeholder={t('data.city') as string}
                                        className={
                                            errors.city ? styles.catalogsDrawerInputError : styles.catalogsDrawerInput
                                        }
                                    />
                                    <ErrorMessage
                                        name="city"
                                        errors={errors}
                                        render={({ message }) => <span>{message}</span>}
                                    />
                                </div>

                                <FormControl fullWidth sx={{ margin: '.4rem 0 1rem 0' }}>
                                    <span className={styles.drawerTopSubTitle}>{t('data.timebelt')}</span>
                                    <Controller
                                        name="timeBeltId"
                                        defaultValue={-1}
                                        control={control}
                                        // rules={{required: createOrUpdate === 'create' ? t('validation.requiredField') as string : false}}
                                        render={({ field, fieldState }) =>
                                            <Autocomplete
                                                {...field}
                                                renderInput={(params) =>
                                                    <TextField
                                                        {...params}
                                                        size="small"
                                                        placeholder={t('data.timebelt') || ''}
                                                        error={!!fieldState.error}
                                                    />}
                                                options={[...timeBelts].sort((a: ILookupItem, b: ILookupItem) => {
                                                    return a.name.localeCompare(b.name, currentLanguage === 'en-US' ? 'en' : 'ru');
                                                })}
                                                getOptionLabel={(option) => option.name}
                                                onChange={
                                                    (event, value: ILookupItem | null) =>
                                                        field.onChange(value?.id)
                                                }
                                                value={timeBelts.filter(el => el?.id === getValues('timeBeltId'))[0] || null}
                                            />
                                        }
                                    />
                                    <ErrorMessage
                                        name="timeBeltId"
                                        errors={errors}
                                        render={({ message }) => <span className={styles.selectErrorMsg}>{message}</span>}
                                    />
                                </FormControl>

                                <label htmlFor="address"
                                       className={styles.drawerTopSubTitle}>{t('data.address')}</label>
                                <div>
                                    <input
                                        {...register('address', {
                                            maxLength: {
                                                value: 500,
                                                message: t('validation.maxLength500')
                                            }
                                        })}
                                        id="address"
                                        type="text"
                                        autoComplete="off"
                                        placeholder={t('data.address') as string}
                                        className={
                                            errors.address ?
                                                styles.catalogsDrawerInputError : styles.catalogsDrawerInput
                                        }
                                    />
                                    <ErrorMessage
                                        name="address"
                                        errors={errors}
                                        render={({ message }) => <span>{message}</span>}
                                    />
                                </div>

                                <label htmlFor="phone"
                                       className={styles.drawerTopSubTitle}>{t('data.phoneNumber')}</label>
                                <div>
                                    <input
                                        {...register('phone', {
                                            maxLength: {
                                                value: 100,
                                                message: t('validation.maxLength100')
                                            }
                                        })}
                                        id="phone"
                                        type="text"
                                        autoComplete="off"
                                        placeholder={t('data.phoneNumber') as string}
                                        className={
                                            errors.phone ? styles.catalogsDrawerInputError : styles.catalogsDrawerInput
                                        }
                                    />
                                    <ErrorMessage
                                        name="phone"
                                        errors={errors}
                                        render={({ message }) => <span>{message}</span>}
                                    />
                                </div>

                                <label htmlFor="email" className={styles.drawerTopSubTitle}>{t('data.email')}</label>
                                <div>
                                    <input
                                        {...register('email', {
                                            maxLength: {
                                                value: 100,
                                                message: t('validation.maxLength100')
                                            },
                                            // pattern: {
                                            //     value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i,
                                            //     message: t('validation.invalidEmail') as string
                                            // },
                                        })}
                                        id="email"
                                        type="text"
                                        autoComplete="off"
                                        placeholder={t('data.email') as string}
                                        className={
                                            errors.email ? styles.catalogsDrawerInputError : styles.catalogsDrawerInput
                                        }
                                    />
                                    <ErrorMessage
                                        name="email"
                                        errors={errors}
                                        render={({ message }) => <span>{message}</span>}
                                    />
                                </div>

                                <label htmlFor="link" className={styles.drawerTopSubTitle}>{t('data.links')}</label>
                                <div>
                                    <input
                                        {...register('link', {
                                            maxLength: {
                                                value: 1000,
                                                message: t('validation.maxLength1000')
                                            }
                                        })}
                                        id="link"
                                        type="text"
                                        autoComplete="off"
                                        placeholder={t('data.links') as string}
                                        className={
                                            errors.link ? styles.catalogsDrawerInputError : styles.catalogsDrawerInput
                                        }
                                    />
                                    <ErrorMessage
                                        name="link"
                                        errors={errors}
                                        render={({ message }) => <span>{message}</span>}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                }

                {employeesActiveTab === 1 &&
                    <div style={{ marginTop: '15px' }}>
                        {getValues('skills')?.map((skill) =>
                            <div key={skill.id} style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'flex-start',
                                maxWidth: '500px',
                                marginBottom: '20px'
                            }}>
                                <div style={{ display: 'flex', gap: '10px' }}>
                                    <div>
                                        <img src={SkillIcon} alt="" style={{ marginTop: '-6px' }}/>
                                    </div>
                                    <div>
                                        <p>{skillList.find(el => el.id === skill.skillId)?.name}</p>
                                        <p>{skillLevels.find(el => el.id === skill.skillLevelId)?.name}</p>
                                    </div>

                                </div>
                                <div style={{ display: 'flex', gap: '10px', marginTop: '5px' }}>
                                    <p>{skill.isBasicSkill ? t('data.mainSkill') : ''}</p>
                                    <img src={DeleteIcon} alt="" style={{ cursor: 'pointer' }} onClick={() => {
                                        setIsOpenDeleteModalSkill(true);
                                        setSkillId(skill.id);
                                    }}/>
                                    <img src={hoverEditIcon} alt="" style={{ cursor: 'pointer' }}
                                         onClick={() => editSkill(skill)}/>
                                </div>
                            </div>
                        )}

                        <Button variant="contained" sx={saveBtn} onClick={() => addSkill()}>
                            {t('buttons.AddSkill')}
                        </Button>

                        {/*getValues={getValues} setValue={setValue} control={control} register={register}*/}

                    </div>
                }
                {!isMyAccount && employeesActiveTab === 2 &&
                    <DocumentsComponent
                        documents={activeEmployee?.documents || []}
                        entity="employees"
                        editDocumentsList={editDocumentsList}
                        activeDocument={activeDocument}
                        setActiveDocument={setActiveDocument}
                        setDocumentModalOpen={setDocumentModalOpen}/>
                }

            </form>
            <ConfirmationModal isModalOpen={isOpenDeleteModalSkill} setIsModalOpen={setIsOpenDeleteModalSkill}
                               actionName={'DeleteSkill'} instanceName={'employees'} handleAction={deleteSkill}/>
            {
                documentModalOpen.isOpen && documentModalOpen.actionName ? (
                    <DocumentModal
                        isModalOpen={documentModalOpen.isOpen}
                        setIsModalOpen={setDocumentModalOpen}
                        actionName={documentModalOpen.actionName}
                        document={activeDocument}
                        instanceName="employees"
                        activeEntityId={id!}
                        editDocumentsList={editDocumentsList}
                    />
                ) : null
            }
            {
                skillModalOpen.isOpen && skillModalOpen.actionName ? (
                    <SkillsModal
                        isModalOpen={skillModalOpen.isOpen}
                        clearForm={clearSkillForm}
                        actionName={skillModalOpen.actionName}
                        skill={activeSkill}
                        activeEntityId={activeSkill?.id ? activeSkill.id : null}
                        getMainFormValues={getValues}
                        setMainFormValue={setValue}
                    />
                ) : null
            }
            <ConfirmationModal
                isModalOpen={isConfirmationModalOpen}
                setIsModalOpen={setConfirmationModalOpen}
                actionName={'Close'}
                instanceName=""
                message={'closeFormWithoutSaving'}
                handleAction={closeModalConfirm}
            />
        </div>
    );
};
