import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'usehooks-ts';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import { EmployeeGeneralInfo } from '../../../features/Employee/EmployeeGeneralInfo';
import EmployeeSkills from '../../../features/Employee/EmployeeSkills/EmployeeSkills';
import EmployeeDocuments from '../../../features/Employee/EmployeeView/EmployeeDocuments/EmployeeDocuments';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { PERSONAL_ACCOUNT_EDIT } from '../../../router/constants';
import {
    getAllowedGroupsByMenuId,
    getAllowedPermissionsByGroupId,
    getAllSkillLevels,
    getAllSkills,
    getEmployeeById
} from '../../../store';
import Loader from '../../../ui/Loader';

export const PersonalApplications = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const employeeDetails = useAppSelector(
        (state) => state.employees.employeeDetails,
    );
    const allSkills = useAppSelector((state) => state.employees.allSkills);
    const userId = useAppSelector((state) => state.auth.userId);
    const allSkillLevels = useAppSelector(
        (state) => state.employees.allSkillLevels,
    );
    const [employeesActiveTab, setEmployeesActiveTab] = useLocalStorage(
        "employeesActiveTab",
        0,
    );
    const [isLoading, setIsLoading] = useState(false);

    const handleActiveTab = (event: React.SyntheticEvent, newValue: number) => {
        setEmployeesActiveTab(newValue);
    };

    // useEffect(() => {
    //     (async () => {
    //         if (userId) {
    //             setIsLoading(true);
    //             await Promise.all([
    //                 dispatch(getEmployeeById(userId)),
    //                 dispatch(getAllSkills()),
    //                 dispatch(getAllSkillLevels()),
    //             ]);
    //             setIsLoading(false);
    //         }
    //     })();
    // }, [dispatch, userId, t]);

    const allowedGroups = useAppSelector((state) => state.auth.allowedGroups);
    const allowedPermissions = useAppSelector(
        (state) => state.auth.allowedPermissions,
    );
    const allowedMenus = useAppSelector((state) => state.auth.allowedMenus);

    useEffect(() => {
        const menu = allowedMenus.find((menu) => menu.name === "Employees");
        typeof menu?.id === "number" &&
            dispatch(getAllowedGroupsByMenuId(menu.id));
    }, [allowedMenus, dispatch]);

    useEffect(() => {
        const group = allowedGroups.find(
            (menu) => menu.name === `api/employees`,
        );
        group?.id && dispatch(getAllowedPermissionsByGroupId(group.id));
    }, [dispatch, allowedGroups]);

    // if (!employeeDetails || isLoading) {
    //     return <Loader />;
    // }

    return <Box sx={{ width: "100%", padding: "10px 0" }}>заявки</Box>;
};
