import { createSlice } from "@reduxjs/toolkit";

import { ILookups } from "../../models/lookups";
import { revertAll } from "../store";
import {
    getCurrencies,
    getEmployeesLookup,
    getEmployeesLookUpThunk,
    getEmployeesWithoutSalary,
    getLookupByName,
    getSkillLevelsBySkillSetId,
    getTypeofemployments,
} from "../thunks";

const initialState: ILookups = {
    countries: [], //all and countriesByRegion
    regions: [],
    months: [],
    genders: [],
    timeBelts: [],
    positions: [],
    typeOfEmployments: [],
    skills: [],
    skillSets: [],
    skillLevels: [], // all and by skillsetid
    roles: [],
    departments: [],
    costCenters: [],
    statuses: [],
    organizationTypes: [],
    guilds: [],
    grades: [],
    employees: [],
    organizations: [],
    skillLevelsBySkillSet: [],
    currencies: [],
    employeesWithoutSalary: [],
    contractStatuses: [],
    typeOfContract: [],
    resourceManagers: [],
};

const lookupsSlice = createSlice({
    name: "lookups",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(revertAll, () => initialState)
            .addCase(getLookupByName.fulfilled, (state, action) => {
                switch (action.payload.name) {
                    case "currencyRate":
                        state.countries = action.payload.data;
                        break;
                    case "countries":
                        state.countries = action.payload.data;
                        break;
                    case "regions":
                        state.regions = action.payload.data;
                        break;
                    case "months":
                        state.months = action.payload.data;
                        break;
                    case "genders":
                        state.genders = action.payload.data;
                        break;
                    case "timeBelts":
                        state.timeBelts = action.payload.data;
                        break;
                    case "positions":
                        state.positions = action.payload.data;
                        break;
                    case "typeOfEmployments":
                        state.typeOfEmployments = action.payload.data;
                        break;
                    case "skills":
                        state.skills = action.payload.data;
                        break;
                    case "skillset":
                        state.skillSets = action.payload.data;
                        break;
                    case "skillLevels":
                        state.skillLevels = action.payload.data;
                        break;
                    case "roles":
                        state.roles = action.payload.data;
                        break;
                    case "departments":
                        state.departments = action.payload.data;
                        break;
                    case "costCenter":
                        state.costCenters = action.payload.data;
                        break;
                    case "statuses":
                        state.statuses = action.payload.data;
                        break;
                    case "organizationTypes":
                        state.organizationTypes = action.payload.data;
                        break;
                    case "guilds":
                        state.guilds = action.payload.data;
                        break;
                    case "grades":
                        state.grades = action.payload.data;
                        break;
                    case "organizations":
                        state.organizations = action.payload.data;
                        break;
                    case "employees":
                        state.employees = action.payload.data;
                        break;
                    case "contractstatuses":
                        state.contractStatuses = action.payload.data;
                        break;
                    case "typeofcontract":
                        state.typeOfContract = action.payload.data;
                        break;
                    case "currencies":
                        state.currencies = action.payload.data;
                        break;
                    default:
                        break;
                }
            })
            .addCase(getEmployeesLookUpThunk.fulfilled, (state, action) => {
                state.resourceManagers = action.payload;
            })
            .addCase(getEmployeesLookup.fulfilled, (state, action) => {
                state.employees = action.payload;
            })
            .addCase(getSkillLevelsBySkillSetId.fulfilled, (state, action) => {
                state.skillLevelsBySkillSet = action.payload;
            })
            .addCase(getCurrencies.fulfilled, (state, action) => {
                state.currencies = action.payload;
            })
            .addCase(getEmployeesWithoutSalary.fulfilled, (state, action) => {
                state.employeesWithoutSalary = action.payload;
            })
            .addCase(getTypeofemployments.fulfilled, (state, action) => {
                state.typeOfEmployments = action.payload;
            });
    },
});

export default lookupsSlice.reducer;
