import { IDocument } from "models/documents";

import { createSlice } from "@reduxjs/toolkit";

import {
    IPersonalDocuments,
} from "../../models/documents";
import {
    IAllSkillLevels,
    IAllSkills,
    IDetailedEmployee,
    IDetailedEmployeeInfo,
    IEmployee,
    IEmployeeErrResponse,
    IEmployeePaged,
} from "../../models/employees";
import { revertAll } from "../store";
import { getAllSkillLevels, getAllSkills, getEmployeeById, getEmployees, getPersonalDocuments,getPersonalInfoEmployeeById } from "../thunks";

type employeeSlice = {
    isLoading: boolean,
    employees: IEmployeePaged,
    employeeFullName: string,
    employeeErrors: null | IEmployeeErrResponse | undefined | string,
    employeeDetails: null | IDetailedEmployee
    personalInfoEmployeeDetails: null | IDetailedEmployeeInfo
    allSkills: IAllSkills[]
    allSkillLevels: IAllSkillLevels[],
    activeEmployee: null | IEmployee,
    personalDocuments:IPersonalDocuments | null,
}

const initialState: employeeSlice = {
    isLoading: false,
    employees: {},
    employeeFullName: '',
    employeeErrors: null,
    employeeDetails: null,
    personalInfoEmployeeDetails: null,
    personalDocuments: null,
    allSkills: [],
    allSkillLevels: [],
    activeEmployee: null
};

const employeesSlice = createSlice({
    name: 'employees',
    initialState,
    reducers: {
        setEmployeeFullName: (state, action) => {
            state.employeeFullName = action.payload;
        },
        setActiveEmployee: (state, action) => {
            state.activeEmployee = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(revertAll, () => initialState)
            .addCase(getEmployees.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getEmployees.fulfilled, (state, action) => {
                state.employees = { ...action.payload };
                state.isLoading = false;
            })
            .addCase(getEmployees.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(getEmployeeById.fulfilled, (state, action) => {
                state.employeeDetails = action.payload;
            })
            .addCase(getPersonalInfoEmployeeById.fulfilled, (state, action) => {
                state.personalInfoEmployeeDetails = action.payload;
            })
            .addCase(getPersonalDocuments.fulfilled, (state, action) => {
                state.personalDocuments = action.payload;
            })
            .addCase(getAllSkills.fulfilled, (state, action) => {
                state.allSkills = action.payload;
            })
            .addCase(getAllSkillLevels.fulfilled, (state, action) => {
                state.allSkillLevels = action.payload;
            });
    }
});

export const {
    setEmployeeFullName,
    setActiveEmployee,
} = employeesSlice.actions;

export default employeesSlice.reducer;
