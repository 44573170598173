import { FC, Fragment } from 'react';
import Guard from 'features/Guard';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { Box, Divider, Typography } from '@mui/material';
import Button from '@mui/material/Button';

import { EMPLOYEES_PATH, PROJECTS_PATH, } from '../../../../router/constants';
import { changeActiveTabInEditProject } from '../../../../store';
import { activateOrDeactivateBtn as editProjectBtn, cancelBtn, } from '../../../../styles/MUIStyles';
import Loader from '../../../../ui/Loader';

import styles from './GeneralInfo.module.scss';


interface Props {
    activeTab: number;
    allowedPermissions: any[];
}

const GeneralInfo: FC<Props> = ({ activeTab, allowedPermissions }): JSX.Element => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const params = useParams();

    const projectDataToView = useAppSelector(
        (state) => state.projects.projectDataToView
    );
    const isProjectViewLoading = useAppSelector(
        (state) => state.projects.isProjectViewLoading
    );

    const getProjectStatusText = (status: number | undefined) => {
        switch (status) {
            case 1:
                return t('statuses.Preparation');
            case 2:
                return t('statuses.InProgress');
            case 3:
                return t('statuses.OnHold');
            case 4:
                return t('statuses.Cancelled');
            case 5:
                return t('statuses.Completed');
            default:
                return '';
        }
    };

    const getProjectStatusColor = (status: number | undefined) => {
        switch (status) {
            case 1:
                return '#BEBEBE';
            case 2:
                return '#25c00e';
            case 3:
                return '#BEBEBE';
            case 4:
                return '#FA0E00';
            case 5:
                return '#BEBEBE';
            default:
                return '#237657';
        }
    };

    return (
        <div className={styles.generalInfoContainer}>
            {isProjectViewLoading ? (
                <Box sx={{ flexGrow: 1 }}>
                    <Loader/>
                </Box>
            ) : (
                <Fragment>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            margin: '1rem 0',
                        }}
                    >
                        <div className={styles.projectStatuses}>
                            <Typography
                                sx={{
                                    color: projectDataToView?.isActive
                                        ? '#237657'
                                        : '#FA0E00',
                                    fontWeight: 600,
                                    fontFamily: 'Source Sans Pro',
                                }}
                            >
                                {projectDataToView?.isActive
                                    ? t('statuses.Active')
                                    : t('statuses.Inactive')}
                            </Typography>
                            <Typography
                                sx={{
                                    color: getProjectStatusColor(
                                        projectDataToView?.projectStatus
                                    ),
                                    fontWeight: 600,
                                    fontFamily: 'Source Sans Pro',
                                }}
                            >
                                {getProjectStatusText(
                                    projectDataToView?.projectStatus
                                )}
                            </Typography>
                        </div>

                        <div>
                            <Guard
                                allowedPermissions={allowedPermissions}
                                permissionName="Edit"
                            >
                                <Button
                                    variant="contained"
                                    type="submit"
                                    sx={editProjectBtn}
                                    onClick={() => {
                                        dispatch(
                                            changeActiveTabInEditProject(
                                                activeTab
                                            )
                                        );
                                        navigate(
                                            `/${PROJECTS_PATH}/management/edit/${params.projectId}`
                                        );
                                    }}
                                >
                                    {t('buttons.Edit')}
                                </Button>
                            </Guard>
                            <Button
                                variant="text"
                                onClick={() => navigate(-1)}
                                sx={cancelBtn}
                            >
                                {t('buttons.Close')}
                                {/*    */}
                            </Button>
                        </div>
                    </Box>

                    <Box>
                        <Box sx={{ marginBottom: '1rem' }}>
                            <span className={styles.projectFTE}>
                                FTE <span>{projectDataToView?.fte}</span>
                            </span>
                        </Box>
                        <Box sx={{ marginBottom: '1rem' }}>
                            <span className={styles.projectCode}>
                                {projectDataToView?.projectCode}
                            </span>
                        </Box>
                        <Box sx={{ marginBottom: '1rem' }}>
                            <h2 className={styles.projectShortName}>
                                {projectDataToView?.shortName}
                            </h2>
                        </Box>
                        <Box sx={{ marginBottom: '1rem' }}>
                            <h3 className={styles.projectFullName}>
                                {projectDataToView?.fullName}
                            </h3>
                        </Box>
                        <Box sx={{ marginBottom: '1rem' }}>
                            <p>{projectDataToView?.description}</p>
                        </Box>
                        <Box sx={{ marginBottom: '1rem' }}>
                            <span>
                                {projectDataToView?.projectType === 1
                                    ? t('projects.projectTypeInternal')
                                    : t('projects.projectTypeExternal')}
                            </span>
                        </Box>
                        <Box sx={{ marginBottom: '1rem' }}>
                            <span>
                                {projectDataToView?.isBillable
                                    ? t('projects.paid')
                                    : t('projects.unpaid')}
                            </span>
                        </Box>
                        <Divider
                            sx={{ borderWidth: '1.5px', marginBottom: '1rem' }}
                        />
                        <Box
                            sx={{
                                marginBottom: '1rem',
                                display: 'flex',
                                gap: '1.5rem',
                            }}
                        >
                            <span>{t('data.manager')}</span>
                            <ul className={styles.projectManagersList}>
                                {projectDataToView?.managers.map((manager) => (
                                    <li key={manager.id}>
                                        <Link
                                            to={`/${EMPLOYEES_PATH}/${manager.id}`}
                                        >
                                            {manager.fullName}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </Box>
                    </Box>
                </Fragment>
            )}
        </div>
    );
};

export default GeneralInfo;
