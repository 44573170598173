import { FC, useEffect, useMemo, useState, } from 'react';
import { AscSortIcon, CreateIcon, DefaultSortIcon, DescSortIcon, EditIcon, } from 'assets/icons';
import Guard from 'features/Guard';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useTranslation } from 'react-i18next';
import { activateDataByCatalogName, deactivateDataByCatalogName, } from 'store';
import { createOrEditBtn } from 'styles/MUIStyles';

import CheckIcon from '@mui/icons-material/Check';
import Button from '@mui/material/Button';

import ConfirmationModal from '../../../features/ConfirmationModal';
import ListItemActions from '../../../features/ListItemActions';
import { currency } from '../../../models/catalogs';

import { CurrencyForm } from './CurrencyForm';

import styles from '../CatalogsLayout.module.scss';

const Currency: FC<{
    name: string;
    createOrUpdate: null | string;
    setCreateOrUpdate: Function;
    fulfilledStatusHandler: Function;
}> = ({
          name,
          createOrUpdate,
          setCreateOrUpdate,
          fulfilledStatusHandler,
      }): JSX.Element => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const currentLanguage = localStorage.getItem('i18nextLng');

    const currencyList = useAppSelector((state) => state.catalogs.currencyList);
    const allowedPermissions = useAppSelector(
        (state) => state.auth.allowedPermissions,
    );

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [activeCurrency, setActiveCurrency] = useState<null | currency>(null);
    const [searchCurrency, setSearchCurrency] = useState('');
    const [sortConfig, setSortConfig] = useState<{
        column: null | string;
        direction: null | string;
    }>({
        column: null,
        direction: null,
    });

    const [confirmationModalOpen, setConfirmationModalOpen] = useState<{
        isOpen: boolean;
        actionName: string;
    }>({ isOpen: false, actionName: '' });

    const activateItem = async () => {
        if (activeCurrency?.id) {
            const response = await dispatch(
                activateDataByCatalogName({
                    catalogName: name,
                    catalogNameId: activeCurrency?.id,
                }),
            );
            if (response.meta.requestStatus === 'fulfilled') {
                fulfilledStatusHandler('notifications.successfullyActivated');
                setConfirmationModalOpen({ isOpen: false, actionName: '' });
            }
            return;
        }
    };

    const deactivateItem = async () => {
        if (activeCurrency?.id) {
            const response = await dispatch(
                deactivateDataByCatalogName({
                    catalogName: name,
                    catalogNameId: activeCurrency?.id,
                }),
            );

            if (
                response.meta.requestStatus === 'fulfilled' &&
                response.payload
            ) {
                fulfilledStatusHandler(
                    t('notifications.recordInUseNotice'),
                    'warn',
                );
                setConfirmationModalOpen({ isOpen: false, actionName: '' });
                return;
            }

            if (response.meta.requestStatus === 'fulfilled') {
                fulfilledStatusHandler('notifications.successfullyDeactivated');
                setConfirmationModalOpen({ isOpen: false, actionName: '' });
            }
        }
    };

    const onCreateItem = () => {
        setIsDrawerOpen(true);
        setCreateOrUpdate('create');
        setActiveCurrency(null);
    };

    const onEditItem = () => {
        setIsDrawerOpen(true);
        setCreateOrUpdate('update');
    };

    const confirmActionHandler = () => {
        switch (confirmationModalOpen.actionName) {
            case 'Activate':
                activateItem();
                break;
            case 'Deactivate':
                deactivateItem();
                break;
        }
    };

    useEffect(() => {
        setActiveCurrency(null);
    }, [dispatch, name]);

    const getArrowForSortDirection = (column: string) => {
        if (sortConfig.column !== column) {
            return <DefaultSortIcon/>;
        }
        return sortConfig.direction === 'asc' ? (
            <AscSortIcon/>
        ) : (
            <DescSortIcon/>
        );
    };

    const handleSort = (column: string) => {
        if (sortConfig.column === column) {
            setSortConfig({
                column,
                direction: sortConfig.direction === 'asc' ? 'desc' : 'asc',
            });
            return;
        }

        setSortConfig({ column, direction: 'asc' });
    };

    const sortedCurrencies = useMemo(() => {
        const copyCurrencies = [...currencyList];

        if (sortConfig.column) {
            copyCurrencies.sort((a: currency, b: currency) => {
                const isAscending = sortConfig.direction === 'asc';
                const compareResult =
                    sortConfig.column &&
                    a[sortConfig.column].localeCompare(
                        b[sortConfig.column],
                        currentLanguage === 'en-US' ? 'en' : 'ru',
                        {
                            sensitivity: 'accent',
                        },
                    );

                return isAscending ? compareResult : -compareResult;
            });
        }

        return copyCurrencies;
    }, [currencyList, sortConfig, currentLanguage]);

    return (
        <div>
            <div className={styles.crudButtonWrapper}>
                <Guard
                    allowedPermissions={allowedPermissions}
                    permissionName="Create"
                >
                    <Button
                        startIcon={<CreateIcon/>}
                        variant="text"
                        disabled={false}
                        onClick={() => onCreateItem()}
                        sx={createOrEditBtn}
                    >
                        {t('buttons.Create')}
                    </Button>
                </Guard>
                <Guard
                    allowedPermissions={allowedPermissions}
                    permissionName="Edit"
                >
                    <Button
                        startIcon={<EditIcon/>}
                        variant="text"
                        disabled={!activeCurrency?.isActive}
                        onClick={() => onEditItem()}
                        sx={createOrEditBtn}
                    >
                        {t('buttons.Edit')}
                    </Button>
                </Guard>
            </div>
            <div className={styles.searchWrapper}>
                <input
                    type="text"
                    placeholder={t('filters.searchByCurrencyName') as string}
                    className={styles.searchCurrencyInput}
                    value={searchCurrency}
                    onChange={(e) => setSearchCurrency(e.target.value)}
                />
            </div>
            <div className={styles.tableHeader}>
                <span
                    onClick={() => handleSort('currencyCode')}
                    className={styles.column}
                >
                    {t('data.currencyCode')}{' '}
                    {getArrowForSortDirection('currencyCode')}
                </span>
                <span
                    onClick={() => handleSort('name')}
                    className={styles.column}
                >
                    {t('data.name')} {getArrowForSortDirection('name')}
                </span>
                <span className={styles.column}>{t('data.isPrimary')}</span>
                <span className={styles.column}>{t('data.actions')}</span>
            </div>
            <ul className={styles.catalogNameDataList}>
                {sortedCurrencies
                    .filter((currency) =>
                        currency.name
                            .toLowerCase()
                            .includes(searchCurrency.toLowerCase()),
                    )
                    .map((item) => (
                        <li
                            key={item.id}
                            className={
                                activeCurrency?.id === item.id
                                    ? `${styles.catalogNameDataListItem} ${styles.catalogNameDataListItemActive}`
                                    : `${styles.catalogNameDataListItem}`
                            }
                            onClick={() => setActiveCurrency({ ...item })}
                        >
                            <span>{item.currencyCode}</span>
                            <span>{item.name}</span>
                            {item.isPrimary ? (
                                <span>
                                    <CheckIcon sx={{ color: '#205493' }}/>
                                </span>
                            ) : (
                                <span/>
                            )}
                            <span>
                                <ListItemActions
                                    itemId={item.id}
                                    isItemActive={item.isActive}
                                    activationAvailable={true}
                                    setConfirmationModalOpen={
                                        setConfirmationModalOpen
                                    }
                                    editItemClick={onEditItem}
                                />
                            </span>
                        </li>
                    ))}
            </ul>

            <CurrencyForm
                name={name}
                activeCurrency={activeCurrency}
                setActiveCurrency={setActiveCurrency}
                createOrUpdate={createOrUpdate}
                setCreateOrUpdate={setCreateOrUpdate}
                isDrawerOpen={isDrawerOpen}
                setIsDrawerOpen={setIsDrawerOpen}
                fulfilledStatusHandler={fulfilledStatusHandler}
            />

            <ConfirmationModal
                isModalOpen={confirmationModalOpen.isOpen}
                setIsModalOpen={setConfirmationModalOpen}
                actionName={confirmationModalOpen.actionName}
                instanceName={name}
                handleAction={confirmActionHandler}
            />
        </div>
    );
};

export default Currency;
