import axiosApi from "core/request";

import { createAsyncThunk } from "@reduxjs/toolkit";

export const getLookupByName = createAsyncThunk(
    "lookups/getLookupByName",
    async (name: string, { rejectWithValue }) => {
        try {
            const response = await axiosApi.get(
                `lookups/${name.toLowerCase()}`,
            );
            return { name, data: response.data };
        } catch (error) {
            return rejectWithValue("Error that i need to handle");
        }
    },
);

export const getEmployeesLookup = createAsyncThunk(
    "lookups/getEmployeesLookup",
    async (_, { rejectWithValue }) => {
        try {
            const response = await axiosApi.get("employees/employeeslookup");
            return response.data;
        } catch (error) {
            return rejectWithValue("Error");
        }
    },
);

export const getEmployeesLookUpThunk = createAsyncThunk(
    "lookups/gemployeeslookup",
    async (_, { rejectWithValue }) => {
        
        try {
            const response = await axiosApi.get("employees/employeesresourceslookup");
            return response.data;
        } catch (error) {
            return rejectWithValue("Error");
        }
    },
);

export const getSkillLevelsBySkillSetId = createAsyncThunk(
    "lookups/getSkillLevelsBySkillSetId",
    async (id: number | string, { rejectWithValue }) => {
        try {
            const response = await axiosApi.get(
                `lookups/skilllevelsbyskillsetid?id=${id}`,
            );
            return response.data;
        } catch (error) {
            return rejectWithValue("Error");
        }
    },
);

export const getCurrencies = createAsyncThunk(
    "lookups/getCurrencies",
    async (_, { rejectWithValue }) => {
        try {
            const response = await axiosApi.get(`lookups/currencies`);
            return response.data;
        } catch (error) {
            return rejectWithValue("Error");
        }
    },
);

export const getEmployeesWithoutSalary = createAsyncThunk(
    "lookups/employeeswithoutsalarylookup",
    async (_, { rejectWithValue }) => {
        try {
            const response = await axiosApi.get(
                `salaries/employeeswithoutsalarylookup`,
            );
            return response.data;
        } catch (error) {
            return rejectWithValue("Error");
        }
    },
);

export const getTypeofemployments = createAsyncThunk(
    "lookups/getTypeofemployments",
    async (_, { rejectWithValue }) => {
        try {
            const response = await axiosApi.get(`lookups/typeofemployments`);
            return response.data;
        } catch (error) {
            return rejectWithValue("Error");
        }
    },
);
