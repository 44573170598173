// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_header__EByin {\n  background: #c4dbfd;\n  display: flex;\n  justify-content: space-between;\n}\n.styles_header__EByin .styles_logo__HK-7b {\n  height: 7.5vh;\n}", "",{"version":3,"sources":["webpack://./src/views/Layout/Header/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,aAAA;EACA,8BAAA;AACF;AACE;EACE,aAAA;AACJ","sourcesContent":[".header {\n  background: #c4dbfd;\n  display: flex;\n  justify-content: space-between;\n\n  .logo {\n    height: 7.5vh;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "styles_header__EByin",
	"logo": "styles_logo__HK-7b"
};
export default ___CSS_LOADER_EXPORT___;
