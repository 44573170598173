import axios from 'axios';

import { LOGIN_PATH } from '../router/constants';
import { setIsAuth } from '../store/slices/authSlice';
import store from '../store/store';

import History from './utils/history';

const axiosApi = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

axiosApi.interceptors.request.use((config: any) => {
    config.headers['Authorization'] = `Bearer ${localStorage.getItem('accessToken')}`;
    if (localStorage.getItem('i18nextLng') === 'ru' || localStorage.getItem('i18nextLng') === 'ru-RU') {
        config.headers['Accept-Language'] = 'ru-RU';
    } else {
        config.headers['Accept-Language'] = 'en-US';
    }

    return config;
}, (error: any) => {
    return Promise.reject(error);
});
axiosApi.interceptors.response.use((config: any) => {
    return config;
}, async (error: any) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
        try {
            originalRequest._retry = true;
            const accessToken = localStorage.getItem('accessToken');
            const refreshToken = localStorage.getItem('refreshToken');
            const response = await axiosApi.post('/account/refreshaccesstoken', { accessToken, refreshToken });
            localStorage.setItem('accessToken', response.data.accessToken);
            response.data.refreshToken && localStorage.setItem('refreshToken', response.data.refreshToken);
            return axiosApi.request(originalRequest);
        } catch (error: any) {
            // do logout
            store.dispatch(setIsAuth(false));
            const currentLanguage = localStorage.getItem('i18nextLng');
            localStorage.clear();
            currentLanguage && localStorage.setItem('i18nextLng', currentLanguage);
            History.push(LOGIN_PATH);
        }
    }
    return Promise.reject(error);
});
export default axiosApi;
