import { createSlice } from "@reduxjs/toolkit";

import { revertAll } from "../store";
import {
    getAllowedGroupsByMenuId,
    getAllowedMenus,
    getAllowedPermissionsByGroupId,
    getUserInfo,
    login,
    logout,
} from "../thunks/authThunk";

// roles/permissions/all по методам

// roles/permissions?roleName=Administrator

// user/permissions?roleName=Administrator

type LoginError = {
    errors: { userName?: string[]; password?: string[]; loginError?: string[] };
};

type userSlice = {
    isAuth: boolean;
    userName: string | null;
    role: string | null;
    isGetUserInfoLoading: boolean;
    allowedMenus: any[];
    allowedGroups: any[];
    allowedPermissions: any[];
    isUserLoginLoading: boolean;
    isAllowedGroupsLoading: boolean;
    loginErrors: LoginError | null;
    expanded: string | boolean;
    userId: string | null;
    pageWithMenuGroups: string;
};

const initialState: userSlice = {
    isAuth: false,
    userName: null,
    role: null,
    isGetUserInfoLoading: true,
    allowedMenus: [],
    allowedGroups: [],
    allowedPermissions: [],
    isUserLoginLoading: false,
    isAllowedGroupsLoading: false,
    loginErrors: null,
    expanded: false,
    userId: null,
    pageWithMenuGroups: "",
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setIsAuth: (state, action) => {
            state.isAuth = action.payload;
        },
        handleNavBarExpanded: (state, action) => {
            state.expanded = action.payload;
        },
        setPageWithMenuGroups: (state, action) => {
            localStorage.setItem("pageWithMenuGroups", action.payload);
            state.pageWithMenuGroups = action.payload;
        },
        resetPermissions: (state) => {
            state.allowedPermissions = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(revertAll, () => initialState)
            .addCase(login.pending, (state) => {
                state.isUserLoginLoading = true;
                state.loginErrors = null;
            })
            .addCase(login.fulfilled, (state, action) => {
                state.isAuth = true;
                state.userName = action.payload.fullName;
                state.role = action.payload.role;
                state.isUserLoginLoading = false;
                state.loginErrors = null;
                state.userId = action.payload.userId;
                localStorage.setItem("accessToken", action.payload.accessToken);
                localStorage.setItem(
                    "refreshToken",
                    action.payload.refreshToken,
                );
            })
            //TODO ADD TYPING TO ACTION PAYLOAD
            .addCase(login.rejected, (state, action: any) => {
                state.isUserLoginLoading = false;
                state.loginErrors = action.payload;
            })
            .addCase(logout.fulfilled, (state) => {
                state.isAuth = false;
                localStorage.removeItem("accessToken");
                localStorage.removeItem("refreshToken");
            })
            .addCase(getUserInfo.pending, (state) => {
                state.isGetUserInfoLoading = true;
            })
            .addCase(getUserInfo.fulfilled, (state, action) => {
                state.userName = action.payload.fullName;
                state.role = action.payload.userRole;
                state.userId = action.payload.userId;
                state.isAuth = true;
                state.isGetUserInfoLoading = false;
            })
            .addCase(getUserInfo.rejected, (state) => {
                state.isGetUserInfoLoading = false;
                state.isAuth = false;
            })
            .addCase(getAllowedMenus.fulfilled, (state, action) => {
                state.allowedMenus = action.payload;
            })
            .addCase(getAllowedGroupsByMenuId.pending, (state) => {
                state.isAllowedGroupsLoading = true;
            })
            .addCase(getAllowedGroupsByMenuId.fulfilled, (state, action) => {
                state.allowedGroups = action.payload;
                state.isAllowedGroupsLoading = false;
            })
            .addCase(getAllowedGroupsByMenuId.rejected, (state) => {
                state.isAllowedGroupsLoading = false;
            })
            .addCase(
                getAllowedPermissionsByGroupId.fulfilled,
                (state, action) => {
                    state.allowedPermissions = action.payload;
                },
            );
    },
});

export const {
    setIsAuth,
    handleNavBarExpanded,
    setPageWithMenuGroups,
    resetPermissions,
} = authSlice.actions;
export default authSlice.reducer;
