import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { currency } from "../../models/catalogs";
import {
    createRate,
    getActiveRates,
    getCurrencyRates,
    updateRate,
} from "../thunks/rateThunk";

interface RateSlice {
    currencyRatesList: Omit<currency[], "isPrimary">;
    activeCurrency: Omit<currency, "isPrimary"> | null;
    activeRates: Omit<currency, "isPrimary">[];
    activeTab: number;
    totalRateCount: number;
    rateIsLoading: boolean;
    rateTableIsLoading: boolean;
}

interface IRate {
    currencyCode: string;
    id: number;
    isActive: boolean;
    name: string;
    rate: number;
    rateDate: string;
}

const initialState: RateSlice = {
    rateIsLoading: false,
    rateTableIsLoading: false,
    currencyRatesList: [],
    activeRates: [],
    activeCurrency: null,
    activeTab: 0,
    totalRateCount: 0,
};

const projectsSlice = createSlice({
    name: "projects",
    initialState,
    reducers: {
        setCurrency: (state, action: PayloadAction<IRate>) => {
            state.activeCurrency = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCurrencyRates.pending, (state) => {
                state.rateTableIsLoading = true;
            })
            .addCase(getCurrencyRates.fulfilled, (state, action) => {
                state.currencyRatesList = action.payload.data;
                state.totalRateCount = action.payload.metadata.totalItemCount;
                state.rateTableIsLoading = false;
            })
            .addCase(updateRate.pending, (state) => {
                state.rateIsLoading = true;
            })
            .addCase(updateRate.fulfilled, (state) => {
                state.rateIsLoading = false;
            })
            .addCase(getActiveRates.fulfilled, (state, action) => {
                state.activeRates = action.payload;
            })
            .addCase(createRate.pending, (state) => {
                state.rateIsLoading = true;
            })
            .addCase(createRate.fulfilled, (state) => {
                state.rateIsLoading = false;
            });
    },
});

export const { setCurrency } = projectsSlice.actions;

export default projectsSlice.reducer;
