import axiosApi from "core/request";
import dayjs, { Dayjs } from "dayjs";

import { createAsyncThunk } from "@reduxjs/toolkit";

export const getContractsEmployees = createAsyncThunk(
    "contractsEmployees/getContractsEmployees",
    async (
        contractsEmployeesQuery: {
            search?: string;
            pageSize?: number;
            pageNumber: number;
            organizationId?: number;
            sortBy?: { column: string | null; direction: string | null };
            status?: string;
            fromStartDate?: Dayjs | null;
            beforeStartDate?: Dayjs | null;
        },
        { rejectWithValue },
    ) => {
        try {
            const params = new URLSearchParams();

            if (contractsEmployeesQuery.search) {
                params.append(
                    "filter.fullName",
                    contractsEmployeesQuery.search,
                );
            }

            if (contractsEmployeesQuery.pageSize) {
                params.append(
                    "pageSize",
                    String(contractsEmployeesQuery.pageSize),
                );
            }

            if (contractsEmployeesQuery.pageNumber) {
                params.append(
                    "pageNumber",
                    String(contractsEmployeesQuery.pageNumber),
                );
            }

            if (contractsEmployeesQuery.organizationId) {
                params.append(
                    "filter.organizationId",
                    String(contractsEmployeesQuery.organizationId),
                );
            }

            if (contractsEmployeesQuery.status) {
                params.append("filter.status", contractsEmployeesQuery.status);
            }

            if (contractsEmployeesQuery.fromStartDate) {
                params.append(
                    "filter.fromStartDate",
                    dayjs(contractsEmployeesQuery.fromStartDate).format(
                        "MM.DD.YYYY",
                    ),
                );
            }

            if (contractsEmployeesQuery.beforeStartDate) {
                params.append(
                    "filter.beforeStartDate",
                    dayjs(contractsEmployeesQuery.beforeStartDate).format(
                        "MM.DD.YYYY",
                    ),
                );
            }

            if (
                contractsEmployeesQuery.sortBy?.column &&
                contractsEmployeesQuery.sortBy.direction
            ) {
                const column = contractsEmployeesQuery.sortBy?.column;
                const direction = contractsEmployeesQuery.sortBy.direction;
                params.append("sortBy", `${column}.${direction}`);
            }

            const response = await axiosApi.get("/contracts/all", { params });
            return response.data;
        } catch (error) {
            return rejectWithValue("Error");
        }
    },
);

export const getContractsDetailsToView = createAsyncThunk(
    "contractsEmployees/getContractsDetailsToView",
    async (contractId: number, { rejectWithValue }) => {
        try {
            const response = await axiosApi.get(
                `/contracts/contractsbyid?id=${contractId}`,
            );
            return response.data;
        } catch (error) {
            return rejectWithValue("Error");
        }
    },
);

export const createContract = createAsyncThunk(
    "contractsEmployees/createContract",
    async (contractData: any, { rejectWithValue }) => {
        try {
            const response = await axiosApi.post("/contracts", contractData);
            return response.data;
        } catch (error) {
            return rejectWithValue("Error");
        }
    },
);

export const getContractById = createAsyncThunk(
    "contractsEmployees/getContractById",
    async (contractId: number, { rejectWithValue }) => {
        try {
            const response = await axiosApi.get(
                `contracts/details?id=${contractId}`,
            );
            return response.data;
        } catch (error) {
            return rejectWithValue("Error");
        }
    },
);

export const getMainContractById = createAsyncThunk(
    "contractsEmployees/getMainContractById",
    async (contractId: number, { rejectWithValue }) => {
        try {
            const response = await axiosApi.get(
                `contracts/details?id=${contractId}`,
            );
            return response.data;
        } catch (error) {
            return rejectWithValue("Error");
        }
    },
);

export const editContract = createAsyncThunk(
    "contractsEmployees/editContract",
    async (contractData: any, { rejectWithValue }) => {
        try {
            const response = await axiosApi.put("contracts", contractData);
            return response.data;
        } catch (error) {
            return rejectWithValue("Error");
        }
    },
);

export const addAdditionToContract = createAsyncThunk(
    "contractsEmployees/addAdditionToContract",
    async (contractData: any, { rejectWithValue }) => {
        try {
            const response = await axiosApi.post("contracts", contractData);
            return response.data;
        } catch (error) {
            return rejectWithValue("Error");
        }
    },
);

export const editContractAddition = createAsyncThunk(
    "contractsEmployees/editContractAddition",
    async (contractData: any, { rejectWithValue }) => {
        try {
            const response = await axiosApi.put("contracts", contractData);
            return response.data;
        } catch (error) {
            return rejectWithValue("Error");
        }
    },
);
