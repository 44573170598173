import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { IHeaderParams } from 'ag-grid-community';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';

import { getFromLocalStorage } from '../../../../core/utils';
import AgGrid from '../../../../features/AgGrid/AgGrid';
import { mergeArrays } from '../../../../helpers/mergerArrays';
import { sortByProp } from '../../../../helpers/sortByProp';
import { useAppSelector } from '../../../../hooks';
import { HeadComponents, useHeadComponents } from '../../../../hooks/useHeadComponents';
import { currency } from '../../../../models/catalogs';
import { setCurrency } from '../../../../store/slices/rateSlice';
import { materialReactTableCell, materialReactTableHeader } from '../../../../styles/MUIStyles';
import Loader from '../../../../ui/Loader';

import './styles.css';

const TOTAL_COLUMN = 4;

interface Props {
    toggleStoryTable: boolean;
    rateFilters: any;
    selectedCurrencyName: string | null;
}

export const Table: FC<Props> = ({ toggleStoryTable, rateFilters, selectedCurrencyName }) => {
    const refContainer = useRef<HTMLDivElement>(null);
    const [widthContainer, setWidthContainer] = useState(() => refContainer.current?.clientWidth);
    const { t } = useTranslation();
    const headVariants = useHeadComponents();
    const currencyList = useAppSelector(state => state.rate.currencyRatesList);
    const copyCurrencyList = rateFilters['filter.currencyId']
        ? currencyList.filter((item) => item.currencyCode === selectedCurrencyName)
        : currencyList;
    const activeCurrency = useAppSelector(state => state.rate.activeCurrency);
    const activeRates = useAppSelector(state => state.rate.activeRates);
    const rateTableIsLoading = useAppSelector(state => state.rate.rateTableIsLoading);
    const activeRatesCopy: currency[] = sortByProp([...activeRates], 'currencyCode');

    useEffect(() => {
        setWidthContainer(refContainer.current?.clientWidth);
    }, [refContainer]);

    const filterRates = useCallback((currencyList: currency[]) => {
        const elements = {} as currency;
        currencyList.forEach(item => {
            if (!elements[item.currencyCode]) {
                elements[item.currencyCode] = [item];
            } else {
                elements[item.currencyCode].push(item);

            }
        });
        return elements;
    }, []);

    const getStoryRates = (currencyList: currency[]) => {
        const combinedArray: currency[] = sortByProp((mergeArrays(currencyList, activeRatesCopy) as currency[]), 'currencyCode');
        const filteredItems = filterRates(combinedArray as currency[]);
        const elements = rateFilters?.['filter.currencyId'] && selectedCurrencyName
            ? ({
                selectedCurrencyName: filteredItems[selectedCurrencyName]?.filter((item: currency) => item.currencyCode === selectedCurrencyName)
            })
            : filteredItems;

        return Object.values(elements).map((item: currency[] = []) => {
            const activeRate = item.filter(i => i.isActive);

            const sorted: currency[] = item.sort((a: currency, b: currency) => new Date(b.rateDate).getTime() - new Date(a.rateDate).getTime());

            if (sorted.length > 1) {
                const modified = sorted.slice(1, sorted.length).map(({ rateDate, rate }) => {
                    return ({ rateDate, rate });
                });
                return [...activeRate, ...modified];
            }

            return sortByProp(sorted, 'currencyCode');
        });
    };

    const recentDates = rateFilters['filter.currencyId'] && selectedCurrencyName
        ? activeRatesCopy.filter((item) => item.currencyCode === selectedCurrencyName)
        : activeRatesCopy;

    const storyDates = getStoryRates(copyCurrencyList).flat();

    const generateColumnDefs = () => {
        const savedColumnState = getFromLocalStorage('agGridProjectsTable');

        let updatedColumnDefs: any[] = [
            {
                field: 'currencyCode',
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: widthContainer ? (widthContainer / TOTAL_COLUMN) : 180,
            },
            {
                field: 'name',
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: widthContainer ? (widthContainer / TOTAL_COLUMN) : 180,
            },
            {
                field: 'rate',
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: widthContainer ? (widthContainer / TOTAL_COLUMN) : 180,
            },
            {
                field: 'rateDate',
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: widthContainer ? (widthContainer / TOTAL_COLUMN) : 180,
            },
        ];

        if (Array.isArray(savedColumnState)) {
            updatedColumnDefs = savedColumnState.map((columnState: any) => {
                return {
                    field: columnState.colId,
                    width: columnState.width,
                    pinned: columnState.pinned,
                    headerComponent: HeadComponent,
                    cellRenderer: RowComponent,
                    resizable: true,
                };
            });
        }

        return updatedColumnDefs;
    };

    const RowComponent = (p: any) => {
        switch (p.colDef.field) {
            case 'currencyCode': {
                return <Box sx={materialReactTableCell}>{p.data.currencyCode}</Box>;
            }
            case 'isActive': {
                return (
                    <Box
                        sx={{
                            color: p.data.isActive ? '#237657' : '#FA0E00',
                            fontWeight: '600',
                            ...materialReactTableCell,
                        }}
                    >
                        {p.data.isActive
                            ? t('statuses.Active')
                            : t('statuses.Inactive')}
                    </Box>
                );
            }
            case 'name': {
                return <Box sx={materialReactTableCell}>{p.data.name}</Box>;
            }
            case 'rate': {
                return <Box sx={{
                    ...materialReactTableCell,
                    fontWeight: (toggleStoryTable && p.data.isActive) ? '600' : '500'
                }}>{p.data.rate}</Box>;
            }
            case 'rateDate': {
                return <Box sx={{
                    ...materialReactTableCell,
                    fontWeight: (toggleStoryTable && p.data.isActive) ? '600' : '500'
                }}
                >
                    {dayjs(p.data.rateDate).format('DD.MM.YYYY HH:mm')}
                </Box>;
            }
        }
    };

    const HeadComponent = (p: IHeaderParams) => {
        switch (p.column.getColId()) {
            case 'name': {
                return (
                    <Box sx={materialReactTableHeader}>
                        <span>{t('data.description')}</span>
                    </Box>
                );
            }
        }

        return headVariants[p.column.getColId() as keyof HeadComponents];
    };

    const columnDefs: any[] = generateColumnDefs();

    const saveColumnState = (columnState: any) => {
        localStorage.setItem(
            'agGridProjectsTable',
            JSON.stringify(columnState)
        );
    };

    const gridOptions = {
        suppressScrollOnNewData: true,
    };

    return (
        <div>
            <div ref={refContainer}>
                <div className="grid-wrapper">
                    {rateTableIsLoading ? <Loader/> : (
                        <AgGrid
                            gridOptions={gridOptions}
                            rowData={toggleStoryTable ? storyDates : recentDates}
                            columnDefs={columnDefs}
                            saveColumnState={saveColumnState}
                            activeRow={activeCurrency}
                            setActiveRow={setCurrency}
                            height="65vh"
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
