import { ICatalogs, Skill } from "models/catalogs";
import {
    activateDataByCatalogName,
    createCurrency,
    createDataByCatalogName,
    createDataWithParentByCatalogName,
    deactivateDataByCatalogName,
    editCurrency,
    getCurrencyList,
    getDataByCatalogName,
} from "store/thunks/catalogsThunk";

import { AnyAction, createSlice } from "@reduxjs/toolkit";

import { ICountry } from "../../models/countries";
import { grades } from "../../models/grades";
import { revertAll } from "../store";
import {
    createSkill,
    createSkillLevels,
    editSkill,
    editSkillLevels,
    getSkillLevels,
    getSkills,
} from "../thunks";
import {
    createCountry,
    createGrade,
    createTimeZone,
    editCountry,
    editGrade,
    editTimeZone,
    getCountries,
    getGrades,
    getTimeZones,
} from "../thunks";

const initialState: ICatalogs = {
    dataByCatalogName: [],
    grades: [],
    timeZones: [],
    countries: [],
    currencyList: [],
    skills: [],
    skillLevels: [],
    typeOfEmployment: [],
    positions: [],
    guilds: [],
    costCenters: [],
    isCreatingOrUpdatingLoading: false,
    isActivationLoading: false,
    isDeactivationLoading: false,
    catalogsDataByNameErrors: null,
    isFirstLoading: false,
    timeZoneErrors: null,
    gradeErrors: null,
    countryErrors: null,
    currencyErrors: null,
    skillsErrors: null,
    skillLevelsErrors: null,
    catalogsWithParentIdErrors: null,
};

const catalogSlice = createSlice({
    name: "catalogs",
    initialState,
    reducers: {
        firstLoadingHandler: (state, action) => {
            state.isFirstLoading = action.payload;
        },
        clearCatalogTextFieldErrors: (state) => {
            state.catalogsDataByNameErrors = null;
            state.timeZoneErrors = null;
            state.gradeErrors = null;
            state.countryErrors = null;
            state.currencyErrors = null;
            state.skillsErrors = null;
            state.skillLevelsErrors = null;
            state.catalogsWithParentIdErrors = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(revertAll, () => initialState)
            //**пометка** Скорее всего можно вынести в AddMatcher, и делать сортировку там
            .addCase(getDataByCatalogName.fulfilled, (state, action) => {
                state.dataByCatalogName = [...action.payload.data].sort(
                    (a: any, b: any) =>
                        a.name.localeCompare(
                            b.name,
                            localStorage.getItem("i18nextLng") === "ru-RU"
                                ? "ru"
                                : "en",
                        ),
                );

                switch (action.payload.catalogName) {
                    case "typeOfEmployments":
                        state.typeOfEmployment = action.payload.data;
                        break;
                    case "positions":
                        state.positions = action.payload.data;
                        break;
                    case "guilds":
                        state.guilds = action.payload.data;
                        break;
                    case "costCenters":
                        state.costCenters = action.payload.data;
                        break;
                    case "countries":
                        state.countries = action.payload.data;
                        break;
                    default:
                        break;
                }
            })
            .addCase(createDataByCatalogName.rejected, (state, action) => {
                if (action.payload) {
                    state.catalogsDataByNameErrors = action.payload;
                }
            })
            .addCase(
                createDataWithParentByCatalogName.rejected,
                (state, action) => {
                    if (action.payload) {
                        state.catalogsWithParentIdErrors = action.payload;
                    }
                },
            )
            .addCase(activateDataByCatalogName.pending, (state) => {
                state.isActivationLoading = true;
            })
            .addCase(activateDataByCatalogName.fulfilled, (state) => {
                state.isActivationLoading = false;
            })
            .addCase(activateDataByCatalogName.rejected, (state) => {
                state.isActivationLoading = false;
            })
            .addCase(deactivateDataByCatalogName.pending, (state) => {
                state.isDeactivationLoading = true;
            })
            .addCase(deactivateDataByCatalogName.fulfilled, (state) => {
                state.isDeactivationLoading = false;
            })
            .addCase(deactivateDataByCatalogName.rejected, (state) => {
                state.isDeactivationLoading = false;
            })
            .addCase(getGrades.fulfilled, (state, action) => {
                state.grades = [...action.payload].sort(
                    (a: grades, b: grades) =>
                        Number(a.gradeLevel) - Number(b.gradeLevel),
                );
            })
            .addCase(createGrade.rejected, (state, action) => {
                if (action.payload) {
                    state.gradeErrors = action.payload;
                }
            })
            .addCase(editGrade.rejected, (state, action) => {
                if (action.payload) {
                    state.gradeErrors = action.payload;
                }
            })
            .addCase(getTimeZones.fulfilled, (state, action) => {
                state.timeZones = action.payload;
            })
            .addCase(createTimeZone.pending, (state) => {
                state.timeZoneErrors = null;
            })
            .addCase(createTimeZone.rejected, (state, action) => {
                if (action.payload) {
                    state.timeZoneErrors = action.payload;
                }
            })
            .addCase(editTimeZone.pending, (state) => {
                state.timeZoneErrors = null;
            })
            .addCase(editTimeZone.rejected, (state, action) => {
                if (action.payload) {
                    state.timeZoneErrors = action.payload;
                }
            })
            //**пометка** Скорее всего можно вынести в AddMatcher, и делать сортировка там
            .addCase(getCountries.fulfilled, (state, action) => {
                state.countries = [...action.payload].sort(
                    (a: ICountry, b: ICountry) =>
                        a.name.localeCompare(
                            b.name,
                            localStorage.getItem("i18nextLng") === "ru-RU"
                                ? "ru"
                                : "en",
                        ),
                );
            })
            .addCase(createCountry.rejected, (state, action) => {
                if (action.payload) {
                    state.countryErrors = action.payload;
                }
            })
            .addCase(editCountry.rejected, (state, action) => {
                if (action.payload) {
                    state.countryErrors = action.payload;
                }
            })
            .addCase(getCurrencyList.fulfilled, (state, action) => {
                state.currencyList = action.payload;
            })
            //**пометка** Скорее всего можно вынести в AddMatcher, и делать сортировку там
            .addCase(getSkills.fulfilled, (state, action) => {
                state.skills = state.countries = [...action.payload].sort(
                    (a: Skill, b: Skill) =>
                        a.name
                            .replace(".", "")
                            .localeCompare(
                                b.name.replace(".", ""),
                                localStorage.getItem("i18nextLng") === "ru-RU"
                                    ? "ru"
                                    : "en",
                            ),
                );
            })
            .addCase(createSkill.rejected, (state, action) => {
                if (action.payload) {
                    state.skillsErrors = action.payload;
                }
            })
            .addCase(editSkill.rejected, (state, action) => {
                if (action.payload) {
                    state.skillsErrors = action.payload;
                }
            })
            .addCase(createSkillLevels.rejected, (state, action) => {
                if (action.payload) {
                    state.skillLevelsErrors = action.payload;
                }
            })
            .addCase(editSkillLevels.rejected, (state, action) => {
                if (action.payload) {
                    state.skillLevelsErrors = action.payload;
                }
            })
            .addCase(getSkillLevels.fulfilled, (state, action) => {
                state.skillLevels = action.payload;
            })
            .addCase(createCurrency.rejected, (state, action) => {
                if (action.payload) {
                    state.currencyErrors = action.payload;
                }
            })
            .addCase(editCurrency.rejected, (state, action) => {
                if (action.payload) {
                    state.currencyErrors = action.payload;
                }
            })
            .addMatcher(isCreateOrEditPending, (state) => {
                state.isCreatingOrUpdatingLoading = true;
                // state.catalogsDataByNameErrors = null;
            })
            .addMatcher(isCreateOrEditFulfilled, (state) => {
                state.isCreatingOrUpdatingLoading = false;
            })
            .addMatcher(isCreateOrEditRejected, (state) => {
                state.isCreatingOrUpdatingLoading = false;
            });
    },
});

const hasCatalogsCreateOrEditPrefix = (action: AnyAction): boolean => {
    return (
        action.type.split("/")[1].slice(0, 4) === "edit" ||
        action.type.split("/")[1].slice(0, 6) === "create"
    );
};

const isCreateOrEditPending = (action: AnyAction) => {
    return (
        action.type.startsWith("catalogs") &&
        action.type.endsWith("pending") &&
        hasCatalogsCreateOrEditPrefix(action)
    );
};

const isCreateOrEditFulfilled = (action: AnyAction) => {
    return (
        action.type.startsWith("catalogs") &&
        action.type.endsWith("fulfilled") &&
        hasCatalogsCreateOrEditPrefix(action)
    );
};

const isCreateOrEditRejected = (action: AnyAction) => {
    return (
        action.type.startsWith("catalogs") &&
        action.type.endsWith("rejected") &&
        hasCatalogsCreateOrEditPrefix(action)
    );
};

export const { firstLoadingHandler, clearCatalogTextFieldErrors } =
    catalogSlice.actions;

export default catalogSlice.reducer;
