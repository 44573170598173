import React, { FC, memo, useEffect } from 'react';
import editIcon from 'assets/icons/hoverEditIcon.svg';
import lockedIcon from 'assets/icons/locked.svg';
import unlocked from 'assets/icons/unlocked.svg';
import viewIcon from 'assets/icons/view.svg';
import { getFromLocalStorage } from 'core/utils';
import dayjs from 'dayjs';
import AgGrid from 'features/AgGrid/AgGrid';
import Guard from 'features/Guard';
import { useAppDispatch, useAppSelector } from 'hooks';
import { IProjectsFilters } from 'models/projects';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { EMPLOYEES_PATH } from 'router/constants';
import { setProject } from 'store';
import { getProjects } from 'store/thunks/projectsThunk';
import { materialReactTableCell, materialReactTableHeader, } from 'styles/MUIStyles';
import BootstrapTooltip from 'ui/Tooltip';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Box from '@mui/material/Box';

interface Props {
    setProjectsPageNumber: (pageNumber: number) => void;
    debouncedSearch: string;
    projectsPageNumber: number;
    isProjectsSearchingRef: {
        current: boolean | null;
    };
    projectsSortConfig: {
        column: string | null;
        direction: string | null;
    };
    setIsActivateOrDeactivateModalOpen: (isOpen: boolean) => void;
    projectsHandleColumnClick: any;
}

const ProjectsTable: FC<Props> = memo((props) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const {
        debouncedSearch,
        setProjectsPageNumber,
        setIsActivateOrDeactivateModalOpen,
        isProjectsSearchingRef,
        projectsSortConfig,
        projectsHandleColumnClick,
        projectsPageNumber,
    } = props;

    const allowedPermissions = useAppSelector(
        (state) => state.auth.allowedPermissions,
    );
    const projects = useAppSelector((state) => state.projects.projects);
    const activeProject = useAppSelector(
        (state) => state.projects.activeProject,
    );
    const allowedMenus = useAppSelector((state) => state.auth.allowedMenus);

    // const isGettingProjectsLoading = useAppSelector(
    //     (state) => state.projects.isGettingProjectsLoading
    // );
    // const isActivateOrDeactivateProjectLoading = useAppSelector(
    //     (state) => state.projects.isActivateOrDeactivateProjectLoading
    // );

    const getArrowForSortDirection = (column: string) => {
        if (projectsSortConfig.column !== column) {
            return null;
        }

        return projectsSortConfig.direction === 'asc' ? (
            <ArrowUpwardIcon sx={{ color: '#605E5C' }}/>
        ) : (
            <ArrowDownwardIcon sx={{ color: '#605E5C' }}/>
        );
    };

    const getProjectStatusColor = (status: number) => {
        switch (status) {
            case 1:
                return '#BEBEBE';
            case 2:
                return '#25c00e';
            case 3:
                return '#BEBEBE';
            case 4:
                return '#FA0E00';
            case 5:
                return '#BEBEBE';
            default:
                return '#237657';
        }
    };

    const getProjectStatusText = (status: number) => {
        switch (status) {
            case 1:
                return t('statuses.Preparation');
            case 2:
                return t('statuses.InProgress');
            case 3:
                return t('statuses.OnHold');
            case 4:
                return t('statuses.Cancelled');
            case 5:
                return t('statuses.Completed');
            default:
                return '';
        }
    };

    useEffect(() => {
        const projectsFilters =
            getFromLocalStorage<IProjectsFilters>('projectsFilters');
        const projectsPageSize =
            getFromLocalStorage<number>('projectsPageSize');

        if (isProjectsSearchingRef.current && projectsPageNumber > 1) {
            setProjectsPageNumber(1);
        } else {
            dispatch(
                getProjects({
                    pageNumber: projectsPageNumber,
                    pageSize: 100,
                    searchProject: debouncedSearch,
                    client: projectsFilters?.client,
                    employee: projectsFilters?.employee,
                    startFTE: projectsFilters?.startFTE,
                    endFTE: projectsFilters?.endFTE,
                    selectedStatuses: projectsFilters?.selectedStatuses,
                    fromStartDate: projectsFilters?.fromStartDate,
                    beforeStartDate: projectsFilters?.beforeStartDate,
                    fromEndDate: projectsFilters?.fromEndDate,
                    beforeEndDate: projectsFilters?.beforeEndDate,
                    sortBy: projectsSortConfig,
                }),
            );
        }
        isProjectsSearchingRef.current = false;
    }, [
        dispatch,
        debouncedSearch,
        setProjectsPageNumber,
        isProjectsSearchingRef,
        projectsPageNumber,
        projectsSortConfig,
        t,
    ]);

    const RowComponent = (p: any) => {
        switch (p.colDef.field) {
            case 'customActions':
                return (
                    <Box
                        onClick={() => {
                            dispatch(setProject(p.data));
                        }}
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-evenly',
                            alignItems: 'center',
                            '& img': {
                                cursor: 'pointer',
                            },
                        }}
                    >
                        <Guard
                            allowedPermissions={allowedPermissions}
                            permissionName="View"
                        >
                            <BootstrapTooltip
                                title={t('buttons.View')}
                                onClick={() => navigate(`view/${p.data.id}`)}
                                placement="top"
                            >
                                <img src={viewIcon} alt="view icon"/>
                            </BootstrapTooltip>
                        </Guard>
                        <Guard
                            allowedPermissions={allowedPermissions}
                            permissionName="Edit"
                        >
                            <BootstrapTooltip
                                onClick={() =>
                                    navigate(`management/edit/${p.data.id}`)
                                }
                                title={t('buttons.Edit')}
                                placement="top"
                            >
                                <img src={editIcon} alt="edit icon"/>
                            </BootstrapTooltip>
                        </Guard>
                        {p.data.isActive ? (
                            <Guard
                                allowedPermissions={allowedPermissions}
                                permissionName="Deactivate"
                            >
                                <BootstrapTooltip
                                    title={t('buttons.Deactivate')}
                                    onClick={() => {
                                        setIsActivateOrDeactivateModalOpen(
                                            true,
                                        );
                                        dispatch(setProject(p.data));
                                    }}
                                    placement="top"
                                >
                                    <img
                                        src={lockedIcon}
                                        alt="deactivate icon"
                                    />
                                </BootstrapTooltip>
                            </Guard>
                        ) : (
                            <Guard
                                allowedPermissions={allowedPermissions}
                                permissionName="Activate"
                            >
                                <BootstrapTooltip
                                    title={t('buttons.Activate')}
                                    onClick={() => {
                                        setIsActivateOrDeactivateModalOpen(
                                            true,
                                        );
                                        dispatch(setProject(p.data));
                                    }}
                                    placement="top"
                                >
                                    <img src={unlocked} alt="activate icon"/>
                                </BootstrapTooltip>
                            </Guard>
                        )}
                    </Box>
                );
            case 'customManagers':
                return (
                    <Box sx={materialReactTableCell}>
                        {p.data.managers.map((manager: any) => {
                            return (
                                <Link
                                    to={`/${EMPLOYEES_PATH}/${manager.id}`}
                                    key={manager.id}
                                >
                                    {manager.fullName}
                                </Link>
                            );
                        })}
                    </Box>
                );
            case 'code':
                return <Box sx={materialReactTableCell}>{p.data.code}</Box>;
            case 'name':
                return <Box sx={materialReactTableCell}>{p.data.name}</Box>;
            case 'client':
                return (
                    <Box
                        sx={materialReactTableCell}
                        onClick={() =>
                            localStorage.setItem(
                                'redirectOrganizationId',
                                p.data.clientId,
                            )
                        }
                    >
                        {allowedMenus.some(
                            (menu) => menu.name === 'Organizations',
                        ) ? (
                            <Link to="/organizations">{p.data.client}</Link>
                        ) : (
                            p.data.client
                        )}
                    </Box>
                );
            case 'fte':
                return <Box sx={materialReactTableCell}>{p.data.fte}</Box>;
            case 'projectStatus':
                return (
                    <Box
                        sx={{
                            color: getProjectStatusColor(p.data.projectStatus),
                            fontWeight: '600',
                            ...materialReactTableCell,
                        }}
                    >
                        {getProjectStatusText(p.data.projectStatus)}
                    </Box>
                );
            case 'startDate':
                return (
                    <Box sx={materialReactTableCell}>
                        {p.data.startDate
                            ? dayjs(p.data.startDate).format('DD.MM.YYYY')
                            : null}
                    </Box>
                );
            case 'finishDate':
                return (
                    <Box sx={materialReactTableCell}>
                        {p.data.finishDate
                            ? dayjs(p.data.finishDate).format('DD.MM.YYYY')
                            : null}
                    </Box>
                );
            case 'isActive':
                return (
                    <Box
                        sx={{
                            color: p.data.isActive ? '#237657' : '#FA0E00',
                            fontWeight: '600',
                            ...materialReactTableCell,
                        }}
                    >
                        {p.data.isActive
                            ? t('statuses.Active')
                            : t('statuses.Inactive')}
                    </Box>
                );
            default:
                return null;
        }
    };

    const HeadComponent = (p: any) => {
        switch (p.column.colId) {
            case 'customActions':
                return (
                    <Box sx={materialReactTableHeader}>
                        <span>{t('data.actions')}</span>
                    </Box>
                );
            case 'code':
                return (
                    <Box
                        sx={materialReactTableHeader}
                        onClick={() => projectsHandleColumnClick('code')}
                    >
                        <span>{t('data.code')}</span>
                        {getArrowForSortDirection('code')}
                    </Box>
                );
            case 'name':
                return (
                    <Box
                        sx={materialReactTableHeader}
                        onClick={() => projectsHandleColumnClick('name')}
                    >
                        <span>{t('data.name')}</span>
                        {getArrowForSortDirection('name')}
                    </Box>
                );
            case 'client':
                return (
                    <Box
                        sx={materialReactTableHeader}
                        onClick={() => projectsHandleColumnClick('client')}
                    >
                        <span>{t('data.client')}</span>
                        {getArrowForSortDirection('client')}
                    </Box>
                );
            case 'fte':
                return (
                    <Box
                        sx={materialReactTableHeader}
                        onClick={() => projectsHandleColumnClick('fte')}
                    >
                        <span>{t('data.fte')}</span>
                        {getArrowForSortDirection('fte')}
                    </Box>
                );
            case 'customManagers':
                return (
                    <Box sx={materialReactTableHeader}>
                        <span>{t('data.managers')}</span>
                    </Box>
                );
            case 'startDate':
                return (
                    <Box
                        sx={materialReactTableHeader}
                        onClick={() => projectsHandleColumnClick('startDate')}
                    >
                        <span>{t('data.startDate')}</span>
                        {getArrowForSortDirection('startDate')}
                    </Box>
                );
            case 'finishDate':
                return (
                    <Box
                        sx={materialReactTableHeader}
                        onClick={() => projectsHandleColumnClick('finishDate')}
                    >
                        <span>{t('data.finishDate')}</span>
                        {getArrowForSortDirection('finishDate')}
                    </Box>
                );
            case 'projectStatus':
                return (
                    <Box
                        sx={materialReactTableHeader}
                        onClick={() =>
                            projectsHandleColumnClick('projectStatus')
                        }
                    >
                        <span>{t('data.projectStatus')}</span>
                        {getArrowForSortDirection('projectStatus')}
                    </Box>
                );
            case 'isActive':
                return (
                    <Box
                        sx={materialReactTableHeader}
                        onClick={() => projectsHandleColumnClick('isActive')}
                    >
                        <span>{t('data.status')}</span>
                        {getArrowForSortDirection('isActive')}
                    </Box>
                );
            default:
                return null;
        }
    };

    const generateColumnDefs = () => {
        const savedColumnState = getFromLocalStorage('agGridProjectsTable');

        let updatedColumnDefs: any[] = [
            {
                field: 'customActions',
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 110,
            },
            {
                field: 'name',
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 130,
            },
            {
                field: 'client',
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 120,
            },
            {
                field: 'fte',
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 80,
            },
            {
                field: 'customManagers',
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 130,
            },
            {
                field: 'code',
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 100,
            },
            {
                field: 'projectStatus',
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 130,
            },
            {
                field: 'startDate',
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 115,
            },
            {
                field: 'finishDate',
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 115,
            },
            {
                field: 'isActive',
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 100,
            },
        ];

        if (Array.isArray(savedColumnState)) {
            updatedColumnDefs = savedColumnState.map((columnState: any) => {
                return {
                    field: columnState.colId,
                    width: columnState.width,
                    pinned: columnState.pinned,
                    headerComponent: HeadComponent,
                    cellRenderer: RowComponent,
                    resizable: true,
                };
            });
        }

        return updatedColumnDefs;
    };

    const saveColumnState = (columnState: any) => {
        localStorage.setItem(
            'agGridProjectsTable',
            JSON.stringify(columnState),
        );
    };

    const columnDefs: any[] = generateColumnDefs();

    return (
        <AgGrid
            rowData={projects}
            columnDefs={columnDefs}
            saveColumnState={saveColumnState}
            activeRow={activeProject}
            setActiveRow={setProject}
            height="65vh"
        />
    );
});

export default ProjectsTable;
