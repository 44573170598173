import { FC, PropsWithChildren } from 'react';
import cn from 'classnames';

import styles from './styles.module.css';

export enum ETextVariant {
    REGULAR = 'REGULAR',
    SMALL = 'SMALL',
    MEDIUM = 'MEDIUM',
    BOLD = 'BOLD',
}

interface TextProps {
    variant: ETextVariant;
    size?: number;
    className?: string;
    style?: object;
}

export const Text: FC<PropsWithChildren<TextProps>> = (props) => {
    const {
        variant,
        children,
        size,
        className,
        style
    } = props;

    return (
        <p
            style={{
                fontSize: size,
                ...style
            }}
            className={cn(
                styles.text,
                {
                    [styles.small]: variant === ETextVariant.SMALL,
                    [styles.regular]: variant === ETextVariant.REGULAR,
                    [styles.medium]: variant === ETextVariant.MEDIUM,
                    [styles.bold]: variant === ETextVariant.BOLD,
                },
                className
            )}
        >
            {children}
        </p>
    );
};
