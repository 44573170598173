import axios from "axios";

import { createAsyncThunk } from "@reduxjs/toolkit";

import axiosApi from "../../core/request";
import { IGradesArgs, IGradesErrResponse } from "../../models/grades";

export const getGrades = createAsyncThunk(
    'catalogs/getGrades', async (gradesEndpointName: string, { rejectWithValue }) => {
        try {
            const response = await axiosApi.get(`/${gradesEndpointName}`);
            return response.data;
        } catch (error) {
            //TODO
            return rejectWithValue('Error that i need to handle');
        }
    }
);

export const createGrade = createAsyncThunk<any, IGradesArgs, {rejectValue: IGradesErrResponse | string}>(
    'catalogs/createGrade', async (grade, { rejectWithValue }) => {
        try {
            const formattedGrade = { ...grade };
            delete formattedGrade.gradesEndpointName;
            const response = await axiosApi.post(`/${grade.gradesEndpointName}`, { ...formattedGrade });
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                if (error.response.status === 400) {
                    return rejectWithValue(error.response.data.errors);
                } else {
                    return rejectWithValue('Что-то пошло не так... Попробуйте обновить страницу');
                }
            }

            if (!navigator.onLine) {
                return rejectWithValue('Проверьте подключение к интернету');
            }

            return rejectWithValue('Что-то пошло не так... Попробуйте обновить страницу');
        }
    }
);

export const editGrade = createAsyncThunk<any, IGradesArgs, {rejectValue: IGradesErrResponse | string}>("catalogs/editGrade",
    async (grade: IGradesArgs, { rejectWithValue }) => {
        try {
            const formattedGrade = { ...grade };
            delete formattedGrade.gradesEndpointName;

            const response = await axiosApi.put(`/${grade.gradesEndpointName}`, { ...formattedGrade });
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                if (error.response.status === 400) {
                    return rejectWithValue(error.response.data.errors);
                } else {
                    return rejectWithValue('Что-то пошло не так... Попробуйте обновить страницу');
                }
            }

            if (!navigator.onLine) {
                return rejectWithValue('Проверьте подключение к интернету');
            }

            return rejectWithValue('Что-то пошло не так... Попробуйте обновить страницу');
        }
    }
);
