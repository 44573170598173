import React from "react";

import Button, { ButtonProps } from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import styles from "./ButtonWithProgress.module.scss";

interface IButtonWithProgress extends ButtonProps {
    children: React.ReactNode;
    loading: boolean;
}

const ButtonWithProgress: React.FC<IButtonWithProgress> = ({
    children,
    loading,
    ...props
}): JSX.Element => {
    return (
        <Button className={styles.wrapper} {...props}>
            {children}
            {loading && (
                <CircularProgress size={20} className={styles.buttonProgress} />
            )}
        </Button>
    );
};

export default ButtonWithProgress;
